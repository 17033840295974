/* eslint-disable unused-imports/no-unused-imports */
import { OnInit, EventEmitter, OnDestroy, Component, Output, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { OutreachContent, Language } from 'src/app/data/model';
import { OutreachContentService } from 'src/app/services/outreach-content.service';
import { PersonsService } from '../../services/persons.service';
import { EndpointType } from 'src/app/data/class';
import { removeLink, createLink } from 'src/app/data/links';

@Component({
  selector: 'app-outreach-content-modal',
  templateUrl: './outreach-content-modal.component.html',
  styleUrls: ['./outreach-content-modal.component.scss'],
})
export class OutreachContentModalComponent implements OnInit, OnDestroy {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public outreachContent: OutreachContent;
  @Input() languages: Language[] = [];
  title: string;
  isEdit: boolean;
  outreachContentForm: UntypedFormGroup;
  errorMsg: string;
  faTimes = faTimes;
  selectedSegments: any[] = [];
  filteredSegments: any[] = [];
  endpointTypes: EndpointType[] = [];

  segmentCode: number | undefined;
  segmentId: number | undefined;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalHelper: NgbModal,
    private outreachContentService: OutreachContentService,
    private personsService: PersonsService
  ) {}
  ngOnDestroy(): void {
    removeLink();
  }

  ngOnInit(): void {
    createLink();
    this.title = this.outreachContent.contentGroup.name ? 'Edit outreach content' : 'Create outreach content';
    this.outreachContentForm = this.formBuilder.group({
      template: [this.outreachContent.template],
      subject: [this.outreachContent.subject, Validators.required],
      //segmentId: [{ value: this.outreachContent.segment?.id, disabled: false }, Validators.required],
      brandCode: [this.outreachContent.brandCode],
      languageId: [{ value: this.outreachContent.language?.id, disabled: false }, Validators.required],
      segmentsCollection: [{ value: this.segmentCode }, Validators.required],
    });
    if (this.outreachContent.segment) {
      this.segmentCode = this.outreachContent.segment.code;
      this.segmentId = this.outreachContent.segment.id;
    }
    this.getLeafNodes();
  }

  ok(): void {
    this.outreachContentForm.removeControl('segmentsCollection');
    this.outreachContentService
      .createOrUpdateOutreachContent({
        contentGroupId: this.outreachContent.contentGroup.id,
        id: this.outreachContent.id,
        segmentId: this.segmentId,
        languageId: this.outreachContent.language?.id,
        ...this.outreachContentForm.value,
      })
      .subscribe(
        () => {
          this.successEvent.emit('success');
        },
        (data: any) => {
          this.errorMsg = data.error.error;
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  updateSelectedSegments(segment: any): void {
    this.segmentCode = segment.code;
    this.segmentId = segment.id;
  }

  getLeafNodes(): void {
    this.personsService.getSegmentLeafNodes().subscribe((data: any) => {
      if (data.success) {
        this.filteredSegments = data.entity;
      }
    });
  }
}
