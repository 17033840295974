import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import {
  CONFIGURATIONS_BORDER_COLOR,
  CONFIGURATIONS_TEST_ID,
  CONFIGURATIONS_TITLE,
  DEFAULT_PARENT_NAME,
  PROGRAMS_BORDER_COLOR,
  PROGRAMS_TEST_ID,
  PROGRAMS_TITLE,
  SPONSORS_BORDER_COLOR,
  SPONSORS_TEST_ID,
  SPONSORS_TITLE,
} from './constants';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EntityPartyRoleName, EntityStatus } from '../../data/model';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { Filter, SearchFilters } from 'src/app/data/class';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { UserPreferencesService } from 'src/app/services/user-preferences.service';
import { Subscription } from 'rxjs';
import { OrganizationModalComponent } from '../organization-modal/organization-modal.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
})
export class OrganizationsComponent implements OnInit, OnDestroy {
  defaultParentName = DEFAULT_PARENT_NAME;
  EntityStatus = EntityStatus;
  routeParams;
  childEntityPartyRoleName = EntityPartyRoleName.CLIENT;
  parentOrganizationBasedOnChildType;
  organizationStatistics: any = [];
  searchFilters: SearchFilters;
  filter = new Filter();
  limit = 20;
  faPlus = faPlus;
  filterSubscription: Subscription;
  filterButton: boolean = false;
  appliedFilters: Filter[];
  filterdata: SearchFilters;
  searchText;
  isLoading = false;
  faPencilAlt = faPencilAlt;
  organizationList = [];
  lengthToCompare: number;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  lockedTables = false;
  parentId;
  clientId;
  lobId;
  marketId;

  constructor(
    private organizationsService: OrganizationsService,
    private route: ActivatedRoute,
    private statusHelperService: StatusHelperService,
    private toastr: ToastrService,
    private modalHelper: NgbModal,
    private title: Title,
    private router: Router,
    private applyfilterService: ApplyFilterService,
    private userPreferences: UserPreferencesService,
    private tableHelperService: TableHelperService
  ) {}

  statusHelper = this.statusHelperService.getStatus('badge');

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'allowed-contacts-search',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'keyword',
          placeholder: 'Search',
        },
        {
          name: 'statuses',
          placeholder: 'Select status',
          type: 'array',
          label: 'Status',
          choices: this.tableHelperService.getStatuses('DROPDOWN'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
          value: this.userPreferences.service.getPreference('communications.defaultFilterValues.status'),
        },
      ],
    };
    this.route.paramMap.subscribe((paramMap) => {
      this.routeParams = paramMap;
    });

    this.childEntityPartyRoleName = this.getType(this.routeParams.params);

    var parentIdForContext = this.getParentIdForContext(this.childEntityPartyRoleName, this.routeParams.params);
    this.setParamsFromRoute(this.routeParams.params);
    if (parentIdForContext) {
      this.getStatistics(parentIdForContext);
      this.organizationsService.getHierarchicalOrg(parentIdForContext).subscribe(
        (response: any) => {
          this.parentOrganizationBasedOnChildType = response.entity;
          this.filter.parentName = this.parentOrganizationBasedOnChildType.name;
          this.filter.parentOrgId = this.parentOrganizationBasedOnChildType.id;
          this.initNewSearch();
        },
        (error) => {
          `There was an error fetching the parent ${this.childEntityPartyRoleName} id`;
        }
      );
    } else {
      this.parentOrganizationBasedOnChildType = null;
      this.filter.parentName = this.defaultParentName;
      this.getStatistics();
      this.initNewSearch();
    }
    if (this.childEntityPartyRoleName === EntityPartyRoleName.PARENT) {
      this.title.setTitle('Organizations');
    } else {
      this.title.setTitle(this.childEntityPartyRoleName);
    }
  }

  getAllHierarchicalOrganizationsForParent(concat) {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);
    this.organizationsService.getAllHierarchicalOrgForParent(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.organizationList = this.organizationList.concat(data.entity);
        } else {
          this.organizationList = data.entity;
        }
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.filter.limit;
        this.isLoading = false;
        this.lockedTables = this.lengthToCompare !== this.filter.limit;
      },
      () => {
        this.lockedTables = false;
        this.isLoading = false;
        this.newLengthEvent.emit(0);
      }
    );
  }

  openModal(data): void {
    const instance = this.modalHelper.open(OrganizationModalComponent);
    instance.componentInstance.entityType = this.childEntityPartyRoleName;
    instance.componentInstance.entity = data;
    instance.componentInstance.parentCode = this.filter.parentName;
    if (this.parentOrganizationBasedOnChildType) {
      instance.componentInstance.parentId = this.parentOrganizationBasedOnChildType.id;
      instance.componentInstance.partyRoles = [{ roleName: this.childEntityPartyRoleName }];
    } else {
      instance.componentInstance.partyRoles = [{ roleName: 'Parent Organization' }];
    }
    instance.componentInstance.successEvent.subscribe((data) => {
      if (data.success) {
        instance.close();
        this.initNewSearch();
      }
    });
  }

  setParamsFromRoute(params) {
    this.parentId = params.parentId;
    this.clientId = params.clientId;
    this.lobId = params.lineOfBusinessId;
    this.marketId = params.marketId;
  }

  getFilters = (existing: Filter): any => {
    (this.searchFilters?.filters || []).forEach((filter: Filter) => {
      if (![null, undefined].includes(filter.value)) {
        existing[filter.name] = filter.value;
      } else {
        delete existing[filter.name];
      }
    });
    return existing;
  };

  getParentIdForContext(childEntityPartyRoleName: EntityPartyRoleName, routeParams: any): undefined | number {
    switch (childEntityPartyRoleName) {
      case EntityPartyRoleName.MARKET:
        return routeParams.lineOfBusinessId;
      case EntityPartyRoleName.LOB:
        return routeParams.clientId;
      case EntityPartyRoleName.CLIENT:
        return routeParams.parentId;
      default:
        return undefined;
    }
  }

  initNewSearch(): void {
    this.filterSubscription = this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getAllHierarchicalOrganizationsForParent(false);
  }

  ngOnDestroy(): void {
    this.filterSubscription?.unsubscribe();
    if (typeof this.searchFilters?.clearAll === 'function') {
      this.searchFilters?.clearAll();
    }
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  search(): void {
    this.filter.search = this.searchText;
    this.initNewSearch();
  }

  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Apply filters';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(this.filterdata);
    });
  }

  getStatistics(organizationId?: number) {
    if (organizationId) {
      this.setOrgStatistics(organizationId);
    } else {
      this.organizationsService.getOrganizationsByUsername('IcarioTenant').subscribe(
        (data: any) => {
          this.setOrgStatistics(data.entity.id);
        },
        (error) => {
          this.toastr.error(`There was an error fetching root parent ID`);
        }
      );
    }
  }

  navigate(id) {
    if (this.childEntityPartyRoleName === EntityPartyRoleName.PARENT) {
      this.router.navigate([`parents/${id}`]);
    } else if (this.childEntityPartyRoleName === EntityPartyRoleName.CLIENT) {
      this.router.navigate([`parents/${this.parentId}/clients/${id}`]);
    } else if (this.childEntityPartyRoleName === EntityPartyRoleName.LOB) {
      this.router.navigate([`parents/${this.parentId}/clients/${this.clientId}/lines-of-business/${id}`]);
    } else if (this.childEntityPartyRoleName === EntityPartyRoleName.MARKET) {
      this.router.navigate([
        `parents/${this.parentId}/clients/${this.clientId}/lines-of-business/${this.lobId}/markets/${id}`,
      ]);
    }
  }

  setOrgStatistics(organizationId) {
    this.organizationsService.getOrgStatistics(organizationId).subscribe(
      (organizationStatistics) => {
        this.organizationStatistics = [
          {
            title: PROGRAMS_TITLE,
            borderTopColor: PROGRAMS_BORDER_COLOR,
            data: organizationStatistics.entity.campaignCount,
            testId: CONFIGURATIONS_TEST_ID,
          },
          {
            title: SPONSORS_TITLE,
            borderTopColor: SPONSORS_BORDER_COLOR,
            data: organizationStatistics.entity.promotionSponsorCount,
            testId: PROGRAMS_TEST_ID,
          },
          {
            title: CONFIGURATIONS_TITLE,
            borderTopColor: CONFIGURATIONS_BORDER_COLOR,
            data: organizationStatistics.entity.promotionConfigCount,
            testId: SPONSORS_TEST_ID,
          },
        ];
      },
      (error) => {
        this.toastr.error(`There was an error fetching statistics for parent ${this.parentId}`);
      }
    );
  }

  getType(params) {
    if (params.parentId && params.clientId && params.lineOfBusinessId) {
      return EntityPartyRoleName.MARKET;
    } else if (params.parentId && params.clientId) {
      return EntityPartyRoleName.LOB;
    } else if (params.parentId) {
      return EntityPartyRoleName.CLIENT;
    }
    return EntityPartyRoleName.PARENT;
  }
}
