<form [formGroup]="promotionTypeForm" novalidate>
  <div class="d-flex justify-content-end mt-4 mr-4">
    <mat-icon (click)="cancel()" class="cursor-pointer">close</mat-icon>
  </div>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Name <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="name" autofocus />
      <!-- <div class="mt-2" [hidden]="promotionTypeForm.controls['name'].valid">
        <small class="text-danger">Input a name</small>
      </div> -->
    </div>

    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Status <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <select formControlName="status" class="form-control">
        <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
      </select>
      <!-- <div class="mt-2" [hidden]="promotionTypeForm.controls['status'].valid">
        <small class="text-danger">Select status</small>
      </div> -->
    </div>

    <div class="modal-footer">
      <app-modal-buttons [submitText]="isEdit ? 'SAVE' : 'ADD'" (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!promotionTypeForm.valid"></app-modal-buttons>
    </div>
  </div>
</form>
