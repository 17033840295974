<div class="panel-body" [ngClass]="commContent && commContent.length > 3 ? '' : 'margin-bottom-100'">
  <div class="row">
    <div class="col-md-12">
      <div class="full-height-scroll">
        <div class="row">
          <div class="col-md-12">
            <div class="row ml-3">
              <div class="col-md-12">
                <div class="row">
                  <app-search-filter
                    style="width: 100%"
                    *ngIf="searchFilters"
                    [searchFilters]="searchFilters"
                  ></app-search-filter>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>

            <div class="row">
              <div class="col-sm-9">
                <div class="d-flex">
                  <h2>Content Group: {{ contentGroup?.name }}</h2>
                  <button
                    style="margin-top: 7px"
                    *ngIf="this.appDataService.isCSRManager"
                    class="btn"
                    [title]="contentGroup ? 'Change Content Group' : 'Add Content Group'"
                    (click)="this.contentModal()"
                  >
                    <fa-icon [icon]="contentGroup ? faPencilAlt : faPlus"></fa-icon>
                  </button>
                </div>
              </div>
              <div class="col-sm-3">
                <button class="filter-btn float-end" *ngIf="appDataService.isProgramManager" (click)="test()">
                  Test
                </button>
              </div>
            </div>
            <div class="row">
              <table class="table table-pointable table-bordered infinite-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Language</th>
                    <th>Subject</th>
                    <th>Segment</th>
                    <th>Template</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let cc of commContent">
                    <td>{{ cc.id }}</td>
                    <td>
                      {{ cc.language.languageCode + " - " + cc.language.languageName }}
                    </td>
                    <td>{{ cc.subject }}</td>
                    <td>{{ cc.segment?.name }}</td>
                    <td>
                      <button
                        [disabled]="!cc.template"
                        class="btn btn-primary btn-sm"
                        (click)="templateModal(cc.template)"
                      >
                        <fa-icon [icon]="faFileCode"></fa-icon> Preview Template
                      </button>
                    </td>
                  </tr>
                  <tr app-is-there-more [newLengthEvent]="newLengthEvent" [numColumns]="5"></tr>
                </tbody>
              </table>
            </div>
          </div>
          <app-add-more-data-to-list (onScroll)="getContents(true)" [lock]="lock" [isLoading]="isLoading">
          </app-add-more-data-to-list>
        </div>
      </div>
    </div>
  </div>
</div>
