import { ReportClassificationHelperService } from './../../../services/helpers/report-classification-helper.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { faEye, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Filter, SearchFilters } from 'src/app/data/class';
import { ReportsService } from 'src/app/services/reports.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { ReportParamsModalComponent } from '../report-params-modal/report-params-modal.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
@Component({
  selector: 'app-reports-results',
  templateUrl: './reports-results.component.html',
  styleUrls: ['./reports-results.component.scss'],
})
export class ReportsResultsComponent implements OnInit, OnDestroy {
  @Input() isAdmin: boolean;
  @Input() classification: any;
  @Input() selectedPersonId: boolean;
  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  searchFilters: SearchFilters;
  lock: boolean;
  lengthToCompare: number;
  reportResults: any[] = [];
  lockedTables: boolean;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  show = true;
  faEye = faEye;
  faDownload = faDownload;
  DAY_IN_MILLIS = 24 * 60 * 60 * 1000;
  filteredStatuses: any[];
  faSpinner = faSpinner;
  filterdata: SearchFilters;
  helpers: any;
  searchText: any;
  appliedFilters: Filter[];
  filterButton: boolean = false;
  statusHelper = this.statusHelperService.getStatus('badge');
  TODAY = this.dateHelperService.toISOFormat(new Date());
  WEEKS_AGO = this.dateHelperService.toISOFormat(new Date(new Date().getTime() - 100 * this.DAY_IN_MILLIS));

  classifications = this.reportClassificationHelperService.getClassifications();

  constructor(
    private _config: NgbModalConfig,
    private reportsService: ReportsService,
    private tableHelperService: TableHelperService,
    private statusHelperService: StatusHelperService,
    private dateHelperService: DateHelperService,
    private reportClassificationHelperService: ReportClassificationHelperService,
    private configurationService: ConfigurationService,
    private http: HttpClient,
    private modalHelper: NgbModal,
    private toastr: ToastrService,
    private applyfilterService: ApplyFilterService
  ) {
    _config.backdrop = 'static';
    _config.keyboard = false;
  }
  ngOnDestroy() {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'reportResultsSearch',
      searchCallback: () => {
        this.initNewSearch();
      },
      buttonsWithText: true,
      filters: [
        {
          name: 'reportName',
          placeholder: 'Add name',
          label: 'Report name',
        },
        {
          name: 'statuses',
          placeholder: 'Select status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('REPORTS'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
          label: 'Status',
        },
        {
          name: 'reportNotes',
          placeholder: 'Add note',
          label: 'Report note',
        },
        {
          name: 'from',
          type: 'date',
          placeholder: 'mm/dd/yyyy',
          value: this.WEEKS_AGO,
          label: 'Start date',
        },
        {
          name: 'to',
          type: 'date',
          endDate: true,
          placeholder: 'mm/dd/yyyy',
          label: 'Through date',
          value: this.TODAY,
        },
        {
          name: 'requestedBy',
          placeholder: 'Add name',
          if: this.isAdmin,
          label: 'Requested by',
        },
      ],
    };

    if (!this.classification) {
      this.searchFilters.filters.push({
        name: 'classifications',
        placeholder: 'Classification',
        type: 'array',
        choices: this.classifications,
        nameProp: 'name',
        valueProp: 'value',
        inputType: 'dropdown',
      });
    }
    this.initNewSearch();
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getReportResults(false);
  }

  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getReportResults(false);
  }

  applyFilter(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    this.lock = true;
    this.getReportResults(false);
  }
  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };
  getReportResults(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);

    if (this.classification === 'Communication') {
      this.filter['classifications'] = 'COMMUNICATION';
    }

    this.reportsService.getReportResults(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.reportResults = this.reportResults.concat(data.entity);
        } else {
          this.reportResults = data.entity;
        }
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.isLoading = false;
        this.lock = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  // TODO
  download(event: any, downloadLink: string): void {
    event.preventDefault();
    // it's friday and I want a damned pipe operator
    const token = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).sessionId;
    this.configurationService.getConfiguration('MEMBER_UI_LOCATION').subscribe((data: any) => {
      if (data.success && data.entity && data.entity.length > 0) {
        const memberUrl = data.entity[0].cfgValue;
        this.http
          .get(memberUrl + downloadLink, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
            observe: 'response',
            responseType: 'blob',
          })
          .subscribe((resp) => {
            const url = window.URL.createObjectURL(resp.body);
            let anchor = document.createElement('a');
            anchor.download = resp.headers.get('content-disposition').split('; ')[1].split('=')[1].replace(/"/g, '');
            anchor.href = url;
            anchor.click();
          });
      }
    });
  }

  viewReportParams(execution: any): void {
    this.reportsService.getReportExecutionParams(execution.id).subscribe((data: any) => {
      if (data.success) {
        const instance = this.modalHelper.open(ReportParamsModalComponent);
        instance.componentInstance.execution = execution;
        instance.componentInstance.reportParams = data.entity;
      }
    });
  }

  changeFilter(filterName: string, value: string): void {
    delete this.filter[filterName];
    if (value !== '') {
      this.filter[filterName] = value;
    }
    this.applyFilter();
  }
  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Reports results';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(data);
    });
  }
}
