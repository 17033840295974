<div
  *ngIf="dropdown"
  [ngClass]="classValue"
  class="search-filter-fields search-filter-bottom segment-filter mr-sm ml-sm"
>
  <div class="segment-filter">
    <div
      [ngClass]="maxWidth ? 'w-100 btn-group filter' : 'btn-group filter'"
      ngbDropdown
      #segmentDropdown="ngbDropdown"
      autoClose="outside"
      placement="bottom-end"
    >
      <button
        type="button"
        class="btn btn-md btn- filter-button form-control"
        id="dropdownSegment"
        ngbDropdownToggle
        (click)="getAllSegments()"
      >
        <span
          class="float-left"
          style="
            width: 96%;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: start;
            color: rgba(0, 0, 0, 0.38);
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
          "
        >
          {{
            allowMultiple
              ? selectedSegments !== null
                ? selectedSegments.length === 1
                  ? selectedSegments[0].name
                  : "Multiple Selected"
                : "Selected Segment"
              : selectedSegments !== null && selectedSegments.length > 0
              ? selectedSegments[0].name
              : "Select segment"
          }}</span
        >
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownSegment" class="segment-filter-search-box">
        <input
          type="text"
          placeholder="Type minimum 3 characters to filter"
          [(ngModel)]="query"
          class="form-control"
          style="margin-bottom: 5px"
          (ngModelChange)="applyFilter(query)"
        />
        <div>
          <a class="m-b-xs" (click)="clearAll()">Clear all</a>
          <span class="float-right" *ngIf="selectedSegments.length > 0 &amp;&amp; query.length > 0">
            <i>Some segments may be filtered...</i>
          </span>
        </div>
        <div class="segment-filter-container">
          <div
            class="checkbox checkbox-info"
            [ngClass]="getClass(item.level)"
            *ngFor="let item of filtered | sort: 'path'"
          >
            <input
              *ngIf="item.assigned === true"
              id="seg_{{ item.id }}"
              name="{{ item.name }}"
              code="{{ item.code }}"
              [(ngModel)]="item.checked"
              (ngModelChange)="addSegments(item)"
              type="checkbox"
            />
            <label for="seg_{{ item.id }}">{{ item.name }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="!dropdown"
  [ngClass]="classValue"
  class="search-filter-fields search-filter-bottom segment-filter mr-sm ml-sm"
>
  <input
    type="text"
    placeholder="Type minimum 3 characters to filter"
    [(ngModel)]="query"
    class="form-control"
    style="margin-bottom: 5px"
    (ngModelChange)="applyFilter(query)"
  />
  <div>
    <a class="m-b-xs" (click)="clearAll()">Clear all</a>
    <span class="float-right" *ngIf="selectedSegments.length > 0 &amp;&amp; query.length > 0">
      <i>Some segments may be filtered...</i>
    </span>
  </div>
  <div class="segment-filter-container">
    <div class="checkbox checkbox-info" [ngClass]="getClass(item.level)" *ngFor="let item of filtered | sort: 'path'">
      <input
        *ngIf="item.assigned === true"
        id="seg_{{ item.id }}"
        name="{{ item.name }}"
        code="{{ item.code }}"
        [(ngModel)]="item.checked"
        (ngModelChange)="addSegments(item)"
        type="checkbox"
      />
      <label for="seg_{{ item.id }}">{{ item.name }}</label>
    </div>
  </div>
</div>
