import { OutboundModalComponent } from './../outbound-modal/outbound-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OutboundService } from './../../services/outbound.service';
import { ReportsService } from './../../services/reports.service';
import { Filter } from './../../data/class';
import { StatusHelperService } from './../../services/helpers/status-helper.service';
import { TableHelperService } from './../../services/helpers/table-helper.service';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Report, OutboundIntegration } from 'src/app/data/model';
import { faPlus, faCogs, faPencilAlt, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { SearchFilters } from '../../data/class';
import { UserPreferencesService } from 'src/app/services/user-preferences.service';
import { ToastrService } from 'ngx-toastr';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
import { selectRow } from 'src/app/data/selectRow';

@Component({
  selector: 'app-outbound',
  templateUrl: './outbound.component.html',
  styleUrls: ['./outbound.component.scss'],
})
export class OutboundComponent implements OnInit, OnDestroy {
  isLoading = false;
  lockedTables = false;
  lock = false;
  filteredIntegrationStatuses: any[] = [];
  filter = new Filter();
  limit = 20;
  statuses: any[];
  reports: Report[];
  outbounds: OutboundIntegration[];
  lengthToCompare: number;
  selectedOutbound: OutboundIntegration;
  statusChoices = this.tableHelperService.getStatuses('ENTITY');
  searchFilters: SearchFilters;
  faPlus = faPlus;
  faCogs = faCogs;
  faPencilAlt = faPencilAlt;
  faFolderOpen = faFolderOpen;
  appliedFilters: Filter[];
  filterButton: boolean = false;
  filterdata: SearchFilters;
  searchText: any;
  helpers: any;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private titleHelper: Title,
    private tableHelperService: TableHelperService,
    private statusHelperService: StatusHelperService,
    private reportsService: ReportsService,
    private outboundService: OutboundService,
    private userPreferences: UserPreferencesService,
    private modalHelper: NgbModal,
    private toastr: ToastrService,
    private applyfilterService: ApplyFilterService
  ) {}

  statusHelper = this.statusHelperService.getStatus('badge');

  ngOnDestroy() {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }
  ngOnInit(): void {
    this.titleHelper.setTitle('Outbound Integrations');

    this.searchFilters = {
      formName: 'outbound_search',
      filters: [
        {
          name: 'statuses',
          placeholder: 'Select status',
          type: 'array',
          choices: this.statusChoices,
          label: 'Status',
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
          // value: this.userPreferences.service.getPreference('outbound.defaultFilterValues.status'),
        },
        {
          name: 'search',
          label: 'Keyword',
          placeholder: 'Search keyword',
        },
      ],
      searchCallback: () => {
        this.initNewSearch();
      },
    };
    this.initNewSearch();
    // this.initFilter();
    // this.setStatuses();
    // this.getAllReports();
  }
  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getOutbound(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  getAllReports(): void {
    this.reportsService.getAllReports().subscribe((data: any) => {
      if (data.success) {
        this.reports = data.entity.records;
      }
    });
  }
  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };
  getOutbound(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);
    this.outboundService.getOutbounds(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.outbounds = this.outbounds.concat(data.entity.records);
        } else {
          this.outbounds = data.entity.records;
        }
        this.lengthToCompare = data.entity.records.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.records.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Outbound batch';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
    });
  }
  showOutbound(event: MouseEvent, outbound: OutboundIntegration): void {
    selectRow(event);
    this.selectedOutbound = outbound;
  }

  showIntegration(): boolean {
    if (this.selectedOutbound !== undefined) {
      return true;
    }
    return false;
  }

  outboundModal(id?: number, e?: any): void {
    if (id) {
      this.outboundService.getOutbound(id).subscribe((data: any) => {
        if (data.success) {
          const instance = this.modalHelper.open(OutboundModalComponent);
          instance.componentInstance.integration = data.entity;
          instance.componentInstance.successEvent.subscribe(() => {
            this.initNewSearch();
            instance.close();
          });
        }
      });
    } else {
      const instance = this.modalHelper.open(OutboundModalComponent);
      instance.componentInstance.successEvent.subscribe(() => {
        this.initNewSearch();
        instance.close();
      });
    }
  }
}
