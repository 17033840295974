import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog.component';
import { ResponseHelperService } from './../services/helpers/response-helper.service';
import { MemberRelationship, ImportFile } from 'src/app/data/class';
import { ManifestFieldChevronList, CustomAttribute, SegmentMapping, MemberInstrument } from './../data/class';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { IntegrationsService } from './../services/integrations.service';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Location } from '@angular/common';
import {
  faChevronUp,
  faChevronDown,
  faRedo,
  faTrash,
  faPlus,
  faArrowsAltV,
  faChevronRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgbAccordion, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manifest',
  templateUrl: './manifest.component.html',
  styleUrls: ['./manifest.component.scss'],
})
export class ManifestComponent implements OnInit {
  @ViewChildren(NgSelectComponent) ngSelectComponentList: QueryList<NgSelectComponent>;
  @ViewChildren('accMain') ngbAccordionList: QueryList<NgbAccordion>;
  model: ImportFile[] = [];
  packagedModel: ImportFile[] = [];
  manifest: any;
  rawJSON = '';
  integrationId: number;
  integrationName: string;
  addImportFileType: string;
  unsavedChanges = false;

  chevronList: boolean[] = [];
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;
  faArrowsAltV = faArrowsAltV;
  faTimes = faTimes;
  faTrash = faTrash;
  faRedo = faRedo;
  faPlus = faPlus;

  importTypes = [
    { key: 'ELIGIBILITY', name: 'Eligibility' },
    { key: 'TRANSACTION', name: 'Transaction' },
    { key: 'ORGANIZATION', name: 'Organization' },
    { key: 'PREFERENCE', name: 'Communication Preferences' },
    { key: 'USER', name: 'User' },
  ];

  constructor(
    private integrationsService: IntegrationsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.integrationId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.integrationsService.getIntegration(this.integrationId).subscribe((response) => {
      this.integrationName = response.entity.name;

      if (response.entity.manifest !== null) {
        this.model = JSON.parse(response.entity.manifest).importFile;

        for (const importFile of this.model) {
          this.setData(importFile);
        }
      }
    });
  }

  setData(importFile: ImportFile): ImportFile {
    importFile.selectedField = [];
    importFile.selectedEditField = [];
    importFile.selectedComplexType = [];
    importFile.dropdownDisplay = [];
    importFile.expandedLookup = false;
    importFile.openFieldChevronList = new ManifestFieldChevronList();
    importFile.openFieldChevronList.fieldPropertyIndexes = {};
    importFile.complexTypesDropdown = [];
    importFile.selectedType = [];

    if (importFile.importType.toLowerCase() === 'eligibility' || importFile.importType.toLowerCase() === 'user') {
      importFile.fieldMappingDefinition = this.assignMemberMappings(importFile.importType.toLowerCase());
    } else if (importFile.importType.toLowerCase() === 'transaction') {
      importFile.fieldMappingDefinition = this.assignTransactionMappings();
    } else if (importFile.importType.toLowerCase() === 'preference') {
      importFile.fieldMappingDefinition = this.assingCommunicationPreferencesMappings();
    } else {
      importFile.fieldMappingDefinition = this.assignOrganizationMappings();
    }

    this.formatDisplayNames(importFile);

    importFile.fieldMappingDefinition.forEach((mapping) => {
      importFile.selectedField[mapping.id] = null;
      importFile.selectedEditField[mapping.id] = null;
      importFile.dropdownDisplay[mapping.id] = mapping.fields;
      if (mapping.complexTypes) {
        mapping.complexTypes.forEach((complexType) => {
          importFile.complexTypesDropdown[mapping.id] = complexType.anyOf;
          importFile.typeFields = complexType.anyOf;
        });
      }
    });

    Object.keys(importFile.fieldMappings).forEach((mapping) => {
      importFile.fieldMappings[mapping].forEach((field) => {
        this.removeFromDropdown(importFile, mapping, field);
      });
    });
    return importFile;
  }

  isFieldIndexUsed(importFile: ImportFile, idx: number): boolean {
    if (!idx) {
      return false;
    }

    const fields = {
      fieldMappings: importFile.fieldMappings,
      memberInstrument: importFile.memberInstrument,
      customAttribute: importFile.customAttribute,
      segmentMapping: importFile.segmentMapping,
      memberRelationship: importFile.memberRelationship,
    };

    for (const property in fields) {
      if (importFile.hasOwnProperty(property)) {
        for (const inner in importFile[property]) {
          if (typeof importFile[property][inner].forEach === 'function') {
            for (const fieldMapping in importFile[property][inner]) {
              if (importFile[property][inner][fieldMapping].fieldMappings) {
                importFile[property][inner][fieldMapping].fieldMappings.forEach((chieldFieldMapping) => {
                  if (chieldFieldMapping.fieldIndex === idx) {
                    this.toastr.warning('Field index already used');
                    return true;
                  }
                });
              } else {
                if (importFile[property][inner][fieldMapping].fieldIndex === idx) {
                  this.toastr.warning('Field index already used');
                  return true;
                }
              }
            }
          } else {
            if (importFile[property][inner].fieldIndex === idx) {
              this.toastr.warning('Field index already used');
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  getParams(fields: any, param: any): any {
    const list = [];
    fields.forEach((element) => {
      list.push(element[param]);
    });
    return list;
  }

  removeFromDropdown(importFile: ImportFile, propertyId: string, field: any): void {
    let index;
    importFile.dropdownDisplay[propertyId].forEach((propField, idx) => {
      if (propField.id === field.entityProp) {
        index = idx;
      }
    });
    importFile.dropdownDisplay[propertyId].splice(index, 1);
    importFile.dropdownDisplay[propertyId] = importFile.dropdownDisplay[propertyId].filter((option: any) => {
      return option.id !== field.entityProp;
    });
    // TODO Check if this is global or import file based
    this.ngSelectComponentList.forEach((ngSelectComponent: any) => {
      if (ngSelectComponent.element.id === 'select-' + propertyId) {
        ngSelectComponent.handleClearClick();
      }
    });
  }

  addToDropDown(importFile: ImportFile, property: any, field: any): void {
    const name = property.type
      ? importFile.displayNames[property.type][field.entityProp]
      : importFile.displayNames[property.id][field.entityProp];
    importFile.dropdownDisplay[property.id] = [
      ...importFile.dropdownDisplay[property.id],
      { id: field.entityProp, name: name },
    ];
  }

  resizeMe(event: any, fieldName: any): void {
    const ibox = $(event.currentTarget).closest('div.ibox');
    const content = ibox.find('div.ibox-content');
    content.slideToggle(200);
    const dropdownName = fieldName.id ? fieldName.id : fieldName;
    this.chevronList[dropdownName] === undefined || !this.chevronList[dropdownName]
      ? (this.chevronList[dropdownName] = true)
      : (this.chevronList[dropdownName] = false);
    ibox.toggleClass('').toggleClass('border-bottom');
    setTimeout(() => {
      ibox.trigger('resize');
      ibox.find('[id^=map-]').trigger('resize');
    }, 50);
  }

  formatDisplayNames(importFile: ImportFile): void {
    let fieldMappings = importFile.fieldMappingDefinition;
    importFile.displayNames = {};
    Object.keys(fieldMappings).forEach((mapping) => {
      const mappingId = fieldMappings[mapping].id;
      importFile.displayNames = { ...importFile.displayNames, [mappingId]: {} };
      fieldMappings[mapping].fields.forEach((field) => {
        importFile.displayNames[mappingId] = { ...importFile.displayNames[mappingId], [field.id]: field.name };
      });
      if (fieldMappings[mapping] && fieldMappings[mapping].complexTypes) {
        fieldMappings[mapping].complexTypes.forEach((complexType) => {
          Object.keys(complexType.anyOf).forEach((mapping) => {
            const mappingId = complexType.anyOf[mapping].id;
            importFile.displayNames = { ...importFile.displayNames, [mappingId]: {} };
            complexType.anyOf[mapping].fields.forEach((field) => {
              importFile.displayNames[mappingId] = { ...importFile.displayNames[mappingId], [field.id]: field.name };
            });
          });
        });
      }
    });
  }

  formatImportFileName(importType: any): string {
    let name;
    switch (importType) {
      case 'ELIGIBILITY':
        name = 'Eligibility';
        break;
      case 'TRANSACTION':
        name = 'Transaction';
        break;
      case 'ORGANIZATION':
        name = 'Organization';
        break;
      case 'PREFERENCE':
        name = 'Communication Preferences';
        break;
      default:
        name = 'User';
        break;
    }

    return name;
  }

  populateImportFile(importFile: ImportFile) {}

  addImportFile() {
    this.unsavedChanges = true;

    const importFile = {
      importType: this.addImportFileType,
      fieldMappings: {},
      memberInstrument: [],
      customAttribute: [],
      segmentMapping: [],
      memberRelationship: [],
    };

    this.setData(importFile);

    this.model.push(importFile);

    this.addImportFileType = null;
  }

  deleteImportFile(importFile: ImportFile, index: number) {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete ' + importFile.importType + ' import file';
    instance.componentInstance.header = 'Delete Import File?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.unsavedChanges = true;

      this.model.splice(index, 1);
      this.responseHelperService.success('Import file successfully removed', true);
    });
  }

  listToggleOpen(importFile: ImportFile, index: number) {
    importFile.expandedLookup = !importFile.expandedLookup;
    this.ngbAccordionList.get(index).toggle('import_file_panel');
  }

  onDragStart(importFile: ImportFile) {
    importFile.selected = true;
  }

  onDragEnd(importFile: ImportFile) {
    importFile.selected = false;
  }

  listDragHandler(item: any, list: any[], effect: any) {
    if (effect === 'move') {
      // const index = list.indexOf(item);
      // list.splice(index, 1);
    }
  }

  listDropHandler(event: any) {
    const { currentIndex, previousIndex } = event;
    const currentElement = { ...this.model[currentIndex] };
    this.model[currentIndex] = this.model[previousIndex];
    this.model[previousIndex] = currentElement;
  }

  selectField(importFile: ImportFile, field: any, property: any): void {
    importFile.selectedField[property.id] = field;
  }

  addComplexFields(importFile: ImportFile, property, i, field): void {
    if (importFile.openFieldChevronList.fieldPropertyIndexes[property.id] === i) {
      importFile.openFieldChevronList.fieldPropertyIndexes[property.id] = null;
      importFile.selectedEditField[property.id] = null;
    } else {
      importFile.openFieldChevronList.fieldPropertyIndexes[property.id] = i;
      importFile.selectedEditField[property.id] = {
        entityName: field.entityName,
        entityProp: field.entityProp,
      };
    }
  }

  selectComplexType(importFile: ImportFile, field: any, property: any): void {
    importFile.selectedComplexType[property.id] = field;
  }

  selectType(importFile: ImportFile, field: any, property: any): void {
    importFile.selectedType[property.id] = field;
    importFile.selectedComplexType[property.id] = null;
    // TODO Check if this is limited to single import file
    this.ngSelectComponentList.forEach((ngSelectComponent: any) => {
      if (ngSelectComponent.element.id === 'selectComplexField-' + property.id) {
        ngSelectComponent.handleClearClick();
      }
    });
  }

  addComplexType(importFile: ImportFile, complexType: any, selectedType: any, property: any): void {
    let id;
    let newId;
    if (!importFile.fieldMappings[property.id]) {
      importFile.fieldMappings[property.id] = [];
    }
    importFile.fieldMappings[property.id].forEach((ct) => {
      if (ct.type && ct.type === selectedType.id) {
        id = ct.id;
      }
    });
    if (id) {
      newId = Number(id.replace(/\D/g, '')) + 1;
    } else {
      newId = 1;
    }

    importFile.fieldMappings[property.id].push({
      id: selectedType.id + newId,
      name: selectedType.name,
      type: selectedType.id,
      entityProp: complexType.id,
      fieldMappings: [],
    });
    importFile.dropdownDisplay[selectedType.id + newId] = [];
    selectedType.fields.forEach((element) => {
      importFile.dropdownDisplay[selectedType.id + newId].push(element);
    });

    this.ngSelectComponentList.forEach((ngSelectComponent: any) => {
      if (ngSelectComponent.element.id === 'selectType-' + property.id) {
        ngSelectComponent.handleClearClick();
      }
    });

    this.toastr.success(`New ${selectedType.name} ${complexType.name} added`);
  }

  removeComplexType(importFile: ImportFile, property: any, index: number): void {
    importFile.fieldMappings[property.id].splice(index, 1);
  }

  /* Field Mapping methods */

  editFieldMapping(importFile: ImportFile, property: any, index: any, field: any, childField?: any): void {
    const propertyId = childField ? field.id : property.id;
    const fieldToEdit = childField ? childField : field;
    if (importFile.openFieldChevronList.fieldPropertyIndexes[propertyId] === index) {
      importFile.openFieldChevronList.fieldPropertyIndexes[propertyId] = null;
      importFile.selectedEditField[propertyId] = null;
    } else {
      importFile.openFieldChevronList.fieldPropertyIndexes[propertyId] = index;
      importFile.selectedEditField[propertyId] = {
        entityName: fieldToEdit.entityName,
        entityProp: fieldToEdit.entityProp,
      };
    }
  }

  handleFieldPropertyEvent(
    importFile: ImportFile,
    data: any,
    property: any,
    complexField?: any,
    parentIndex?: any
  ): void {
    this.unsavedChanges = true;

    switch (data.type) {
      case 'add':
        this.addFieldMapping(importFile, property, data.value, complexField);
        break;
      case 'save':
        this.saveFieldMapping(importFile, property, data.index, data.value, complexField, parentIndex);
        break;
      default:
        this.resetFieldMapping(importFile, property);
        break;
    }
  }

  addFieldMapping(importFile: any, property: any, fieldProperty: any, complexField?: any): void {
    this.isFieldIndexUsed(importFile, fieldProperty.fieldIndex);
    const id = complexField ? importFile.selectedComplexType[property.id].id : importFile.selectedField[property.id].id;
    const selectedFieldModel = {
      ...fieldProperty,
      ...{
        entityName: fieldProperty.id,
        entityProp: id,
      },
    };

    if (complexField) {
      importFile.fieldMappings[property.id].forEach((ct) => {
        if (ct.id && ct.id === complexField) {
          if (!ct.fieldMappings) {
            ct.fieldMappings = [];
          }

          ct.fieldMappings.push(selectedFieldModel);
          this.removeFromDropdown(importFile, complexField, selectedFieldModel);
          this.toastr.success('Field Mapping added');
        }
      });
    } else {
      if (!importFile.fieldMappings[property.id]) {
        importFile.fieldMappings[property.id] = [];
      }
      importFile.fieldMappings[property.id].push(selectedFieldModel);
      this.removeFromDropdown(importFile, property.id, selectedFieldModel);
      this.toastr.success('Field Mapping added');
    }
  }

  saveFieldMapping(
    importFile: ImportFile,
    property: any,
    index: number,
    fieldProperty: any,
    complexField?: any,
    parentIndex?: any
  ): void {
    const id = complexField ? complexField.id : property.id;
    if (complexField) {
      if (
        importFile.fieldMappings[property.id][parentIndex].fieldMappings[index].fieldIndex !== fieldProperty.fieldIndex
      ) {
        this.isFieldIndexUsed(importFile, fieldProperty.fieldIndex);
      }
      importFile.fieldMappings[property.id][parentIndex].fieldMappings[index] = {
        ...fieldProperty,
        entityName: importFile.selectedEditField[id].entityName,
        entityProp: importFile.selectedEditField[id].entityProp,
      };
    } else {
      if (importFile.fieldMappings[property.id][index].fieldIndex !== fieldProperty.fieldIndex) {
        this.isFieldIndexUsed(importFile, fieldProperty.fieldIndex);
      }
      importFile.fieldMappings[property.id][index] = {
        ...fieldProperty,
        entityName: importFile.selectedEditField[id].entityName,
        entityProp: importFile.selectedEditField[id].entityProp,
      };
    }

    importFile.openFieldChevronList.fieldPropertyIndexes[id] = null;
    importFile.selectedEditField[id] = null;
    this.toastr.success('Field Mapping saved');
  }

  resetFieldMapping(importFile: ImportFile, property: any): void {
    importFile.openFieldChevronList.fieldPropertyIndexes[property.id] = null;
    importFile.selectedEditField[property.id] = null;
  }

  removeFieldMapping(importFile: ImportFile, property: any, index: number, fieldProperty: any, childField: any): void {
    if (!fieldProperty.type) {
      this.addToDropDown(importFile, property, fieldProperty);
    }
    if (childField) {
      this.addToDropDown(importFile, fieldProperty, childField);
      importFile.fieldMappings[property.id].find((obj) => obj.id === fieldProperty.id).fieldMappings.splice(index, 1);
    } else {
      importFile.fieldMappings[property.id].splice(index, 1);
    }
    this.toastr.success('Field Mapping removed');
  }

  /* Member Instrument methods */

  handleMemberInstrumentEvent(importFile: ImportFile, data: any): void {
    this.unsavedChanges = true;

    switch (data.type) {
      case 'add':
        this.addMemberInstrument(importFile, data.value);
        break;
      case 'save':
        this.saveMemberInstrument(importFile, data.index, data.value);
        break;
      default:
        this.removeMemberInstrument(importFile, data.index);
        break;
    }
  }

  editMemberInstrument(importFile: ImportFile, index: number): void {
    if (importFile.openFieldChevronList.memberInstrumentIndex === index) {
      importFile.openFieldChevronList.memberInstrumentIndex = null;
    } else {
      importFile.openFieldChevronList.memberInstrumentIndex = index;
    }
  }

  addMemberInstrument(importFile: ImportFile, memberInstrument: MemberInstrument): void {
    this.isFieldIndexUsed(importFile, memberInstrument.fieldIndex);
    if (!importFile.memberInstrument) {
      importFile.memberInstrument = [];
    }
    importFile.memberInstrument.push(memberInstrument);
    this.toastr.success('Instrument added');
  }

  saveMemberInstrument(importFile: ImportFile, index: number, memberInstrument: MemberInstrument): void {
    if (importFile.memberInstrument[index].fieldIndex !== memberInstrument.fieldIndex) {
      this.isFieldIndexUsed(importFile, memberInstrument.fieldIndex);
    }
    importFile.memberInstrument[index] = memberInstrument;
    this.toastr.success('Instrument saved');
  }

  removeMemberInstrument(importFile: ImportFile, index: number): void {
    importFile.memberInstrument.splice(index, 1);
    this.toastr.success('Instrument removed');
  }

  /* Custom Attribute methods */

  handleCustomAttributeEvent(importFile: ImportFile, data: any): void {
    this.unsavedChanges = true;

    switch (data.type) {
      case 'add':
        this.addCustomAttribute(importFile, data.value);
        break;
      case 'save':
        this.saveCustomAttribute(importFile, data.index, data.value);
        break;
      default:
        this.removeCustomAttribute(importFile, data.index);
        break;
    }
  }

  editCustomAttribute(importFile: ImportFile, index: number): void {
    if (importFile.openFieldChevronList.customAttributeIndex === index) {
      importFile.openFieldChevronList.customAttributeIndex = null;
    } else {
      importFile.openFieldChevronList.customAttributeIndex = index;
    }
  }

  addCustomAttribute(importFile: ImportFile, customAttribute: CustomAttribute): void {
    this.isFieldIndexUsed(importFile, customAttribute.fieldIndex);
    if (!importFile.customAttribute) {
      importFile.customAttribute = [];
    }
    importFile.customAttribute.push(customAttribute);
    this.toastr.success('Attribute added');
  }

  saveCustomAttribute(importFile: ImportFile, index: number, customAttribute: CustomAttribute): void {
    if (importFile.customAttribute[index].fieldIndex !== customAttribute.fieldIndex) {
      this.isFieldIndexUsed(importFile, customAttribute.fieldIndex);
    }
    importFile.customAttribute[index] = customAttribute;
    this.toastr.success('Attribute saved');
  }

  removeCustomAttribute(importFile: ImportFile, index: number): void {
    importFile.customAttribute.splice(index, 1);
    this.toastr.success('Attribute removed');
  }

  /* Segment Mapping methods */

  handleSegmentMappingEvent(importFile: ImportFile, data: any): void {
    this.unsavedChanges = true;

    switch (data.type) {
      case 'add':
        this.addSegmentMapping(importFile, data.value);
        break;
      case 'save':
        this.saveSegmentMapping(importFile, data.index, data.value);
        break;
      default:
        this.removeSegmentMapping(importFile, data.index);
        break;
    }
  }

  editSegmentMapping(importFile: ImportFile, index: number): void {
    if (importFile.openFieldChevronList.segmentMappingIndex === index) {
      importFile.openFieldChevronList.segmentMappingIndex = null;
    } else {
      importFile.openFieldChevronList.segmentMappingIndex = index;
    }
  }

  addSegmentMapping(importFile: ImportFile, segmentMapping: SegmentMapping): void {
    this.isFieldIndexUsed(importFile, segmentMapping.fieldIndex);
    if (!importFile.segmentMapping) {
      importFile.segmentMapping = [];
    }
    importFile.segmentMapping.push(segmentMapping);
    this.toastr.success('Segment Mapping added');
  }

  saveSegmentMapping(importFile: ImportFile, index: number, segmentMapping: SegmentMapping): void {
    if (importFile.segmentMapping[index].fieldIndex !== segmentMapping.fieldIndex) {
      this.isFieldIndexUsed(importFile, segmentMapping.fieldIndex);
    }
    importFile.segmentMapping[index] = segmentMapping;
    this.toastr.success('Segment Mapping saved');
  }

  removeSegmentMapping(importFile: ImportFile, index: number): void {
    importFile.segmentMapping.splice(index, 1);
    this.toastr.success('Segment Mapping removed');
  }

  /* Member Relationship methods */

  handleMemberRelationshipEvent(importFile: ImportFile, data: any): void {
    this.unsavedChanges = true;

    switch (data.type) {
      case 'add':
        this.addMemberRelationship(importFile, data.value);
        break;
      case 'save':
        this.saveMemberRelationship(importFile, data.index, data.value);
        break;
      default:
        this.removeMemberRelationship(importFile, data.index);
        break;
    }
  }

  editMemberRelationship(importFile: ImportFile, index: number): void {
    if (importFile.openFieldChevronList.memberRelationshipIndex === index) {
      importFile.openFieldChevronList.memberRelationshipIndex = null;
    } else {
      importFile.openFieldChevronList.memberRelationshipIndex = index;
    }
  }

  addMemberRelationship(importFile: ImportFile, memberRelationship: MemberRelationship): void {
    this.isFieldIndexUsed(importFile, memberRelationship.fieldIndex);
    if (!importFile.memberRelationship) {
      importFile.memberRelationship = [];
    }
    importFile.memberRelationship.push(memberRelationship);
    this.toastr.success('Relationship added');
  }

  saveMemberRelationship(importFile: ImportFile, index: number, memberRelationship: MemberRelationship): void {
    if (importFile.memberRelationship[index].fieldIndex !== memberRelationship.fieldIndex) {
      this.isFieldIndexUsed(importFile, memberRelationship.fieldIndex);
    }
    importFile.memberRelationship[index] = memberRelationship;
    this.toastr.success('Relationship saved');
  }

  removeMemberRelationship(importFile: ImportFile, index: number): void {
    importFile.memberRelationship.splice(index, 1);
    this.toastr.success('Relationship removed');
  }

  createJSON(): void {
    this.unsavedChanges = false;
    this.updateJSON();

    this.integrationsService
      .updateIntegrationManifest(this.integrationId, { importFile: this.packagedModel })
      .subscribe(
        () => {
          this.toastr.success('Manifest updated');
        },
        (error) => {
          this.responseHelperService.error(this, error.errorMsg);
        }
      );
  }

  cancel() {
    this.location.back();
  }

  updateJSON(): void {
    this.packagedModel = [];

    this.model.forEach((importFile: ImportFile) => {
      this.packagedModel.push({
        importType: importFile.importType,
        fieldMappings: importFile.fieldMappings,
        memberInstrument: importFile.memberInstrument,
        customAttribute: importFile.customAttribute,
        segmentMapping: importFile.segmentMapping,
        memberRelationship: importFile.memberRelationship,
      });
    });

    this.rawJSON = JSON.stringify(this.packagedModel, null, '\t');
  }

  assignMemberMappings(importType: string): any {
    let mappings = [
      {
        id: 'member',
        name: 'Member Data',
        fields: [
          {
            id: 'firstName',
            name: 'First name',
          },
          {
            id: 'lastName',
            name: 'Last name',
          },
          {
            id: 'middleInitial',
            name: 'Middle Initial',
          },
          {
            id: 'suffix',
            name: 'Suffix',
          },
          {
            id: 'gender',
            name: 'Gender',
          },
          {
            id: 'homePhone',
            name: 'Home Phone',
          },
          {
            id: 'homePhoneDoNotCall',
            name: 'Home Phone (Do not call)',
          },
          {
            id: 'homePhoneDoNotText',
            name: 'Home Phone (Do not text)',
          },
          {
            id: 'businessPhone',
            name: 'Business Phone',
          },
          {
            id: 'businessPhoneDoNotCall',
            name: 'Business Phone (Do not call)',
          },
          {
            id: 'businessPhoneDoNotText',
            name: 'Business Phone (Do not text)',
          },
          {
            id: 'mobilePhoneDoNotCall',
            name: 'Mobile Phone (Do not call)',
          },
          {
            id: 'mobilePhoneDoNotText',
            name: 'Mobile Phone (Do not text)',
          },
          {
            id: 'mobilePhone',
            name: 'Mobile Phone',
          },
          {
            id: 'dateOfBirth',
            name: 'Date Of Birth',
          },
          {
            id: 'username',
            name: 'Username',
          },
          {
            id: 'password',
            name: 'Password',
          },
          {
            id: 'memberURL',
            name: 'Member URL',
          },
          {
            id: 'role',
            name: 'Role',
          },
          {
            id: 'status',
            name: 'Status',
          },
          {
            id: 'language',
            name: 'Language',
          },
        ],
      },
      {
        id: 'memberAddress',
        name: 'Member Addresses',
        fields: [
          {
            id: 'addressType',
            name: 'Address Type',
          },
          {
            id: 'primary',
            name: 'Primary',
          },
          {
            id: 'address',
            name: 'Address',
          },
          {
            id: 'address2',
            name: 'Address2',
          },
          {
            id: 'city',
            name: 'City',
          },
          {
            id: 'state',
            name: 'State',
          },
          {
            id: 'zip',
            name: 'Zip',
          },
          {
            id: 'country',
            name: 'Country',
          },
          {
            id: 'doNotAllowContact',
            name: 'Do not allow contact',
          },
        ],
      },
      {
        id: 'memberEmailAddress',
        name: 'Member Email Addresses',
        fields: [
          {
            id: 'emailAddress',
            name: 'Email Address',
          },
          {
            id: 'primary',
            name: 'Primary',
          },
          {
            id: 'doNotAllowContact',
            name: 'Do not allow contact',
          },
        ],
      },
    ];

    if (importType === 'eligibility') {
      mappings[0].fields = mappings[0].fields.filter((field) => {
        return field.id != 'role';
      });
    }

    return mappings;
  }

  assignTransactionMappings(): any {
    return [
      {
        id: 'transaction',
        name: 'Activity data',
        fields: [
          {
            id: 'txnRefNum',
            name: 'Transaction Reference Number',
          },
          {
            id: 'originalTntRefNum',
            name: 'Original Tnt Ref Num',
          },
          {
            id: 'amount',
            name: 'Amount',
          },
          {
            id: 'locationCode',
            name: 'Location Code',
          },
          {
            id: 'activityCode',
            name: 'Activity Code',
          },
          {
            id: 'status',
            name: 'Status',
          },
          {
            id: 'behaviorHierarchySource',
            name: 'Behavior Hierarchy Source Id',
          },
          {
            id: 'txnDateTime',
            name: 'Transaction Date Time',
          },
          {
            id: 'locationHierarchySource',
            name: 'Location Hierarchy Source Id',
          },
        ],
      },
      {
        id: 'txnInstrument',
        name: 'Transaction instrument',
        fields: [
          {
            id: 'instrumentType',
            name: 'Instrument Type',
          },
          {
            id: 'instrumentNumber',
            name: 'Instrument Number',
          },
        ],
      },
      {
        id: 'txnProduct',
        name: 'Transaction products',
        fields: [
          {
            id: 'quantity',
            name: 'Quantity',
          },
          {
            id: 'amount',
            name: 'Amount',
          },
          {
            id: 'product',
            name: 'Product',
          },
        ],
      },
    ];
  }

  assingCommunicationPreferencesMappings(): any {
    return [
      {
        id: 'headers',
        name: 'Headers',
        fields: [
          {
            id: 'message_uuid',
            name: 'Message UUID',
          },
          {
            id: 'transaction_uuid',
            name: 'Transaction UUID',
          },
          {
            id: 'creation_time',
            name: 'Creation Time',
          },
          {
            id: 'message_type',
            name: 'Message Type',
          },
          {
            id: 'message_schema_owning_service',
            name: 'Message Schema Owning Service',
          },
          {
            id: 'message_schema_category',
            name: 'Message Schema Category',
          },
          {
            id: 'message_schema_version',
            name: 'Message Schema Version',
          },
          {
            id: 'source_service',
            name: 'Source Service',
          },
          {
            id: 'environment',
            name: 'Environment',
          },
          {
            id: 'contains_phi',
            name: 'Contains PHI',
          },
          {
            id: 'contains_pii',
            name: 'Contains PII',
          },
        ],
      },
      {
        id: 'member_information',
        name: 'Member Information',
        fields: [
          {
            id: 'member_id',
            name: 'Member ID',
          },
          {
            id: 'organization_id',
            name: 'Organization ID',
          },
        ],
      },
      {
        id: 'communication_preferences',
        name: 'Communication Preferences',
        fields: [
          {
            id: 'preference_type',
            name: 'Preference Type',
          },
        ],
        complexTypes: [
          {
            id: 'contact_method',
            name: 'Contact Method',
            multi: true,
            anyOf: [
              {
                id: 'email',
                name: 'Email',
                multi: true,
                fields: [
                  {
                    id: 'sub_type',
                    name: 'Sub Type',
                  },
                  {
                    id: 'email_address',
                    name: 'Email Address',
                  },
                  {
                    id: 'do_not_contact',
                    name: 'Do Not Contact',
                  },
                  {
                    id: 'effective_from',
                    name: 'Effective From',
                  },
                  {
                    id: 'effective_to',
                    name: 'Effective To',
                  },
                ],
              },
              {
                id: 'phone',
                name: 'Phone',
                multi: true,
                fields: [
                  {
                    id: 'sub_type',
                    name: 'Sub Type',
                  },
                  {
                    id: 'number',
                    name: 'Number',
                  },
                  {
                    id: 'do_not_contact',
                    name: 'Do Not Contact',
                  },
                  {
                    id: 'effective_from',
                    name: 'Effective From',
                  },
                  {
                    id: 'effective_to',
                    name: 'Effective To',
                  },
                ],
              },
              {
                id: 'address',
                name: 'Address',
                multi: true,
                fields: [
                  {
                    id: 'sub_type',
                    name: 'Sub Type',
                  },
                  {
                    id: 'address_line_1',
                    name: 'Address Line 1',
                  },
                  {
                    id: 'address_line_2',
                    name: 'Address Line 2',
                  },
                  {
                    id: 'city',
                    name: 'City',
                  },
                  {
                    id: 'state_province',
                    name: 'State or Province',
                  },
                  {
                    id: 'postal_code',
                    name: 'Postal Code',
                  },
                  {
                    id: 'postal_suffix',
                    name: 'Postal Suffix',
                  },
                  {
                    id: 'country',
                    name: 'Country',
                  },
                  {
                    id: 'do_not_contact',
                    name: 'Do Not Contact',
                  },
                  {
                    id: 'effective_from',
                    name: 'Effective From',
                  },
                  {
                    id: 'effective_to',
                    name: 'Effective To',
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
  }

  assignOrganizationMappings(): any {
    return [
      {
        id: 'organization',
        name: 'Organization Data',
        fields: [
          {
            id: 'organizationName',
            name: 'Organization name',
          },
          {
            id: 'description',
            name: 'Description',
          },
          {
            id: 'notes',
            name: 'Notes',
          },
          {
            id: 'createLogin',
            name: 'Create Login',
          },
          {
            id: 'memberCredential',
            name: 'Member Credential',
          },
          {
            id: 'username',
            name: 'Username',
          },
          {
            id: 'password',
            name: 'Password',
          },
          {
            id: 'yearStart',
            name: 'Year Start',
          },
          {
            id: 'sponsor',
            name: 'Sponsor',
          },
        ],
      },
      {
        id: 'organizationAddress',
        name: 'Organization Address',
        fields: [
          {
            id: 'primary',
            name: 'Primary',
          },
          {
            id: 'address',
            name: 'Address',
          },
          {
            id: 'address2',
            name: 'Address2',
          },
          {
            id: 'city',
            name: 'City',
          },
          {
            id: 'state',
            name: 'State',
          },
          {
            id: 'zip',
            name: 'Zip',
          },
          {
            id: 'country',
            name: 'Country',
          },
        ],
      },
    ];
  }
}
