import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RelationshipsService {
 setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getRelationships(partyId: number, params: any): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'party-relationships/party/' + partyId, this.setParams(params)
    );
  }
}
