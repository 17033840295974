import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from './security/security.service';
import { Observable } from 'rxjs';

@Injectable()
export class LoginGuard  {
  constructor(private router: Router, private securityService: SecurityService) {}
  public canActivate(): Observable<boolean> {
    return this.securityService.isLoggedIn;
  }
}
