<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-between">
            <div>
              <h2 class="com-title" style="color: #000; font-weight: 500; font-size: 22px">
                {{
                  parentOrganizationBasedOnChildType ? parentOrganizationBasedOnChildType.partyRoles[0]?.roleName : null
                }}
                {{
                  parentOrganizationBasedOnChildType === null
                    ? defaultParentName
                    : parentOrganizationBasedOnChildType?.name
                }}
              </h2>
            </div>
            <div class="d-flex align-items-center">
              <dt>Status:</dt>
              <dd class="d-flex align-items-center">
                <span
                  class="badgeactive ml-2 mt-2 rounded-pill"
                  [ngClass]="
                    statusHelper[
                      parentOrganizationBasedOnChildType
                        ? parentOrganizationBasedOnChildType.status
                        : EntityStatus.ACTIVE
                    ]
                  "
                >
                  {{
                    parentOrganizationBasedOnChildType ? parentOrganizationBasedOnChildType.status : EntityStatus.ACTIVE
                  }}
                </span>
              </dd>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 mb-3">
        <div class="d-flex justify-content-between dyn-bg">
          <div *ngFor="let stat of organizationStatistics" class="dyn-bgn">
            <div class="grey-box">
              <div class="stat-title-container">
                <span class="stat-title">{{ stat.title }}</span>
              </div>
              <div class="stat-count-container">
                <span class="stat-count">{{ stat.data }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-container">
        <div class="row">
          <div class="col-md-12">
            <div class="filters_container">
              <div class="search-input1">
                <input class="search-input-field" placeholder="Search" (change)="search()" [(ngModel)]="searchText" />
                <mat-icon class="search">search</mat-icon>
              </div>
              <div class="right_container1">
                <button class="filter-btn" (click)="FilterModel()">
                  FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
                </button>
                <button
                  class="btn-add gaponbothside"
                  style="width: auto !important; font-size: 14px !important"
                  (click)="openModal(null)"
                >
                  <strong> ADD {{ childEntityPartyRoleName | uppercase }} </strong>
                </button>
              </div>
            </div>
            <app-filter-tags
              *ngIf="searchFilters"
              isPopup="true"
              [(filterButton)]="filterButton"
              [(searchFilters)]="searchFilters"
              [(appliedFilters)]="appliedFilters"
            ></app-filter-tags>
          </div>
          <div class="col-md-12">
            <div class="row mt-3">
              <table class="table table-pointable table-bordered" width="100%">
                <thead>
                  <tr>
                    <th>{{ childEntityPartyRoleName }} name</th>

                    <th class="align-text-center">Status {{ childEntityPartyRoleName }}</th>
                    <th class="align-text-center">Date edited</th>
                    <th class="align-text-center">Action</th>
                    <th *ngIf="childEntityPartyRoleName === 'Market'" class="align-text-center">Logo</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let org of organizationList; let i = index">
                    <tr>
                      <td [title]="org.name">
                        <a class="link-row" (click)="navigate(org.id)">{{ org.name }}</a>
                      </td>
                      <td class="align-text-center">{{ org.status }}</td>
                      <td class="align-text-center">{{ org.updatedOn | date: "yyyy-MM-dd" }}</td>
                      <td class="align-text-center">
                        <a class="btn btn-outline-primary btn-sm edit action" title="Edit" (click)="openModal(org)"
                          ><fa-icon [icon]="faPencilAlt"></fa-icon>
                        </a>
                      </td>
                      <td *ngIf="childEntityPartyRoleName === 'Market'" class="align-text-center">
                        <img [src]="org.logoUrl" height="30px" />
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
                <tr
                  app-is-there-more
                  [numColumns]="childEntityPartyRoleName === 'Market' ? 5 : 4"
                  [newLengthEvent]="newLengthEvent"
                ></tr>
              </table>
            </div>
            <app-add-more-data-to-list
              (onScroll)="getAllHierarchicalOrganizationsForParent(true)"
              [lock]="lockedTables"
              [isLoading]="isLoading"
            >
            </app-add-more-data-to-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
