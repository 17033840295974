<div [ngClass]="filter.inputClass" class="search-filter-bottom" *ngIf="section.open">
  <div class="btn-group filter" ngbDropdown #filterDropdown="ngbDropdown" autoClose="outside">
    <button type="button" class="btn btn-md btn- filter-button form-control" id="dropdownSegment" ngbDropdownToggle>
      <span
        class="float-left"
        style="
          width: 97%;
          color: rgba(0, 0, 0, 0.38);
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          text-align: start;
          display: inline-block;
        "
        >{{ filter.placeholder }}
        <span *ngIf="heading !== 'Processing Log' && heading !== 'Outbound batch'"
          >({{ filter.selectedCount || 0 }})</span
        >
      </span>
    </button>
    <div ngbDropdownMenu aria-labelledby="filterDropdown" class="filter-dropdown_container">
      <span
        class="d-flex justify-content-end text-small mr-2 mt-2"
        style="color: rgb(25, 137, 189)"
        (click)="filterDropdown.close()"
        >Close menu</span
      >
      <div *ngFor="let choice of filter.choices">
        <div ngbDropdownItem class="checkbox checkbox-info" style="white-space: nowrap">
          <input
            id="{{ section.key + '-' + 'dropdown-checkbox' + '-' + choice[filter.nameProp] }}"
            [(ngModel)]="choice.state"
            type="checkbox"
            (ngModelChange)="toggleSelection(choice, filter)"
          />
          <label for="{{ section.key + '-' + 'dropdown-checkbox' + '-' + choice[filter.nameProp] }}">
            <span style="overflow: hidden; text-overflow: ellipsis; max-width: 100%">{{
              choice[filter.nameProp]
            }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
