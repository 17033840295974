import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ForgotPasswordService } from '../services/forgot-password.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppDataService } from '../app-data.service';
import { SecurityService } from '../services/security/security.service';
import { User } from '../data/model';
import { Apollo } from 'apollo-angular';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  party: any;
  newPassword: string;
  confirmPassword: string;
  loginBanner = '';
  setPasswordForm: UntypedFormGroup;
  errorMsg = '';
  passwordResetComplete: boolean = false;

  constructor(
    private router: Router,
    private forgotPasswordService: ForgotPasswordService,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    public globalData: AppDataService,
    private securityService: SecurityService,
    private apollo: Apollo
  ) {}

  ngOnInit(): void {
    this.setPasswordForm = this.formBuilder.group(
      {
        newPassword: ['', Validators.compose([Validators.required, this.patternValidator()])],
        repeatPassword: ['', Validators.compose([Validators.required, this.patternValidator()])],
      },
      {
        validator: this.matchValidator,
      }
    );
    this.securityService.requestCurrentUser.subscribe(
      (user: User) => {
        if (user) {
          this.party = user;
        }
      },
      (res) => {
        this.securityService.handleLoginError();
      }
    );
  }

  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp('^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).{8,}$');
      return regex.test(control.value) ? null : { invalidPassword: true };
    };
  }

  matchValidator(group: UntypedFormGroup): any {
    const repeatPassword = group.controls.repeatPassword;
    if (group.controls.newPassword.value !== repeatPassword.value && !repeatPassword.errors) {
      repeatPassword.setErrors({ invalidMatch: 'Passwords do not match' });
    } else if (group.controls.newPassword.value === repeatPassword.value && repeatPassword.errors) {
      delete repeatPassword.errors.invalidMatch;
    }
    return null;
  }

  submit() {
    if (this.setPasswordForm.get('newPassword').value !== this.setPasswordForm.get('repeatPassword').value) {
      this.errorMsg = 'Password does not match';
    } else if (this.setPasswordForm.get('newPassword').value === this.setPasswordForm.get('repeatPassword').value) {
      var splitted = window.location.href.split('/');
      var code = splitted[splitted.length - 1];
      this.party.password = this.setPasswordForm.get('newPassword').value;
      delete this.party.authorities;
      const data = {
        password: this.setPasswordForm.get('newPassword').value,
      };
      this.forgotPasswordService.newPassword(this.party).subscribe((res) => {
        this.toastr.success('Password has been changed. Please login again.');
        //this.router.navigate(['/logged-out']);
        this.passwordResetComplete = true;
        this.securityService.cleanOauth();
        localStorage.removeItem('token');
        this.globalData.onLogout(true);
        this.apollo.getClient().resetStore();
      });
    }
  }

  login() {
    this.router.navigate(['login']);
  }
}
