import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Campaign } from '../data/model';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCalendar, faExclamationTriangle, faPlus, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Choice } from '../data/class';
import { TableHelperService } from '../services/helpers/table-helper.service';
import { CampaignService } from '../services/campaign.service';
import { DateHelperService } from '../services/helpers/date-helper.service';
import { CommunicationStrategyService } from '../services/communications-strategy.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';

@Component({
  selector: 'app-campaign-modal',
  templateUrl: './campaign-modal.component.html',
  styleUrls: ['./campaign-modal.component.scss'],
})
export class CampaignModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public campaign: Campaign;
  @Input() public sponsorPartyId: number;
  communications: any[] = [];
  title: string;
  isEdit: boolean;
  isChanges: boolean = false;
  campaignForm: UntypedFormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;
  faExclamationTriangle = faExclamationTriangle;
  statuses: Choice[];
  faCalendar = faCalendar;
  activeTabIdx = 0;
  faPlus = faPlus;
  faTrash = faTrash;
  comm: any[];
  intervals = ['', 'YEAR', 'QUARTER', 'MONTH', 'WEEK', 'DAY'];
  tabs = [
    {
      displayName: 'Program',
      title: 'program',
      idx: 0,
    },
    {
      displayName: 'Program Attributes',
      title: 'program attributes',
      idx: 1,
    },
    {
      displayName: 'Party Attributes',
      title: 'party attributes',
      idx: 2,
    },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tableHelperService: TableHelperService,
    private campaignService: CampaignService,
    private communicationStrategyService: CommunicationStrategyService,
    private responseHelperService: ResponseHelperService,
    private dateHelperService: DateHelperService,
    private modalHelper: NgbModal
  ) {}

  ngOnInit(): void {
    this.setStatuses();
    if (this.campaign) {
      this.title = 'Edit Program';
      this.isEdit = true;
      this.campaignForm = this.formBuilder.group({
        name: [this.campaign.name, Validators.required],
        code: [this.campaign.code, Validators.required],
        status: [this.campaign.status, Validators.required],
        description: [this.campaign.description],
        expirationDate: [this.dateHelperService.stringToNgbDate(this.campaign.expirationDate)],
        externalProgramId: [this.campaign.externalProgramId, Validators.required],
        fromDate: [this.dateHelperService.stringToNgbDate(this.campaign.fromDate), Validators.required],
        thruDate: [this.dateHelperService.stringToNgbDate(this.campaign.thruDate)],
        cap: [this.campaign.cap],
        capRewardCodes: [this.campaign.capRewardCodes],
        sponsorCap: [this.campaign.sponsorCap],
        communicationStrategyId: [this.campaign.communicationStrategyId],
        rolloverInterval: [this.campaign.rolloverInterval],
        campaignAttribs: this.formBuilder.array([]),
        campaignEligibilityCondition: this.formBuilder.array([]),
      });
      if (this.campaign.campaignAttribs) {
        this.campaign.campaignAttribs.forEach((attibute) => {
          this.addAttrib(attibute.attrName, attibute.attrValue);
        });
      }
      if (this.campaign.campaignEligibilityCondition) {
        this.campaign.campaignEligibilityCondition.forEach((campaignEligibility) => {
          this.addCampEli(campaignEligibility.attrName, campaignEligibility.attrValues);
        });
      }
    } else {
      this.title = 'New Program';
      this.isEdit = false;
      this.campaignForm = this.formBuilder.group({
        name: ['', Validators.required],
        code: ['', Validators.required],
        status: [null, Validators.required],
        expirationDate: [null],
        description: [''],
        fromDate: [null, Validators.required],
        thruDate: [],
        cap: [],
        externalProgramId: ['', Validators.required],
        capRewardCodes: [],
        sponsorCap: [],
        communicationStrategyId: [],
        rolloverInterval: [],
        campaignAttribs: this.formBuilder.array([]),
        campaignEligibilityCondition: this.formBuilder.array([]),
      });
    }
    this.communicationStrategyService.getAllCommunicationStrategy().subscribe((data) => {
      if (data.success) {
        this.communications = data.entity.records;
      }
    });
  }

  campaignAttribs(): UntypedFormArray {
    return this.campaignForm.controls.campaignAttribs as UntypedFormArray;
  }

  campaignEligibilityCondition(): UntypedFormArray {
    return this.campaignForm.controls.campaignEligibilityCondition as UntypedFormArray;
  }

  camp(formContr): UntypedFormArray {
    return formContr.controls.attrValues as UntypedFormArray;
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  changeTab(tabIndex: number): void {
    this.activeTabIdx = tabIndex;
  }

  ok(): void {
    const formValue = Object.assign({}, this.campaignForm.value);
    formValue.expirationDate = this.dateHelperService.ngbDateToString(formValue.expirationDate);
    formValue.fromDate = this.dateHelperService.ngbDateToString(formValue.fromDate);
    formValue.thruDate = this.dateHelperService.ngbDateToString(formValue.thruDate);
    formValue.sponsorPartyId = this.sponsorPartyId;
    const list = [];
    formValue.campaignEligibilityCondition.forEach((element) => {
      const elCopy = { ...element };
      elCopy.attrValues = element.attrValues.map(function (item) {
        return item['attrValue'];
      });
      list.push(elCopy);
    });
    formValue.campaignEligibilityCondition = list;

    formValue.campaignAttribs.forEach((element) => {
      // trim whitespace
      element.attrValue = element.attrValue.trim();
      element.attrName = element.attrName.trim();
    });

    this.campaignService.createOrUpdateCampaign(this.campaign && this.campaign.id, formValue).subscribe(
      () => {
        this.successEvent.emit('success');
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error, false);
      }
    );
  }

  clearDate(name: string): void {
    this.campaignForm.patchValue({
      [name]: null,
    });
  }

  changesMade(): void {
    this.isChanges = true;
  }

  cancel(): void {
    if (this.isChanges) {
      if (confirm('Are you sure you want to cancel without saving changes?')) {
        this.modalHelper.dismissAll();
      }
    } else {
      this.modalHelper.dismissAll();
    }
  }

  newAttribute(attrName?: string, attrValue?: string): UntypedFormGroup {
    return this.formBuilder.group({
      attrName: [attrName || '', Validators.required],
      attrValue: [attrValue || '', Validators.required],
    });
  }

  newCampaignEli(attrName?: string, attrValues?: string[]) {
    const builder = this.formBuilder.group({
      attrName: [attrName || '', Validators.required],
      attrValues: this.formBuilder.array([]),
    });
    const cont = builder.controls.attrValues as UntypedFormArray;
    if (attrValues) {
      attrValues.forEach((value) => {
        cont.push(this.newValue(value));
      });
    }
    return builder;
  }

  addAttrib(attrName?: string, attrValue?: string): void {
    this.campaignAttribs().push(this.newAttribute(attrName, attrValue));
  }
  addCampEli(attrName?: string, attrValues?: string[]): void {
    this.campaignEligibilityCondition().push(this.newCampaignEli(attrName, attrValues));
  }

  addValue(form) {
    form.push(this.newValue());
  }

  newValue(value?: string): UntypedFormGroup {
    return this.formBuilder.group({
      attrValue: [value || '', Validators.required],
    });
  }

  removeAttrib(index: number): void {
    this.campaignAttribs().removeAt(index);
  }
  removeCampaignEli(index: number): void {
    this.campaignEligibilityCondition().removeAt(index);
  }

  removeValue(form, index: number): void {
    form.removeAt(index);
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
