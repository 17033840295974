import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddressModel } from 'src/app/data/model';
import { AddressService } from 'src/app/services/address.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { AutocompleteComponent } from 'angular-ng-autocomplete';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit, AfterViewInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public address: AddressModel = new AddressModel();
  @Input() public partyId: number;
  @Input() public numberOfAddresses: number;
  @Input() public addressData: any;
  allStates: any[] = [];
  title: string;
  isEdit: boolean;
  addressForm: UntypedFormGroup;
  errorMsg: string;
  faTimes = faTimes;
  @ViewChild('country') country: AutocompleteComponent;
  @ViewChild('state') state: AutocompleteComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private responseHelperService: ResponseHelperService,
    private addressService: AddressService,
    private modalHelper: NgbModal
  ) {}

  ngAfterViewInit(): void {
    this.country.close();
  }

  initFilteredStates(): void {
    this.state.filteredList = this.addressData.states;
  }

  ngOnInit(): void {
    this.allStates = [...this.addressData.states];
    if (this.address.addressId) {
      this.title = 'Edit address';
      this.isEdit = true;
    } else {
      this.title = 'Add address';
      this.isEdit = false;
        this.setStatesForCountry(
          this.addressData.countries.find((country: any) => country.code === this.address.country)
        );
    }
    this.addressForm = this.formBuilder.group({
      address1: [this.address.address1, Validators.required],
      address2: [this.address.address2],
      city: [this.address.city, Validators.required],
      country: [this.address.country, Validators.required],
      postalCode: [this.address.postalCode, Validators.required],
      state: [this.formatStateCode(this.address.state), Validators.required],
      addressType: [this.address.addressType, Validators.required],
      isPrimary: [this.address.isPrimary],
    });
    this.addressForm.get('country').valueChanges.subscribe((country: any) => {
      this.setStatesForCountry(country);
    });
  }

  setStatesForCountry(country: any): void {
    if (country) {
      this.addressForm?.controls.state.enable();
      if(typeof country === 'string'){
        this.addressData.states = this.allStates.filter((state: any) => state.countryCode === country);
      } else {
        this.addressData.states = this.allStates.filter((state: any) => state.countryCode === country.code);
      }
    } else {
      this.addressForm.controls.state.setValue('');
      this.addressForm.controls.state.disable();
    }
  }

  selectState(event: any): void {
    this.addressForm.patchValue({ state: event.code.split('-')[1] });
  }

  formatStateCode(stateCode: string): string {
    if (stateCode) {
      return stateCode.includes('-') ? stateCode.split('-')[1] : stateCode;
    }
    return stateCode;
  }

  selectCourty(event: any): void {
    this.addressForm.patchValue({ country: event.code });
  }

  ok(): void {
    const value = this.addressForm.value;
    value.partyId = this.partyId;
    if (value.state.countryCode) {
      value.state = this.formatStateCode(value.state.code);
    }
    if (value.country.code) {
      value.country = value.country.code;
    }
    if (!this.isEdit && this.numberOfAddresses === 0) {
      this.address.isPrimary = true;
    }
    this.addressService
      .createOrUpdateAddresss(this.partyId, this.address.addressId, { ...this.address, ...value })
      .subscribe(
        () => {
          this.successEvent.emit('success');
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  filterByNameAndCode(items: any[], query: string): any[] {
    if (query) {
      query = query.toLocaleLowerCase();
      return items.filter(
        (state: any) => state.name.toLocaleLowerCase().includes(query) || state.code.toLocaleLowerCase().includes(query)
      );
    }
    return items;
  }
}
