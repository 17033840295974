<form [formGroup]="form">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Name <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" class="form-control" formControlName="name" placeholder="Name" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Year start</label>
          </div>
          <div class="input-group date">
            <input class="form-control" type="text" placeholder="mm/yy" formControlName="yearStart" mask="00/00" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="yearStart.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
          <input
            class="form-control cursor-pointer bgcolor"
            style="visibility: hidden; height: 0px !important; padding: 0px !important"
            placeholder="mm/dd"
            name="yearStart"
            ngbDatepicker
            (dateSelect)="yearStartChanged($event)"
            #yearStart="ngbDatepicker"
            [readonly]="true"
            (click)="yearStart.toggle()"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Status <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <select name="status" class="form-control" formControlName="status">
            <option *ngFor="let status of statuses" value="{{ status }}">{{ status }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>URL</label>
          </div>
          <input type="text" class="form-control" formControlName="url" placeholder="URL" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Organization Logo URL</label>
          </div>
          <input type="text" class="form-control" formControlName="logoUrl" placeholder="Logo URL" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>External Organization Code</label>
          </div>
          <input
            type="text"
            class="form-control"
            formControlName="externalOrgCode"
            placeholder="External Organization Code"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons [submitText]="isEdit ? 'Save' : 'Add'" (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!form.valid"></app-modal-buttons>
  </div>
</form>
