<div>
  <form>
    <label>The member's age is:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="ageRestriction !== null">
        <div *ngFor="let ageRes of ageRestriction; let i = index" class="border-card-padding form-group col-lg-12">
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeRestriction(ageRes)"
            title="Remove Restriction"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form #form="ngForm" name="form_{{ i }}">
            <div class="form-group">
              <select
                required
                class="form-control styled-select"
                [(ngModel)]="ageRes.direction"
                name="direction_{{ i }}"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let direction of directions"
                  [selected]="ageRes.direction === direction.value"
                  [value]="direction.value"
                >
                  {{ direction.value }}
                </option>
              </select>
              <div class="mt-sm" [hidden]="ageRes.direction">
                <small class="text-danger">Select direction</small>
              </div>
            </div>
            <ng-container>
              <div class="form-group">
                <label class="control-label" for="value_{{ i }}">Than:</label>
                <input
                  required
                  class="form-control m-b"
                  type="number"
                  id="value_{{ i }}"
                  name="value_{{ i }}"
                  [(ngModel)]="ageRes.value"
                  (ngModelChange)="updateRestrictions()"
                  (keypress)="keyPressNumbers($event)"
                />
                <div class="mt-sm" [hidden]="ageRes.value">
                  <small class="text-danger">Input value</small>
                </div>
              </div>
              <div class="form-group">
                <select
                  required
                  class="form-control styled-select"
                  name="units{{ i }}"
                  [(ngModel)]="ageRes.units"
                  (ngModelChange)="updateRestrictions()"
                >
                  <option *ngFor="let per of units" [selected]="ageRes.units === per.value" [value]="per.value">
                    {{ per.text }}
                  </option>
                </select>
                <div class="mt-sm" [hidden]="ageRes.units">
                  <small class="text-danger">Select units</small>
                </div>
              </div>
            </ng-container>
            <div class="form-group" *ngIf="configurationType === 'rules'">
              <label class="control-label mb-2">Message:</label>
              <textarea
                type="text"
                class="form-control"
                [(ngModel)]="ageRes.message"
                name="message"
                name="message_{{ i }}"
                (ngModelChange)="updateRestrictions()"
              ></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group float-right col-md-12">
        <button class="btn btn-default float-right" (click)="initRestrictionObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Party Age Restriction
        </button>
      </div>
    </div>
  </form>
</div>
