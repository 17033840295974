<form [formGroup]="parameterForm">
  <div class="modal-header">
    <h1>Add parameter</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group" *ngIf="!param.systemParameter">
      <label>Order</label>
      <input type="number" formControlName="paramSeqNum" class="form-control" />
    </div>
    <div class="form-group">
      <label>Code</label>
      <input type="text" formControlName="paramCode" class="form-control" />
    </div>
    <div class="form-group">
      <label>Name</label>
      <input type="text" formControlName="paramName" class="form-control" />
    </div>
    <div class="form-group">
      <label>Type</label>
      <select formControlName="paramType" class="form-control">
        <option [selected]="param.paramType === option" *ngFor="let option of paramTypes" value="{{ option }}">
          {{ option }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>Default</label>
      <div *ngIf="param.autocompleteAction && (param.paramType === 'BIGINT' || param.paramType === 'VARCHAR')">
        <ng-select
          [items]="autocomplete[param.paramCode]"
          bindLabel="name"
          [multiple]="false"
          formControlName="promotions"
          [hideSelected]="true"
          [closeOnSelect]="true"
        >
        </ng-select>
      </div>
      <div
        *ngIf="param.autocompleteAction && (param.paramType === 'ARRAY VARCHAR' || param.paramType === 'ARRAY INTEGER')"
      >
        <ng-select
          [items]="allDataArray[param.paramCode]"
          bindLabel="name"
          [multiple]="false"
          formControlName="promotions"
          [hideSelected]="true"
          [closeOnSelect]="true"
        >
        </ng-select>
      </div>
      <div *ngIf="param.paramType === 'TIMESTAMP'">
        <select formControlName="defaultValue" class="form-control">
          <option *ngFor="let option of defaultValues[param.paramType]" value="{{ option }}">{{ option }}</option>
        </select>
      </div>
      <div *ngIf="param.paramType === 'BIGINT'">
        <select formControlName="defaultValue" class="form-control">
          <option
            [selected]="param.defaultValue === option"
            *ngFor="let option of defaultValues[param.paramType]"
            value="{{ option }}"
          >
            {{ option }}
          </option>
        </select>
      </div>
      <div *ngIf="(isInput(param.paramType) && !param.autocompleteAction) || !param.paramType">
        <input type="text" class="form-control" formControlName="defaultValue" />
      </div>
    </div>
    <div class="form-group" *ngIf="!param.systemParameter">
      <label>Autocomplete</label>
      <select
        *ngIf="isArray(param)"
        formControlName="autocompleteAction"
        (change)="getDataForParams(param)"
        class="form-control"
      >
        <option
          [selected]="param.autocompleteAction === option"
          *ngFor="let option of autocomplete"
          value="{{ option }}"
        >
          {{ option }}
        </option>
      </select>
      <input
        *ngIf="!isArray(param)"
        type="text"
        formControlName="autocompleteAction"
        (keyup)="changedAuto(param)"
        class="form-control"
      />
    </div>
    <div class="form-group form-check" *ngIf="!param.systemParameter">
      <input class="form-check-input" type="checkbox" formControlName="requiredParam" />
      <label for="requiredParam">Required</label>
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()"></app-modal-buttons>
  </div>
</form>
