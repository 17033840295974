import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Filter } from 'src/app/data/class';
import { SponsorsService } from './../services/sponsors.service';
import { PersonsService } from './../services/persons.service';
import { ResponseHelperService } from './../services/helpers/response-helper.service';
import { CampaignService } from './../services/campaign.service';
import { StatusHelperService } from './../services/helpers/status-helper.service';
import { ProgramService } from './../services/program.service';
import { PromotionCategoryService } from './../services/promotion-category.service';
import { Segment, Promotion, Program } from './../data/model';
import { OnInit, EventEmitter, AfterViewInit, Component, Input, Output, ViewChild } from '@angular/core';
import { faCalendar, faCogs, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DateHelperService } from '../services/helpers/date-helper.service';
import { NgForm } from '@angular/forms';
import { GlobalEventService } from '../services/global-event.service';

@Component({
  selector: 'app-add-sponsorship-modal',
  templateUrl: './add-sponsorship-modal.component.html',
  styleUrls: ['./add-sponsorship-modal.component.scss'],
})
export class AddSponsorshipModalComponent implements OnInit, AfterViewInit {
  @Input() public item: any;
  @Input() public sponsorName: string;
  @Input() public personId: number;
  @Input() public filter: Filter;
  @Input() public campaignId: number;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();

  title = 'Add Sponsorship';
  select: any = {
    selectedType: undefined,
    selectedPackage: undefined,
    selectedCategory: undefined,
  };
  showOnlyPackagePromos = false;
  selectedSegments: Segment[] = [];
  filteredSegments: Segment[] = [];
  parentsOfSelectedChildPromos = [];
  segment: Segment;
  packageHasPromos = false;
  today = new Date();
  packageName: string;
  programStructure: any;
  promotionCategoryFilter: any = {};
  defaultStatuses = ['ACTIVE', 'PENDING', 'STARTED'];
  campaignsFilter: any = {};
  numOfPromotions = 0;
  programName: string;
  programCode: number;
  programId: number;
  faCalendar = faCalendar;
  faCogs = faCogs;
  faSave = faSave;
  faTimes = faTimes;
  errorMsg: string;
  activeId = 1;
  hasErrors = true;
  @ViewChild('form') form: NgForm;

  constructor(
    private promotionCategoryService: PromotionCategoryService,
    private programService: ProgramService,
    private statusHelperService: StatusHelperService,
    private campaignService: CampaignService,
    private personsService: PersonsService,
    private sponsorsService: SponsorsService,
    private responseHelper: ResponseHelperService,
    private dateService: DateHelperService,
    private modalHelper: NgbModal,
    private globalEventService: GlobalEventService
  ) {}

  statusHelper = this.statusHelperService.getStatus('badge');

  ngOnInit(): void {
    this.item.promoSponsor = {};
    this.updateTag();
    this.promotionCategoryFilter.statuses = this.defaultStatuses;
    this.promotionCategoryFilter.sponsorId = this.personId;
    this.campaignsFilter.statuses = this.defaultStatuses;
    this.getAllPromotionCategories();
    //this.getAllCampaigns({ statuses: ['ACTIVE', 'PENDING', 'STARTED'] });
    this.getPersonSegments();
  }

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe(() => {
      this.hasErrors = Object.keys(this.form.controls).some((key: string) => {
        this.form.controls[key].markAsTouched();
        return this.form.controls[key].invalid;
      });
    });

    this.globalEventService.getGlobalEvent().subscribe((response: any) => {
      if (response.toComponent === 'add-sponsorship-modal') {
        this.select.selectedPromotion = response.data;
      }
    });
  }

  clearDate(name: string): void {
    this.item.promoSponsor[name] = null;
  }

  updateTag(): void {
    this.item.promoSponsor.sponsorshipTag =
      this.sponsorName +
      ':' +
      (this.programCode ? this.programCode + ':' : '') +
      (this.packageName ? this.packageName + ':' : '') +
      this.today.toISOString().split('T')[0];
  }

  getAllPromotionCategories(): void {
    this.promotionCategoryService.getAllPromotionCategories(this.promotionCategoryFilter).subscribe((data: any) => {
      if (data.success) {
        this.item.categories = data.entity.records;
      }
    });
  }

  getProgramPackages(programId: number): void {
    this.programService.getProgramPackages(programId).subscribe((data: any) => {
      if (data.success) {
        this.item.programPackages = data.entity;
      }
    });
  }

  selection(event: any): void {
    this.select.selectedType = event;
    this.select.selectedPackage = undefined;
    this.select.selectedPromotion = undefined;
    this.select.promotions = undefined;
    this.programStructure = undefined;
    this.packageName = undefined;
    this.errorMsg = '';
    const promosLimit = 500;
    const promosOffset = 0;
    this.getProgramPromos(this.select.selectedType, promosLimit, promosOffset);
    this.selectProgram(this.select.selectedType);
  }

  getProgramPromos(item: any, promosLimit: number, promosOffset: number): void {
    this.item.promotions = null;
    this.programService.getProgramPromotions(item.id, promosLimit, promosOffset).subscribe((data: any) => {
      if (data.success) {
        if (!this.item.promotions) {
          this.item.promotions = [];
        }
        this.item.promotions = this.item.promotions.concat(data.entity);
        this.numOfPromotions += this.item.promotions.length;
        this.programName = this.select.selectedType.name;
        this.programCode = this.select.selectedType.code;
        this.programId = this.select.selectedType.id;

        if (data.entity.length === promosLimit) {
          promosOffset += promosLimit;
          this.getProgramPromos(item, promosLimit, promosOffset);
        } else {
          this.updateTag();
          this.getProgramPackages(item.id);
        }
      }
    });
  }

  selectProgram(item: Program): void {
    this.programService.getProgramStructure(item.id).subscribe((data: any) => {
      this.programStructure = data.entity;
    });
  }

  doesPackageHavePromos(promotion: any): boolean {
    let hasPackage: boolean;
    if (promotion.hasSelectedPackage || hasPackage) {
      hasPackage = true;
      return hasPackage;
    } else if (promotion.children && promotion.children.length > 0) {
      for (var i = 0, len = promotion.children.length; i < len; i++) {
        if (promotion.children[i].hasSelectedPackage) {
          hasPackage = true;
          break;
        } else if (promotion.children[i].children && promotion.children[i].children.length > 0) {
          //return this.doesPackageHavePromos(promotion.children[i]);
          for (var j = 0, leng = promotion.children[i].children.length; j < leng; j++) {
            if (promotion.children[i].children[j].hasSelectedPackage) {
              hasPackage = true;
              break;
            }
          }
        }
      }
    }
    return hasPackage;
  }

  packageSelection(): void {
    const params = {
      packageId: this.select.selectedPackage.id,
      includeTemplates: true,
    };

    this.programService.getProgramStructure(this.programId, params).subscribe((data: any) => {
      this.programStructure = data.entity;
      this.packageHasPromos = this.programStructure.promotions.some((promo: any) => {
        return this.doesPackageHavePromos(promo);
      });

      if (!this.packageHasPromos) {
        this.errorMsg = 'No promotion templates have been associated to the selected package.';
      } else {
        this.errorMsg = '';
      }
    });

    this.packageName = this.select.selectedPackage.packageName;
    this.updateTag();
    this.item.promoSponsor.scoreStartDate = this.dateService.stringToNgbDate(this.select.selectedPackage.startDate);
    this.item.promoSponsor.scoreEndDate = this.dateService.stringToNgbDate(this.select.selectedPackage.endDate);
  }

  getPersonSegments(): void {
    const params = {
      children: true,
    };
    this.personsService.getPersonSegments(this.personId, params).subscribe((data: any) => {
      if (data.success) {
        this.filteredSegments = data.entity;
        if (this.filteredSegments.length === 1) {
          this.segment = this.filteredSegments[0];
        }
      }
    });
  }

  compareById(promotion1: any, promotion2: any): boolean {
    return promotion1 && promotion2 && promotion2.id === promotion1.id;
  }

  convertNgbDatesToString(promotionSponsor: any): void {
    if (promotionSponsor.processingEndDate) {
      promotionSponsor.processingEndDate = this.dateService.ngbDateToString(promotionSponsor.processingEndDate);
    }
    if (promotionSponsor.processingStartDate) {
      promotionSponsor.processingStartDate = this.dateService.ngbDateToString(promotionSponsor.processingStartDate);
    }
    if (promotionSponsor.publishedEndDate) {
      promotionSponsor.publishedEndDate = this.dateService.ngbDateToString(promotionSponsor.publishedEndDate);
    }
    if (promotionSponsor.publishedStartDate) {
      promotionSponsor.publishedStartDate = this.dateService.ngbDateToString(promotionSponsor.publishedStartDate);
    }
    if (promotionSponsor.scoreEndDate) {
      promotionSponsor.scoreEndDate = this.dateService.ngbDateToString(promotionSponsor.scoreEndDate);
    }
    if (promotionSponsor.scoreStartDate) {
      promotionSponsor.scoreStartDate = this.dateService.ngbDateToString(promotionSponsor.scoreStartDate);
    }
  }

  ok(): void {
    const promotionSponsors = [];
    if (this.select.selectedPromotion && this.select.selectedPromotion.length > 0 && !this.select.selectedPackage) {
      this.select.selectedPromotion.forEach((promotion: Promotion) => {
        const promotionSponsor = { ...this.item.promoSponsor };
        promotionSponsor.segmentIds = [];
        this.convertNgbDatesToString(promotionSponsor);
        promotionSponsor.sponsorPartyId = this.personId;
        promotionSponsor.promotionId = promotion.id;
        promotionSponsor.categoryId = this.select.selectedCategory && this.select.selectedCategory.id;
        promotionSponsor.status = promotion.status;
        promotionSponsor.campaignId = this.campaignId;
        if (this.select.selectedPackage) {
          promotionSponsor.packageId = this.select.selectedPackage.id;
        }
        promotionSponsor.segmentIds.push(this.segment.id);
        promotionSponsors.push(promotionSponsor);
      });
      this.sponsorsService.addPromotionToSponsor(promotionSponsors).subscribe(
        () => {
          this.responseHelper.success('Promotion successfully updated', true);
          this.successEvent.emit('success');
        },
        (data: any) => {
          this.errorMsg = data.error.error;
        }
      );
    } else if (this.programStructure && this.select.selectedPackage) {
      const packagedSponsorship: any = {};
      packagedSponsorship.promotionSponsor = this.item.promoSponsor;
      packagedSponsorship.segmentIds = [];
      this.convertNgbDatesToString(packagedSponsorship.promotionSponsor);
      packagedSponsorship.promotionSponsor.sponsorPartyId = this.personId;
      packagedSponsorship.campaignId = this.campaignId;
      packagedSponsorship.promotionSponsor.categoryId = this.select.selectedCategory.id;
      packagedSponsorship.programId = this.select.selectedType.id;
      packagedSponsorship.packageId = this.select.selectedPackage.id;
      packagedSponsorship.baseCfgRef = this.sponsorName + ':' + this.select.selectedPackage.packageName;
      packagedSponsorship.segmentIds.push(this.segment.id);
      this.sponsorsService.createPackagedSponsorship(packagedSponsorship).subscribe(
        () => {
          this.responseHelper.success('Sponsorships and Configurations successfully created', true);
          this.successEvent.emit('success');
        },
        (data: any) => {
          this.responseHelper.error(this, data.error.error, false);
        }
      );
    }
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  searchByNameAndCode(term: string, program: Program): boolean {
    term = term.toLocaleLowerCase();
    return (
      program.code.toString().toLocaleLowerCase().indexOf(term) > -1 ||
      program.name.toLocaleLowerCase().indexOf(term) > -1
    );
  }

  // TODO: I can't create sponsorship on qa1 to test these

  // // so the segments validation works with the funky vs-repeat dropdown
  // $scope.$watch('selectedSegments.length', function(newVal) {
  //   if( newVal ) {
  //     $scope.segmentsLength = newVal;
  //   } else {
  //     $scope.segmentsLength = undefined;
  //   }
  // });
}
