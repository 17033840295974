import { ToastrService } from 'ngx-toastr';
import { GlobalEventService } from './../../services/global-event.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketService } from './../../services/ticket.service';
import { ContactReason } from './../../data/model';
import { ContactReasonService } from './../../services/contact-reason.service';
import { InstrumentsService } from './../../services/instruments.service';
import { MembersService } from './../../services/members.service';
import { ContactMethodHelperService } from './../../services/helpers/contact-method-helper.service';
import { PartyExtensionService } from './../../services/party-extension.service';
import { ConfigurationService } from './../../services/configuration.service';

import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { openWindow } from 'src/app/data/window-open-function';

@Component({
  selector: 'app-start-note-modal',
  templateUrl: './start-note-modal.component.html',
  styleUrls: ['./start-note-modal.component.scss'],
})
export class StartNoteModalComponent implements OnInit {
  @Input() interaction: any;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  interactionForm: UntypedFormGroup;
  contactMethods: any[];
  contactReasons: ContactReason[];
  selectedSurveyPath: string;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;
  memberUrl: string;
  primaryInstrumentCode: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private contactMethodHelperService: ContactMethodHelperService,
    private contactReasonService: ContactReasonService,
    private ticketService: TicketService,
    private partyExtensionService: PartyExtensionService,
    private configurationService: ConfigurationService,
    private instrumentsService: InstrumentsService,
    private membersService: MembersService,
    private modalHelper: NgbModal,
    private globalEventService: GlobalEventService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.interactionForm = this.formBuilder.group({
      subject: ['', Validators.required],
      contactMethod: ['', Validators.required],
      contactReason: ['', Validators.required],
    });

    this.interactionForm.addControl('memberPartyId', new UntypedFormControl(this.interaction.memberPartyId));

    this.contactMethods = this.contactMethodHelperService.getContactMethods('value');
    this.getContactReasons();
    this.getMemberUrl(this.interaction.memberPartyId);
    this.getPrimaryInstrumentForParty(this.interaction.memberPartyId);
  }

  getContactReasons(): void {
    this.contactReasonService.getContactReasons({ statuses: ['ACTIVE'] }).subscribe(
      (data: any) => {
        if (data.success) {
          this.contactReasons = data.entity;
        }
      },
      (data: any) => {
        this.toastr.error(data.error.error);
      }
    );
  }

  ok(): void {
    this.contactReasons.forEach((reason) => {
      if (reason.id.toString() === this.interactionForm.controls['contactReason'].value) {
        this.selectedSurveyPath = reason.surveyPath;
      }
    });
    this.ticketService.createOrUpdateTicket(this.interactionForm.value).subscribe((data: any) => {
      if (data.success) {
        if (this.selectedSurveyPath) {
          this.successEvent.emit();
          this.openSurvey(this.selectedSurveyPath, data.entity.id);
        } else {
          const dataToSend: any = {};
          dataToSend.data = data.entity;
          dataToSend.toComponent = 'tickets';
          this.globalEventService.sendGlobalEvent(dataToSend);
          this.successEvent.emit();
        }
      } else {
        this.errorMsg = data.entity;
      }
    });
  }

  getPrimaryInstrumentForParty(partyId: number): void {
    this.instrumentsService.getPrimaryInstrumentForParty(partyId).subscribe((resp: any) => {
      if (resp.success && resp.entity) {
        this.primaryInstrumentCode = resp.entity.instrumentNumber;
      }
    });
  }

  getMemberUrl(partyId: number): void {
    // first see if it's defined at the party_extension level
    this.partyExtensionService
      .getNamedExtension(this.interaction.memberPartyId, 'member_extensions')
      .subscribe((data: any) => {
        if (data.success && data.entity && data.entity.member_extensions && data.entity.member_extensions.memberUrl) {
          this.memberUrl = data.entity.member_extensions.memberUrl;
        } else {
          this.configurationService.getConfiguration('MEMBER_UI_LOCATION').subscribe((configData: any) => {
            if (configData.success && configData.entity && configData.entity.length > 0) {
              this.memberUrl = configData.entity[0].cfgValue;
            } else {
              // otherwise this just isn't configured, so fail it.
              this.toastr.error('Member Impersonation is not configured.');
            }
          });
        }
      });
  }

  openSurvey = function (surveyPath, memberInteractionId) {
    const token = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).sessionId;
    if (this.token !== null && surveyPath !== null && this.primaryInstrumentCode !== null) {
      const emulationRequestLog = {
        partyIdEmulated: this.interaction.memberPartyId,
        authorizedBy: 'SYSTEM',
        notes: surveyPath,
        emulationTarget: 'INTERACTION SURVEY',
      };
      this.membersService.logEmulate(this.interaction.memberPartyId, emulationRequestLog).subscribe((data: any) => {
        if (data.success) {
          var url =
            this.memberUrl +
            '/redirect?token=' +
            token +
            '&path=' +
            surveyPath +
            '&on_behalf_of=' +
            this.primaryInstrumentCode +
            '&fullscreen=t&type=INTERACTION&interactionId=' +
            memberInteractionId +
            '&params=type,interactionId';
          openWindow(url, '_blank', 'location=yes,height=1000,width=800,left=100,scrollbars=yes,status=yes');
        }
      });
    }
  };

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
