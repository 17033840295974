<form>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="row">
        <div class="table margin-right-1 mb-0">
          <table class="table table-pointable table-hover mb-0">
            <thead>
              <tr>
                <th width="5%">ID</th>
                <th>Item Id</th>
                <th width="10%">Image</th>
                <th>Line #</th>
                <th>Name</th>
                <th>Cost</th>
                <th>Points</th>
                <th>Profit</th>
                <th>Markup Rate</th>
                <th width="5%">#</th>
                <th>Color</th>
                <th>Size</th>
                <th>Conversion Rate</th>
                <th>Attributes</th>
                <th>Status</th>
                <th>Updated</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of redemptionItems">
                <td>{{ item.id }}</td>
                <td class="import-message">{{ item.itemId }}</td>
                <td><img src="{{ item.imageUrl }}" width="100" alt="" /></td>
                <td>{{ item.linenum }}</td>
                <td>{{ item.itemName }}</td>
                <td>{{ item.itemCost }}</td>
                <td>{{ item.itemPoints }}</td>
                <td>{{ item.itemProfit }}</td>
                <td>{{ item.markupRate }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.color }}</td>
                <td>{{ item.itemSize }}</td>
                <td>{{ item.convRate }}</td>
                <td>
                  <div>{{ item.attr1 }}</div>
                  <div>{{ item.attr2 }}</div>
                  <div>{{ item.attr3 }}</div>
                </td>
                <td class="status-editor">
                  <app-status-select
                    [type]="type"
                    [selectedModel]="item"
                    [permission]="globalData.isCSRManager && securityService.allowStatusUpdates"
                  ></app-status-select>
                </td>
                <td>{{ item.updatedOn }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons [showSubmitButton]="false" cancelText="Close" (cancel)="cancel()"></app-modal-buttons>
  </div>
</form>
