import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppDataService } from '../app-data.service';
@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss'],
})
export class CommunicationsComponent implements OnInit {
  constructor(
    private titleHelper: Title,
    private globalData: AppDataService
  ) {}

  active = 0;
  isAdmin = this.globalData.isAdmin;
  useAllowlist = this.globalData.useAllowlist;

  ngOnInit(): void {
    this.titleHelper.setTitle('Communications');
  }

  tabClick(tab) {
    this.active = tab.index;
    if (this.isAdmin && this.useAllowlist) {
      tab.index == 7 ? this.titleHelper.setTitle('Allow List') : this.titleHelper.setTitle('Communications');
    }
  }
}
