import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faTimes, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { RegexSearchModalComponent } from 'src/app/regex-search-modal/regex-search-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-promotion-code-form',
  templateUrl: './promotion-code-form.component.html',
  styleUrls: ['./promotion-code-form.component.scss'],
})
export class PromotionCodeFormComponent implements OnInit {
  @Input() promoCodeFormModel: any;
  @Input() promoCodeFormValue: any;
  @Input() index: any;
  @Input() configurationType: string;
  @Output() promoCodeEvent = new EventEmitter();
  promoCodeForm: UntypedFormGroup;
  @ViewChild(RegexSearchModalComponent) regexSearchModalComponent: RegexSearchModalComponent;
  originalPromotionCode: string;
  faTimes = faTimes;
  faPlus = faPlus;
  faSave = faSave;

  constructor(private formBuilder: UntypedFormBuilder, private modalHelper: NgbModal) {}

  ngOnInit(): void {
    if (this.index !== null) {
      const model =
        this.configurationType === 'rules' ? this.promoCodeFormModel : this.promoCodeFormModel.promotionCode;
      this.promoCodeForm = this.formBuilder.group({
        promotionCode: model,
      });
      this.originalPromotionCode = model;
    } else {
      this.promoCodeForm = this.formBuilder.group({
        promotionCode: [''],
      });
    }
  }

  emitEvent(type: string, index?: number): void {
    const value = this.promoCodeForm.value;
    this.promoCodeEvent.emit({ type, index, value });
    if (type === 'add') {
      this.promoCodeForm.reset();
    }
  }

  resetEdit(): void {
    this.promoCodeForm.controls.promotionCode.setValue(this.originalPromotionCode);
  }

  regexSearchModal(
    searchFor: string,
    item: any,
    size: string,
    limitToOne: boolean,
    setValue: boolean,
    type: string
  ): void {
    this.openRegexSearchModal(searchFor, item, size, limitToOne, setValue, type);
  }

  openRegexSearchModal(
    searchFor: string,
    item: any,
    size: string,
    limitToOne: boolean,
    setValue: boolean,
    type: string
  ): void {
    const instance = this.modalHelper.open(RegexSearchModalComponent);
    instance.componentInstance.searchFor = searchFor;
    instance.componentInstance.item = item;
    instance.componentInstance.size = size;
    instance.componentInstance.limitToOne = limitToOne;
    instance.componentInstance.setValue = setValue;
    instance.componentInstance.itemIndex = this.index;
    instance.componentInstance.successEvent.subscribe((text: any) => {
      const value = { promotionCode: item['object']['value'] };
      this.promoCodeEvent.emit({ type, index: this.index, value });
      this.promoCodeForm.reset();
      instance.close();
    });
  }
}
