import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faTimes, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-alternative-form',
  templateUrl: './alternative-form.component.html',
  styleUrls: ['./alternative-form.component.scss'],
})
export class AlternativeFormComponent implements OnInit {
  @Input() alternativeFormModel: any;
  @Input() index: any;
  @Output() alternativeEvent = new EventEmitter();

  alternativeForm: UntypedFormGroup;
  originalPromotionCode: string;
  originalPromotionName: string;
  faTimes = faTimes;
  faPlus = faPlus;
  faSave = faSave;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.index !== null) {
      this.alternativeForm = this.formBuilder.group({
        promotionCode: [this.alternativeFormModel.promotionCode],
        promotionName: [this.alternativeFormModel.promotionName],
      });
      this.originalPromotionCode = this.alternativeFormModel.promotionCode;
      this.originalPromotionName = this.alternativeFormModel.promotionName;
    } else {
      this.alternativeForm = this.formBuilder.group({
        promotionCode: [''],
        promotionName: [''],
      });
    }
  }

  emitEvent(type: string, index?: number): void {
    const value = this.alternativeForm.value;
    this.alternativeEvent.emit({ type, index, value });
    if (type === 'add') {
      this.alternativeForm.reset();
    }
  }

  resetEdit(): void {
    this.alternativeForm.controls.promotionCode.setValue(this.originalPromotionCode);
    this.alternativeForm.controls.promotionName.setValue(this.originalPromotionName);
  }
}
