<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <h2 class="fullname">Products</h2>
          </div>
        </div>
      </div>

      <div class="grey-box"></div>
      <div class="tabs-container mt-2">
        <mat-tab-group class="remove-border-bottom" animationDuration="0ms" (selectedTabChange)="tabClick($event)" mat-stretch-tabs="false">
          <mat-tab label="Products">
            <app-program-tab></app-program-tab>
          </mat-tab>
          <!--<mat-tab label="Program">
            <app-campaign-tab></app-campaign-tab>
          </mat-tab>-->
          <mat-tab label="Type">
            <app-promotion-types-tab></app-promotion-types-tab>
          </mat-tab>
          <!--          <mat-tab label="Category">
            <app-promotion-category-tab></app-promotion-category-tab>
          </mat-tab>
-->
          <mat-tab label="Packages">
            <app-packages-tab></app-packages-tab>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
