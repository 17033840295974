<div class="wrapper wrapper-content">
  <div class="row">
    <div
      [ngClass]="{
        'col-lg-9': (!isPartner && isLiveAgent) || isCSRManager,
        'col-lg-12': isPartner && !isCSRManager && !isLiveAgent
      }"
      class="content-container ibox-content"
    >
      <app-search-filter *ngIf="searchFilters" [searchFilters]="searchFilters"></app-search-filter>
      <div class="row">
        <div class="col-md-12 form-group margin-bottom-5" *ngIf="jiraLink && jiraLink.length > 0 && allowJiraAccess">
          <a class="btn btn-success btn-sm float-right color-fff" href="{{ jiraLink }}" target="_blank"> JIRA </a>
        </div>
      </div>
      <div class="row table-members ibox-content">
        <table class="table table-pointable table-bordered">
          <thead>
            <tr>
              <th class="member-id-header">ID</th>
              <th class="member-status-header">Status</th>
              <th *ngIf="!isSSO">Username</th>
              <th>First name</th>
              <th>Last name</th>
              <th class="member-dob-header">DOB</th>
              <th>Identifier number</th>
              <th>Inst. type</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let member of members" (click)="showMember($event, member.id)">
              <td>
                <a routerLink="/members/{{ member.id }}">{{ member.id }}</a>
              </td>
              <td [attr.title]="member.status">{{ member.status }}</td>
              <td class="flexibleText" [attr.title]="member.username" *ngIf="!isSSO">{{ member.username }}</td>
              <td [attr.title]="member.firstName">{{ member.firstName }}</td>
              <td [attr.title]="member.lastName">{{ member.lastName }}</td>
              <td [attr.title]="member.dateOfBirth | date: 'yyyy-MM-dd'">
                {{ member.dateOfBirth | date: "yyyy-MM-dd" }}
              </td>
              <td [attr.title]="member.activityInstruments[0].instrumentNumber">
                {{ member.activityInstruments[0].instrumentNumber }}
              </td>
              <td class="flexibleText" [attr.title]="member.activityInstruments[0].instrumentType.code">
                {{ member.activityInstruments[0].instrumentType.code }}
              </td>
              <td class="flexibleText" [attr.title]="member.partyEmails[0].emailAddress">
                {{ member.partyEmails[0].emailAddress }}
              </td>
            </tr>
            <tr
              app-is-there-more
              [numColumns]="numColumns"
              [newLengthEvent]="newLengthEvent"
              waitUntilApply="true"
            ></tr>
          </tbody>
        </table>
      </div>
      <div class="row" *ngIf="!lock && lengthToCompare === limit">
        <div class="col-md-12 text-center">
          <app-add-more-data-to-list
            (onScroll)="getMembers(true)"
            [searchApplied]="applyHasBeenClicked"
            [lock]="lock"
            [isLoading]="isLoading"
          >
          </app-add-more-data-to-list>
        </div>
      </div>
    </div>
    <div id="detailsPanel" *ngIf="showPerson()" class="col-lg-3">
      <app-member-details-panel
        [type]="type"
        [selectedPersonId]="selectedPersonId"
        (personChange)="selectedPerson = $event"
        (navigateEvent)="navigateToTab($event)"
      ></app-member-details-panel>
    </div>
  </div>
</div>
<ng-template #redirectTemplate>
  <div class="modal-header">
    <h1>New Agent Portal</h1>
  </div>
  <div class="modal-body">
    <p>We've moved websites! <a href="{{ liveAgentUrl }}">Log in</a> to the new Agent Portal</p>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (cancel)="dismiss(false)" cancelText="Dismiss" [showSubmitButton]="false"></app-modal-buttons>
  </div>
</ng-template>
