<form [formGroup]="adjustmentForm" novalidate>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Account <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <ng-select [items]="additionalAttributes.account" bindValue="id" bindLabel="account.rewardType.code" [clearable]="false">
        <ng-template ng-option-tmp let-item="item">
          {{ item.id + "-" + item.rewardType.code }}
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
         <span *ngIf="item">{{ item.id + "-" + item.rewardType.code }}</span> 
        </ng-template>
      </ng-select>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Amount <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="number" class="form-control" formControlName="amount" />
    </div>
    <div class="form-group">
      <label>Notes</label>
      <textarea class="form-control vertical-expand" formControlName="txnNotes"></textarea>
    </div>
    <div class="modal-footer">
      <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!adjustmentForm.valid"></app-modal-buttons>
    </div>
  </div>
</form>
