import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotionCategory } from '../data/model';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PromotionCategoryService } from '../services/promotion-category.service';

@Component({
  selector: 'app-promotion-category-modal',
  templateUrl: './promotion-category-modal.component.html',
  styleUrls: ['./promotion-category-modal.component.scss'],
})
export class PromotionCategoryModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public promotionCategory: PromotionCategory;
  @Input() public sponsorId: number;
  title: string;
  isEdit: boolean;
  promotionCategoryForm: UntypedFormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private statusHelperService: StatusHelperService,
    private promotionCategoryService: PromotionCategoryService,
    private modalHelper: NgbModal
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.promotionCategory) {
      this.title = 'Edit promotion category';
      this.isEdit = true;
      this.promotionCategoryForm = this.formBuilder.group({
        name: [this.promotionCategory.name, Validators.required],
        status: [this.promotionCategory.status, Validators.required],
        sort: [this.promotionCategory.sort, Validators.required],
      });
    } else {
      this.title = 'Add promotion category';
      this.isEdit = false;
      this.promotionCategoryForm = this.formBuilder.group({
        name: ['', Validators.required],
        status: [this.sponsorId && [], Validators.required],
        sort: [0, Validators.required],
      });
    }
  }

  ok(): void {
    const promotionCategory = this.promotionCategoryForm.value;

    promotionCategory.sponsorPartyId = this.sponsorId;

    this.promotionCategoryService
      .createOrUpdatePromotionCategory(this.promotionCategory && this.promotionCategory.id, promotionCategory)
      .subscribe(
        () => {
          this.successEvent.emit('success');
        },
        (data: any) => {
          this.errorMsg = data.error.error;
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
