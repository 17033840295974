<form [formGroup]="promoCodeForm">
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-md-3 control-label">Code</label>
      <div class="col-md-9">
        <input
          type="text"
          formControlName="promotionCode"
          class="form-control form-readonly"
          name="code"
          (click)="
            regexSearchModal(
              'PROMOTIONS',
              { object: { value: promoCodeFormValue }, name: 'value' },
              { windowClass: 'wider-modal-window' },
              false,
              false,
              index === null ? 'add' : 'save'
            )
          "
          [(ngModel)]="promoCodeFormValue"
          readonly
        />
      </div>
    </div>
    <!--    <div class="form-group row">-->
    <!--      <div class="col-sm-4">-->
    <!--        <div *ngIf="index === null">-->
    <!--          <button class="btn btn-attention" title="Cancel" (click)="promoCodeForm.reset()">-->
    <!--            <fa-icon [icon]="faTimes"></fa-icon>-->
    <!--          </button>-->
    <!--          <button-->
    <!--            id="create-promo-code"-->
    <!--            class="btn btn-dark-blue"-->
    <!--            type="button"-->
    <!--            (click)="emitEvent('add')"-->
    <!--            title="Add Alternative"-->
    <!--          >-->
    <!--            <fa-icon [icon]="faPlus"></fa-icon>-->
    <!--          </button>-->
    <!--          &lt;!&ndash; TODO: &ndash;&gt;-->
    <!--          &lt;!&ndash; <button id="add-all-child-promos" class="btn btn-dark-blue" type="button"-->
    <!--            (click)="emitEvent('add-child-promos')"-->
    <!--            *ngIf="(childCompletionPromoCodes.length > 0 || childPromoCodes.length > 0) && !model.completionIndicator[0].useChildren"-->
    <!--            title="Add Child Promos">-->
    <!--            <fa-icon [icon]="faPlus"></fa-icon>-->
    <!--          </button> &ndash;&gt;-->
    <!--        </div>-->
    <!--        <div *ngIf="index !== null">-->
    <!--          <button class="btn btn-attention" title="Cancel" (click)="resetEdit()">-->
    <!--            <fa-icon [icon]="faTimes"></fa-icon>-->
    <!--          </button>-->
    <!--          <button-->
    <!--            id="create-promo-code"-->
    <!--            class="btn btn-dark-blue"-->
    <!--            type="button"-->
    <!--            (click)="emitEvent('save', index)"-->
    <!--            title="Save"-->
    <!--          >-->
    <!--            <fa-icon [icon]="faSave"></fa-icon>-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</form>
