import { OnInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppDataService } from './app-data.service';
import { faSignOutAlt, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { SecurityService } from './services/security/security.service';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { ApolloService } from './services/apollo.service';
import { OrganizationsService } from './services/organizations.service';
import { GlobalEventService } from './services/global-event.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { openWindow } from './data/window-open-function';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public globalData: AppDataService,
    private httpClient: HttpClient,
    private router: Router,
    private securityService: SecurityService,
    private apollo: Apollo,
    private toastr: ToastrService,
    private apolloService: ApolloService,
    private organizationsService: OrganizationsService,
    private globalEventService: GlobalEventService,
    private route: ActivatedRoute
  ) {}
  isToggle: boolean = false;
  @ViewChild('#openTicket') openTicket: any;
  title = 'cheetah-admin';
  faSignOutAlt = faSignOutAlt;
  faCaretDown = faCaretDown;
  showEnvs: boolean;
  year = new Date().getFullYear();
  ticket: any;
  escalateTooltip = 'Escalate Ticket';
  openedTickets: any[] = [];
  interactionText = '';
  isTicketsOpened = false;
  externalOrgCode: string;

  ngOnInit(): void {
    this.apolloService.createClient();

    const dataToSend: any = {};
    this.route.queryParams.subscribe((params) => {
      this.externalOrgCode = params.externalOrgCode;
    });
    dataToSend.data = { externalOrgCode: this.externalOrgCode };
    dataToSend.toComponent = 'app';
    this.globalEventService.sendGlobalEvent(dataToSend);

    this.globalEventService.getGlobalEvent().subscribe((response: any) => {
      if (response.toComponent === 'app') {
        this.organizationsService.getOrgByExternalCode(response.data.externalOrgCode).then((data: any) => {
          if (data.entity) {
            localStorage.setItem('orgId', data.entity.id);
            localStorage.setItem('externalOrgCode', data.entity.username);
            localStorage.setItem('organizationLogoUrl', data.entity.logoUrl);
            localStorage.setItem('orgUsername', data.entity.username);
            localStorage.setItem('segmentId', data.entity.segmentsCollection[0].id);
            this.setGlobalData();
          }
        });
      }
    });
    this.setGlobalData();
  }

  setGlobalData(): void {
    this.globalData.orgId = Number(localStorage.getItem('orgId'));
    this.globalData.externalOrgCode = localStorage.getItem('externalOrgCode');
    this.globalData.orgUsername = localStorage.getItem('orgUsername');
    this.globalData.organizationLogoUrl = localStorage.getItem('organizationLogoUrl');
    this.globalData.segmentId = Number(localStorage.getItem('segmentId'));
  }

  logout(): void {
    localStorage.removeItem('orgId');
    localStorage.removeItem('externalOrgCode');
    localStorage.removeItem('orgUsername');
    localStorage.removeItem('segmentId');
    localStorage.removeItem('organizationLogoUrl');
    this.router.navigate(['/logged-out']);
    this.securityService.cleanOauth();
    // localStorage.removeItem('token');
    this.globalData.onLogout(true);
    this.apollo.getClient().resetStore();
  }

  doEmulate(url: string): void {
    var token = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).sessionId;

    const data = {
      token: token,
    };
    const headers: HttpHeaders = new HttpHeaders();
    headers.set('withCredentials', 'true');

    this.httpClient.post(url + '/token', JSON.stringify(data), { headers }).subscribe(
      () => {
        openWindow(url, '_blank');
      },
      () => {}
    );
  }
  changeToggle(data) {
    this.isToggle = !this.isToggle;
  }

  openAdmin(): void {
    this.securityService.getConfiguration('MEMBER_UI_LOCATION').subscribe((configData: any) => {
      if (configData.success && configData.entity && configData.entity.length > 0) {
        this.doEmulate(configData.entity[0].cfgValue);
      } else {
        this.toastr.error('Member UI location is not configured.');
      }
    });
  }
}
