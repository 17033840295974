import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RewardTypeService } from 'src/app/services/reward-type.service';
import { UntypedFormBuilder, FormsModule } from '@angular/forms';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-percentage-reward-consequence',
  templateUrl: './percentage-reward-consequence.component.html',
  styleUrls: ['./percentage-reward-consequence.component.scss'],
})
export class PercentageRewardConsequenceComponent implements OnInit {
  @Input() percentOfValue: any;
  faTimes = faTimes;
  faPlus = faPlus;
  rewards: any;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();

  constructor(
    private router: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal,
    private rewardTypeService: RewardTypeService
  ) {
    this.debouncer.pipe(debounce(() => interval(1000))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  ngOnInit(): void {
    this.getRewardTypes();
  }

  getRewardTypes(): void {
    this.rewardTypeService.getRewardTypes().subscribe(
      (data: any) => {
        if (data.success) {
          this.rewards = data.entity.records;
        }
      },
      () => {}
    );
  }

  initConsequenceObject(): void {
    console.log(this.percentOfValue);
    var tmpObj = {
      class:
        'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Consequences$PercentOfValue',
    };
    if (this.percentOfValue) {
      this.percentOfValue.push(tmpObj);
    } else {
      this.percentOfValue = [];
      this.percentOfValue.push(tmpObj);
    }
    console.log(this.percentOfValue);
  }

  updateConsequences() {
    this.debouncer.next(this.percentOfValue);
  }

  removeConsequence(consequence) {
    this.percentOfValue.splice(this.percentOfValue.indexOf(consequence), 1);
    this.updateConsequences();
  }
}
