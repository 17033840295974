import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { InstrumentType } from '../../data/model';
import { StatusHelperService } from '../../services/helpers/status-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { InstrumentTypeService } from 'src/app/services/instrument-type.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';

@Component({
  selector: 'app-instrument-types-modal',
  templateUrl: './instrument-types-modal.component.html',
  styleUrls: ['./instrument-types-modal.component.scss'],
})
export class InstrumentTypesModalComponent implements OnInit {
  @Input() instrumentType: InstrumentType;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  instrumentTypeForm: UntypedFormGroup;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;
  isEdit: boolean;

  constructor(
    private statusHelperService: StatusHelperService,
    private instrumentTypeService: InstrumentTypeService,
    private modalHelper: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private responseHelperService: ResponseHelperService
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  stripRegexAnchors(regex: string | null) {
    if (!regex) {
      return '';
    }
    return regex.slice(1, -1);
  }

  ngOnInit(): void {
    if (this.instrumentType) {
      this.title = 'Edit Identifier Type';
      this.isEdit = true;
      this.instrumentTypeForm = this.formBuilder.group({
        code: [this.instrumentType.code, Validators.required],
        extCode: [this.instrumentType.extCode],
        status: [this.instrumentType.status, Validators.required],
        description: [this.instrumentType.description],
        inputMaskRegex: [this.stripRegexAnchors(this.instrumentType.inputMaskRegex)],
        displayFormat: [this.instrumentType.displayFormat],
      });
    } else {
      this.title = 'New Identifier Type';
      this.isEdit = false;
      this.instrumentTypeForm = this.formBuilder.group({
        code: ['', Validators.required],
        extCode: [''],
        status: ['', Validators.required],
        description: [''],
        inputMaskRegex: [''],
        displayFormat: [''],
      });
    }
  }

  ok(): void {
    const regexMask = !!this.instrumentTypeForm.value.inputMaskRegex
      ? `^${this.instrumentTypeForm.value.inputMaskRegex}$`
      : null;
    this.instrumentTypeForm.value.inputMaskRegex = regexMask;

    this.instrumentTypeService
      .createOrUpdateInstrumentType(this.instrumentType && this.instrumentType.id, this.instrumentTypeForm.value)
      .subscribe(
        (data: any) => {
          this.successEvent.emit(data);
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
