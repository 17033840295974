import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { StatusHelperService } from '../../services/helpers/status-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { LocationService } from 'src/app/services/location.service';
import { Location } from 'src/app/data/model';

@Component({
  selector: 'app-location-modal',
  templateUrl: './location-modal.component.html',
  styleUrls: ['./location-modal.component.scss'],
})
export class LocationModalComponent implements OnInit {
  @Input() location: Location;
  @Input() hierarchySources: any[];
  @Input() allLocations: Location[] = [];
  filteredParents: Location[] = [];
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  locationForm: UntypedFormGroup;
  isEdit: boolean;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;
  locationId: number;

  constructor(
    private statusHelperService: StatusHelperService,
    private locationService: LocationService,
    private modalHelper: NgbModal,
    private formBuilder: UntypedFormBuilder
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.location) {
      this.locationId = this.location.id;
      this.title = 'Edit Location';
      this.isEdit = true;
      this.locationForm = this.formBuilder.group({
        name: [this.location.name],
        code: [this.location.code, Validators.required],
        status: [this.location.status, Validators.required],
        hierarchySourceId: [this.location.hierarchySourceId, Validators.required],
        description: [this.location.description],
        parentCode: [this.location.parentCode],
      });
      this.filterParents(this.location.hierarchySourceId, this.locationId);
    } else {
      this.title = 'New Location';
      this.isEdit = false;
      this.locationForm = this.formBuilder.group({
        name: [''],
        code: ['', Validators.required],
        status: ['', Validators.required],
        hierarchySourceId: [null, Validators.required],
        description: [''],
        parentCode: [null],
      });
    }
    this.locationForm.controls.hierarchySourceId.valueChanges.subscribe((hierarchySourceId: number) => {
      this.filterParents(hierarchySourceId, this.locationId);
    });
  }

  filterParents(hierarchySourceId: number, locationId: number): void {
    if (locationId) {
      this.filteredParents = this.allLocations.filter(
        (location: Location) => location.hierarchySourceId === hierarchySourceId && location.id !== locationId
      );
    } else {
      this.filteredParents = this.allLocations.filter(
        (location: Location) => location.hierarchySourceId === hierarchySourceId
      );
    }
    if (this.filteredParents.length > 0) {
      this.locationForm.controls.parentCode.enable();
    } else {
      this.locationForm.controls.parentCode.disable();
    }
  }

  ok(): void {
    this.locationService.createOrUpdateLocation(this.locationId, this.locationForm.value).subscribe(
      (data: any) => {
        this.successEvent.emit(data);
      },
      (data: any) => {
        this.errorMsg = data.error.error;
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
