import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Segment } from '../data/model';
import { PersonsService } from '../services/persons.service';
import { Subject } from 'rxjs';
import { AppDataService } from '../app-data.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-segment-tree-modal',
  templateUrl: './segment-tree-modal.component.html',
})
export class SegmentTreeModalComponent implements OnInit {
  @Input() class: string;
  @Input() ngbDropdownClass = 'btn-group filter';
  @Input() maxWidth;
  @Input() selectedSegments: any[] = [];
  @Output() filterSegments: EventEmitter<any> = new EventEmitter(true);
  @Output() selectedSegmentsChange = new EventEmitter();
  @Input() personId: number;
  @Input() allowMultiple: boolean;
  @Input() selectedCode: string;
  @Input() automaticFetch: boolean;
  @Input() dropdown = true;
  @Input() isInFilter: boolean;
  segmentsFetched = false;
  filtered: Segment[];
  allSegments: Segment[];
  toRemove: number[];
  toAdd: number[];
  query = '';
  classValue: string;
  subject: Subject<any> = new Subject<any>();
  faChevronDown = faChevronDown;

  constructor(private personsService: PersonsService, private appDataService: AppDataService) {
    this.subject.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => {
      this.filtered = [];
      if (this.query && this.query.length > 2) {
        this.allSegments.forEach((segment: Segment) => {
          if (segment.code && segment.code.toUpperCase().indexOf(this.query.toUpperCase()) !== -1) {
            this.filtered.push(segment);
          }
        });
        for (let i = this.allSegments.length - 1; i >= 0; i--) {
          if (this.isParent(this.allSegments[i].id)) {
            const idx = this.getIdx(this.allSegments[i].id);
            this.filtered.splice(idx, 0, this.allSegments[i]);
          }
        }
      } else {
        this.filtered = [...this.allSegments];
      }
    });
  }

  isParent(id: number): boolean {
    let parent = false;
    let inArray = true;
    this.filtered.forEach((segment: Segment) => {
      if (segment.parentId === id) {
        parent = true;
      }
      if (segment.id === id) {
        inArray = false;
      }
    });
    return parent && inArray;
  }

  getIdx(segmentId: number): number {
    const indexs = [];
    this.filtered.forEach((seg: Segment, idx: number) => {
      if (seg.parentId === segmentId) {
        indexs.push(idx);
      }
    });
    return Math.min.apply(null, indexs);
  }

  ngOnInit(): void {
    this.filtered = [];

    this.classValue = this.class ? this.class + ' font-relative-width' : 'col-md-2 font-relative-width';

    if (this.automaticFetch || !this.dropdown) {
      this.getAllSegments();
    }
  }

  getClass(level: number): string {
    return 'level_' + level;
  }

  getAllSegments(): void {
    if (!this.segmentsFetched) {
      this.personsService
        .getPersonSegmentList(this.personId ? this.personId : this.appDataService.userId, false)
        .subscribe((data: any) => {
          if (data.status === 200) {
            this.allSegments = data.body.entity;
            this.filtered = data.body.entity;
            this.segmentsFetched = true;
            this.allSegments = this.personsService.levelSegments(data.body.entity);
            this.filtered = this.personsService.levelSegments(data.body.entity);
            if (this.selectedCode) {
              this.filtered.forEach((seg: Segment) => {
                if (seg.code === this.selectedCode) {
                  seg.checked = true;
                  this.selectedSegments.push(seg);
                }
              });
            } else if (this.selectedSegments) {
              this.filtered.forEach((seg: Segment) => {
                if (this.selectedSegments.indexOf(seg.id) !== -1) {
                  seg.checked = true;
                }
              });
            }
          }
        });
    }
  }

  addSegments(model: Segment): void {
    if (!this.allowMultiple) {
      this.filtered.forEach((seg: Segment) => {
        if (seg.checked && seg.code !== model.code) {
          seg.checked = !seg.checked;
        }
      });

      this.selectedSegmentsChange.emit(model);

      this.selectedSegments.push(model);
    } else {
      if (model.checked) {
        this.addChildren(model);
      } else {
        this.removeChildren(model);
      }

      this.selectedSegmentsChange.emit(this.selectedSegments);
    }
  }

  clearAll(): void {
    if (this.allSegments) {
      this.filtered = this.allSegments;
      this.filtered.forEach((seg: Segment) => {
        if (seg.checked) {
          seg.checked = !seg.checked;
        }
      });
      this.selectedSegments = [];
      this.toRemove = [];
      this.toAdd = [];
      this.query = '';
      if(this.isInFilter) {
        this.selectedSegmentsChange.emit(this.selectedSegments);
      } else {
        this.filterSegments.emit(this.selectedSegments);
      }
    }
  }

  applyFilter(query: string): void {
    this.subject.next(query);
  }

  addChildren(model: Segment): void {
    this.toAdd = [];
    this.toAdd.push(model.id);
    this.filtered.forEach((item) => {
      if (
        this.toAdd.indexOf(item.parentId) !== -1 &&
        this.selectedSegments.indexOf(item.id) === -1 &&
        item.id != item.parentId
      ) {
        item.checked = model.checked;
        this.toAdd.push(item.id);
      }
    });
    this.selectedSegments = this.selectedSegments.concat(this.toAdd);
    if(!this.isInFilter) {
      this.filterSegments.emit(this.selectedSegments);
    }
  }

  removeChildren(model: Segment): void {
    this.toRemove = [];
    this.toRemove.push(model.id);
    this.filtered.forEach((item: Segment) => {
      if (this.toRemove.indexOf(item.parentId) !== -1 && this.selectedSegments.indexOf(item.id) !== -1) {
        item.checked = model.checked;
        this.toRemove.push(item.id);
      }
    });
    this.toRemove.forEach((remove) => {
      const idx = this.selectedSegments.indexOf(remove);
      this.selectedSegments.splice(idx, 1);
    });
    if(!this.isInFilter) {
      this.filterSegments.emit(this.selectedSegments);
    }
  }
}
