import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateSubtract',
})
export class DateSubtractPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) { }

  transform(dateString: string, daysToSubtract: number, format: string = 'shortDate', locale: string = 'en-US'): string {
    let date = new Date(dateString);

    if (isNaN(date.getTime())) {
      const parts = dateString.split('-');
      const year = Number(parts[2]);
      const month = Number(parts[0]) - 1;
      const day = Number(parts[1]);
      date = new Date(year, month, day);
    }

    date.setDate(date.getDate() - daysToSubtract);
    return this.datePipe.transform(date, format, locale);
  }
}