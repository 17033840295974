import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Communication } from 'src/app/data/model';
import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from 'src/app/app-data.service';
import {
  faStar,
  faUserCheck,
  faPlus,
  faEdit,
  faChevronLeft,
  faTimes,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { SearchFilters, Filter } from 'src/app/data/class';
import { CommunicationsService } from 'src/app/services/communications.service';
import { EmailService } from 'src/app/services/email.service';
import { AddressService } from 'src/app/services/address.service';
import { PhoneService } from 'src/app/services/phone.service';
import { Phone, EmailModel, AddressModel } from 'src/app/data/model';
import { ChirpService } from 'src/app/services/chirp.service';
import { CommunicationPreferenceModalComponent } from '../communication-preference-modal/communication-preference-modal.component';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-communications-tab',
  templateUrl: './communications-tab.component.html',
  styleUrls: ['./communications-tab.component.scss'],
  providers: [DatePipe],
})
export class CommunicationsTabComponent implements OnInit {
  limit = 20;
  filter = new Filter();
  searchFilters: SearchFilters;
  communications: any[] = [];
  lock: boolean;
  lengthToCompare = 0;
  selectedComm: Communication;
  faPlus = faPlus;
  faEdit = faEdit;
  faStar = faStar;
  faUserCheck = faUserCheck;
  faChevronLeft = faChevronLeft;
  faChevronDown = faChevronDown;
  faTimes = faTimes;
  lockedTables: boolean;
  isLoading = false;
  commHistKeyword = '';
  preferedCommunications: Communication[] = [];
  commHistory: any[] = [];
  to: NgbDate = null;
  deliveredTo: Date;
  from: NgbDate = null;
  deliveredFrom: Date;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  @Input() public personId;
  @Input() public users;
  emailExists = false;
  phoneExists = false;
  addressExists = false;
  phones: Phone[];
  emails: EmailModel[];
  addresses: AddressModel[];
  show = true;
  openRow: boolean[] = [];
  isCSRManager: boolean;
  expandedLookup = [];
  showExpired: boolean;
  currentDate: any;

  constructor(
    private emailService: EmailService,
    private modalHelper: NgbModal,
    private communicationsService: CommunicationsService,
    private datePipe: DatePipe,
    private addresService: AddressService,
    private chirpService: ChirpService,
    private phoneService: PhoneService,
    private dateHelperService: DateHelperService,
    private globalData: AppDataService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'processing-logs-search',
      filters: [
        {
          name: 'keyword',
          placeholder: 'Keyword...',
        },
        {
          name: 'deliveredFrom',
          type: 'date',
          placeholder: 'From Date',
        },
        {
          name: 'deliveredTo',
          type: 'date',
          endDate: true,
          placeholder: 'To Date',
        },
      ],
      searchCallback: () => {
        this.initNewSearchHistory();
      },
    };
    this.isCSRManager = this.globalData.isCSRManager;
    this.initFilter();
    this.getCommunicationsForParty();
    this.currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  initFilter(): void {
    this.limit = 20;
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
    this.filter.column = 0;
  }

  communicationsModal(communication?: Communication): void {
    Promise.all([
      this.addresService.getPartyAddresses(this.personId).toPromise(),
      this.phoneService.getPartyPhones(this.personId).toPromise(),
      this.emailService.getPartyEmails(this.personId).toPromise(),
      this.communicationsService.getCommunications({ partyId: this.personId, ap: true }).toPromise(),
    ]).then((result) => {
      const instance = this.modalHelper.open(CommunicationPreferenceModalComponent);
      instance.componentInstance.communication = communication;
      instance.componentInstance.communications = result[3].entity.records;
      instance.componentInstance.personId = this.personId;
      instance.componentInstance.phones = result[1].entity;
      instance.componentInstance.emails = result[2].entity;
      instance.componentInstance.addresses = result[0].entity;
      instance.componentInstance.successEvent.subscribe((data: any) => {
        this.getCommunicationsForParty();
      });
    });
  }

  clearLookup(): void {
    this.expandedLookup = [];
  }

  getCommunicationsHistory(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.filter.partyId = this.personId;
    this.isLoading = true;
    this.communicationsService.getCommunicationsHistory(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.commHistory = this.commHistory.concat(data.entity);
        } else {
          this.commHistory = data.entity;
        }
        this.commHistory.forEach(() => this.openRow.push(false));
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  initNewSearchHistory(): void {
    this.initFilter();
    this.getCommunicationsHistory(false);
  }

  getCommunicationsForParty(): void {
    this.chirpService.getPreferedCommunicationsForParty(this.personId).subscribe((data: any) => {
      this.preferedCommunications = data.entity;
    });
  }

  getPrefData(comm, commType): string {
    if (commType == 'phone') {
      return JSON.parse(comm.prefData).phoneNumber;
    } else if (commType == 'email') {
      return JSON.parse(comm.prefData).emailAddress;
    } else if (commType == 'address') {
      const address = JSON.parse(comm.prefData);
      return address.address1 + ', ' + address.city;
    }
  }
}
