import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private http: HttpClient) {}

  getConfiguration(code: string): any {
    return this.http.get<any>(environment.baseUrl + 'configuration/' + code);
  }

  getSSOConfig(): any {
    return this.http.get<any>(environment.baseUrl + 'configuration/SSO', { headers: { skipInterceptor: 'true' } });
  }

  getSysTag(): any {
    return this.http.get<any>(environment.baseUrl + 'configuration/systag');
  }

  getLoginBanner(): any {
    return this.http.get<any>(environment.baseUrl + 'configuration/login-banner', {
      headers: { skipInterceptor: 'true' },
    });
  }

  getOauthtoken(authCode: string): any {
    return this.http.post(
      environment.baseUrl + 'v2/auth/oauth/token',
      {
        client_id: 'CheetahAdmin',
        grant_type: 'authorization_code',
        code: authCode,
      },
      { headers: { skipInterceptor: 'true', 'Content-Type': 'application/x-www-form-urlencoded' } }
    );
  }

  // getconfigurationSSO(): any {
  //   return this.http.get(environment.baseUrl +'configuration/SSO');
  // }
}
