/* eslint-disable unused-imports/no-unused-imports */

import { ExecuteReportModalComponent } from './reports/execute-report-modal/execute-report-modal.component';
import { ProcessingLogsComponent } from './processing-log/processing-logs/processing-logs.component';
import { ProcessingLogNotesModalComponent } from './processing-log/processing-log-notes-modal/processing-log-notes-modal.component';
import { ProcessingLogComponent } from './processing-log/processing-log/processing-log.component';
import { IdAttributePipe } from './services/pipes/id-attribute.pipe';
import { SortPipe } from './services/pipes/sort.pipe';
import { ListGroupByPipe } from './services/pipes/list-group-by.pipe';
import { DateSubtractPipe } from './services/pipes/date-subtract.pipe';
import { ContactsTabComponent } from './shared/contacts-tab/contacts-tab.component';
import { MemberActivityReversalModalComponent } from './member/member-tabs/member-activity-reversal-modal/member-activity-reversal-modal.component';
import { MemberActivityModalComponent } from './member/member-tabs/member-activity-modal/member-activity-modal.component';
import { MemberActivityTabComponent } from './member/member-tabs/member-activity-tab/member-activity-tab.component';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgChartsModule } from 'ng2-charts';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';

import { CallbackPipe } from './services/pipes/callback.pipe';
import { LogPipe } from './services/pipes/log.pipe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MembersComponent } from './member/members/members.component';
import { MemberComponent } from './member/member/member.component';
import { MemberDetailsPanelComponent } from './member/member-details-panel/member-details-panel.component';
import { AuthInterceptor } from './http-interceptors/auth-interceptor';
import { HomeComponent } from './home/home.component';
import { SponsorComponent } from './sponsor/sponsor.component';
import { CampaignComponent } from './campaign/campaign.component';
import { FilterHeaderUsersComponent } from './search-filter/filter-header-users/filter-header-users.component';
import { FilterNumberComponent } from './search-filter/filter-number/filter-number.component';
import { FilterButtonPanelComponent } from './search-filter/filter-button-panel/filter-button-panel.component';
import { FilterTagsComponent } from './search-filter/filter-tags/filter-tags.component';
import { FilterRadioComponent } from './search-filter/filter-radio/filter-radio.component';
import { FilterCheckboxComponent } from './search-filter/filter-checkbox/filter-checkbox.component';
import { FilterStringComponent } from './search-filter/filter-string/filter-string.component';
import { FilterTypeaheadComponent } from './search-filter/filter-typeahead/filter-typeahead.component';
import { FilterSelectComponent } from './search-filter/filter-select/filter-select.component';
import { FilterHeaderComponent } from './search-filter/filter-header/filter-header.component';
import { FilterHeaderActivityTabComponent } from './search-filter/filter-header-activity-tab/filter-header-activity-tab.component';
import { FilterHeaderGenericComponent } from './search-filter/filter-header-generic/filter-header-generic.component';
import { FilterHeaderMembersComponent } from './search-filter/filter-header-members/filter-header-members.component';
import { FilterDatepickerComponent } from './search-filter/filter-datepicker/filter-datepicker.component';
import { FilterDropdownComponent } from './search-filter/filter-dropdown/filter-dropdown.component';
import { FilterExpanderComponent } from './search-filter/filter-expander/filter-expander.component';
import { SearchFilterComponent } from './search-filter/search-filter/search-filter.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SponsorModalComponent } from './sponsor-modal/sponsor-modal.component';
import { LoginGuard } from './services/login-guard.service';
import { SponsorshipGroupsTabComponent } from './sponsorship-groups-tab/sponsorship-groups-tab.component';
import { PromotionModalComponent } from './promotion-modal/promotion-modal.component';
import { PromotionsTabComponent } from './promotions-tab/promotions-tab.component';
import { SegmentFilterComponent } from './segment-filter/segment-filter.component';
import { SegmentTreeModalComponent } from './segment-tree-modal/segment-tree-modal.component';
import { AddSponsorshipModalComponent } from './add-sponsorship-modal/add-sponsorship-modal.component';
import { CommunicationsComponent } from './communications/communications.component';
import { CommunicationComponent } from './communication/communication.component';
import { CommunicationsModalComponent } from './communications-modal/communications-modal.component';
import { CommunicationsTestModalComponent } from './communications-test-modal/communications-test-modal.component';
import { IntegrationsComponent } from './integrations-tab/integrations/integrations.component';
import { IntegrationModalComponent } from './integrations-tab/integration-modal/integration-modal.component';
import { CommunicationSegmentsTabComponent } from './communication-tabs/communication-segments-tab/communication-segments-tab.component';
import { CommunicationHistoryTabComponent } from './communication-tabs/communication-history-tab/communication-history-tab.component';
import { CommunicationContentTabComponent } from './communication-tabs/communication-content-tab/communication-content-tab.component';
import { CommunicationAttachmentsTabComponent } from './communication-tabs/communication-attachments-tab/communication-attachments-tab.component';
import { CommunicationAttributeModalComponent } from './communication-tabs/communication-attribute-modal/communication-attribute-modal.component';
import { CommunicationAllowlistComponent } from './communication-tabs/communication-allowlist/communication-allowlist.component';
import { CommunicationAllowlistModalComponent } from './modals/communication-allowlist-modal/communication-allowlist-modal.component';
import { ProgramsComponent } from './programs/programs.component';
import { UploadIntegrationModalComponent } from './integrations-tab/upload-integration-modal/upload-integration-modal.component';
import { IntegrationStageLoadComponent } from './integrations-tab/integration-stage-load/integration-stage-load.component';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { IntegrationMessagesComponent } from './integrations-tab/integration-messages/integration-messages.component';
import { SelectOrganizationComponent } from './select-organization/select-organization.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ProgramTabComponent } from './programs/programs-tabs/program-tab/program-tab.component';
import { CampaignTabComponent } from './shared/campaign-tab/campaign-tab.component';
import { PromotionTypesTabComponent } from './programs/programs-tabs/promotion-types-tab/promotion-types-tab.component';
import { CommunicationStrategyTabComponent } from './communication-tabs/communication-strategy-tab/communication-strategy-tab.component';
import { ProgramTestModalComponent } from './program-test-modal/program-test-modal.component';
import { ProgramModalComponent } from './program-modal/program-modal.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ProgramImportModalComponent } from './program-import-modal/program-import-modal.component';
import { PackagesTabComponent } from './programs/programs-tabs/packages-tab/packages-tab.component';
import { CampaignModalComponent } from './campaign-modal/campaign-modal.component';
import { PromotionTypeModalComponent } from './promotion-type-modal/promotion-type-modal.component';
import { PromotionCategoryModalComponent } from './promotion-category-modal/promotion-category-modal.component';
import { IntegrationStageImportComponent } from './integrations-tab/integration-stage-import/integration-stage-import.component';
import { CommunicationStrategyConfigurationModalComponent } from './communication-strategy-configuration-modal/communication-strategy-configuration-modal.component';
import { AssociatedProgramsModalComponent } from './associated-programs-modal/associated-programs-modal.component';
import { OutboundComponent } from './outbound-batch/outbound/outbound.component';
import { OutboundModalComponent } from './outbound-batch/outbound-modal/outbound-modal.component';
import { OutboundStageLoadsComponent } from './outbound-batch/outbound-stage-loads/outbound-stage-loads.component';
import { OutboundStageExportsComponent } from './outbound-batch/outbound-stage-exports/outbound-stage-exports.component';
import { IsThereMoreComponent } from './components/is-there-more/is-there-more.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { PackageModalComponent } from './package-modal/package-modal.component';
import { RewardTypesTabComponent } from './system-tab/reward-types-tab/reward-types-tab.component';
import { CommunicationStrategyModalComponent } from './communication-strategy-modal/communication-strategy-modal.component';
import { CommunicationAttachmentModalComponent } from './communication-tabs/communication-attachment-modal/communication-attachment-modal.component';
import { CommunicationContentTemplateModalComponent } from './communication-tabs/communication-content-template-modal/communication-content-template-modal.component';
import { CommunicationSegmentModalComponent } from './communication-tabs/communication-segment-modal/communication-segment-modal.component';
import { OutreachContentTemplateModalComponent } from 'src/app/outreach-content/outreach-content-template-modal/outreach-content-template-modal.component';
import { SystemComponent } from './system-tab/system/system.component';
import { PromoTreeComponent } from './promo-tree/promo-tree.component';
import { RewardTypeModalComponent } from './system-tab/reward-type-modal/reward-type-modal.component';
import { SponsorTabComponent } from './sponsor-tab/sponsor-tab.component';
import { InstrumentTypesTabComponent } from './system-tab/instrument-types-tab/instrument-types-tab.component';
import { InstrumentTypesModalComponent } from './system-tab/instrument-types-modal/instrument-types-modal.component';
import { BehaviorsTabComponent } from './system-tab/behaviors-tab/behaviors-tab.component';
import { BehaviorsModalComponent } from './system-tab/behaviors-modal/behaviors-modal.component';
import { WorldCurrenciesTabComponent } from './system-tab/world-currencies-tab/world-currencies-tab.component';
import { WorldCurrencyModalComponent } from './system-tab/world-currency-modal/world-currency-modal.component';
import { DeliveryProtocolsTabComponent } from './system-tab/delivery-protocols-tab/delivery-protocols-tab.component';
import { DeliveryProtocolModalComponent } from './system-tab/delivery-protocol-modal/delivery-protocol-modal.component';
import { DeliveryOptionsModalComponent } from './system-tab/delivery-options-modal/delivery-options-modal.component';
import { IntegrationServerModalComponent } from './system-tab/integration-server-modal/integration-server-modal.component';
import { IntegrationServersComponent } from './system-tab/integration-servers/integration-servers.component';
import { IntegrationKeysTabComponent } from './system-tab/integration-keys-tab/integration-keys-tab.component';
import { IntegrationKeyModalComponent } from './system-tab/integration-key-modal/integration-key-modal.component';
import { FiltersTabComponent } from './system-tab/filters-tab/filters-tab.component';
import { FilterModalComponent } from './system-tab/filter-modal/filter-modal.component';
import { LocationsTabComponent } from './system-tab/locations-tab/locations-tab.component';
import { LocationModalComponent } from './system-tab/location-modal/location-modal.component';
import { JobManagementTabComponent } from './system-tab/job-management-tab/job-management-tab.component';
import { JobManagementModalComponent } from './system-tab/job-management-modal/job-management-modal.component';
import { JobArgumentsModalComponent } from './system-tab/job-arguments-modal/job-arguments-modal.component';
import { MoreDetailsModalComponent } from './more-details-modal/more-details-modal.component';
import { ContactReasonModalComponent } from './system-tab/contact-reason-modal/contact-reason-modal.component';
import { ContactReasonsTabComponent } from './system-tab/contact-reasons-tab/contact-reasons-tab.component';
import { MemberSetPasswordModalComponent } from './member/member-set-password-modal/member-set-password-modal.component';
import { AttributeMappingsTabComponent } from './system-tab/attribute-mappings-tab/attribute-mappings-tab.component';
import { AttributeMappingsGroupModalComponent } from './system-tab/attribute-mappings-group-modal/attribute-mappings-group-modal.component';
import { AddAttributeMappingsModalComponent } from './system-tab/add-attribute-mappings-modal/add-attribute-mappings-modal.component';
import { MemberPromotionsTabComponent } from './member/member-tabs/member-promotions-tab/member-promotions-tab.component';
import { AdjustmentModalComponent } from './member/member-tabs/adjustment-modal/adjustment-modal.component';
import { ReversalConfirmationModalComponent } from './member/member-tabs/reversal-confirmation-modal/reversal-confirmation-modal.component';
import { AccountsTabComponent } from './member/member-tabs/accounts-tab/accounts-tab.component';
import { AccountsModalComponent } from './member/member-tabs/accounts-modal/accounts-modal.component';
import { RedemptionItemsModalComponent } from './member/member-tabs/redemption-items-modal/redemption-items-modal.component';
import { RedemptionAddressModalComponent } from './member/member-tabs/redemption-address-modal/redemption-address-modal.component';
import { AddressModalComponent } from './member/member-tabs/address-modal/address-modal.component';
import { EmailModalComponent } from './member/member-tabs/email-modal/email-modal.component';
import { PhoneModalComponent } from './member/member-tabs/phone-modal/phone-modal.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { InstrumentsTabComponent } from './member/member-tabs/instruments-tab/instruments-tab.component';
import { InstrumentModalComponent } from './member/member-tabs/instrument-modal/instrument-modal.component';
import { PartyAttributesTabComponent } from './party-attributes-tab/party-attributes-tab.component';
import { PromotionAttributesTabComponent } from './promotion-attributes-tab/promotion-attributes-tab.component';
import { SegmentsTabComponent } from './shared/segments-tab/segments-tab.component';
import { AddUserToSegmentModalComponent } from './member/member-tabs/add-user-to-segment-modal/add-user-to-segment-modal.component';
import { StatusSelectComponent } from './components/status-select/status-select.component';
import { SortArrowsComponent } from './components/sort-arrows/sort-arrows.component';
import { NotesTabComponent } from './member/member-tabs/notes-tab/notes-tab.component';
import { TicketsComponent } from './tickets/tickets.component';
import { OpenTicketsComponent } from './open-tickets/open-tickets.component';
import { PartnersComponent } from './partners/partners.component';
import { PartnerModalComponent } from './partner-modal/partner-modal.component';
import { CommunicationsTabComponent } from './member/member-tabs/communications-tab/communications-tab.component';
import { CommunicationPreferenceModalComponent } from './member/member-tabs/communication-preference-modal/communication-preference-modal.component';
import { PromotionCategoryTabComponent } from './shared/promotion-category-tab/promotion-category-tab.component';
import { PartnerComponent } from './partner/partner.component';
import { EventsTabComponent } from './member/member-tabs/member-events-tab/member-events-tab.component';
import { MemberMeasuresTabComponent } from './member/member-tabs/member-measures-tab/member-measures-tab.component';
import { UserModalComponent } from './user-modal/user-modal.component';
import { UsersComponent } from './users/users.component';
import { InteractionEntryModalComponent } from './member/member-tabs/interaction-entry-modal/interaction-entry-modal.component';
import { ReportsComponent } from './reports/reports.component';
import { JwtModule } from '@auth0/angular-jwt';
import { ReportsAvailableComponent } from './reports/report-tabs/reports-available/reports-available.component';
import { ReportModalComponent } from './reports/report-tabs/report-modal/report-modal.component';
import { ReportsResultsComponent } from './reports/report-tabs/reports-results/reports-results.component';
import { ReportParamsModalComponent } from './reports/report-tabs/report-params-modal/report-params-modal.component';
import { SegmentModalComponent } from './segment-modal/segment-modal.component';
import { SegmentsComponent } from './segments/segments.component';
import { NumbersOnlyDirective } from './services/directives/numbers-only.directive';
import { InteractionsComponent } from './member/interactions/interactions.component';
import { ReportComponent } from './report/report.component';
import { ParameterModalComponent } from './parameter-modal/parameter-modal.component';
import { ReportDataComponent } from './report-data/report-data.component';
import { OrbComponent } from './orb/orb.component';
import { ScrollWithMeDirective } from './services/directives/scroll-with-me';
import { AddMoreDataToListComponent } from './shared/add-more-data-to-list/add-more-data-to-list.component';
import { SponsorshipPromoTreeComponent } from './sponsorship-promo-tree/sponsorship-promo-tree.component';
import { StartNoteModalComponent } from './member/start-note-modal/start-note-modal.component';
import { ManifestComponent } from './manifest/manifest.component';
import { EventSourcesTabComponent } from './integrations-tab/event-sources-tab/event-sources-tab.component';
import { NewEventSourceModalComponent } from './integrations-tab/new-event-source-modal/new-event-source-modal.component';
import { NewEventSourceMeasureModalComponent } from './integrations-tab/new-event-source-measure-modal/new-event-source-measure-modal.component';
import { NewMeasureFunctionDialogComponent } from './integrations-tab/new-measure-function-dialog/new-measure-function-dialog.component';
import { PromotionTemplatesTabComponent } from './promotion-templates-tab/promotion-templates-tab.component';
import { AddCampaignModalComponent } from './add-campaign-modal/add-campaign-modal.component';
import { PromotionDisplayTemplatesTabComponent } from './promotion-display-templates-tab/promotion-display-templates-tab.component';
import { JiraTicketModalComponent } from './jira-ticket-modal/jira-ticket-modal.component';
import { PromotionTemplateBuilderV2Component } from './promotion-config-builder/promotion-template-builder-v2/promotion-template-builder-v2.component';
import { BreadCrumbsComponent } from './bread-crumbs/bread-crumbs.component';
import { PromotionConfigBuilderV2Component } from './promotion-config-builder/promotion-config-builder-v2/promotion-config-builder-v2.component';
import { ActivityTriggerComponent } from './promotion-config-builder/activity-trigger/activity-trigger.component';
import { RewardTriggerComponent } from './promotion-config-builder/reward-trigger/reward-trigger.component';
import { ConfigSectionComponent } from './promotion-config-builder/config-section/config-section.component';
import { RegexSearchModalComponent } from './regex-search-modal/regex-search-modal.component';
import { PackagedSponsorshipComponent } from './packaged-sponsorship/packaged-sponsorship.component';
import { ConfigurationsTabComponent } from './configurations-tab/configurations-tab.component';
import { PromotionSponsorCampaignTabComponent } from './promotion-sponsor-campaign-tab/promotion-sponsor-campaign-tab.component';
import { ActivitySearchComponent } from './activity-search/activity-search.component';
import { ManifestCustomAttributeFormComponent } from './manifest/manifest-custom-attribute-form/manifest-custom-attribute-form.component';
import { ManifestMemberInstrumentFormComponent } from './manifest/manifest-member-instrument-form/manifest-member-instrument-form.component';
import { ManifestSegmentMappingFormComponent } from './manifest/manifest-segment-mapping-form/manifest-segment-mapping-form.component';
import { ManifestMemberRelationshipFormComponent } from './manifest/manifest-member-relationship-form/manifest-member-relationship-form.component';
import { ManifestFieldPropertyFormComponent } from './manifest/manifest-field-property-form/manifest-field-property-form.component';
import { PromotionSponsorRemindersTabComponent } from './promotion-sponsor-reminders-tab/promotion-sponsor-reminders-tab.component';
import { PromotionSponsorReminderModalComponent } from './promotion-sponsor-reminder-modal/promotion-sponsor-reminder-modal.component';
import { SponsorshipModalComponent } from './sponsorship-modal/sponsorship-modal.component';
import { PartyAttributeRestrictionComponent } from './promotion-config-builder/party-attribute-restriction/party-attribute-restriction.component';
import { ActivityRangeRestrictionComponent } from './promotion-config-builder/activity-range-restriction/activity-range-restriction.component';
import { ActivityWindowRestrictionComponent } from './promotion-config-builder/activity-window-restriction/activity-window-restriction.component';
import { AgeRestrictionComponent } from './promotion-config-builder/age-restriction/age-restriction.component';
import { GenderRestrictionComponent } from './promotion-config-builder/gender-restriction/gender-restriction.component';
import { ActivityAgeRestrictionComponent } from './promotion-config-builder/activity-age-restriction/activity-age-restriction.component';
import { ActivityAmountMatchRestrictionComponent } from './promotion-config-builder/activity-amount-match-restriction/activity-amount-match-restriction.component';
import { ActivityAmountRangeRestrictionComponent } from './promotion-config-builder/activity-amount-range-restriction/activity-amount-range-restriction.component';
import { ActivityAttributeRestrictionComponent } from './promotion-config-builder/activity-attribute-restriction/activity-attribute-restriction.component';
import { PartyAttributeIntervalRestrictionComponent } from './promotion-config-builder/party-attribute-interval-restriction/party-attribute-interval-restriction.component';
import { ActivityAttributeIntervalRestrictionComponent } from './promotion-config-builder/activity-attribute-interval-restriction/activity-attribute-interval-restriction.component';
import { LocationAttributeRestrictionComponent } from './promotion-config-builder/location-attribute-restriction/location-attribute-restriction.component';
import { PartyRelationshipRestrictionComponent } from './promotion-config-builder/party-relationship-restriction/party-relationship-restriction.component';
import { BehaviorCountThresholdComponent } from './promotion-config-builder/behavior-count-threshold/behavior-count-threshold.component';
import { AttributeIntervalBehaviorCountThresholdComponent } from './promotion-config-builder/attribute-interval-behavior-count-threshold/attribute-interval-behavior-count-threshold.component';
import { RewardCountThresholdComponent } from './promotion-config-builder/reward-count-threshold/reward-count-threshold.component';
import { RewardAmountThresholdComponent } from './promotion-config-builder/reward-amount-threshold/reward-amount-threshold.component';
import { TenderAmountThresholdComponent } from './promotion-config-builder/tender-amount-threshold/tender-amount-threshold.component';
import { CountMatchThresholdComponent } from './promotion-config-builder/count-match-threshold/count-match-threshold.component';
import { AttributeIntervalRewardCountThresholdComponent } from './promotion-config-builder/attribute-interval-reward-count-threshold/attribute-interval-reward-count-threshold.component';
import { CandidateCommunicationCountThresholdComponent } from './promotion-config-builder/candidate-communication-count-threshold/candidate-communication-count-threshold.component';
import { GatekeeperThresholdComponent } from './promotion-config-builder/gatekeeper-threshold/gatekeeper-threshold.component';
import { BehaviorCountCapComponent } from './promotion-config-builder/behavior-count-cap/behavior-count-cap.component';
import { RewardCountCapComponent } from './promotion-config-builder/reward-count-cap/reward-count-cap.component';
import { AttributeIntervalCountCapComponent } from './promotion-config-builder/attribute-interval-count-cap/attribute-interval-count-cap.component';
import { RewardEarnCapComponent } from './promotion-config-builder/reward-earn-cap/reward-earn-cap.component';
import { PerBehaviorCountCapComponent } from './promotion-config-builder/per-behavior-count-cap/per-behavior-count-cap.component';
import { CandidateCommunicationCapComponent } from './promotion-config-builder/candidate-communication-cap/candidate-communication-cap.component';
import { FixedRewardConsequenceComponent } from './promotion-config-builder/fixed-reward-consequence/fixed-reward-consequence.component';
import { OptOutConsequenceComponent } from './promotion-config-builder/opt-out-consequence/opt-out-consequence.component';
import { PercentageRewardConsequenceComponent } from './promotion-config-builder/percentage-reward-consequence/percentage-reward-consequence.component';
import { SteppedRewardConsequenceComponent } from './promotion-config-builder/stepped-reward-consequence/stepped-reward-consequence.component';
import { AddAttributeConsequenceComponent } from './promotion-config-builder/add-attribute-consequence/add-attribute-consequence.component';
import { RemoveAttributeConsequenceComponent } from './promotion-config-builder/remove-attribute-consequence/remove-attribute-consequence.component';
import { ChangeLanguageConsequenceComponent } from './promotion-config-builder/change-language-consequence/change-language-consequence.component';
import { RewardTransferConsequenceComponent } from './promotion-config-builder/reward-transfer-consequence/reward-transfer-consequence.component';
import { TrackingRewardConsequenceComponent } from './promotion-config-builder/tracking-reward-consequence/tracking-reward-consequence.component';
import { RelationshipConsequenceComponent } from './promotion-config-builder/relationship-consequence/relationship-consequence.component';
import { CandidateCommunicationConsequenceComponent } from './promotion-config-builder/candidate-communication-consequence/candidate-communication-consequence.component';
import { RelatedConfigurationsComponent } from './promotion-config-builder/related-configurations/related-configurations.component';
import { JsonPreviewComponent } from './promotion-config-builder/json-preview/json-preview.component';
import { ValidationComponent } from './promotion-config-builder/validation/validation.component';
import { AccessRolesComponent } from './access-roles/access-roles.component';
import { AccessRoleModalComponent } from './access-role-modal/access-role-modal.component';
import { CampaignInfoPanelComponent } from './promotions/campaign-info-panel/campaign-info-panel.component';
import { DisplayXmlTabComponent } from './display-xml-tab/display-xml-tab.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { EligiblePromotionsPanelComponent } from './promotions/eligible-promotions-panel/eligible-promotions-panel.component';
import { AnthemEligiblePromotionsPanelComponent } from './promotions/anthem-eligible-promotions-panel/anthem-eligible-promotions-panel.component';
import { PromotionPodComponent } from './promotions/promotion-pod/promotion-pod.component';
import { ButtonShowPromoDetailsComponent } from './components/button-show-promo-details/button-show-promo-details.component';
import { PromotionBehaviorCodeModalComponent } from './modals/promotion-behavior-code-modal/promotion-behavior-code-modal.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { AlternativeFormComponent } from './alternative-form/alternative-form.component';
import { PromotionCodeFormComponent } from './promotion-code-form/promotion-code-form.component';
import { CommunicationManagementComponent } from './communication-management/communication-management.component';
import { CampaignDetailsComponent } from './modals/campaign-details-component/campaign-details.component';
import { StringToHtmlPipe } from './string-to-html.pipe';
import { FilterPipe } from './filter.pipe';
import { PromotionPreviewModalComponent } from './promotion-preview-modal/promotion-preview-modal.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { AttributesDetailsModalComponent } from './attributes-details-modal/attributes-details-modal.component';
import { CommunicationAttributesTabComponent } from './communication-attributes-tab/communication-attributes-tab.component';
import { MemberDocumentTabComponent } from './member/member-tabs/member-document-tab/member-document-tab.component';
import { DocumentModalComponent } from './modals/document-modal/document-modal.component';
import { RedemptionDashboardComponent } from './redemption-dashboard/redemption-dashboard.component';
import { ChartComponent } from './charts/chart/chart.component';
import { SurveyTabComponent } from './survey-tab/survey-tab.component';
import { SurveyModalComponent } from './survey-modal/survey-modal.component';
import { SurveyQuestionsModalComponent } from './survey-questions-modal/survey-questions-modal.component';
import { ProspectiveModalComponent } from './prospective-modal/prospective-modal.component';
import { CommunicationTreeComponent } from './communication-tree/communication-tree.component';
import { CommStrategyTabComponent } from './comm-strategy-tab/comm-strategy-tab.component';
import { StrategyCampaignTabComponent } from './strategy-campaign-tab/strategy-campaign-tab.component';
import { CommStrategyHistoryTabComponent } from './comm-strategy-history-tab/comm-strategy-history-tab.component';
import { EndpointTabComponent } from './endpoint-tab/endpoint-tab.component';
import { AddFilterDialogComponent } from './add-filter-dialog/add-filter-dialog.component';
import AppErrorHandler from './error-handling/AppErrorHandler';
import { NavComponent } from './nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { CommunicationConfigurationTabComponent } from './communication-tabs/communication-configuration-tab/communication-configuration-tab.component';
import { PreferenceRestrictionsComponent } from './outreach-rules-builder/preference-restrictions/preference-restrictions.component';
import { TemporalConditionsComponent } from './outreach-rules-builder/temporal-conditions/temporal-conditions.component';
import { RewardsComponent } from './outreach-rules-builder/rewards/rewards.component';
import { RewardTypesFormComponent } from './outreach-rules-builder/reward-types-form/reward-types-form.component';
import { BehaviorsComponent } from './outreach-rules-builder/behaviors/behaviors.component';
import { BehaviorCodeFormComponent } from './outreach-rules-builder/behavior-code-form/behavior-code-form.component';
import { BindingComponent } from './outreach-rules-builder/binding/binding.component';
import { FeatureFlagDirective } from './feature-flag/feature-flag.directive';
import { OptOutPreferencesComponent } from './outreach-rules-builder/opt-out-preferences/opt-out-preferences.component';
import { LiveAgentDashboardComponent } from './live-agent-dashboard/live-agent-dashboard.component';
import { InstrumentNumberLabelComponent } from './instrument-number-label/instrument-number-label.component';
import { ManifestUnsavedChangesModalComponent } from './manifest/manifest-unsaved-changes-modal/manifest-unsaved-changes-modal.component';
import { ModalButtonsComponent } from './modal-buttons/modal-buttons.component';
import { IdentifierTypesTabComponent } from './identifier-types-tab/identifier-types-tab.component';
import { IdentifierTypesModalComponent } from './identifier-types-modal/identifier-types-modal.component';
import { ManifestSegmentMappingModalComponent } from './manifest/manifest-segment-mapping-modal/manifest-segment-mapping-modal.component';
import { CommunicationChannelConfigurationComponent } from './communication-tabs/communication-channel-configuration/communication-channel-configuration.component';
import { ChannelConfigurationModalComponent } from './communication-tabs/channel-configuration-modal/channel-configuration-modal.component';
import { CommunicationParentTreeComponent } from './communication/communication-parent-tree/communication-parent-tree.component';
import { JsonModalComponent } from './components/json-modal/json-modal.component';
import { CommunicationContentGroupComponent } from './components/communication-content-group/communication-content-group.component';
import { ContentGroupModalComponent } from './components/communication-content-group/content-group-modal/content-group-modal.component';
import { OutreachContentComponent } from './outreach-content/outreach-content.component';
import { OutreachContentModalComponent } from './outreach-content/outreach-content-modal/outreach-content-modal.component';
import { CommnunicationOutreachContentModalComponent } from './communication-tabs/commnunication-outreach-content-modal/commnunication-outreach-content-modal.component';
import { FutureOutreachComponent } from './communication-tabs/future-outreach/future-outreach.component';
import { OrganizationsComponent } from './organization/organizations/organizations.component';
import { OrganizationModalComponent } from './organization/organization-modal/organization-modal.component';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { IMaskModule } from 'angular-imask';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { RelationshipsTabComponent } from './member/member-tabs/relationships-tab/relationships-tab.component';
import { TrimDirective } from './services/directives/trim.directive';
//import { ManifestSegmentMappingModalComponent } from './manifest/manifest-segment-mapping-modal/manifest-segment-mapping-modal.component';
//void initLogging();

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SponsorsComponent,
    HomeComponent,
    SponsorComponent,
    CampaignComponent,
    MembersComponent,
    MemberComponent,
    MemberDetailsPanelComponent,
    ProcessingLogsComponent,
    DashboardComponent,
    SponsorModalComponent,
    SponsorshipGroupsTabComponent,
    PromotionsTabComponent,
    FilterHeaderUsersComponent,
    FilterNumberComponent,
    FilterButtonPanelComponent,
    FilterTagsComponent,
    FilterRadioComponent,
    FilterCheckboxComponent,
    FilterStringComponent,
    FilterTypeaheadComponent,
    FilterSelectComponent,
    FilterHeaderComponent,
    FilterHeaderActivityTabComponent,
    FilterHeaderGenericComponent,
    FilterHeaderMembersComponent,
    FilterDatepickerComponent,
    FilterDropdownComponent,
    FilterExpanderComponent,
    SearchFilterComponent,
    DashboardComponent,
    SegmentFilterComponent,
    SegmentTreeModalComponent,
    CallbackPipe,
    LogPipe,
    AddSponsorshipModalComponent,
    CommunicationsComponent,
    CommunicationComponent,
    CommunicationsModalComponent,
    CommunicationsTestModalComponent,
    IntegrationsComponent,
    IntegrationModalComponent,
    CommunicationSegmentsTabComponent,
    CommunicationHistoryTabComponent,
    CommunicationContentTabComponent,
    CommunicationAttachmentsTabComponent,
    CommunicationAttributeModalComponent,
    CommunicationAllowlistComponent,
    CommunicationAllowlistModalComponent,
    ProgramsComponent,
    UploadIntegrationModalComponent,
    ProgramTabComponent,
    CampaignTabComponent,
    PromotionTypesTabComponent,
    PromotionCategoryTabComponent,
    CommunicationStrategyTabComponent,
    ProgramTestModalComponent,
    ProgramModalComponent,
    ConfirmDialogComponent,
    ProgramImportModalComponent,
    PackagesTabComponent,
    CampaignModalComponent,
    ScrollWithMeDirective,
    UploadIntegrationModalComponent,
    IntegrationStageLoadComponent,
    MultiSelectDropdownComponent,
    IntegrationMessagesComponent,
    SelectOrganizationComponent,
    IntegrationStageImportComponent,
    CommunicationStrategyModalComponent,
    CommunicationStrategyConfigurationModalComponent,
    PromotionTypeModalComponent,
    PromotionCategoryModalComponent,
    IntegrationStageImportComponent,
    AssociatedProgramsModalComponent,
    OutboundComponent,
    OutboundModalComponent,
    OutboundStageLoadsComponent,
    OutboundStageExportsComponent,
    IsThereMoreComponent,
    SystemComponent,
    PromotionsComponent,
    PromotionModalComponent,
    PackageModalComponent,
    RewardTypesTabComponent,
    CommunicationAttachmentModalComponent,
    CommunicationContentTemplateModalComponent,
    OutreachContentTemplateModalComponent,
    CommunicationSegmentModalComponent,
    PromoTreeComponent,
    RewardTypeModalComponent,
    SponsorTabComponent,
    AccountsTabComponent,
    AccountsModalComponent,
    AdjustmentModalComponent,
    ReversalConfirmationModalComponent,
    InstrumentTypesTabComponent,
    InstrumentTypesModalComponent,
    BehaviorsTabComponent,
    BehaviorsModalComponent,
    WorldCurrenciesTabComponent,
    WorldCurrencyModalComponent,
    DeliveryProtocolsTabComponent,
    DeliveryProtocolModalComponent,
    DeliveryOptionsModalComponent,
    IntegrationServerModalComponent,
    IntegrationServersComponent,
    ContactsTabComponent,
    IntegrationKeysTabComponent,
    IntegrationKeyModalComponent,
    FiltersTabComponent,
    FilterModalComponent,
    LocationsTabComponent,
    LocationModalComponent,
    JobManagementTabComponent,
    JobManagementModalComponent,
    JobArgumentsModalComponent,
    MoreDetailsModalComponent,
    ContactReasonModalComponent,
    ContactReasonsTabComponent,
    MemberSetPasswordModalComponent,
    AttributeMappingsTabComponent,
    AttributeMappingsGroupModalComponent,
    AddAttributeMappingsModalComponent,
    MemberPromotionsTabComponent,
    RedemptionItemsModalComponent,
    RedemptionAddressModalComponent,
    AddressModalComponent,
    EmailModalComponent,
    PhoneModalComponent,
    InstrumentsTabComponent,
    InstrumentModalComponent,
    PartyAttributesTabComponent,
    CommunicationAttributesTabComponent,
    PromotionAttributesTabComponent,
    SegmentsTabComponent,
    AddUserToSegmentModalComponent,
    StatusSelectComponent,
    NotesTabComponent,
    TicketsComponent,
    OpenTicketsComponent,
    SortArrowsComponent,
    NotesTabComponent,
    PartnersComponent,
    PartnerModalComponent,
    CommunicationsTabComponent,
    CommunicationPreferenceModalComponent,
    MemberActivityTabComponent,
    MemberActivityModalComponent,
    MemberActivityReversalModalComponent,
    PartnerComponent,
    EventsTabComponent,
    MemberMeasuresTabComponent,
    InteractionEntryModalComponent,
    ReportsComponent,
    ReportsAvailableComponent,
    ReportModalComponent,
    ReportsResultsComponent,
    ReportParamsModalComponent,
    UserModalComponent,
    UsersComponent,
    InteractionEntryModalComponent,
    SegmentModalComponent,
    SegmentsComponent,
    ReportComponent,
    ParameterModalComponent,
    ReportDataComponent,
    OrbComponent,
    NumbersOnlyDirective,
    InteractionsComponent,
    ProcessingLogComponent,
    SortPipe,
    ListGroupByPipe,
    DateSubtractPipe,
    IdAttributePipe,
    AddMoreDataToListComponent,
    SponsorshipPromoTreeComponent,
    StartNoteModalComponent,
    StartNoteModalComponent,
    ManifestComponent,
    EventSourcesTabComponent,
    NewEventSourceModalComponent,
    NewEventSourceMeasureModalComponent,
    NewMeasureFunctionDialogComponent,
    ProcessingLogNotesModalComponent,
    BreadCrumbsComponent,
    PromotionTemplatesTabComponent,
    ExecuteReportModalComponent,
    AddCampaignModalComponent,
    PromotionDisplayTemplatesTabComponent,
    AddCampaignModalComponent,
    JiraTicketModalComponent,
    PromotionTemplateBuilderV2Component,
    PromotionConfigBuilderV2Component,
    ActivityTriggerComponent,
    RewardTriggerComponent,
    ConfigSectionComponent,
    RegexSearchModalComponent,
    PackagedSponsorshipComponent,
    ConfigurationsTabComponent,
    PromotionSponsorCampaignTabComponent,
    ActivitySearchComponent,
    ManifestCustomAttributeFormComponent,
    ManifestMemberInstrumentFormComponent,
    ManifestSegmentMappingFormComponent,
    ManifestMemberRelationshipFormComponent,
    ManifestFieldPropertyFormComponent,
    ActivityRangeRestrictionComponent,
    ActivityWindowRestrictionComponent,
    PromotionSponsorRemindersTabComponent,
    PromotionSponsorReminderModalComponent,
    SponsorshipModalComponent,
    PartyAttributeRestrictionComponent,
    AgeRestrictionComponent,
    GenderRestrictionComponent,
    ActivityAgeRestrictionComponent,
    ActivityAmountMatchRestrictionComponent,
    ActivityAmountRangeRestrictionComponent,
    ActivityAttributeRestrictionComponent,
    PartyAttributeIntervalRestrictionComponent,
    ActivityAttributeIntervalRestrictionComponent,
    LocationAttributeRestrictionComponent,
    PartyRelationshipRestrictionComponent,
    BehaviorCountThresholdComponent,
    AttributeIntervalBehaviorCountThresholdComponent,
    RewardCountThresholdComponent,
    RewardAmountThresholdComponent,
    TenderAmountThresholdComponent,
    CountMatchThresholdComponent,
    AttributeIntervalRewardCountThresholdComponent,
    CandidateCommunicationCountThresholdComponent,
    GatekeeperThresholdComponent,
    BehaviorCountCapComponent,
    RewardCountCapComponent,
    AttributeIntervalCountCapComponent,
    RewardEarnCapComponent,
    PerBehaviorCountCapComponent,
    CandidateCommunicationCapComponent,
    FixedRewardConsequenceComponent,
    OptOutConsequenceComponent,
    PercentageRewardConsequenceComponent,
    SteppedRewardConsequenceComponent,
    AddAttributeConsequenceComponent,
    RemoveAttributeConsequenceComponent,
    ChangeLanguageConsequenceComponent,
    RewardTransferConsequenceComponent,
    TrackingRewardConsequenceComponent,
    RelationshipConsequenceComponent,
    CandidateCommunicationConsequenceComponent,
    RelatedConfigurationsComponent,
    JsonPreviewComponent,
    ValidationComponent,
    AccessRolesComponent,
    AccessRoleModalComponent,
    DisplayXmlTabComponent,
    AccessRoleModalComponent,
    CampaignInfoPanelComponent,
    EligiblePromotionsPanelComponent,
    AnthemEligiblePromotionsPanelComponent,
    DisplayXmlTabComponent,
    ForbiddenComponent,
    PromotionPodComponent,
    ButtonShowPromoDetailsComponent,
    PromotionBehaviorCodeModalComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    AlternativeFormComponent,
    PromotionCodeFormComponent,
    CommunicationManagementComponent,
    CampaignDetailsComponent,
    StringToHtmlPipe,
    FilterPipe,
    PromotionPreviewModalComponent,
    NotFoundComponent,
    LoggedOutComponent,
    AttributesDetailsModalComponent,
    MemberDocumentTabComponent,
    DocumentModalComponent,
    RedemptionDashboardComponent,
    ChartComponent,
    SurveyTabComponent,
    SurveyModalComponent,
    SurveyQuestionsModalComponent,
    ProspectiveModalComponent,
    AddFilterDialogComponent,
    CommStrategyTabComponent,
    StrategyCampaignTabComponent,
    CommStrategyHistoryTabComponent,
    EndpointTabComponent,
    NavComponent,
    ProspectiveModalComponent,
    CommunicationTreeComponent,
    CommunicationConfigurationTabComponent,
    PreferenceRestrictionsComponent,
    TemporalConditionsComponent,
    RewardsComponent,
    RewardTypesFormComponent,
    BehaviorsComponent,
    BehaviorCodeFormComponent,
    BindingComponent,
    LiveAgentDashboardComponent,
    FeatureFlagDirective,
    OptOutPreferencesComponent,
    InstrumentNumberLabelComponent,
    ManifestUnsavedChangesModalComponent,
    ModalButtonsComponent,
    IdentifierTypesTabComponent,
    IdentifierTypesModalComponent,
    ManifestSegmentMappingModalComponent,
    CommunicationChannelConfigurationComponent,
    ChannelConfigurationModalComponent,
    CommunicationParentTreeComponent,
    JsonModalComponent,
    CommunicationContentGroupComponent,
    ContentGroupModalComponent,
    OutreachContentComponent,
    OutreachContentModalComponent,
    CommnunicationOutreachContentModalComponent,
    FutureOutreachComponent,
    OrganizationsComponent,
    OrganizationModalComponent,
    RelationshipsTabComponent,
    TrimDirective,
    // ManifestSegmentMappingModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgbModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgChartsModule,
    AutocompleteLibModule,
    JwtModule,
    NgxDropzoneModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    LayoutModule,
    NgxGraphModule,
    ApolloModule,
    IMaskModule,
    AngularEditorModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    LoginGuard,
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: 'https://48p1r2roz4.sse.codesandbox.io',
          }),
        };
      },
      deps: [HttpLink],
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
