import { LiveAgentSurveyService } from './../services/live-agent-survey.service';
import { ApplyFilterService } from './../services/filter-pop-service/applyFilter.service';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from 'src/app/app-data.service';
import { Filter, SearchFilters } from 'src/app/data/class';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from '../data/saveAs';

@Component({
  selector: 'app-live-agent-dashboard',
  templateUrl: './live-agent-dashboard.component.html',
})
export class LiveAgentDashboardComponent implements OnInit, OnDestroy {
  liveAgentSurveys: any[];
  lockedTables: boolean;
  lock: boolean;
  limit = 20;
  filter = new Filter();
  searchFilters: SearchFilters;
  appliedFilters: Filter[];
  filterButton: boolean = false
  isLoading: boolean;
  numColumns: number;
  isLiveAgentManager: boolean;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  lengthToCompare: number;
  segments = [];

  constructor(
    private _config: NgbModalConfig,
    private liveAgentSurveyService: LiveAgentSurveyService,
    private toastr: ToastrService,
    private globalData: AppDataService,
    private modalHelper: NgbModal,
    private applyfilterService: ApplyFilterService
  ) {
    _config.backdrop = 'static';
    _config.keyboard = false;
  }

  ngOnDestroy() {
    if (this.searchFilters?.clearAll) {
      this.searchFilters?.clearAll();
    }
  }

  ngOnInit(): void {
    this.isLiveAgentManager = this.globalData.checkPermission('LIVE_AGENT_MANAGER');

    if (!this.isLiveAgentManager) {
      this.segments.push(this.globalData.segmentId);
    }

    this.numColumns = this.isLiveAgentManager ? 6 : 5;

    if (this.isLiveAgentManager) {
      this.liveAgentSurveyService
        .getLiveAgentList({ roles: ['LIVE_AGENT', 'LIVE_AGENT_MANAGER'] })
        .subscribe((response: any) => {
          this.initSearchFilter();

          this.searchFilters.filters.push({
            name: 'selectedAgentName',
            placeholder: 'Select Agent Name',
            type: 'array',
            choices: response.entity.map((element: any) => ({
              id: element.id,
              name: element.firstName + ' ' + element.lastName,
            })),
            nameProp: 'name',
            label: 'Agent Name',
            valueProp: 'id',
            inputType: 'select',
            multiple: false,
          });

          this.initFilter();
          this.initNewSearch();
        });
    } else {
      this.initSearchFilter();
      this.initNewSearch();
    }
  }

  initSearchFilter(): void {
    this.searchFilters = {
      formName: 'survey-status',
      filters: [
        {
          name: 'assessmentStartDate',
          placeholder: 'mm/dd/yyyy',
          label: 'Assessment Date Start',
          type: 'date',
        },
        {
          name: 'assessmentEndDate',
          placeholder: 'mm/dd/yyyy',
          label: 'Assessment Date End',
          type: 'date',
        },
        {
          name: 'memberFirstName',
          placeholder: 'Add First Name',
          label: 'First Name',
        },
        {
          name: 'memberLastName',
          placeholder: 'Add Last Name',
          label: 'Last Name',
        },
        {
          name: 'completedByFirstName',
          placeholder: 'Add Completed By First Name',
          label: 'Completed By First Name',
        },
        {
          name: 'completedByLastName',
          placeholder: 'Add Completed By  Last Name',
          label: 'Completed By Last Name',
        },
        {
          name: 'primaryInstrumentNumber',
          placeholder: 'Add Primary Instrument Number',
          label: 'Instrument Number',
        },
      ],
      searchCallback: () => {
        this.initNewSearch();
      },
    };
  }

  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getSurveys(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };

  getSurveys(concat: boolean): void {
    if (this.segments.length > 100) {
      this.toastr.warning('Please select less than a 100 segments in order to retrieve surveys');
    } else if (this.isLiveAgentManager && (this.segments === null || this.segments.length === 0)) {
      this.toastr.warning('Please select at least 1 segment in order to retrieve surveys');
      this.liveAgentSurveys = null;
      this.newLengthEvent.emit(0);
      this.lock = true;
      this.isLoading = false;
    } else {
      this.isLoading = true;
      this.filter = this.getFilters(this.filter);
      const params = { ...this.filter, segments: this.segments };
      this.liveAgentSurveyService.getLiveAgentSurveys(params, this.isLiveAgentManager).subscribe(
        (res: any) => {
          if (concat) {
            this.liveAgentSurveys = this.liveAgentSurveys.concat(res.entity);
          } else {
            this.liveAgentSurveys = res.entity;
          }
          this.lengthToCompare = res.entity.length;
          this.newLengthEvent.emit(this.lengthToCompare);
          this.filter.offset += this.limit;
          this.lockedTables = res.entity.length !== this.limit;
          this.lock = this.lockedTables;
          this.isLoading = false;
        },
        (res: any) => {
          this.lockedTables = false;
          this.isLoading = false;
          this.lock = false;
          this.toastr.error('Error occured!');
        }
      );
    }
  }

  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Outreach plan';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.searchFilters = data;
      this.applyfilterService.getFunctions(this.searchFilters);
    });
  }

  exportCSV(): void {
    if (this.segments.length == 0) {
      this.toastr.error('You must select at least one market to export');
    } else {
      this.filter = this.getFilters(this.filter);
      const params = { ...this.filter, segments: this.segments };
      this.liveAgentSurveyService.exportLiveAgent(params, this.isLiveAgentManager).subscribe(
        (data: any) => {
          const blob = new Blob([data], { type: 'application/octet-stream' });
          saveAs(
            blob,
            new Date()
              .toISOString()
              .slice(-24)
              .replace(/[^0-9]/g, '')
              .slice(0, 14) + '-survey-export.csv'
          );

          this.toastr.success('Surveys successfully exported.');
        },
        (data: any) => {
          console.log(data);
          this.toastr.error('Error while exporting surveys.');
        }
      );
    }
  }
}
