<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox">
        <div class="ibox-content">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="filters_container">
                  <div class="search-input1">
                    <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
                    <mat-icon class="search">search</mat-icon>
                  </div>
                  <div class="right_container1">
                    <button class="filter-btn" (click)="FilterModel()">
                      FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
                    </button>
                  </div>
                </div>
                <app-filter-tags
                  *ngIf="searchFilters"
                  isPopup="true"
                  [(filterButton)]="filterButton"
                  [(searchFilters)]="searchFilters"
                  [(appliedFilters)]="appliedFilters"
                ></app-filter-tags>
              </div>
              <div class="row double-scroll">
                <table class="table table-pointable table-bordered w-100">
                  <thead>
                    <tr>
                      <th class="table_field_name">Report name</th>
                      <th class="table_field_name">Report notes</th>
                      <th class="table_field_name">Requested date</th>
                      <th class="table_field_name">Status</th>
                      <th class="table_field_name">Format</th>
                      <th class="table_field_name">Error message</th>
                      <th class="table_field_name">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let reportResult of reportResults | filter : searchText; let i = index">
                      <td>{{ reportResult.reportName }}</td>
                      <td>{{ reportResult.notes }}</td>
                      <td>{{ reportResult.startedOn | date : "yyyy-MM-dd hh:mm:ss" }}</td>
                      <td>{{ reportResult.status }}</td>
                      <td>{{ reportResult.extension }}</td>
                      <td>{{ reportResult.errorMessage }}</td>
                      <td>
                        <a
                          [hidden]="!reportResult.downloadLink"
                          (click)="download($event, reportResult.downloadLink)"
                          href="{{ reportResult.downloadLink }}"
                          class="btn btn-dark-blue-action btn-sm ml-1"
                          ><fa-icon [icon]="faDownload"></fa-icon
                        ></a>
                        <a
                          class="btn btn-dark-blue-action btn-sm ml-1"
                          (click)="viewReportParams(reportResult)"
                          title="View Report Parameters"
                          ><fa-icon [icon]="faEye"></fa-icon
                        ></a>
                      </td>
                    </tr>
                    <tr app-is-there-more [numColumns]="7" [newLengthEvent]="newLengthEvent"></tr>
                  </tbody>
                </table>
              </div>
              <app-add-more-data-to-list (onScroll)="getReportResults(true)" [lock]="lock" [isLoading]="isLoading">
              </app-add-more-data-to-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
