<form [formGroup]="campaignForm" novalidate>
  <div class="d-flex justify-content-end mt-4 mr-4">
    <mat-icon (click)="cancel()" class="cursor-pointer">close</mat-icon>
  </div>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="col-lg-12">
    <nav mat-tab-nav-bar mat-align-tabs="center" animationDuration="200ms" style="margin-bottom: 10px">
      <a
        mat-tab-link
        label="{{ tab.displayName }}"
        *ngFor="let tab of tabs"
        (click)="changeTab(tab.idx)"
        [active]="activeTabIdx === tab.idx"
        >{{ tab.displayName }}</a
      >
    </nav>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div *ngIf="activeTabIdx === 0">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label>Name <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <input type="text" class="form-control" (change)="changesMade()" formControlName="name" autofocus />
            <!-- <div class="mt-sm" [hidden]="campaignForm.controls['name'].valid">
              <small class="text-danger">Input a Name</small>
            </div> -->
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label>Code <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <input type="text" class="form-control" (change)="changesMade()" formControlName="code" />
            <!-- <div class="mt-sm" [hidden]="campaignForm.controls['code'].valid">
              <small class="text-danger">Input a Code</small>
            </div> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label>External Program Id <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <input type="text" class="form-control" (change)="changesMade()" formControlName="externalProgramId" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label>Expiration Date</label>
          <div class="input-group date">
            <input
              class="form-control cursor-pointer"
              readonly
              (click)="expirationDate.toggle()"
              (ngModelChange)="changesMade()"
              placeholder="mm/dd/yyyy"
              name="expirationDate"
              ngbDatepicker
              #expirationDate="ngbDatepicker"
              formControlName="expirationDate"
              [footerTemplate]="footerTemplateExpirationDate"
            />
            <ng-template #footerTemplateExpirationDate>
              <div class="width-100 datePickerTemplate">
                <button class="buttonDateTemplate" (click)="clearDate('expirationDate'); expirationDate.toggle()">
                  Clear
                </button>
              </div>
            </ng-template>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="expirationDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label>Status <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <select name="status" class="form-control" (change)="changesMade()" formControlName="status">
              <option *ngFor="let option of statuses" value="{{ option.value }}">{{ option.value }}</option>
            </select>
            <!-- <div class="mt-sm" [hidden]="campaignForm.controls['status'].valid">
              <small class="text-danger">Select status</small>
            </div> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Cap</label>
            <input type="number" class="form-control" (change)="changesMade()" formControlName="cap" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Cap Reward Codes</label>
            <input type="text" class="form-control" (change)="changesMade()" formControlName="capRewardCodes" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label>From Date <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <div class="input-group date">
              <input
                class="form-control cursor-pointer"
                readonly
                (click)="fromDate.toggle()"
                (ngModelChange)="changesMade()"
                placeholder="mm/dd/yyyy"
                name="fromDate"
                ngbDatepicker
                #fromDate="ngbDatepicker"
                formControlName="fromDate"
                [footerTemplate]="footerTemplateFromDate"
              />

              <ng-template #footerTemplateFromDate>
                <div class="width-100 datePickerTemplate">
                  <button class="buttonDateTemplate" (click)="clearDate('fromDate'); fromDate.toggle()">Clear</button>
                </div>
              </ng-template>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="fromDate.toggle()" type="button">
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </button>
              </div>
            </div>
            <!-- <div class="mt-sm" [hidden]="campaignForm.controls['fromDate'].value">
              <small class="text-danger">Input a from date</small>
            </div> -->
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Thru Date</label>
            <div class="input-group date">
              <input
                class="form-control cursor-pointer"
                readonly
                (click)="thruDate.toggle()"
                (ngModelChange)="changesMade()"
                placeholder="mm/dd/yyyy"
                name="thruDate"
                ngbDatepicker
                #thruDate="ngbDatepicker"
                formControlName="thruDate"
                [footerTemplate]="footerTemplateThruDate"
              />
              <ng-template #footerTemplateThruDate>
                <div class="width-100 datePickerTemplate">
                  <button class="buttonDateTemplate" (click)="clearDate('thruDate'); thruDate.toggle()">Clear</button>
                </div>
              </ng-template>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="thruDate.toggle()" type="button">
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Rollover Interval</label>
            <select name="interval" class="form-control" (change)="changesMade()" formControlName="rolloverInterval">
              <option *ngFor="let int of intervals" value="{{ int }}">{{ int }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Communication Strategy</label>
            <ng-select
              [items]="communications"
              (ngModelChange)="changesMade()"
              bindLabel="name"
              bindValue="id"
              formControlName="communicationStrategyId"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Description</label>
        <textarea
          formControlName="description"
          (change)="changesMade()"
          type="text"
          class="form-control vertical-expand"
        ></textarea>
      </div>
    </div>
    <div class="form-group" *ngIf="activeTabIdx === 1">
      <div>
        <label>Program Attributes</label>
        <button
          class="btn btn-sm btn-dark-blue float-right"
          type="button"
          (click)="addAttrib()"
          title="Add New Campaign Attribute"
        >
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
        <hr />
        <div class="row mt-2" [hidden]="campaignAttribs().controls.length === 0">
          <div class="col-md-5">
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label>Attribute Name <span style="color: red">*</span></label>
                <span>(Required)</span>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label>Attribute Value <span style="color: red">*</span></label>
                <span>(Required)</span>
              </div>
            </div>
          </div>
        </div>
        <div formArrayName="campaignAttribs">
          <div class="row" *ngFor="let attrControl of campaignAttribs().controls; let i = index" [formGroupName]="i">
            <div class="col-md-5">
              <div class="form-group">
                <input type="text" class="form-control" formControlName="attrName" />
              </div>
              <!-- <div class="mt-sm" [hidden]="attrControl.controls['attrName'].valid">
                <small class="text-danger">Input a Name</small>
              </div> -->
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <input type="text" class="form-control" formControlName="attrValue" />
              </div>
              <!-- <div class="mt-sm" [hidden]="attrControl.controls['attrValue'].valid">
                <small class="text-danger">Input a Value</small>
              </div> -->
            </div>
            <div class="col-md-2">
              <div class="form-group float-right">
                <button class="btn btn-dark-blue btn-sm delete float-right" (click)="removeAttrib(i)">
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="activeTabIdx === 2">
      <div>
        <label>Party Attributes</label>
        <button
          class="btn btn-sm btn-dark-blue float-right"
          type="button"
          (click)="addCampEli()"
          title="Add New Campaign Attribute"
        >
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
        <hr />
        <div class="row mt-2" [hidden]="campaignEligibilityCondition().controls.length === 0">
          <div class="col-md-5">
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label>Attribute Name <span style="color: red">*</span></label>
                <span>(Required)</span>
              </div>
            </div>
          </div>
        </div>
        <div formArrayName="campaignEligibilityCondition">
          <div
            class="row"
            *ngFor="let campEliControl of campaignEligibilityCondition().controls; let i = index"
            [formGroupName]="i"
          >
            <div class="col-md-9">
              <div class="form-group">
                <input type="text" class="form-control" formControlName="attrName" (change)="changesMade()" />
              </div>
              <!-- <div class="mt-sm" [hidden]="campEliControl.controls['attrName'].valid">
                <small class="text-danger">Input a Name</small>
              </div> -->
            </div>
            <div class="col-md-3">
              <div class="form-group float-right">
                <button class="btn btn-dark-blue btn-sm delete float-right" (click)="removeCampaignEli(i)">
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </div>
              <div class="form-group float-right">
                <button class="btn btn-dark-blue btn-sm add float-right" (click)="addValue(camp(campEliControl))">
                  <fa-icon [icon]="faPlus"></fa-icon>
                </button>
              </div>
            </div>
            <div formArrayName="attrValues">
              <div
                class="row"
                *ngFor="let campValue of camp(campEliControl).controls; let b = index"
                [formGroupName]="b"
              >
                <div class="row col-md-12 offset-md-1 d-flex align-items-center">
                  <div class="col-md-7 d-flex flex-column">
                    <div class="mb-1">Input a Value <span style="color: red">*</span></div>
                    <div class="form-group">
                      <input type="text" class="form-control" (change)="changesMade()" formControlName="attrValue" />
                    </div>
                    <!-- <div class="mt-sm" [hidden]="campValue.controls['attrValue'].valid">
                      <small class="text-danger">Input a Value</small>
                    </div> -->
                  </div>
                  <div class="col-md-2">
                    <div class="form-group float-right">
                      <button
                        class="btn btn-dark-blue btn-sm delete float-right"
                        (click)="removeValue(camp(campEliControl), b)"
                      >
                        <fa-icon [icon]="faTrash"></fa-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button class="btn btn-dark-blue" type="submit" [disabled]="!campaignForm.valid" (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button> -->
    <button class="btn btn-outline-primary font-weight-bold mr-2" type="button" (click)="cancel()" title="Cancel">
      CANCEL
    </button>
    <button
      class="btn btn-primary font-weight-bold"
      type="submit"
      style="width: 75px"
      [disabled]="!campaignForm.valid"
      (click)="ok()"
      title="Save"
    >
      SAVE
    </button>
  </div>
</form>
