import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DeliveryProtocol } from '../../data/model';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { CommunicationsService } from 'src/app/services/communications.service';

@Component({
  selector: 'app-delivery-protocol-modal',
  templateUrl: './delivery-protocol-modal.component.html',
  styleUrls: ['./delivery-protocol-modal.component.scss'],
})
export class DeliveryProtocolModalComponent implements OnInit {
  title: string;
  errorMsg = '';
  deliveryProtocolForm: UntypedFormGroup;
  faTimes = faTimes;
  faSave = faSave;
  @Input() endpointTypes: any[];
  @Input() protocol: DeliveryProtocol;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private communicationsService: CommunicationsService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    if (this.protocol) {
      this.title = 'Edit communication';
      this.deliveryProtocolForm = this.formBuilder.group({
        name: [this.protocol.name, Validators.required],
        endpointType: [this.protocol.endpointType, Validators.required],
        emailType: [this.protocol.emailType || 'DEFAULTSENDER'],
      });
    } else {
      this.title = 'New Delivery Protocol';
      this.deliveryProtocolForm = this.formBuilder.group({
        name: ['', Validators.required],
        endpointType: ['', Validators.required],
        emailType: ['DEFAULTSENDER'],
      });
    }
  }

  ok(): void {
    let id;
    let value;
    if (this.protocol) {
      id = this.protocol.id;
      value = { ...this.protocol, ...this.deliveryProtocolForm.value };
    } else {
      value = this.deliveryProtocolForm.value;
    }
    this.communicationsService.upsertDeliveryProtocol(value).subscribe(
      (data: any) => {
        this.responseHelperService.success('Protocol successfully updated', true);
        this.successEvent.emit();
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error, false);
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
