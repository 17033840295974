import { ResponseHelperService } from './../../services/helpers/response-helper.service';
import { IntegrationsService } from './../../services/integrations.service';
import { Filter, Choice, SearchFilters } from './../../data/class';
import { AppDataService } from './../../app-data.service';
import { StatusHelperService } from './../../services/helpers/status-helper.service';
import { TableHelperService } from './../../services/helpers/table-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { faCircle, faCopy, faSyncAlt, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
@Component({
  animations: [
    trigger('slideRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate(200, style({ transform: 'translateY(0%)' })),
      ]),
    ]),
    trigger('openClose', [
      state('true', style({ width: '96%' })),
      state('false', style({ width: '100%' })),
      transition('false <=> true', animate(200)),
    ]),
  ],
  selector: 'app-integration-stage-import',
  templateUrl: './integration-stage-import.component.html',
  styleUrls: ['./integration-stage-import.component.scss'],
})
export class IntegrationStageImportComponent implements OnInit {
  importKeyword = '';
  filteredImportStatus = [];
  filter = new Filter();
  isLoading = false;
  limit = 20;
  statuses: Choice[] = [];
  selectedIntegrationId: number;
  selectedLoadId: number;
  selectedImport: any;
  enableStandardView = false;
  imports: any;
  lengthToCompare: number;
  selectedIntegrationName: string;
  lock: boolean;
  lockedTable: boolean;
  searchFilters: SearchFilters;
  faCircle = faCircle;
  faCopy = faCopy;
  faSyncAlt = faSyncAlt;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  rawViewData = '';
  appliedFilters: Filter[];
  filterButton: boolean = false;
  filterdata: SearchFilters;
  searchText: any;
  helpers: any;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private titleHelper: Title,
    private route: ActivatedRoute,
    private router: Router,
    private tableHelperService: TableHelperService,
    private globalData: AppDataService,
    private statusHelperService: StatusHelperService,
    private integrationsService: IntegrationsService,
    private responseHelperService: ResponseHelperService,
    private toastr: ToastrService,
    private applyfilterService: ApplyFilterService,
    private modalHelper: NgbModal
  ) {}

  statusHelper = this.statusHelperService.getStatus('badge');
  isProgramManager = this.globalData.isProgramManager;

  ngOnInit(): void {
    this.titleHelper.setTitle('Imports');
    this.selectedIntegrationId = parseInt(this.route.snapshot.paramMap.get('integrationId'), 10);
    this.selectedLoadId = parseInt(this.route.snapshot.paramMap.get('loadId'), 10);

    if (this.selectedIntegrationId === undefined) {
      this.router.navigate(['integrations']);
    }
    if (this.selectedLoadId === undefined) {
      this.router.navigate(['integrations/' + this.selectedIntegrationId + '/loads']);
    }

    this.searchFilters = {
      formName: 'integration-stage-import-search',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('IMPORT'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
      ],
      searchCallback: () => {
        this.initNewSearch();
      },
    };
    this.initNewSearch();

    this.initFilter();
    this.setStatuses();
    this.tableHelperService.showTitle();
    this.getIntegration();
  }

  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getImports(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };
  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Integrations';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
    });
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('IMPORT');
  }
  getImports(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);
    this.integrationsService.getImports(this.selectedIntegrationId, this.selectedLoadId, this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.imports = this.imports.concat(data.entity);
        } else {
          this.imports = data.entity;
        }
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTable = data.entity.length !== this.limit;
        this.lock = this.lockedTable;
        this.isLoading = false;
      },
      () => {
        this.lockedTable = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  showImport(e, selectedImport): void {
    if (e) {
      if (this.selectedImport) {
        this.selectedImport.selected = false;
      }
      this.selectedImport = selectedImport;
      selectedImport.selected = true;
    }
  }

  showFirstImport(): void {
    if (!this.selectedImport) {
      this.selectedImport = this.imports[0];
    }
  }

  showRawData(id: string): void {
    if (this.rawViewData === id) {
      this.rawViewData = '';
    } else {
      this.rawViewData = id;
    }
  }

  clearAllFilters(): void {
    this.filteredImportStatus = [];
    this.setStatuses();
    this.filter = new Filter();
    this.initFilter();
    this.getImports(false);
  }

  // TODO: We don't have imports on qa1, so I don't know how to test behavior of this part of code
  // ctrl.$watch('enableStandardView', function (newVal) {
  //   if (ctrl.enableStandardView && !ctrl.selectedImport && ctrl.imports) {
  //     // select the first one to look pretty
  //     ctrl.imports[0].selected = true;
  //     ctrl.selectedImport = ctrl.imports[0];
  //   }
  // });

  retrySingleImport(stageLoadId: number, importId: number): void {
    this.integrationsService.retryImport(stageLoadId, importId).subscribe(
      (data: any) => {
        this.responseHelperService.success('Staged import marked for Retry', false);
        this.initFilter();
        this.getImports(false);
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error);
      }
    );
  }

  applyFilter(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredImportStatus);
    this.lock = true;
    this.getImports(false);
  }

  getIntegration(): void {
    this.integrationsService.getIntegration(this.selectedIntegrationId).subscribe((data: any) => {
      if (data.success) {
        this.selectedIntegrationName = data.entity.name;
      }
    });
  }
}
