import { ReportClassificationHelperService } from './../../../services/helpers/report-classification-helper.service';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Filter, Choice, SearchFilters } from 'src/app/data/class';
import { faPlus, faCogs, faPlane, faRocket, faCog } from '@fortawesome/free-solid-svg-icons';
import { ReportsService } from 'src/app/services/reports.service';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportModalComponent } from '../report-modal/report-modal.component';
import { ToastrService } from 'ngx-toastr';
import { ExecuteReportModalComponent } from '../../execute-report-modal/execute-report-modal.component';
import { Report } from 'src/app/data/model';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { selectRow } from 'src/app/data/selectRow';

@Component({
  selector: 'app-reports-available',
  templateUrl: './reports-available.component.html',
  styleUrls: ['./reports-available.component.scss'],
})
export class ReportsAvailableComponent implements OnInit, OnDestroy {
  @Input() isAdmin: boolean;
  @Input() classification: any;
  @Input() selectedPersonId: number;
  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  searchFilters: SearchFilters;
  statuses: Choice[] = [];
  lock: boolean;
  lengthToCompare: number;
  reportsAvailable: any[] = [];
  lockedTables: boolean;
  params: any[] = [];
  tableFields: any[];
  tableColumns: any[] = [];
  tableData: any[] = [];
  tableRows: any[] = [];
  autocompleteDataArray: any[] = [];
  report: any;
  autocomplete: any = {};
  autocompleteArray: any = {};
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  faPlus = faPlus;
  faCogs = faCogs;
  faPlane = faPlane;
  faRocket = faRocket;
  faCog = faCog;
  show = true;
  searchText: any;
  types: any = [
    {
      value: 'Interactive',
      state: false,
    },
    {
      value: 'Outbound',
      state: false,
    },
    {
      value: 'Dashboard',
      state: false,
    },
    {
      value: 'Integrations',
      state: false,
    },
  ];
  filterdata: SearchFilters;
  helpers: any;
  appliedFilters: Filter[];
  filterButton: boolean = false;

  constructor(
    private reportsService: ReportsService,
    private http: HttpClient,
    private modalHelper: NgbModal,
    private responseHelper: ResponseHelperService,
    private tableHelperService: TableHelperService,
    private reportClassificationHelperService: ReportClassificationHelperService,
    private toastr: ToastrService,
    private applyfilterService: ApplyFilterService
  ) {}
  ngOnDestroy() {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }

  classifications = this.reportClassificationHelperService.getClassifications();

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'reportsAvailableSearch',
      searchCallback: () => {
        this.initNewSearch();
      },
      buttonsWithText: true,
      filters: [
        {
          name: 'reportName',
          placeholder: 'Add name',
          label: 'Report name',
        },
        {
          name: 'types',
          placeholder: 'Select type',
          type: 'array',
          choices: this.types,
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
          label: 'Type',
        },
        {
          name: 'statuses',
          placeholder: 'Select status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('AVAILABLE_REPORTS'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
          label: 'Status',
        },
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'Add Report',
          if: this.isAdmin,
          callback: () => {
            this.createReport();
          },
          title: 'Add Report',
        },
      ],
    };

    if (!this.classification) {
      this.searchFilters.filters.push({
        name: 'classifications',
        placeholder: 'Classification',
        type: 'array',
        choices: this.classifications,
        nameProp: 'name',
        valueProp: 'value',
        inputType: 'dropdown',
      });
    }
    this.initNewSearch();
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getReportsAvaliable(false);
  }

  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getReportsAvaliable(false);
  }
  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };
  getReportsAvaliable(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);

    if (this.classification === 'Communication') {
      this.filter['classifications'] = 'COMMUNICATION';
    }

    this.reportsService.getAllReports(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          if (this.isAdmin) {
            this.reportsAvailable = this.reportsAvailable.concat(data.entity.records);
          } else {
            this.reportsAvailable = this.reportsAvailable.concat(this.interactiveFilter(data.entity.records));
          }
        } else {
          if (this.isAdmin) {
            this.reportsAvailable = data.entity.records;
          } else {
            this.reportsAvailable = this.interactiveFilter(data.entity.records);
          }
        }
        this.lengthToCompare = data.entity.records.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = this.lengthToCompare !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.isLoading = false;
        this.lock = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  private interactiveFilter(reportsArray): Object[] {
    let reportsAvailableNonAdmin = [];
    if (reportsArray.length > 0) {
      reportsAvailableNonAdmin = reportsArray.filter(this.isInteractive);
      return reportsAvailableNonAdmin;
    }
  }

  private isInteractive(element, index, array): any {
    return element.interactiveReport;
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  toggleSort(column: number): void {
    const dir = this.filter.column === column ? this.flipDirection() : 'DESC';
    this.initFilter();
    this.filter.dir = dir;
    this.filter.column = column;
    this.lock = true;
    this.getReportsAvaliable(false);
  }

  flipDirection(): string {
    if (this.filter.dir === 'DESC') {
      return 'ASC';
    } else {
      return 'DESC';
    }
  }

  createReport(): void {
    const instance = this.modalHelper.open(ReportModalComponent);
    instance.componentInstance.classification = this.classification;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data.success) {
        this.responseHelper.success('Report successfully created', true);
        this.initNewSearch();
      }
    });
  }

  prepForRendering(attrs: any[]): void {
    this.tableFields = attrs;
    attrs.forEach((attr: any) => {
      if (attr.type === 'DATA') {
        this.tableData.push(attr.caption);
      }
      if (attr.type === 'COLUMN') {
        this.tableColumns.push(attr.caption);
      }
      if (attr.type === 'ROW') {
        this.tableRows.push(attr.caption);
      }
    });
  }

  runReport(report: Report): void {
    this.reportsService.getReportParams(report.id).subscribe((data: any) => {
      console.log('console 1', data);
      this.params = data.entity.clientParams;
      this.report = report;
      data.entity.reportAttributes = data.entity.reportAttributes.filter((attr: any) => {
        return attr.attrName !== '_CHARTTYPE' && attr.attrName !== 'EXTERNAL_APP_URL';
      });
      if (data.entity.reportAttributes.length > 0) {
        this.prepForRendering(JSON.parse(data.entity.reportAttributes[0].attrValue));
      }
      this.params.forEach((param: any) => {
        if (
          (param.paramType === 'BIGINT' || param.paramType === 'VARCHAR') &&
          param.autocompleteAction !== null &&
          param.autocompleteAction !== ''
        ) {
          this.http.get('/cheetah/api' + param.autocompleteAction).subscribe((response: any) => {
            if (response.data.entity.records) {
              this.autocomplete[param.paramCode] = response.data.entity.records;
            } else {
              if (response.data.entity[0] && typeof response.data.entity[0] === 'string') {
                const autocompletePrepared = [];
                response.data.entity.forEach((item: any) => {
                  autocompletePrepared.push({
                    name: item,
                  });
                });
                this.autocomplete[param.paramCode] = autocompletePrepared;
              } else {
                this.autocomplete[param.paramCode] = response.data.entity;
              }
            }
          });
        } else if (param.paramType === 'ARRAY' && param.autocompleteAction !== null) {
          this.reportsService.getReportArrayParamsData(param.autocompleteAction).subscribe((response: any) => {
            if (response.entity.records) {
              this.autocompleteArray[param.paramCode] = response.entity.records;
            } else {
              this.autocompleteArray[param.paramCode] = response.entity;
            }
            // fill in default params
            if (param.defaultValue) {
              const defaultArray = param.defaultValue.split(',').map((elem: string) => {
                return elem.trim();
              });
              this.autocompleteDataArray[param.paramCode] = [];
              this.autocompleteArray[param.paramCode].forEach((elem: any) => {
                if (defaultArray.indexOf(elem.id) !== -1 || defaultArray.indexOf(elem.text) !== -1) {
                  this.autocompleteDataArray[param.paramCode].push(elem);
                }
              });
            }
          });
        }
      });
      this.openviewReportModal(report);
    });
  }

  openviewReportModal(report: Report): void {
    const instance = this.modalHelper.open(ExecuteReportModalComponent);
    instance.componentInstance.report = report;
    instance.componentInstance.params = this.params;
    instance.componentInstance.autocomplete = this.autocomplete;
    instance.componentInstance.autocompleteArray = this.autocompleteArray;
    instance.componentInstance.dismissModalEvent.subscribe(() => {
      instance.close();
    });
  }

  getArrayType(paramCode: string): any {
    return (this.params.find((param: any) => param.paramCode === paramCode) || {}).arrayElementType;
  }

  contExteranlAppUrls(report: any): number {
    return report.reportAttributes.filter((element: any) => element.attrName === 'EXTERNAL_APP_URL').length;
  }

  showReportAvaliable(event: any, report: any): void {
    selectRow(event);
    this.selectedPersonId = report;
  }
  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Reports';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(data);
    });
  }
}
