<div class="panel-body">
  <div class="col-md-12">
    <div class="full-height-scroll" full-scroll>
      <app-search-filter [searchFilters]="searchFilters" [helperData]="selectedActivitiesCount"></app-search-filter>
    </div>
    <div class="row m-b"></div>
    <div class="row table-activities">
      <table class="table table-pointable table-bordered">
        <thead>
          <tr>
            <th class="width-55-px">
              <div
                *ngIf="appDataService.isCSRManager"
                class="checkbox checkbox-info float-right m-t-none m-b-none inline-block width-50-percentage"
              >
                <input
                  id="select-all-cb"
                  [(ngModel)]="checkAll"
                  type="checkbox"
                  class="proc-check"
                  (click)="selectAllCb($event)"
                />
                <label for="select-all-cb"></label>
              </div>
            </th>
            <th width="10%" (click)="toggleSort(1)">
              ID
              <app-sort-arrows [selectedColumn]="filter.column" [column]="1" [dir]="filter.dir"> </app-sort-arrows>
            </th>
            <th width="15%" (click)="toggleSort(3)">
              Activity Date
              <app-sort-arrows [selectedColumn]="filter.column" [column]="3" [dir]="filter.dir"> </app-sort-arrows>
            </th>
            <th width="15%" (click)="toggleSort(2)">
              Ref Num
              <app-sort-arrows [selectedColumn]="filter.column" [column]="2" [dir]="filter.dir"> </app-sort-arrows>
            </th>
            <th width="18%" (click)="toggleSort(4)">
              Behavior Code
              <app-sort-arrows [selectedColumn]="filter.column" [column]="4" [dir]="filter.dir"> </app-sort-arrows>
            </th>
            <th width="19%" (click)="toggleSort(6)">
              Behavior Name
              <app-sort-arrows [selectedColumn]="filter.column" [column]="6" [dir]="filter.dir"> </app-sort-arrows>
            </th>
            <th width="10%" (click)="toggleSort(17)">
              Location
              <app-sort-arrows [selectedColumn]="filter.column" [column]="17" [dir]="filter.dir"> </app-sort-arrows>
            </th>
            <th width="10%" (click)="toggleSort(10)">
              Amount / Quantity
              <app-sort-arrows [selectedColumn]="filter.column" [column]="10" [dir]="filter.dir"> </app-sort-arrows>
            </th>
            <th width="10%" (click)="toggleSort(11)">
              Reward
              <app-sort-arrows [selectedColumn]="filter.column" [column]="11" [dir]="filter.dir"> </app-sort-arrows>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let activity of activities; let i = index">
            <tr (click)="show(i); showDetails(activity)">
              <td>
                <span
                  *ngIf="
                    activity.rewardNumber > 0 ||
                    activity.processingCount > 0 ||
                    activity.attributesCount > 0 ||
                    activity.description
                  "
                >
                  <span
                    class="width-50-percentage inline-block"
                    *ngIf="!expandedLookup[i]"
                    title="Expand to see history"
                  >
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                  </span>
                  <span
                    *ngIf="expandedLookup[i]"
                    class="inline-block width-50-percentage"
                    title="Expand to see history"
                  >
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                  </span>
                </span>
                <div
                  *ngIf="appDataService.isCSRManager"
                  class="checkbox checkbox-info float-right m-t-none m-b-none inline-block width-50-percentage"
                >
                  <input
                    id="cb-{{ activity.id }}"
                    class="proc-check"
                    type="checkbox"
                    (click)="$event.stopPropagation(); updateSelectedActivities(activity.id)"
                  />
                  <label for="cb-{{ activity.id }}"></label>
                </div>
              </td>
              <td>{{ activity.id }}</td>
              <td>{{ activity.activityDateStr }}</td>
              <td [attr.title]="activity.referenceNumber">{{ activity.referenceNumber }}</td>
              <td [attr.title]="activity.behaviorCode">{{ activity.behaviorCode }}</td>
              <td [attr.title]="activity.behaviorName">{{ activity.behaviorName }}</td>
              <td [attr.title]="activity.locationName">{{ activity.locationName }}</td>
              <td>{{ activity.tenderAmount }}</td>
              <td>
                <span
                  ><strong>{{ activity.rewardAmount }}</strong></span
                >
                <span class="label table-label label-info float-right">
                  <strong
                    >{{ activity.rewardNumber }}
                    <fa-icon [icon]="faTrophy"></fa-icon>
                  </strong>
                </span>
              </td>
            </tr>
            <tr
              class="additional-data"
              *ngIf="
                allActivityToShow[i].attributes && allActivityToShow[i].attributes.length !== 0 && expandedLookup[i]
              "
            >
              <td colspan="9" class="no-padding white-background">
                <table class="attribute-log table table-pointable additional-log">
                  <thead>
                    <tr class="align-text-left">
                      <td colspan="3"><b>Attributes:</b></td>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let attribute of allActivityToShow[i].attributes">
                      <tr class="align-text-left">
                        <td>Name: {{ attribute.attrName }}</td>
                        <td class="word-break-break-all">Value: {{ attribute.attrValue }}</td>
                        <td>Created On: {{ attribute.createdOn | date: "short" }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr
              class="additional-data"
              *ngIf="expandedLookup[i] && allActivityToShow[i].rewards && allActivityToShow[i].rewards.length !== 0"
            >
              <td class="no-padding white-background" colspan="9">
                <table class="reward-log table table-pointable additional">
                  <thead>
                    <tr class="align-text-left">
                      <td colspan="7"><b>Rewards:</b></td>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let reward of allActivityToShow[i].rewards">
                      <tr class="align-text-left">
                        <td>Code: {{ reward.promotion.code }}</td>
                        <td>Sponsor ID: {{ reward.promotionSponsor.id }}</td>
                        <td>Date: {{ reward.rewardDateStr }}</td>
                        <td>Type: {{ reward.rewardType.code }}</td>
                        <td>
                          Amount: <strong>{{ reward.amount }}</strong>
                        </td>
                        <td>Amt/Quantity: {{ activity.tenderAmount }}</td>
                        <td>Notes: {{ reward.notes }}</td>
                        <td *ngIf="canReverse && !reward.reversedBy">
                          <a href="" (click)="confirmReversal(i, reward.id, $event)">reverse</a>
                        </td>
                        <td [hidden]="!reward.reversedBy">
                          Reversed By: {{ reward.reversedBy }} On: {{ reward.reversedOnStr | date: "yyyy-MM-dd" }}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr class="additional-data" *ngIf="expandedLookup[i] && allActivityToShow[i].activityLog.length > 0">
              <td colspan="9" class="no-padding white-background">
                <table class="processing-log table table-pointable">
                  <thead>
                    <tr class="align-text-left">
                      <td colspan="2"><b>Processing Logs:</b></td>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let log of allActivityToShow[i].activityLog; let index = index">
                      <tr class="align-text-left">
                        <td>
                          Processing Log:
                          <a
                            *ngIf="appDataService.isCSR && securityService.allowProcessingTab"
                            href="/processing-log/{{ log.id }}?activityId={{ activity.id }}"
                            >{{ log.id }}</a
                          >
                        </td>
                        <td>Proc Code: {{ log.procCode }}</td>
                        <td>Status: {{ log.status }}</td>
                        <td>Started: {{ log.createdOn | date: "short" }}</td>
                        <td>Finished: {{ log.finishedOn | date: "short" }}</td>
                      </tr>
                      <tr
                        class="additional-log-message table table-pointable"
                        *ngIf="log.activityLogMessages && log.activityLogMessages.length > 0"
                      >
                        <td colspan="5" class="no-padding white-background">
                          <table class="additional-log-message table table-pointable">
                            <thead>
                              <tr class="align-text-left">
                                <td colspan="2"><b>Processing Log Details:</b></td>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngFor="let msg of log.activityLogMessages">
                                <tr class="align-text-left">
                                  <td>Created On: {{ msg.createdOn | date: "short" }}</td>
                                  <td>Message: {{ msg.friendlyMessage }}</td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr
              class="additional-data"
              *ngIf="expandedLookup[i] && activity.description && activity.description !== ''"
            >
              <td colspan="9" class="no-padding white-background">
                <table class="activity-descritpion table table-pointable additional-description">
                  <thead>
                    <tr class="align-text-left">
                      <td colspan="9"><b>Description:</b></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="9">{{ activity.description }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </ng-container>
          <tr app-is-there-more [newLengthEvent]="newLengthEvent" [numColumns]="9"></tr>
        </tbody>
      </table>
    </div>
    <app-add-more-data-to-list (onScroll)="getActivities(true)" [lock]="lock" [isLoading]="isLoading">
    </app-add-more-data-to-list>
  </div>
</div>
