import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { AccountModel } from 'src/app/data/model';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MembersService } from 'src/app/services/members.service';

@Component({
  selector: 'app-accounts-modal',
  templateUrl: './accounts-modal.component.html',
  styleUrls: ['./accounts-modal.component.scss'],
})
export class AccountsModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public account: AccountModel;
  @Input() public personId: number;
  @Input() public rewardTypes: any[] = [];
  faTimes = faTimes;
  title: string;
  isEdit: boolean;
  accountForm: UntypedFormGroup;
  errorMsg: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private statusHelperService: StatusHelperService,
    private ressponseHelperService: ResponseHelperService,
    private membersService: MembersService,
    private modalHelper: NgbModal
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.account) {
      this.title = 'Edit account';
      this.isEdit = true;
      this.accountForm = this.formBuilder.group({
        accountType: [this.account.accountType, Validators.required],
        rewardType: [this.account.rewardType, Validators.required],
        status: [this.account.status, Validators.required],
      });
    } else {
      this.title = 'New account';
      this.isEdit = false;
      this.accountForm = this.formBuilder.group({
        accountType: ['', Validators.required],
        rewardType: ['', Validators.required],
        status: ['', Validators.required],
      });
    }
  }

  ok(): void {
    const value = this.accountForm.value;
    value.partyId = this.personId;
    this.membersService.createOrUpdateAccount(this.personId, this.account && this.account.id, value).subscribe(
      () => {
        this.ressponseHelperService.success('Account successfully updated', true);
        this.successEvent.emit('success');
      },
      (data: any) => {
        this.ressponseHelperService.error(this, data.error.error);
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  compareById(element1: any, element2: any): boolean {
    return element1 && element2 && element1.id === element2.id;
  }
}
