<div>
  <form>
    <label>The gender of the member:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="genderRestriction !== null && genderRestriction !== undefined">
        <button
          class="btn btn-attention float-right"
          type="button"
          (click)="removeRestriction()"
          title="Remove Restriction"
        >
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
        <form #form="ngForm">
          <ng-container *ngIf="configurationType === 'rules'; else promotionTemplate">
            <div class="form-group">
              <select
                name="genderRestriction"
                class="form-control styled-select"
                [(ngModel)]="genderRestriction.condition"
                (ngModelChange)="updateRestrictions()"
                required
              >
                <option
                  *ngFor="let condition of conditions"
                  [selected]="genderRestriction.condition === condition.value"
                  [value]="condition.value"
                >
                  {{ condition.text }}
                </option>
              </select>
              <div
                class="mt-sm"
                *ngIf="genderRestriction.condition === undefined || genderRestriction.condition === null"
              >
                <small class="text-danger">Select condition</small>
              </div>
            </div>
            <div class="form-group">
              <select
                required
                name="genderRestriction_{{ genderRestriction.gender }}"
                class="form-control styled-select"
                [(ngModel)]="genderRestriction.gender"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let value of values"
                  [selected]="genderRestriction.gender === value.value"
                  [value]="value.value"
                >
                  {{ value.value }}
                </option>
              </select>
              <div class="mt-sm" [hidden]="genderRestriction.gender">
                <small class="text-danger">Select gender</small>
              </div>
            </div>
            <div class="form-group" *ngIf="configurationType === 'rules'">
              <label class="control-label mb-2">Message:</label>
              <textarea
                type="text"
                class="form-control"
                [(ngModel)]="genderRestriction.message"
                name="message"
                (ngModelChange)="updateRestrictions()"
              ></textarea>
            </div>
          </ng-container>
          <ng-template #promotionTemplate>
            <div class="form-group">
              <select
                name="genderRestriction_{{ genderRestriction.condition }}"
                class="form-control styled-select"
                [(ngModel)]="genderRestriction.condition"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let condition of conditions"
                  [selected]="genderRestriction.condition === condition.value"
                  [value]="condition.value"
                >
                  {{ condition.text }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <select
                name="genderRestriction_{{ genderRestriction.value }}"
                class="form-control styled-select"
                [(ngModel)]="genderRestriction.value"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let value of values"
                  [selected]="genderRestriction.value === value.value"
                  [value]="value.value"
                >
                  {{ value.text }}
                </option>
              </select>
            </div>
          </ng-template>
        </form>
      </div>
      <div class="form-group float-right col-md-12">
        <button class="btn btn-default float-right" [disabled]="genderRestriction" (click)="initRestrictionObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Gender Restriction
        </button>
      </div>
    </div>
  </form>
</div>
