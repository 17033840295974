import { OnInit, EventEmitter, OnDestroy, Component, Output } from '@angular/core';
import { Filter, SearchFilters } from '../../data/class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeliveryProtocol } from '../../data/model';
import {
  faPlus,
  faTimes,
  faPencilAlt,
  faTrash,
  faList,
  faPhoneAlt,
  faHome,
  faAt,
} from '@fortawesome/free-solid-svg-icons';
import { DeliveryProtocolModalComponent } from '../delivery-protocol-modal/delivery-protocol-modal.component';
import { CommunicationsService } from 'src/app/services/communications.service';
import { DeliveryOptionsModalComponent } from '../delivery-options-modal/delivery-options-modal.component';
import { ToastrService } from 'ngx-toastr';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';

@Component({
  selector: 'app-delivery-protocols-tab',
  templateUrl: './delivery-protocols-tab.component.html',
  styleUrls: ['./delivery-protocols-tab.component.scss'],
})
export class DeliveryProtocolsTabComponent implements OnInit, OnDestroy {
  isLoading = false;
  lockedTables = false;
  lock: boolean;
  limit = 20;
  searchFilters: SearchFilters;
  filter: Filter = new Filter();
  lengthToCompare: number;
  deliveryProtocols: DeliveryProtocol[];
  endpointTypes: any[];
  show: boolean;
  faPlus = faPlus;
  faTimes = faTimes;
  faPencilAlt = faPencilAlt;
  faPhoneAlt = faPhoneAlt;
  faTrash = faTrash;
  faHome = faHome;
  faAt = faAt;
  faList = faList;
  searchText: any;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  helpers: any;
  filterdata: any;
  appliedFilters: Filter[];
  filterButton: boolean = false;
  entity: any;

  constructor(
    private modalHelper: NgbModal,
    private communicationsService: CommunicationsService,
    private toastr: ToastrService,
    private applyfilterService: ApplyFilterService
  ) {}
  ngOnDestroy() {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }

  ngOnInit(): void {
    this.communicationsService.getAllEndpointTypes(false).subscribe((response: any) => {
      this.show = true;
      this.searchFilters = {
        formName: 'contactReasonsSearch',
        searchCallback: () => {
          this.initNewSearch();
        },
        filters: [
          {
            name: 'endpointTypeName',
            placeholder: 'Select endpoint',
            type: 'array',
            choices: response.entity,
            nameProp: 'name',
            valueProp: 'name',
            label: 'Endpoint type',
            inputType: 'dropdown',
          },
        ],
      };
      this.initNewSearch();
    });
  }

  initFilter(): void {
    this.filter = new Filter();
  }
  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Delivery';
    popUpInstance.componentInstance.label = 'Endpoint type';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(data);
    });
  }

  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getDeliveryProtocols();
  }

  deliveryModal(deliveryProtocol: DeliveryProtocol): void {
    const instance = this.modalHelper.open(DeliveryProtocolModalComponent);
    instance.componentInstance.protocol = deliveryProtocol;
    instance.componentInstance.endpointTypes = this.searchFilters.filters[0].choices;
    instance.componentInstance.successEvent.subscribe(() => {
      this.initNewSearch();
    });
  }
  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };

  getDeliveryProtocols(): void {
    const endpointTypeNames = this.getFilters(this.filter).endpointTypeName;
    const filter: any = {};
    if (endpointTypeNames && endpointTypeNames.length > 0) {
      filter.endpointTypeName = endpointTypeNames;
    }
    this.communicationsService.getDeliveryProtocols({ ...filter, includeInit: false }).subscribe(
      (data: any) => {
        this.deliveryProtocols = data.entity;
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
      },
      () => {
        this.toastr.error('Error occured!');
      }
    );
  }

  editOptions(deliveryProtocol: DeliveryProtocol): void {
    const instance = this.modalHelper.open(DeliveryOptionsModalComponent, { windowClass: 'wider-modal-window' });
    instance.componentInstance.delivery = deliveryProtocol;
    instance.componentInstance.successEvent.subscribe(() => {
      this.initNewSearch();
    });
  }
}
