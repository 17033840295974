<div class="full-height-scroll main-div" *ngIf="show">
  <div class="row mt-3">
    <div class="form-group col-md-2">
      <label class="control-label">Channel</label>
      <div>
        <ng-select
          [items]="deliveryProtocols"
          bindLabel="name"
          name="importChannel"
          (change)="selectDeliveryProtocol($event)"
          theme="bootstrap"
          placeholder="Select Delivery Protocol"
          [(ngModel)]="selectedDeliveryProtocol"
        >
        </ng-select>
      </div>
    </div>
    <div class="form-group col-md-2">
      <label class="control-label">Program</label>
      <input
        *ngIf="!selectedDeliveryProtocol"
        [disabled]="true"
        type="text"
        class="form-control campaign-input"
        placeholder="Select a Delivery Protocol first"
      />
      <ng-select
        (change)="selectCampaign($event)"
        *ngIf="selectedDeliveryProtocol"
        [virtualScroll]="true"
        [loading]="loading"
        [items]="programs"
        [typeahead]="input$"
        bindLabel="code"
        name="importChannel"
        theme="bootstrap"
        [(ngModel)]="selectedCampaign"
        (scrollToEnd)="getCampaigns(true)"
        placeholder="Import channel"
        #select
      >
      </ng-select>
    </div>
    <div class="form-group col-md-8">
      <label class="control-label"></label>
      <button
        [disabled]="!selectedDeliveryProtocol"
        class="btn btn-dark-blue btn-sm float-right modal-btn"
        style="position: relative; bottom: -25px"
        (click)="openChannelConfigurationModal()"
      >
        <fa-icon [icon]="faPlus"></fa-icon>
      </button>
    </div>
  </div>
  <div class="table-communication-strategy">
    <hr *ngIf="selectedDeliveryProtocol" />
    <h2 [hidden]="!selectedDeliveryProtocol">
      {{ selectedDeliveryProtocol?.name }}
    </h2>

    <table class="table table-pointable table-bordered">
      <thead>
        <tr>
          <th class="table_field_name border-left-none">Name</th>
          <th class="table_field_name border-left-none border-right-none">Value</th>
          <th class="table_field_name border-left-none border-right-none align-text-center">Promotion Specific</th>
          <th class="table_field_name border-left-none border-right-none align-text-center">Communication Specific</th>
          <th class="table_field_name border-left-none border-right-none align-text-center">Sensitive</th>
          <th class="table_field_name border-left-none border-right-none align-text-center">Action</th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let ca of channelAttributes">
          <tr>
            <td class="border-right-none">{{ ca.name }}</td>
            <td class="border-left-none border-right-none">
              <input class="input-sensitive" *ngIf="ca.sensitive" type="password" [value]="ca.value" disabled />{{
                ca.sensitive ? "" : ca.value
              }}
            </td>
            <td class="border-left-none border-right-none align-text-center">
              <fa-icon [hidden]="!(ca.promotionId && ca.promotionId > 0)" [icon]="faCheck"></fa-icon>
            </td>
            <td class="border-left-none border-right-none align-text-center">
              <fa-icon [hidden]="!(ca.communicationId && ca.communicationId > 0)" [icon]="faCheck"></fa-icon>
            </td>
            <td class="border-left-none border-right-none align-text-center">
              <fa-icon [hidden]="!ca.sensitive" [icon]="faCheck"></fa-icon>
            </td>
            <td class="border-left-none align-text-center">
              <button class="btn btn-dark-blue btn-sm" (click)="openChannelConfigurationModal(null, ca)">
                <fa-icon [icon]="faPencil"></fa-icon>
              </button>
              <button class="btn btn-dark-blue btn-sm" (click)="removeChannelConfiguration(ca)">
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngFor="let do of deliveryOptions">
          <tr>
            <td class="border-right-none">{{ do.optionName }}</td>
            <td class="border-left-none border-right-none">
              <input class="input-sensitive" *ngIf="do.sensitive" type="password" [value]="do.optionValue" disabled />{{
                do.sensitive ? "" : do.optionValue
              }}
            </td>
            <td class="border-left-none border-right-none align-text-center"></td>
            <td class="border-left-none border-right-none align-text-center"></td>
            <td class="border-left-none border-right-none align-text-center">
              <fa-icon [hidden]="!do.sensitive" [icon]="faCheck"></fa-icon>
            </td>
            <td class="border-left-none align-text-center">
              <button class="btn btn-dark-blue btn-sm" (click)="openChannelConfigurationModal(do, null)">
                <fa-icon [icon]="faPencil"></fa-icon>
              </button>
              <button class="btn btn-dark-blue btn-sm" (click)="removeDeliveryOption(do)">
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
            </td>
          </tr>
        </ng-container>
        <tr *ngIf="!selectedDeliveryProtocol">
          <td class="text-center cursor-auto" [attr.colspan]="6">Select a Delivery Protocol to continue</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
