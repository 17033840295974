import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ContentGroupModalComponent } from './content-group-modal/content-group-modal.component';
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { SearchFilters, Filter } from '../../data/class';
import { ContentGroup } from '../../data/model';
import { ContentGroupService } from '../../services/content-group.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { CommunicationsService } from 'src/app/services/communications.service';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-communication-content-group',
  templateUrl: './communication-content-group.component.html',
  styleUrls: ['./communication-content-group.component.scss'],
})
export class CommunicationContentGroupComponent implements OnInit, OnDestroy {
  constructor(
    private _config: NgbModalConfig,
    private applyfilterService: ApplyFilterService,
    private contentGroupService: ContentGroupService,
    private communicationsService: CommunicationsService,
    private modalHelper: NgbModal,
    private toastr: ToastrService
  ) {
    _config.backdrop = 'static';
    _config.keyboard = false;
  }

  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  modalConfig: NgbModalOptions = { backdrop: 'static', keyboard: false };
  filter: any = { limit: 20, offset: 0, search: '', protocolIds: [] };
  isLoading = false;
  lockedTables = false;
  lengthToCompare: number;
  searchFilters: SearchFilters;
  filterSubscription: Subscription;
  appliedFilters: Filter[];
  filterButton: boolean = false;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  contentGroups: ContentGroup[] = [];

  ngOnInit(): void {
    this.communicationsService.getDeliveryProtocols({ includeInit: false }).subscribe((res: any) => {
      if (res.success) {
        this.searchFilters = {
          formName: 'Communication content group',
          filters: [
            {
              name: 'search',
              placeholder: 'Filter by name',
              label: 'Search',
            },
            {
              name: 'protocolIds',
              placeholder: 'Select protocols',
              label: 'Select protocols',
              choices: res.entity.map((element: any) => ({
                id: element.id,
                name: element.name + ' (' + element.endpointType.name + ')',
              })),
              nameProp: 'name',
              valueProp: 'id',
              inputType: 'select',
              multiple: true,
              type: 'array',
              section: { key: 'firstRow' },
            },
          ],
          searchCallback: () => {
            this.initNewSearch();
          },
        };
      }
    });
    this.initNewSearch();
  }

  ngOnDestroy() {
    if (this.searchFilters?.clearAll) {
      this.searchFilters?.clearAll();
    }
  }

  clearAllFilters(): void {
    this.initFilter();
    this.getContentGroups(false);
  }

  getContentGroups(concat?: boolean): void {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);
    this.contentGroupService.getContentGrops(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.contentGroups = this.contentGroups.concat(data.entity.records);
        } else {
          this.contentGroups = data.entity.records;
        }
        this.lengthToCompare = data.entity.records.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.filter.limit;
        this.isLoading = false;
        this.lockedTables = this.lengthToCompare !== this.filter.limit;
      },
      () => {
        this.lockedTables = false;
        this.isLoading = false;
      }
    );
  }

  initFilter(): void {
    this.filter.protocolIds = [];
    this.filter.search = '';
    this.filter.offset = 0;
  }

  addContentGroup(contentGroup?: ContentGroup): void {
    const instance = this.modalHelper.open(ContentGroupModalComponent, this.modalConfig);
    instance.componentInstance.contentGroup = contentGroup || new ContentGroup();
    instance.componentInstance.successEvent.subscribe(() => {
      this.toastr.success('Content group successfully ' + (contentGroup ? 'updated' : 'created'));
      instance.close();
      this.initNewSearch();
    });
  }

  deleteContentGroup(contentGroup: ContentGroup): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Delete content group with id: ' + contentGroup.id;
    instance.componentInstance.header = 'Delete content group content?';
    instance.componentInstance.promotionEdit = true;
    instance.componentInstance.onlyConfirm = true;
    instance.componentInstance.successEvent.subscribe(() => {
      instance.close();
      this.contentGroupService.deleteContentGrop(contentGroup.id).subscribe(
        () => {
          this.toastr.success('Content group successfully deleted');
          this.initNewSearch();
        },
        () => {
          this.toastr.error('Error');
        }
      );
    });
  }

  initNewSearch(): void {
    this.filterSubscription = this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getContentGroups(false);
  }

  getFilters = (existing: Filter): any => {
    (this.searchFilters?.filters || []).forEach((filter: Filter) => {
      if (![null, undefined].includes(filter.value)) {
        existing[filter.name] = filter.value;
      } else {
        delete existing[filter.name];
      }
    });
    return existing;
  };

  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Communication content group';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data: any) => {
      this.searchFilters = data;
      this.applyfilterService.getFunctions(this.searchFilters);
    });
  }
}
