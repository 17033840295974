import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { StatusHelperService } from '../../services/helpers/status-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { IntegrationServer } from 'src/app/data/model';
import { IntegrationServerService } from 'src/app/services/integration-server.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';

@Component({
  selector: 'app-integration-server-modal',
  templateUrl: './integration-server-modal.component.html',
  styleUrls: ['./integration-server-modal.component.scss'],
})
export class IntegrationServerModalComponent implements OnInit {
  @Input() integrationServer: IntegrationServer;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  protocolTypes = this.tableHelperService.getProtocolTypes();
  credentialTypes = this.tableHelperService.getCredentialTypes();
  title: string;
  integrationServerForm: UntypedFormGroup;
  isEdit: boolean;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;

  constructor(
    private statusHelperService: StatusHelperService,
    private integrationServerService: IntegrationServerService,
    private modalHelper: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private tableHelperService: TableHelperService
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.integrationServer) {
      this.title = 'Edit Integration Server';
      this.isEdit = true;
      this.integrationServerForm = this.formBuilder.group({
        name: [this.integrationServer.name, Validators.required],
        credentialType: [this.integrationServer.credentialType, Validators.required],
        status: [this.integrationServer.status, Validators.required],
        password: [this.integrationServer.password, [Validators.required, Validators.minLength(5)]],
        repeatPassword: ['', Validators.required],
        protocolType: [this.integrationServer.protocolType, Validators.required],
        sshKey: [this.integrationServer.sshKey, Validators.required],
        username: [this.integrationServer.username, Validators.required],
        url: [this.integrationServer.url, Validators.required],
      });
    } else {
      this.title = 'New Integration Server';
      this.isEdit = false;
      this.integrationServerForm = this.formBuilder.group({
        name: ['', Validators.required],
        credentialType: ['', Validators.required],
        status: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(5)]],
        repeatPassword: ['', Validators.required],
        protocolType: ['', Validators.required],
        sshKey: ['', Validators.required],
        username: ['', Validators.required],
        url: ['', Validators.required],
      });
    }
  }

  ok(): void {
    let value = this.integrationServerForm.value;
    delete value.repeatPassword;
    value.credentialType = 'BASIC';
    value.protocolType = 'FTP';
    this.integrationServerService
      .createOrUpdateIntegrationServer(this.integrationServer && this.integrationServer.id, value)
      .subscribe(
        (data: any) => {
          this.successEvent.emit(data);
        },
        (data: any) => {
          this.errorMsg = data.error.error;
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
