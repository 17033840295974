<form [formGroup]="attachmentForm">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Name <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="name" />

      <!-- <div class="m-t-xs" [hidden]="attachmentForm.controls['name'].valid">
        <small class="text-danger">Input attachment name</small>
      </div> -->
    </div>
    <div class="form-group" *ngIf="resourceType === 'promotion'">
      <label>Mime/Type</label>
      <input type="text" class="form-control" formControlName="mimeType" />
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn-pop-cancel" type="button" (click)="cancel()" title="Cancel">CANCEL</button>
    <button class="btn-save" type="submit" [disabled]="!attachmentForm.valid" (click)="ok()" title="Add">
      {{ isEdit ? "SAVE" : "ADD" }}
    </button>
  </div>
</form>
