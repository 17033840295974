import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NutritionService } from '../../services/nutrition.service';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-attribute-mappings-modal',
  templateUrl: './add-attribute-mappings-modal.component.html',
  styleUrls: ['./add-attribute-mappings-modal.component.scss'],
})
export class AddAttributeMappingsModalComponent implements OnInit {
  @Input() attributePair: any;
  @Input() attrMappings: any;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  errorMsg = '';
  attributePairForm: UntypedFormGroup;
  faSave = faSave;
  faTimes = faTimes;

  constructor(
    private nutritionService: NutritionService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    if (this.attributePair.id) {
      this.attributePairForm = this.formBuilder.group({
        attrNameExpr: [this.attributePair.attrNameExpr],
        attrValueExpr: [this.attributePair.attrValueExpr],
        id: [this.attributePair.id],
        nutritionTagGroupId: [this.attributePair.nutritionTagGroupId],
      });
    } else {
      this.attributePairForm = this.formBuilder.group({
        attrNameExpr: [''],
        attrValueExpr: [''],
        id: [''],
        nutritionTagGroupId: [this.attributePair.nutritionTagGroupId],
      });
    }
  }

  ok(): void {
    this.nutritionService
      .createOrUpdateMappingForGroup(
        this.attributePair.nutritionTagGroupId,
        this.attributePair.id,
        this.attributePairForm.value
      )
      .subscribe(
        (data: any) => {
          this.attrMappings.forEach((mapping: any) => {
            if (mapping.id === data.entity.nutritionTagGroupId && !this.attributePair.id) {
              if (!mapping.mappings) {
                mapping.mappings = [];
              }
              mapping.mappings.push(data.entity);
            }
          });
          this.successEvent.emit();
          this.responseHelperService.success('Mapping successfully created', true);
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
