<div class="list-wrapper cursor-pointer">
  <div
    class="row"
    style="margin-top: 20px; word-break: break-word; position: relative"
    (click)="selectPromotion(promotion, $event)"
    [ngClass]="{
      'com-active-promo': selectedPromotion?.id === promotion.id,
      'faded-promo': !promotion.hasSelectedPackage && selectedPackage
    }"
    title="{{ type === 'packaged-sponsorship' ? promotion.promotionName : promotion.name }}"
  >
    <!-- <div class="col-md-1">
      <span
        *ngIf="type !== 'packaged-sponsorship'"
        class="float-left colored-circle uppercase"
        [ngClass]="promotion.typeColor"
        [attr.title]="promotion.typeName"
        >{{ promotion.typeName.substring(0, 1) }}</span
      >
      <span
        *ngIf="type === 'packaged-sponsorship'"
        class="float-left colored-circle uppercase"
        [ngClass]="promotion.typeColor"
        [attr.title]="promotion.promotionName"
        >{{ promotion.promotionName.substring(0, 1) }}</span
      >
    </div> -->
    <div class="col-md-10 uppercase d-flex align-items-center">
      <div (click)="selectedData(promotion)" class="d-flex">
        <div class="no_txt_decoration">
          <mat-icon *ngIf="promotion.children" (click)="toggleChildren()">
            {{ showChilds ? "arrow_drop_up" : "arrow_drop_down" }}</mat-icon
          >
        </div>
        <span *ngIf="type !== 'packaged-sponsorship'" class="underline_hover">
          {{ promotion.name }}
        </span>
        <span *ngIf="type === 'packaged-sponsorship'" class="underline_hover">
          {{ promotion.promotionName }}
        </span>
      </div>
    </div>
    <div class="col-md-2" style="position: absolute; right: 0px">
      <span class="colored-circle" [ngClass]="statusHelper[promotion.status]" [attr.title]="promotion.status"
        >{{ promotion.status.substring(0, 1) }}
      </span>
      <!-- <span *ngIf="promotion.children" class="float-right mr-2"> ({{ promotion.children.length }}) </span> -->
    </div>
  </div>
  <ul *ngIf="promotion.children && showChilds" class="list-none">
    <li *ngFor="let promo of promotion.children" [ngClass]="'parent-id-' + promo.parentId">
      <app-promo-tree
        [promotion]="promo"
        [selectedPackage]="selectedPackage"
        [selectedPromotion]="selectedPromotion"
        (promotionChanged)="promotionChanged.emit($event)"
        [type]="type"
      >
      </app-promo-tree>
    </li>
  </ul>
</div>
