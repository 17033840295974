import { AddSponsorshipModalComponent } from './../add-sponsorship-modal/add-sponsorship-modal.component';
import { ProgramService } from './../services/program.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SponsorsService } from './../services/sponsors.service';
import { ResponseHelperService } from './../services/helpers/response-helper.service';
import { PromotionService } from './../services/promotion.service';
import { CampaignService } from './../services/campaign.service';
import { Filter, SearchFilters } from './../data/class';
import { TableHelperService } from './../services/helpers/table-helper.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Campaign, Promotion } from '../data/model';
import { faUserPlus, faTimes, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { SearchFilterComponent } from '../search-filter/search-filter/search-filter.component';
import { ApplyFilterService } from '../services/filter-pop-service/applyFilter.service';
import { Router } from '@angular/router';
import { selectRow } from '../data/selectRow';

@Component({
  selector: 'app-promotions-tab',
  templateUrl: './promotions-tab.component.html',
  styleUrls: ['./promotions-tab.component.scss'],
})
export class PromotionsTabComponent implements OnInit, OnDestroy {
  @Input() personId: number;
  @Input() sponsorName: number;
  @Input() campaign: Campaign;

  isLoading = false;
  lockedTables = false;
  lock = false;
  filteredStatuses = [];
  select: any = {};
  selectedCampaigns: Campaign[] = [];
  defaultStatusFilter = {};
  defaultStatuses = ['ACTIVE', 'PENDING', 'STARTED'];
  // defaultStatusFilter['statuses'] = defaultStatuses;
  limit = 20;
  filter = new Filter();
  statuses: object[] = [];
  campaignsFilter: any;
  campaigns: Campaign[] = [];
  selectedPromotion: Promotion;
  promotions: Promotion[] = [];
  lengthToCompare: number;
  show = true;
  faUserPlus = faUserPlus;
  faTimes = faTimes;
  faCalendarPlus = faCalendarPlus;
  searchFilters: SearchFilters;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  showSearch = false;
  helpers: any;
  filterButton: boolean = false;
  filterdata: SearchFilters;
  appliedFilters: Filter[];
  url: string;

  constructor(
    private tableHelper: TableHelperService,
    private campaignService: CampaignService,
    private promotionService: PromotionService,
    private responseHelper: ResponseHelperService,
    private sponsorsService: SponsorsService,
    private modalHelper: NgbModal,
    private programService: ProgramService,
    private tableHelperService: TableHelperService,
    private toastr: ToastrService,
    private applyfilterService: ApplyFilterService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    if (this.filterdata?.clearAll) {
      this.filterdata.clearAll();
    }
  }
  ngOnInit(): void {
    const buttonTextAndTitle = this.sponsorName ? 'Add Sponsorship' : 'New Promotion';
    this.url = this.router.url;
    this.searchFilters = {
      formName: 'promotions-search',
      filters: [
        {
          label: 'Keywords',
          name: 'search',
          placeholder: 'Add keyword',
        },
        {
          label: 'Status',
          name: 'statuses',
          placeholder: 'Select status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
      ],

      addEntityButtons: [
        {
          icon: faUserPlus,
          buttonText: buttonTextAndTitle,
          if: true,
          callback: () => {
            this.promotionModal({ windowClass: 'wider-modal-window' }, 'program');
          },
          title: buttonTextAndTitle,
        },
      ],
      searchCallback: () => {
        this.initNewSearch();
      },
    };
    this.initNewSearch();
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };

  getPromotions(concat: boolean): void {
    this.isLoading = true;
    this.filter['campaigns'] = [this.campaign.name];
    this.filter = this.getFilters(this.filter);

    this.sponsorsService.getSponsorPromotions(this.personId, this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.promotions = this.promotions.concat(data.entity.records);
        } else {
          this.campaigns = [];
          this.select.selected = false;
          this.promotions = data.entity.records;
        }

        this.lengthToCompare = data.entity.records.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.records.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  showPromotion(event: MouseEvent, promotion: Promotion): void {
    selectRow(event);
    this.selectedPromotion = promotion;
  }

  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getPromotions(false);
  }
  checkArrayOrNot(array): Boolean {
    return Array.isArray(array);
  }
  prepareLabel(placeholder: string): string {
    if (!placeholder || placeholder === '') {
      return '';
    }
    return placeholder.replace(/\.\.\.$/g, '').replace(/:$/g, '') + ': ';
  }
  popUpModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Promotions';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(this.filterdata);
    });
  }

  promotionModal(size: any, type: string): void {
    const item: any = {};
    item.type = type;
    const progFilter: any = {};
    progFilter.includeCounts = 'false';
    progFilter.statuses = this.defaultStatuses;

    if (type === 'campaign') {
      this.campaignService.getAllCampaigns(progFilter).subscribe((data) => {
        if (data.success) {
          item.data = data.entity.records;
          const instance = this.modalHelper.open(AddSponsorshipModalComponent, { size: 'lg' });
          instance.componentInstance.item = item;
          instance.componentInstance.sponsorName = this.sponsorName;
          instance.componentInstance.personId = this.personId;
          instance.componentInstance.filter = this.filter;
          instance.componentInstance.campaignId = this.campaign.id;
          instance.componentInstance.successEvent.subscribe(() => {
            this.initNewSearch();
          });
        }
      });
    } else {
      this.programService.getAllPrograms(progFilter).subscribe((data) => {
        if (data.success) {
          item.data = data.entity.records;
          const instance = this.modalHelper.open(AddSponsorshipModalComponent, { size: 'lg' });
          instance.componentInstance.item = item;
          instance.componentInstance.sponsorName = this.sponsorName;
          instance.componentInstance.personId = this.personId;
          instance.componentInstance.filter = this.filter;
          instance.componentInstance.campaignId = this.campaign.id;
          instance.componentInstance.successEvent.subscribe(() => {
            this.initNewSearch();
          });
        }
      });
    }
  }

  // TODO: I can't create sponsorship on qa1 to test this
  // scope.$watchCollection('select.campaigns', function (newValue, oldValue) {
  //   if (newValue) {
  //     selectedCampaigns = [];
  //     newValue.forEach(function (camp) {
  //       selectedCampaigns.push(camp.name);
  //     });

  //       scope.filter['campaigns'] = selectedCampaigns;
  //       initFilter();
  //       scope.getPromotions(false);

  //   }
  // });
}
