import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from 'src/app/app-data.service';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { ToastrService } from 'ngx-toastr';
import { OrganizationsService } from '../services/organizations.service';
import { AuditService } from '../services/audit.service';
import { GlobalEventService } from '../services/global-event.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-organization',
  templateUrl: './select-organization.component.html',
  styleUrls: ['./select-organization.component.scss'],
})
export class SelectOrganizationComponent implements OnInit {
  markets: any[] = [];
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public personId;
  @Input() public isModal;
  displaySelect: boolean = false;

  constructor(
    private modalHelper: NgbModal,
    private dateHelperService: DateHelperService,
    private globalData: AppDataService,
    private toastr: ToastrService,
    private organizationsService: OrganizationsService,
    private auditService: AuditService,
    private responseHelperService: ResponseHelperService,
    private globalEventService: GlobalEventService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getHierarchicalOrganizationsForParty();
    if (this.isModal === null) {
      this.isModal = false;
    }
  }

  setGlobalOrg(selectedOrg: any): void {
    if (localStorage.getItem('singleOrgUser') === 'false') {
      this.logOrgChange(selectedOrg);
    }
    localStorage.setItem('orgId', selectedOrg.id);
    localStorage.setItem('externalOrgCode', selectedOrg.username);
    localStorage.setItem('organizationLogoUrl', selectedOrg.logoUrl);
    localStorage.setItem('orgUsername', selectedOrg.username);
    localStorage.setItem('segmentId', selectedOrg.segmentsCollection[0].id);
    this.setGlobalData();
  }

  setGlobalData(): void {
    this.globalData.orgId = Number(localStorage.getItem('orgId'));
    this.globalData.externalOrgCode = localStorage.getItem('externalOrgCode');
    this.globalData.orgUsername = localStorage.getItem('orgUsername');
    this.globalData.organizationLogoUrl = localStorage.getItem('organizationLogoUrl');
    this.globalData.segmentId = Number(localStorage.getItem('segmentId'));
    this.globalData.singleOrgUser = localStorage.getItem('singleOrgUser');
    if (this.isModal) {
      this.modalHelper.dismissAll();
    } else {
      this.router.navigate(['/members']);
    }
    this.globalEventService.sendGlobalEvent({ data: this.globalData.orgId, toComponent: 'members' });
    this.globalEventService.sendGlobalEvent({ data: this.globalData.orgId, toComponent: 'select-organization' });
  }

  getHierarchicalOrganizationsForParty(): void {
    this.organizationsService.getMarketsForUser().subscribe((data: any) => {
      this.markets = data.entity;
      if (this.markets.length === 1) {
        localStorage.setItem('singleOrgUser', 'true');
        this.setGlobalOrg(this.markets[0]);
      } else {
        this.displaySelect = true;
        localStorage.setItem('singleOrgUser', 'false');
      }
    });
  }

  logOrgChange(selectedOrg: any): void {
    if (this.globalData.orgId !== selectedOrg.orgId) {
      const auditLog = {
        oldOrgId: this.globalData.orgId,
        oldOrgLogo: this.globalData.organizationLogoUrl,
        newOrgId: selectedOrg.id,
        newOrgLogo: selectedOrg.logoUrl,
      };
      const auditObj = {
        auditLog: JSON.stringify(auditLog),
      };
      this.auditService.createSelectedOrgAuditRecord(auditObj).subscribe(
        () => {
          this.successEvent.emit('success');
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error, false);
        }
      );
    }
  }
}
