import { AppEnvironmentData } from './environment.type';

export const environment: AppEnvironmentData = {
  production: true,
  baseHref: '',
  baseUrl: 'cheetah/api/',
  baseChirpUrl: 'chirp/api/',
  baseGoatUrl: 'goat/api/',
  // These values are populated via the dev.yml file during Github Action builds
  releaseName: 'elevance-ivr-upgrades-3390',
  buildNumber: '3.3.006-beta.20240112225740',
};
