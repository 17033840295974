import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faTimes, faCalendar, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Report } from 'src/app/data/model';
import { ReportsService } from 'src/app/services/reports.service';
import { ToastrService } from 'ngx-toastr';
import { DateHelperService } from '../../services/helpers/date-helper.service';

@Component({
  selector: 'app-execute-report-modal',
  templateUrl: './execute-report-modal.component.html',
  styleUrls: ['./execute-report-modal.component.scss'],
})
export class ExecuteReportModalComponent implements OnInit {
  @Input() report: Report;
  @Input() params: any;
  @Input() autocomplete: any;
  @Input() autocompleteArray: any;
  @Output() dismissModalEvent: EventEmitter<any> = new EventEmitter();
  executeReportForm: UntypedFormGroup;
  disableButtons: boolean;
  isLoading: boolean;
  inputFilter = ['INTEGER', 'VARCHAR', 'FLOAT', 'NUMERIC', 'BIGINT'];
  faPaperPlane = faPaperPlane;
  faTimes = faTimes;
  faCalendar = faCalendar;
  Reportname: String;
  paramname: string;
  paramObject: object[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalHelper: NgbModal,
    private reportsService: ReportsService,
    private toastr: ToastrService,
    private dateHelperService: DateHelperService
  ) {}

  ngOnInit(): void {
    this.Nameconvert();
    const formConfig = { reportNotes: '' };
    this.params.forEach((param) => {
      param.requiredParam
        ? (formConfig[param.paramCode] = [param.value, Validators.required])
        : (formConfig[param.paramCode] = [param.value]);
    });

    this.executeReportForm = this.formBuilder.group(formConfig);
  }
  removespecialchar(param) {
    let data = param.paramName.replace(/_/g, ' ');
    return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
  }

  isInput(paramName: string): boolean {
    if (this.inputFilter.indexOf(paramName) !== -1) {
      return true;
    }
    return false;
  }

  Nameconvert() {
    const NewString = this.report.name.charAt(0).toUpperCase() + this.report.name.slice(1).toLowerCase();
    this.Reportname = NewString;
  }
  getArrayType(paramCode: string): boolean {
    let type;
    this.params.forEach((param) => {
      if (param.paramCode === paramCode) {
        type = param.arrayElementType;
      }
    });
    return type;
  }

  clearDate(name: string): void {
    this.executeReportForm.patchValue({
      [name]: null,
    });
  }

  enableButtons(): void {
    setTimeout(() => {
      this.disableButtons = false;
    }, 5000);
  }

  ok(): void {
    this.disableButtons = true;
    this.params.forEach((param) => {
      if (
        param.autocompleteAction &&
        (param.paramType === 'BIGINT' || param.paramType === 'VARCHAR') &&
        this.executeReportForm.value[param.paramCode]
      ) {
        this.executeReportForm.value[param.paramCode] = this.executeReportForm.value[param.paramCode].id
          ? this.executeReportForm.value[param.paramCode].id
          : this.executeReportForm.value[param.paramCode].name;
      } else if (
        param.autocompleteAction &&
        param.paramType === 'ARRAY' &&
        this.executeReportForm.value[param.paramCode] &&
        this.executeReportForm.value[param.paramCode].length > 0
      ) {
        const autocompleteArray = [];
        this.executeReportForm.value[param.paramCode].forEach((paramItem) => {
          if (param.arrayElementType === 'VARCHAR') {
            autocompleteArray.push(paramItem.id ? paramItem.id : paramItem);
          } else {
            autocompleteArray.push(paramItem.text ? paramItem.text : paramItem);
          }
        });
        this.executeReportForm.value[param.paramCode] = autocompleteArray;
      } else if (param.paramType === 'TIMESTAMP') {
        this.executeReportForm.value[param.paramCode] = this.formatDate(this.executeReportForm.value[param.paramCode]);
      }
    });

    console.log('Triggering external execution of report.');
    this.isLoading = true;
    const params = { ...this.executeReportForm.value };
    delete params.reportNotes;
    const reportParams = {
      params,
      notes: this.executeReportForm.value.reportNotes,
    };
    this.reportsService
      .triggerExternalExecution(this.report.id, { params, notes: this.executeReportForm.value.reportNotes })
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.toastr.success(response.entity);
          this.enableButtons();
          this.dismissModalEvent.emit();
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error(error.error.message);
          this.enableButtons();
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  formatDate(dateToFormat: any): string {
    return this.dateHelperService.ngbDateToString(dateToFormat);
  }
}
