<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-content">
      <div class="display-title">
        <h2>Rules configuration for {{ selectedComm?.name }}</h2>
      </div>
      <div class="ibox-content" *ngIf="rulesJson">
        <div class="row">
          <div class="col-md-12">
            <div class="wrapper wrapper-content">
              <div class="row">
                <div class="col-md-6">
                  <h4 class="mb-5">
                    Conditions which must be satisfied for an outreach communication to be scheduled:
                  </h4>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ibox">
                        <div class="display-title border-bottom" (click)="resizeIbox(0)">
                          <h4 class="user-select-none">Gender Restriction</h4>
                          <div class="ibox-tools">
                            <span class="dropdown_view_all">View all</span>
                            <a>
                              <fa-icon [icon]="expandedLookup[0] ? faChevronUp : faChevronDown"> </fa-icon>
                            </a>
                          </div>
                        </div>
                        <div class="ibox-content" [@expandCollapse]="expandedLookup[0] ? 'open' : 'close'">
                          <app-gender-restriction
                            [(isValid)]="isGenderRestrictionsValid"
                            [genderRestriction]="rulesJson.entryConditions?.genderRestriction"
                            [configurationType]="'rules'"
                            (inputChangeEmitter)="entryConditionsChangeHandler($event, 'genderRestriction')"
                          >
                          </app-gender-restriction>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ibox">
                        <div class="display-title border-bottom" (click)="resizeIbox(1)">
                          <h4 class="user-select-none">Age Restriction</h4>
                          <div class="ibox-tools">
                            <span class="dropdown_view_all">View all</span>
                            <a>
                              <fa-icon [icon]="expandedLookup[1] ? faChevronUp : faChevronDown"> </fa-icon>
                            </a>
                          </div>
                        </div>
                        <div class="ibox-content" [@expandCollapse]="expandedLookup[1] ? 'open' : 'close'">
                          <app-age-restriction
                            [(isValid)]="isAgeRestrictionsValid"
                            [ageRestriction]="rulesJson.entryConditions?.ageRestrictions"
                            [configurationType]="'rules'"
                            (inputChangeEmitter)="entryConditionsChangeHandler($event, 'ageRestrictions')"
                          >
                          </app-age-restriction>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ibox">
                        <div class="display-title border-bottom" (click)="resizeIbox(2)">
                          <h4 class="user-select-none">Preference Restriction</h4>
                          <div class="ibox-tools">
                            <span class="dropdown_view_all">View all</span>
                            <a>
                              <fa-icon [icon]="expandedLookup[2] ? faChevronUp : faChevronDown"> </fa-icon>
                            </a>
                          </div>
                        </div>
                        <div class="ibox-content" [@expandCollapse]="expandedLookup[2] ? 'open' : 'close'">
                          <app-preference-restrictions
                            [(isValid)]="isPreferenceRestrictionsValid"
                            [preferenceRestrictions]="rulesJson.entryConditions?.preferenceRestrictions"
                            [preferenceType]="'restriction'"
                            (inputChangeEmitter)="entryConditionsChangeHandler($event, 'preferenceRestrictions')"
                          >
                          </app-preference-restrictions>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ibox">
                        <div class="display-title border-bottom" (click)="resizeIbox(3)">
                          <h4 class="user-select-none">Attribute Restriction</h4>
                          <div class="ibox-tools">
                            <span class="dropdown_view_all">View all</span>
                            <a>
                              <fa-icon [icon]="expandedLookup[3] ? faChevronUp : faChevronDown"> </fa-icon>
                            </a>
                          </div>
                        </div>
                        <div class="ibox-content" [@expandCollapse]="expandedLookup[3] ? 'open' : 'close'">
                          <app-party-attribute-restriction
                            [(isValid)]="isEntryAttributeRestrictionsValid"
                            [partyAttributeRestriction]="rulesJson.entryConditions?.attributeRestrictions"
                            [configurationType]="'rules'"
                            (inputChangeEmitter)="entryConditionsChangeHandler($event, 'attributeRestrictions')"
                          >
                          </app-party-attribute-restriction>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ibox">
                        <div class="display-title border-bottom" (click)="resizeIbox(4)">
                          <h4 class="user-select-none">Temporal Conditions</h4>
                          <div class="ibox-tools">
                            <span class="dropdown_view_all">View all</span>
                            <a>
                              <fa-icon [icon]="expandedLookup[4] ? faChevronUp : faChevronDown"> </fa-icon>
                            </a>
                          </div>
                        </div>
                        <div class="ibox-content" [@expandCollapse]="expandedLookup[4] ? 'open' : 'close'">
                          <app-temporal-conditions
                            [(isValid)]="isTemporalConditionsValid"
                            [temporalConditions]="rulesJson.entryConditions?.temporalConditions"
                            (inputChangeEmitter)="entryConditionsChangeHandler($event, 'temporalConditions')"
                          >
                          </app-temporal-conditions>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 class="mb-5">
                    Conditions which when satisfied, indicate that the member should exit the outreach sequence:
                  </h4>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ibox">
                        <div class="display-title border-bottom" (click)="resizeIbox(5)">
                          <h4 class="user-select-none">Attribute Restriction</h4>
                          <div class="ibox-tools">
                            <span class="dropdown_view_all">View all</span>
                            <a>
                              <fa-icon [icon]="expandedLookup[5] ? faChevronUp : faChevronDown"> </fa-icon>
                            </a>
                          </div>
                        </div>
                        <div class="ibox-content" [@expandCollapse]="expandedLookup[5] ? 'open' : 'close'">
                          <app-party-attribute-restriction
                            [(isValid)]="isExitAttributeRestrictionsValid"
                            [partyAttributeRestriction]="rulesJson.exitConditions?.attributeRestrictions"
                            [configurationType]="'rules'"
                            (inputChangeEmitter)="exitConditionsChangeHandler($event, 'attributeRestrictions')"
                          >
                          </app-party-attribute-restriction>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ibox">
                        <div class="display-title border-bottom" (click)="resizeIbox(6)">
                          <h4 class="user-select-none">Opt Out Preferences</h4>
                          <div class="ibox-tools">
                            <span class="dropdown_view_all">View all</span>
                            <a>
                              <fa-icon [icon]="expandedLookup[6] ? faChevronUp : faChevronDown"> </fa-icon>
                            </a>
                          </div>
                        </div>
                        <div class="ibox-content" [@expandCollapse]="expandedLookup[6] ? 'open' : 'close'">
                          <app-opt-out-preferences
                            [(isValid)]="isPreferenceConditionsValid"
                            [optOutPreferences]="rulesJson.exitConditions?.optOutPreferences"
                            (inputChangeEmitter)="exitConditionsChangeHandler($event, 'optOutPreferences')"
                          >
                          </app-opt-out-preferences>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ibox">
                        <div class="display-title border-bottom" (click)="resizeIbox(7)">
                          <h4 class="user-select-none">Rewards</h4>
                          <div class="ibox-tools">
                            <span class="dropdown_view_all">View all</span>
                            <a>
                              <fa-icon [icon]="expandedLookup[7] ? faChevronUp : faChevronDown"> </fa-icon>
                            </a>
                          </div>
                        </div>
                        <div class="ibox-content" [@expandCollapse]="expandedLookup[7] ? 'open' : 'close'">
                          <app-rewards
                            [(isValid)]="isRewardsValid"
                            [rewards]="rulesJson.exitConditions?.rewards"
                            (inputChangeEmitter)="exitConditionsChangeHandler($event, 'rewards')"
                          >
                          </app-rewards>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ibox">
                        <div class="display-title border-bottom" (click)="resizeIbox(8)">
                          <h4 class="user-select-none">Behaviors</h4>
                          <div class="ibox-tools">
                            <span class="dropdown_view_all">View all</span>
                            <a>
                              <fa-icon [icon]="expandedLookup[8] ? faChevronUp : faChevronDown"> </fa-icon>
                            </a>
                          </div>
                        </div>
                        <div class="ibox-content" [@expandCollapse]="expandedLookup[8] ? 'open' : 'close'">
                          <app-behaviors
                            [(isValid)]="isBehaviorsValid"
                            [behaviors]="rulesJson.exitConditions?.behaviors"
                            (inputChangeEmitter)="exitConditionsChangeHandler($event, 'behaviors')"
                          >
                          </app-behaviors>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 class="mb-5">Instance Attribute Bindings:</h4>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ibox">
                        <div class="display-title border-bottom" (click)="resizeIbox(9)">
                          <h4 class="user-select-none">Instance Attribute Bindings</h4>
                          <div class="ibox-tools">
                            <span class="dropdown_view_all">View all</span>
                            <a>
                              <fa-icon [icon]="expandedLookup[9] ? faChevronUp : faChevronDown"> </fa-icon>
                            </a>
                          </div>
                        </div>
                        <div class="ibox-content" [@expandCollapse]="expandedLookup[9] ? 'open' : 'close'">
                          <app-binding
                            [bindings]="rulesJson.instanceAttributeBindings"
                            (inputChangeEmitter)="instanceAttributeBindingsChangeHandler($event)"
                          >
                          </app-binding>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ibox">
                        <button
                          class="btn btn-dark-blue"
                          type="button"
                          title="Save"
                          (click)="saveCommunicationConfiguration()"
                        >
                          <fa-icon [icon]="faSave"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <h4 class="mb-5">Tools and Information</h4>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ibox">
                        <div class="display-title border-bottom" (click)="resizeIbox(10)">
                          <h4 class="user-select-none">Advanced View</h4>
                          <div class="ibox-tools">
                            <span class="dropdown_view_all">View all</span>
                            <a>
                              <fa-icon [icon]="expandedLookup[10] ? faChevronUp : faChevronDown"> </fa-icon>
                            </a>
                          </div>
                        </div>
                        <div class="ibox-content" [@expandCollapse]="expandedLookup[10] ? 'open' : 'close'">
                          <div class="px-0">
                            <div class="row">
                              <div class="col-md-12 copy-paste">
                                <button class="btn btn-outline-primary ml-0" (click)="edit()">
                                  <fa-icon [icon]="faPen"></fa-icon>
                                  EDIT
                                </button>
                                <button
                                  id="copy"
                                  class="btn btn-outline-primary"
                                  type="button"
                                  (click)="copy()"
                                  title="Copy"
                                >
                                  COPY
                                </button>
                              </div>
                            </div>
                            <app-json-preview [ruleSource]="rulesJson" contenteditable="false"></app-json-preview>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
