import { Component, Input, OnInit, OnChanges, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { faFileCode, faPencilAlt, faPlus, faTrash, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from 'src/app/app-data.service';
import { CommunicationContent, Filter, SearchFilters } from 'src/app/data/class';
import { CommunicationContentTemplateModalComponent } from '../communication-content-template-modal/communication-content-template-modal.component';
import { Language, Communication } from '../../data/model';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from '../../services/language.service';
import { CommunicationsTestModalComponent } from 'src/app/communications-test-modal/communications-test-modal.component';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { OutreachContentService } from 'src/app/services/outreach-content.service';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { CommnunicationOutreachContentModalComponent } from '../commnunication-outreach-content-modal/commnunication-outreach-content-modal.component';
import { ContentGroupService } from 'src/app/services/content-group.service';

@Component({
  selector: 'app-communication-content-tab',
  templateUrl: './communication-content-tab.component.html',
  styleUrls: ['./communication-content-tab.component.scss'],
})
export class CommunicationContentTabComponent implements OnInit, OnChanges {
  @Input() deliveryProtocolName: string;
  @Input() selectedComm: Communication;
  @Input() endpointType: string;
  commContent: CommunicationContent[];
  language: Map<number, Language>;
  searchFilters: SearchFilters;
  faFileCode = faFileCode;
  faPencilAlt = faPencilAlt;
  faPlus = faPlus;
  faTrash = faTrash;
  faClipboardCheck = faClipboardCheck;
  languages = [];
  filter = new Filter();
  limit = 20;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  lengthToCompare: number;
  lockedTables: boolean;
  lock: boolean;
  isLoading: boolean;
  contentGroup: any;

  constructor(
    private outreachContentService: OutreachContentService,
    private responseHelperService: ResponseHelperService,
    public appDataService: AppDataService,
    private languageService: LanguageService,
    private contentGroupService: ContentGroupService,
    private modalHelper: NgbModal,
    private toastr: ToastrService,
    private globalData: AppDataService
  ) {
    this.language = new Map<number, Language>();
    this.languageService.getLanguages().subscribe((res: any) => {
      res.entity.forEach((lang) => {
        this.language.set(lang.id, lang);
      });
    });
  }

  currentUser = this.globalData.username;
  ngOnInit(): void {
    this.getContentGroup(this.selectedComm.contentGroup.id);
    this.languageService.getLanguages().subscribe((res: any) => {
      this.languages = res.entity;
      this.searchFilters = {
        formName: 'contentSearch',
        title: 'Content search',
        searchCallback: () => {
          this.initNewSearch();
        },
        filters: [
          {
            name: 'segmentIds',
            type: 'array',
            inputType: 'segment-filter',
            singleLabel: 'Segment',
            hideableSegments: true,
          },

          {
            name: 'language',
            placeholder: 'Select Language',
            type: 'array',
            choices: this.languages,
            nameProp: 'languageName',
            valueProp: 'languageName',
            inputType: 'select',
            multiple: true,
          },
        ],
      };
    });
  }

  private getContentGroup(contentGroupId) {
    if (contentGroupId) {
      this.contentGroupService.getContentGroupById(contentGroupId).subscribe((res: any) => {
        this.contentGroup = res.entity;
      });
    } else {
      this.contentGroup = null;
    }
  }

  initNewSearch(): void {
    this.initFilter();
    this.getContents(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedComm) {
      this.getContentGroup(changes.selectedComm.currentValue.contentGroup.id);
      if (this.searchFilters) {
        this.searchFilters.clearAll();
        this.initNewSearch();
      }
    }
  }

  test(): void {
    const instance = this.modalHelper.open(CommunicationsTestModalComponent);
    instance.componentInstance.commId = this.selectedComm.id;
  }

  getContents(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.filter.commId = this.selectedComm.id;
    this.outreachContentService.getCommunicationContentsFromContentGroups(this.filter).subscribe(
      (res: any) => {
        if (concat) {
          this.commContent = this.commContent.concat(res.entity.records);
        } else {
          this.commContent = res.entity.records;
        }
        this.lengthToCompare = res.entity.records.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = res.entity.records.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      (res: any) => {
        this.lockedTables = false;
        this.isLoading = false;
        this.lock = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  templateModal(template: string): void {
    const instance = this.modalHelper.open(CommunicationContentTemplateModalComponent);
    instance.componentInstance.template = template;
  }

  contentModal(content?: any): void {
    const instance = this.modalHelper.open(CommnunicationOutreachContentModalComponent, { size: 'xl' });
    instance.componentInstance.communication = this.selectedComm;
    instance.componentInstance.contentGroup = this.contentGroup;
    instance.componentInstance.content = content;
    instance.componentInstance.endpointType = this.endpointType;
    instance.componentInstance.successEvent.subscribe((id) => {
      this.getContentGroup(id);
      this.initFilter();
      this.getContents(false);
      instance.close();
    });
  }

  deleteContent(content: CommunicationContent): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete Content with Subject: ' + content.subject;
    instance.componentInstance.header = `Delete Content with Subject: ${content.subject}?`;
    instance.componentInstance.title = 'Content';
    instance.componentInstance.successEvent.subscribe(() => {
      this.outreachContentService.deleteOutreachContent(content.id).subscribe(
        () => {
          this.getContents(false);
          this.responseHelperService.success(`Content with Subject ${content.subject} successfully removed`, true);
        },
        (res: any) => {
          this.responseHelperService.error(this, res.errorMsg, true);
        }
      );
    });
  }
}
