import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from '../../app-data.service';
import { EventSource } from '../../data/model';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { MemberEventsService } from 'src/app/services/member-events.service';

@Component({
  selector: 'app-new-event-source-modal',
  templateUrl: './new-event-source-modal.component.html',
  styleUrls: ['./new-event-source-modal.component.scss'],
})
export class NewEventSourceModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public eventSource: EventSource;
  title: string;
  isEdit: boolean;
  eventSourceForm: UntypedFormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalHelper: NgbModal,
    private memberEventsService: MemberEventsService,
    private globalData: AppDataService,
    private responseHelperService: ResponseHelperService
  ) {}

  ngOnInit(): void {
    if (this.eventSource) {
      this.title = 'Edit Event Source';
      this.isEdit = true;
      this.eventSourceForm = this.formBuilder.group({
        code: [this.eventSource.code, Validators.required],
        description: [this.eventSource.description],
      });
    } else {
      this.title = 'Event Source';
      this.isEdit = false;
      this.eventSourceForm = this.formBuilder.group({
        code: ['', Validators.required],
        description: [''],
      });
    }
  }

  ok(): void {
    let formValue = this.eventSourceForm.value;
    formValue.measuresCollection = [];
    this.memberEventsService.createOrUpdateEventSource(formValue).subscribe(
      () => {
        this.responseHelperService.success('Event Source successfully updated', true);
        this.successEvent.emit('success');
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error, false);
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
