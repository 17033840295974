import { StatusHelperService } from './../services/helpers/status-helper.service';
import { Title } from '@angular/platform-browser';
import { SponsorsService } from './../services/sponsors.service';
import { OnInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPencilAlt, faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SponsorModalComponent } from './../sponsor-modal/sponsor-modal.component';
import { MARKET_ID_KEY } from '../route.constants';
import { ApplyFilterService } from '../services/filter-pop-service/applyFilter.service';
import { AppDataService } from '../app-data.service';
import { openWindow } from '../data/window-open-function';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss'],
})
export class SponsorComponent implements OnInit {
  selectedPerson: any;
  faPencilAlt = faPencilAlt;
  faUsersCog = faUsersCog;
  active = 1;
  personId: number;
  sponsorStats: any;
  filterFunctions: any;
  statistics: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public globalData: AppDataService,
    private sponsorsService: SponsorsService,
    private title: Title,
    private statusHelperService: StatusHelperService,
    private modalHelper: NgbModal,
    private applyfilterService: ApplyFilterService
  ) {}

  statusHelper = this.statusHelperService.getStatus('badge');

  ngOnInit(): void {
    this.personId = parseInt(this.route.snapshot.paramMap.get(MARKET_ID_KEY), 10);
    if (this.personId) {
      this.getSponsor(this.personId);
      this.getSponsorStats(this.personId);
    }
  }

  getSponsor(id: number): void {
    this.sponsorsService.getSponsor(id).subscribe(
      (data) => {
        if (data.success) {
          this.selectedPerson = data.entity;
          this.title.setTitle(this.selectedPerson.name);
        }
      },
      () => {
        this.router.navigate(['markets']);
      }
    );
  }

  getSponsorStats(sponsorPartyId: number): void {
    this.sponsorsService.getSponsorStats({ sponsorPartyId: sponsorPartyId }).subscribe(
      (data) => {
        if (data.success) {
          this.sponsorStats = data.entity;
          this.statistics.push({ count: this.sponsorStats.campaignCount, title: 'Programs' });
          this.statistics.push({ count: this.sponsorStats.promotionSponsorCount, title: 'Sponsorships' });
          this.statistics.push({
            count: this.sponsorStats.promotionConfigCount,
            title: 'Configuration',
          });
        }
      },
      () => {}
    );
  }

  parseResponse(data: any): void {
    if (data.success) {
      this.selectedPerson = data.entity;
    }
  }

  edit(id: number): void {
    this.sponsorsService.getSponsor(id).subscribe((data) => {
      if (data.success) {
        const instance = this.modalHelper.open(SponsorModalComponent);
        instance.componentInstance.sponsor = data.entity;
        instance.componentInstance.selectedPersonId = this.selectedPerson.id;
        instance.componentInstance.successEvent.subscribe(() => {
          this.getSponsor(id);
          instance.close();
        });
      }
    });
  }
  tabClick(tab) {
    this.applyfilterService.filterfun.subscribe((data) => {
      this.filterFunctions = data;
    });
    if (this.filterFunctions) {
      this.filterFunctions.clearAll();
    }
    this.applyfilterService.getFilterData([]);
  }

  openAdmin(): void {
    var token = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).sessionId;

    const headers: HttpHeaders = new HttpHeaders();
    headers.set('withCredentials', 'true');
    this.http.post(this.selectedPerson.url + '/token/', JSON.stringify({ token }), { headers }).subscribe(() => {
      openWindow(this.selectedPerson.url, '_blank');
    });
  }
}
