import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCalendar, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { OrganizationsService } from 'src/app/services/organizations.service';

@Component({
  selector: 'app-organization-modal',
  templateUrl: './organization-modal.component.html',
  styleUrls: ['./organization-modal.component.scss'],
})
export class OrganizationModalComponent implements OnInit {
  @Input() public entity;
  @Input() public entityType;
  @Input() public parentCode;
  @Input() public parentId;
  @Input() public partyRoles = [];
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  form: UntypedFormGroup;
  isEdit = false;
  title: string;
  errorMsg = '';
  faSave = faSave;
  faTimes = faTimes;
  faCalendar = faCalendar;

  constructor(
    private statusHelperService: StatusHelperService,
    private modalHelper: NgbModal,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private dateHelperService: DateHelperService,
    private organizationService: OrganizationsService
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.entity) {
      this.title = `Edit ${this.entityType}`;
      this.isEdit = true;
      this.form = this.formBuilder.group({
        name: [{ value: this.entity.name, disabled: true }, Validators.required],
        yearStart: [this.entity.yearStart],
        status: [this.entity.status, Validators.required],
        url: [this.entity.url],
        logoUrl: [this.entity.logoUrl],
        externalOrgCode: [this.entity.externalOrgCode],
      });
    } else {
      this.title = `New ${this.entityType}`;
      this.isEdit = false;
      this.form = this.formBuilder.group({
        name: ['', Validators.required],
        yearStart: [null],
        status: [null, Validators.required],
        url: [''],
        logoUrl: [''],
        externalOrgCode: [''],
      });
    }
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  ok(): void {
    var entity = {
      ...this.entity,
      ...this.form.value,
      partyRoles: this.entity ? this.entity.partyRoles : this.partyRoles,
      parentCode: this.parentCode,
    };
    if (this.parentId && !this.entity) {
      entity.parentId = this.parentId;
    }
    this.organizationService.createOrUpdateOrg(entity).subscribe(
      (data: any) => {
        this.successEvent.emit(data);
        var message = `${this.entityType} successfully `;
        if (this.isEdit) {
          message += 'updated';
        } else {
          message += 'created';
        }
        this.toastr.success(message);
      },
      (data: any) => {
        this.errorMsg = data.error.error;
      }
    );
  }

  yearStartChanged(date) {
    this.form.controls.yearStart.setValue(formatDate(this.dateHelperService.ngbDateToString(date), 'MM/dd', 'en-US'));
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
