import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IntegrationKey } from '../../data/model';
import { StatusHelperService } from '../../services/helpers/status-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave, faUpload } from '@fortawesome/free-solid-svg-icons';
import { IntegrationKeyService } from 'src/app/services/integration-key.service';

@Component({
  selector: 'app-integration-key-modal',
  templateUrl: './integration-key-modal.component.html',
  styleUrls: ['./integration-key-modal.component.scss'],
})
export class IntegrationKeyModalComponent implements OnInit {
  @Input() integrationKey: IntegrationKey;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  integrationKeyForm: UntypedFormGroup;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;
  faUpload = faUpload;
  keyFormats = ['PGP', 'AES', 'X509'];
  isEdit: boolean;
  publicKeyFileName: string;
  privateKeyFileName: string;
  publicKeyValue: number[] = [];
  privateKeyValue: number[] = [];
  fileContent: string;

  constructor(
    private statusHelperService: StatusHelperService,
    private integrationKeyService: IntegrationKeyService,
    private modalHelper: NgbModal,
    private formBuilder: UntypedFormBuilder
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.integrationKey) {
      this.title = 'Edit Integration Key';
      this.isEdit = true;
      this.integrationKeyForm = this.formBuilder.group({
        name: [this.integrationKey.name, Validators.required],
        keyPassword: [this.integrationKey.keyPassword],
        keyFormat: [this.integrationKey.keyFormat, Validators.required],
        status: [this.integrationKey.status, Validators.required],
      });
    } else {
      this.title = 'New Integration Key';
      this.isEdit = false;
      this.integrationKeyForm = this.formBuilder.group({
        name: ['', Validators.required],
        keyPassword: [''],
        keyFormat: ['', Validators.required],
        status: ['', Validators.required],
      });
    }
  }

  ok(): void {
    let value = this.integrationKeyForm.value;
    if (this.privateKeyValue.length > 0) {
      value.privateKey = this.privateKeyValue;
    }
    value.keyPassword = this.convertStringToArray(value.keyPassword);
    value.publicKey = this.publicKeyValue;
    this.integrationKeyService
      .createOrUpdateIntegrationKey(this.integrationKey && this.integrationKey.id, value)
      .subscribe(
        (data: any) => {
          this.successEvent.emit(data);
        },
        (data: any) => {
          this.errorMsg = data.error.error;
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  changeListener(event, isPublicKey?: boolean): void {
    let inputValue = event.target;
    var file: File = inputValue.files[0];
    if (isPublicKey) {
      this.publicKeyFileName = file.name;
      this.publicKeyValue = this.convertStringToArray(this.publicKeyFileName);
    } else {
      this.privateKeyFileName = file.name;
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.privateKeyValue = this.convertStringToArray(fileReader.result.toString());
      };
    }
  }

  convertStringToArray(input: string): number[] {
    if (input) {
      let array: number[] = [];
      for (let index = 0; index < input.length; index++) {
        array.push(input.charCodeAt(index));
      }
      return array;
    }
    return null;
  }
}
