import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  faTimes,
  faCalendar,
  faStar,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { AddressService } from 'src/app/services/address.service';
import { InstrumentsService } from 'src/app/services/instruments.service';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { LocationService } from 'src/app/services/location.service';
import { BehaviorsService } from 'src/app/services/behaviors.service';
import { ActivityService } from 'src/app/services/activity.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ProcessingService } from 'src/app/services/processing.service';
import { UserPreferencesService } from 'src/app/services/user-preferences.service';
import { MatDialogRef } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-member-activity-modal',
  templateUrl: './member-activity-modal.component.html',
  styleUrls: ['./member-activity-modal.component.scss'],
})
export class MemberActivityModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() activity: any = {}; // todo assign type
  address: any = {};
  @Input() public addressData: any = {};
  @Input() public personId: number;
  @Input() public instrument: any;
  @Input() public defaultLocation: any;
  @Input() public behaviorId: number;
  instrumentNumber: any;
  activityForm: UntypedFormGroup;
  title: string;
  showAttrs = false;
  showAddress = false;
  addressRequired = false;
  countClick = 0;
  faTimes = faTimes;
  faCalendar = faCalendar;
  faStar = faStar;
  faAngleDoubleDown = faAngleDoubleDown;
  faAngleDoubleUp = faAngleDoubleUp;
  faTrash = faTrash;
  faPlus = faPlus;
  isEdit: boolean;
  process: boolean;
  @ViewChild('contry') contry;
  @ViewChild('states') states;
  @ViewChild('locations') locations;
  manualRef: boolean;
  amount: number;
  instruments: any[];
  maxDate = undefined;
  locationsFilter: any;
  location: any;
  behaviors: any[];
  behavior: any;
  selectedBehavior: any;
  filteredBehaviors: Observable<string[]>;
  errorMsg: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalHelper: NgbModal,
    private addressService: AddressService,
    private instrumentService: InstrumentsService,
    private locationService: LocationService,
    private dateHelperService: DateHelperService,
    private behaviorService: BehaviorsService,
    private responseHelper: ResponseHelperService,
    private activityService: ActivityService,
    private processingService: ProcessingService,
    private userPreferencesService: UserPreferencesService,
    public dialogRef: MatDialogRef<MemberActivityModalComponent>
  ) {
    const current = new Date();
    this.maxDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
    this.behaviors = [];
    this.filteredBehaviors = new Observable<string[]>();
  }

  ngOnInit(): void {
    this.activity.activityTenderCollection = [{}];
    this.activity.attributes = [];

    if (this.defaultLocation) {
      this.location = this.defaultLocation.code + '-' + this.defaultLocation.name;
    }

    this.locationsFilter = {};
    this.locationsFilter.statuses = ['ACTIVE'];
    this.locationService.getAllLocations(this.locationsFilter).subscribe((data: any) => {
      if (data.success) {
        this.locations = data.entity.map((i) => {
          i.codeName = i.code + '-' + (i.name || '').toString();
          return i;
        });
      }
    });

    this.instrumentService.getPartyInstruments(this.personId).subscribe((data: any) => {
      if (data.success) {
        this.instruments = data.entity;
      }
    });

    if (this.instrument) {
      this.instrumentNumber = this.instrument.instrumentNumber;
    }

    if (this.activity.id) {
      this.title = 'Edit Activity';
      this.isEdit = true;
    } else {
      this.title = 'Load New Activity';
    }

    this.activityForm = this.formBuilder.group({
      description: [],
      process: [this.activity.process || false],
      addressType: [this.address.addressType],
      country: [this.address.country],
      postalCode: [this.address.postalCode],
      city: [this.address.city],
      state: [this.address.state],
      address2: [this.address.address2],
      address1: [this.address.address1],
      attrs: this.formBuilder.array([]),
      referenceNumber: [this.activity.referenceNumber, Validators.required],
      activityDate: [this.activity.activityDate, Validators.required],
      amount: [this.amount, Validators.required],
      instrument: [this.instrumentNumber, Validators.required],
      location: [this.location, Validators.required],
      behavior: [this.behavior, Validators.required],
    });
    this.activityForm.patchValue({
      process: true,
    });

    this.behaviorService.getBehaviors().subscribe((data: any) => {
      if (data.success) {
        this.behaviors = data.entity.records;
        this.filteredBehaviors = this.activityForm.controls.behavior.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filter(value).splice(0, 100))
        );
      }
      if (this.behaviorId) {
        this.setBehavior(this.behaviorId);
      }
    });

    this.activityForm.controls.activityDate.valueChanges.subscribe((newActivityData) => {
      this.genRefDate(newActivityData);
    });

    if (this.activityForm.controls.instrument) {
      this.genRef();
    }
  }

  setBehavior(id): void {
    let foundBehavior = this.behaviors.find((each) => each.id === id);

    if (foundBehavior) {
      this.activityForm.controls['behavior'].setValue(foundBehavior.code);
      this.genRef();
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.behaviors.filter((behavior) => behavior.code.toLowerCase().includes(filterValue));
  }

  ok(): void {
    const value = this.activityForm.value;
    if (location) {
      this.locations.forEach((e) => {
        if (e.codeName === value.location) {
          this.activity.locationId = e.id;
          this.activity.locationName = e.name;
          this.activity.locationCode = e.code;
          this.activity.locationHierarchySourceId = e.hierarchySourceId;
        }
      });
    }
    if (value.activityDate) {
      this.activity.activityDate = this.dateHelperService.ngbDateToString(value.activityDate) + 'T12:00:00.000000';
    }
    if (value.behavior) {
      this.behaviors.forEach((e) => {
        if (e.code === value.behavior) {
          this.activity.behaviorCode = e.code;
          this.activity.behaviorDescription = e.description;
          this.activity.behaviorHierarchySourceId = e.hierarchySourceId;
        }
      });
    }
    if (value.instrument) {
      this.instruments.forEach((e) => {
        if (value.instrument === e.instrumentNumber) {
          this.activity.activityTenderCollection[0].amount = value.amount;
          this.activity.activityTenderCollection[0].instrumentType = e.instrumentType.code;
          this.activity.activityTenderCollection[0].instrumentNumber = e.instrumentNumber;
        }
      });
    }
    if (value.attrs.length > 0) {
      value.attrs = value.attrs.filter((obj) => obj.name && obj.name !== '' && obj.value && obj.value !== '');
      value.attrs.forEach((element) => {
        this.activity.attributes.push({
          name: element.name,
          value: element.value,
        });
      });
    }
    if (this.countClick > 0 && this.showAddress) {
      this.activity.attributes.push({
        name: 'AUTOREDEEM_ADDRESS',
        value: JSON.stringify({
          city: value.city,
          state: value.state,
          country: value.country,
          address1: value.address1,
          address2: value.address2,
          postalCode: value.postalCode,
          addressType: value.addressType,
        }),
      });
    } else if (this.countClick > 0 && !this.showAddress) {
      this.activity.attributes.push({
        name: 'AUTOREDEEM_ADDRESS',
        value: JSON.stringify({
          city: this.address.city,
          state: this.address.state,
          country: this.address.country,
          address1: this.address.address1,
          address2: this.address.address2,
          postalCode: this.address.postalCode,
          addressType: this.address.addressType,
        }),
      });
    }
    this.activity.status = 'ACTIVE';
    this.activity.referenceNumber = value.referenceNumber;
    this.activityService.insertActivity(this.activity).subscribe(
      (data) => {
        this.responseHelper.success('Activity Created', true);
        if (value.process) {
          this.processingService.updateProcessActivity(data.entity.id).subscribe(
            () => {
              this.responseHelper.success('Activity successfully sent for processing');
            },
            (e: any) => {
              this.responseHelper.error(this, e.error.error);
            }
          );
        }
        this.dialogRef.close();
      },
      (data: any) => {
        this.activity.attributes = [];
        this.responseHelper.error(this, data.error.error);
      }
    );
  }

  getAttr(): UntypedFormArray {
    return this.activityForm.controls.attrs as UntypedFormArray;
  }

  addAttr(name?: string, value?: string): void {
    this.getAttr().push(this.newAttr(name, value));
  }

  newAttr(name?: string, value?: string): UntypedFormGroup {
    return this.formBuilder.group({
      name: [name || ''],
      value: [value || ''],
    });
  }

  setManualRef(): void {
    this.manualRef = true;
  }

  genRef(): void {
    const value = this.activityForm.value;
    this.activityForm.patchValue({
      referenceNumber:
        value.instrument + '-' + value.behavior + '-' + this.dateHelperService.ngbDateToString(value.activityDate),
    });
  }

  addAddress(): void {
    if (this.showAddress) {
      this.countClick++;
      this.activityForm.controls.addressType.setValidators(Validators.required);
      this.activityForm.controls.country.setValidators(Validators.required);
      this.activityForm.controls.postalCode.setValidators(Validators.required);
      this.activityForm.controls.city.setValidators(Validators.required);
      this.activityForm.controls.state.setValidators(Validators.required);
      this.activityForm.controls.address1.setValidators(Validators.required);
      this.addressService.getPrimaryPartyAddress(this.personId).subscribe((address: any) => {
        if (address.success) {
          this.address = address.entity;
          this.activityForm.patchValue({
            city: address.entity.city,
            state: address.entity.state,
            country: address.entity.country,
            address1: address.entity.address1,
            address2: address.entity.address2,
            postalCode: address.entity.postalCode,
            addressType: address.entity.addressType,
          });
        }
      });
    } else if (!this.showAddress) {
      this.addressService.getPrimaryPartyAddress(this.personId).subscribe((address: any) => {
        if (address.success) {
          this.address = address.entity;
          this.activityForm.patchValue({
            city: address.entity.city,
            state: address.entity.state,
            country: address.entity.country,
            address1: address.entity.address1,
            address2: address.entity.address2,
            postalCode: address.entity.postalCode,
            addressType: address.entity.addressType,
          });
        }
      });
    }
  }

  genRefDate(val): void {
    const value = this.activityForm.value;
    this.activityForm.patchValue({
      referenceNumber: value.instrument + '-' + value.behavior + '-' + this.dateHelperService.ngbDateToString(val),
    });
  }
  clearInput(): void {
    this.activityForm.patchValue({
      activityDate: '',
    });
  }
  cancel(): void {
    this.dialogRef.close();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  focusCountries(): void {
    this.contry.close();
  }

  focusStates(): void {
    this.states.close();
  }

  removeAttr(i: number): void {
    this.getAttr().removeAt(i);
  }

  focusLocations(): void {
    this.locations.close();
  }
}
