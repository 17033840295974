import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RedemptionService } from 'src/app/services/redemption.service';
import { AddressModel } from 'src/app/data/model';

@Component({
  selector: 'app-redemption-address-modal',
  templateUrl: './redemption-address-modal.component.html',
  styleUrls: ['./redemption-address-modal.component.scss'],
})
export class RedemptionAddressModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public address: AddressModel = new AddressModel();
  faTimes = faTimes;
  faSave = faSave;
  addressForm: UntypedFormGroup;
  errorMsg: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private redemptionService: RedemptionService,
    private ressponseHelperService: ResponseHelperService,
    private modalHelper: NgbModal
  ) {}

  ngOnInit(): void {
    this.addressForm = this.formBuilder.group({
      address1: [this.address.address1],
      address2: [this.address.address2],
      state: [this.address.state],
      zip: [this.address.zip],
      country: [this.address.country],
      city: [this.address.city],
    });
  }

  ok(): void {
    this.redemptionService
      .updateRedemptionAddress(this.address.id, this.addressForm.value)
      .subscribe(
        (data: any) => {
          this.ressponseHelperService.success(
            'Redemption successfully updated',
            true
          );
          this.successEvent.emit('success');
        },
        (data: any) => {
          this.ressponseHelperService.error(this, data.error.error);
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
