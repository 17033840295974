<form [formGroup]="deliveryOptionsForm" class="com-model">
  <div class="com-delivery-modal">
    <div>
      <div>
        <span class="title">Delivery</span>
        <hr class="mb-0" />
      </div>
      <div class="mb-3 mt-3">
        <span class="com-title">{{ title }}</span>
      </div>
    </div>
    <div class="">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{ errorMsg }}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div formArrayName="options">
        <div>
          <div
            class="d-flex justify-content-between align-items-end mb-3"
            *ngFor="let option of getOptions().controls; let i = index"
            [formGroupName]="i"
          >
            <div class="">
              <label class="com-label">Option name</label>
              <input
                type="text"
                name="optionName"
                class="form-control"
                formControlName="optionName"
                autocomplete="off"
                placeholder="Add option name"
                autofocus
                style="width: 420px"
              />
              <div class="mt-xs" [hidden]="option.controls.optionName.valid"></div>
            </div>
            <div class="">
              <label class="com-label">Option value</label>
              <input
                type="text"
                name="optionValue"
                class="form-control"
                formControlName="optionValue"
                autocomplete="off"
                placeholder="Add option value"
                style="width: 420px"
              />
              <div class="mt-xs" [hidden]="option.controls.optionValue.valid"></div>
            </div>
            <div class="">
              <input
                type="number"
                [hidden]="true"
                name="optionValue"
                class="form-control"
                formControlName="id"
                autocomplete="off"
                style="width: 420px"
              />
              <div *ngIf="getOptions().controls.length > 1">
                <button class="btn btn-outline-primary addoption" (click)="removeOption(i)">
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </div>
              <div *ngIf="getOptions().controls.length === 1">
                <button class="btn btn-outline-primary addoption" (click)="addOption()">
                  <fa-icon [icon]="faPlus"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-4 mb-1" *ngIf="getOptions().controls.length > 1">
            <button class="btn btn-outline-primary addoption" (click)="addOption()">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-btn-container mt-5">
      <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!deliveryOptionsForm.valid"></app-modal-buttons>
    </div>
  </div>
</form>
