import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { InstrumentType, ActivityInstrument } from '../../../data/model';
import { StatusHelperService } from '../../../services/helpers/status-helper.service';
import { faCalendar, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DateHelperService } from '../../../services/helpers/date-helper.service';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstrumentsService } from '../../../services/instruments.service';
import { ResponseHelperService } from '../../../services/helpers/response-helper.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { getHelperTextForControl } from '../../../services/helper-text.service';
import { getDisplayMask, shouldDropSpecialCharacters, validateInstrumentNumber } from './instrument-modal.util';

@Component({
  selector: 'app-instrument-modal',
  templateUrl: './instrument-modal.component.html',
  styleUrls: ['./instrument-modal.component.scss'],
})
export class InstrumentModalComponent implements OnInit {
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() instrument: ActivityInstrument;
  @Input() instruments: ActivityInstrument[];
  @Input() instrumentTypes: InstrumentType[];
  @Input() personId: number;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  selectedType: InstrumentType;
  title: string;
  errorMsg = '';
  activationDateValue: NgbDate;
  expiryDateValue: NgbDate;
  cancelationDateValue: NgbDate;
  closeDateValue: NgbDate;
  instrumentNumberInputMask: string;
  shouldDropSpecialCharacters: boolean;
  getDisplayText: (displayMask: string, instrument: string) => string;

  // TODO:
  instrumentTypeCode: string;

  faCalendar = faCalendar;
  faSave = faSave;
  faTimes = faTimes;

  constructor(
    private statusHelperService: StatusHelperService,
    private dateHelperService: DateHelperService,
    private modalHelper: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private instrumentsService: InstrumentsService,
    private responseHelperService: ResponseHelperService
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.instrument) {
      this.title = 'Edit Instrument';
    } else {
      this.title = 'New Instrument';
      this.instrument = new ActivityInstrument();
      this.instrument.instrumentNumber = '';
    }
    this.setupFormAndValidation();
  }

  setupFormAndValidation() {
    // The instrumentType data on the existing instrument model may be out of date. We replace it with the latest
    // option from the server here.

    this.selectedType = this.pickInstrumentType(this.instrument?.instrumentType?.id);
    this.setMaskAttributes(this.selectedType);

    this.formGroup = this.formBuilder.group({
      instrumentType: [this.selectedType, Validators.required],
      instrumentNumber: [
        this.instrument.instrumentNumber,
        Validators.compose([
          Validators.required,
          (control) => validateInstrumentNumber(control, this.formGroup.controls.instrumentType),
        ]),
      ],
      status: [this.instrument.status, Validators.required],
      account: [this.instrument.account],
      holderName: [this.instrument.holderName],
      country: [this.instrument.country],
      isPrimary: [this.instrument.isPrimary],
      cancelationDate: [this.dateHelperService.stringToNgbDate(this.instrument.cancelationDate)],
      activationDate: [this.dateHelperService.stringToNgbDate(this.instrument.activationDate)],
      expiryDate: [this.dateHelperService.stringToNgbDate(this.instrument.expiryDate)],
      closeDate: [this.dateHelperService.stringToNgbDate(this.instrument.closeDate)],
    });

    // The instrument number validity is dependent upon the instrument type control validity.
    this.formGroup.controls.instrumentType.valueChanges.subscribe(() => {
      this.formGroup.controls.instrumentNumber.updateValueAndValidity();
    });
  }

  pickInstrumentType(id: number | undefined): InstrumentType | undefined {
    return this.instrumentTypes?.find((instrumentType) => instrumentType.id === id);
  }

  setMaskAttributes(instrumentTypeOption: InstrumentType) {
    this.instrumentNumberInputMask = getDisplayMask(instrumentTypeOption);
    this.shouldDropSpecialCharacters = shouldDropSpecialCharacters(instrumentTypeOption);
  }

  setInstrumentTypeValidation(event: InstrumentType): void {
    if (!event?.id) {
      return;
    }
    if (!this.selectedType?.id || this.formGroup.controls.instrumentType?.value?.id !== this.selectedType.id) {
      this.selectedType = this.pickInstrumentType(event.id);
      this.setMaskAttributes(this.selectedType);
    }
  }

  clearDateInput(name: string): void {
    this[name] = null;
  }

  formatDate(value: NgbDate) {
    return this.dateHelperService.ngbDateToString(value);
  }

  ok(): void {
    this.instrument.partyId = this.personId;
    if (this.title === 'New Instrument' && this.instruments.length === 0) {
      this.instrument.isPrimary = true;
    }

    const formValues = this.formGroup.value;
    const activityInstrument: ActivityInstrument = {
      ...this.instrument,
      ...formValues,
      activationDate: this.formatDate(formValues.activationDate),
      expiryDate: this.formatDate(formValues.expiryDate),
      cancelationDate: this.formatDate(formValues.cancelationDate),
      closeDate: this.formatDate(formValues.closeDate),
    };

    this.instrumentsService.createOrUpdateInstrument(this.instrument.id, activityInstrument).subscribe((data: any) => {
      if (data.success) {
        this.responseHelperService.success('Identifier type successfully updated', true);
        this.successEvent.emit();
      }
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  getInstrumentNumberHelperText(): string {
    if (this.formGroup && this.formGroup.controls) {
      const { instrumentNumber } = this.formGroup.controls;
      return getHelperTextForControl({
        defaultHelperText: 'Input identifier',
        control: instrumentNumber,
      });
    }
  }
}
