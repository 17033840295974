<form [formGroup]="programForm" novalidate>
  <div class="d-flex justify-content-end mt-4 mr-4">
    <mat-icon (click)="cancel()" class="cursor-pointer">close</mat-icon>
  </div>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Name <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="name" autofocus />
      <!-- <div class="m-t-xs" [hidden]="programForm.controls['name'].valid">
        <small class="text-danger">Input a name</small>
      </div> -->
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Code <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="code" />
      <!-- <div class="m-t-xs" [hidden]="programForm.controls['code'].valid">
        <small class="text-danger">Input a code</small>
      </div> -->
    </div>

    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Status <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <select formControlName="status" class="form-control" formControlName="status">
        <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
      </select>
      <!-- <div class="m-t-xs" [hidden]="programForm.controls['status'].valid">
        <small class="text-danger">Select status</small>
      </div> -->
    </div>
    <div class="form-group">
      <label>Description</label>
      <textarea formControlName="description" type="text" class="form-control vertical-expand"></textarea>
    </div>

    <div class="form-group">
      <label>Icon</label>
      <div class="input-group">
        <input class="form-control" formControlName="imageUrl" />
        <span
          class="input-group-addon"
          placement="right"
          #popOver="ngbPopover"
          [ngbPopover]="popContent"
          [popoverTitle]="popTitle"
          [autoClose]="'outside'"
        >
          <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
        </span>
      </div>
    </div>

    <div class="modal-footer">
      <app-modal-buttons [submitText]="isEdit ? 'SAVE' : 'ADD'" (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!programForm.valid"></app-modal-buttons>
    </div>
  </div>
</form>

<ng-template #popTitle
  ><input
    class="form-control"
    placeholder="Type to filter"
    (click)="$event.preventDefault()"
    (keyup)="filterIcons($event.target.value)"
/></ng-template>

<ng-template #popContent>
  <table class="icon-picker-table">
    <tr *ngFor="let iconKey of everyThirdIconArray; let i = index">
      <td>
        <button
          (click)="selectIcon(filterIconKeys[3 * i])"
          type="button"
          [title]="filterIconKeys[3 * i]"
          class="btn btn-light btn-sm"
        >
          <fa-icon class="fa-2x" [icon]="allIcons[filterIconKeys[3 * i]]"></fa-icon>
        </button>
      </td>
      <td>
        <button
          (click)="selectIcon(filterIconKeys[3 * i + 1])"
          type="button"
          [title]="filterIconKeys[3 * i + 1]"
          class="btn btn-light btn-sm"
        >
          <fa-icon class="fa-2x" [icon]="allIcons[filterIconKeys[3 * i + 1]]"></fa-icon>
        </button>
      </td>
      <td>
        <button
          (click)="selectIcon(filterIconKeys[3 * i + 2])"
          type="button"
          [title]="filterIconKeys[3 * i + 2]"
          class="btn btn-light btn-sm"
        >
          <fa-icon class="fa-2x" [icon]="allIcons[filterIconKeys[3 * i + 2]]"></fa-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="filterIconKeys.length % 3 === 1">
      <td>
        <button
          (click)="selectIcon(filterIconKeys[filterIconKeys.length - 1])"
          type="button"
          [title]="filterIconKeys[filterIconKeys.length - 1]"
          class="btn btn-sm btn-light"
        >
          <fa-icon class="fa-2x" [icon]="allIcons[filterIconKeys[filterIconKeys.length - 1]]"></fa-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="filterIconKeys.length % 3 === 2">
      <td>
        <button
          (click)="selectIcon(filterIconKeys[filterIconKeys.length - 2])"
          type="button"
          [title]="filterIconKeys[filterIconKeys.length - 2]"
          class="btn btn-sm btn-light"
        >
          <fa-icon class="fa-2x" [icon]="allIcons[filterIconKeys[filterIconKeys.length - 2]]"></fa-icon>
        </button>
      </td>
      <td>
        <button
          (click)="selectIcon(filterIconKeys[filterIconKeys.length - 1])"
          type="button"
          [title]="filterIconKeys[filterIconKeys.length - 1]"
          class="btn btn-sm btn-light"
        >
          <fa-icon class="fa-2x" [icon]="allIcons[filterIconKeys[filterIconKeys.length - 1]]"></fa-icon>
        </button>
      </td>
    </tr>
  </table>
</ng-template>
