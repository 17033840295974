<ng-container *ngIf="searchFilters && !isPopup && !packagepop">
  <form name="{{ searchFilters.formName }}" (submit)="searchFilters.applyFilters()">
    <div class="row col-md-12">
      <div
        [ngClass]="headerType !== 'generic' ? 'col-md-12' : 'col-md-10'"
        [hidden]="!searchFilters.showSearchFilters && searchFilters.showSearchFilters !== undefined"
      >
        <div
          [ngClass]="headerType !== 'generic' ? 'row seven-cols' : 'row search-filter-row'"
          class="search-filter-flex-row"
        >
          <app-filter-header
            class="full-width"
            [(helperData)]="helperData"
            [(searchFilters)]="searchFilters"
            [(filterSegments)]="filterSegments"
            [(headerType)]="headerType"
          ></app-filter-header>
          <!-- TODO: implement trackby: key -->
          <span class="full-width" *ngFor="let section of searchFilters.sections; let sIndex = index">
            <div class="row">
              <app-filter-expander [(section)]="searchFilters.sections[sIndex]"></app-filter-expander>
            </div>
            <div class="row">
              <div style="width: fit-content;" *ngFor="let filter of searchFilters.filters; let fIndex = index">
                <div *ngIf="searchFilters.filters[fIndex].section.key === searchFilters.sections[sIndex].key">
                  <app-segment-filter
                    custom-class="filter.inputClass"
                    (filterSegments)="filterSegmentsHandler($event)"
                    (inputChangeEmitter)="inputChangeHandler()"
                    *ngIf="filter.inputType === 'segment-filter' &amp;&amp; !globalData.externalOrgCode"
                  ></app-segment-filter>

                  <app-filter-select
                    *ngIf="
                      searchFilters.filters[fIndex].type === 'array' &&
                      searchFilters.filters[fIndex].inputType === 'select'
                    "
                    [(section)]="searchFilters.sections[sIndex]"
                    [(filter)]="searchFilters.filters[fIndex]"
                    (inputChangeEmitter)="inputChangeHandler()"
                  ></app-filter-select>

                  <app-filter-typeahead
                    *ngIf="
                      searchFilters.filters[fIndex].type === 'string' &&
                      searchFilters.filters[fIndex].inputType === 'typeahead'
                    "
                    [(section)]="searchFilters.sections[sIndex]"
                    [(filter)]="searchFilters.filters[fIndex]"
                    (inputChangeEmitter)="inputChangeHandler()"
                  ></app-filter-typeahead>

                  <app-filter-dropdown
                    *ngIf="
                      searchFilters.filters[fIndex].type === 'array' &&
                      searchFilters.filters[fIndex].inputType === 'dropdown'
                    "
                    [(section)]="searchFilters.sections[sIndex]"
                    [(filter)]="searchFilters.filters[fIndex]"
                    (inputChangeEmitter)="inputChangeHandler()"
                  ></app-filter-dropdown>

                  <app-filter-datepicker
                    *ngIf="searchFilters.filters[fIndex].type === 'date'"
                    [(section)]="searchFilters.sections[sIndex]"
                    [(filter)]="searchFilters.filters[fIndex]"
                    (inputChangeEmitter)="inputChangeHandler()"
                  ></app-filter-datepicker>

                  <app-filter-string
                    *ngIf="!searchFilters.filters[fIndex].type || searchFilters.filters[fIndex].type === 'string'"
                    [(section)]="searchFilters.sections[sIndex]"
                    [(filter)]="searchFilters.filters[fIndex]"
                    (inputChangeEmitter)="inputChangeHandler()"
                    class="ng-scope ng-isolate-scope"
                  ></app-filter-string>

                  <app-filter-number
                    *ngIf="searchFilters.filters[fIndex].type === 'number'"
                    [(section)]="searchFilters.sections[sIndex]"
                    [(filter)]="searchFilters.filters[fIndex]"
                    (inputChangeEmitter)="inputChangeHandler($event)"
                  ></app-filter-number>

                  <app-filter-checkbox
                    *ngIf="searchFilters.filters[fIndex].type === 'boolean'"
                    [(section)]="searchFilters.sections[sIndex]"
                    [(filter)]="searchFilters.filters[fIndex]"
                    (inputChangeEmitter)="inputChangeHandler($event)"
                  ></app-filter-checkbox>

                  <app-filter-radio
                    [(section)]="searchFilters.sections[sIndex]"
                    [(filter)]="searchFilters.filters[fIndex]"
                    (inputChangeEmitter)="inputChangeHandler($event)"
                    *ngIf="searchFilters.filters[fIndex].type === 'radio'"
                    [ngClass]="{ 'filter-radio-width': filter.linebreak }"
                    class="filter-radio"
                  ></app-filter-radio>
                </div>
              </div>
            </div>
          </span>
          <div
            class="row m-b-sm m-t-sm full-width"
            [ngClass]="headerType !== 'memberSearch' ? 'search-filter-row' : ''"
            *ngIf="searchFilters.showSearchFilters"
          >
            <app-filter-tags
              class="full-width"
              [(searchFilters)]="searchFilters"
              [(helpers)]="helpers"
              [(appliedFilters)]="appliedFilters"
              [(someFiltersNotApplied)]="someFiltersNotApplied"
            ></app-filter-tags>
          </div>
        </div>
      </div>
      <div class="col-md-2 float-right" *ngIf="headerType === 'generic'">
        <app-filter-button-panel [(searchFilters)]="searchFilters"></app-filter-button-panel>
      </div>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="searchFilters && isPopup" class="search-filter-model">
  <div class="container com-model" [ngClass]="heading === Program ? 'filter-modal' : ''">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <span class="heading-search-Filter">{{ heading }}</span>
        <hr />
        <div class="heading_filter">Apply filters</div>
      </div>
      <div class="col-md-10">
        <form name="{{ searchFilters.formName }}" (submit)="clickFilter()">
          <div class="full-width" *ngFor="let section of searchFilters.sections; let sIndex = index">
            <div *ngFor="let filter of searchFilters.filters; let fIndex = index">
              <div>
                <div *ngIf="filter.label && filter.label !== 'Require preference'" class="filter_label">
                  {{ filter.label }}
                </div>
                <app-segment-filter
                  custom-class="filter.inputClass"
                  (filterSegments)="filterSegmentsHandler($event)"
                  [(filter)]="searchFilters.filters[fIndex]"
                  (inputChangeEmitter)="inputChangeHandler()"
                  *ngIf="
                    filter.inputType === 'segment-filter' &&
                    (!filter.hideableSegments || filter.segmentMembers !== undefined)
                  "
                  class="filter-dd"
                ></app-segment-filter>
                <app-filter-dropdown
                  *ngIf="
                    searchFilters.filters[fIndex].type === 'array' &&
                    searchFilters.filters[fIndex].inputType === 'dropdown'
                  "
                  [(section)]="searchFilters.sections[sIndex]"
                  [(filter)]="searchFilters.filters[fIndex]"
                  (inputChangeEmitter)="inputChangeHandler()"
                  class="filter-dd"
                  [(heading)]="heading"
                >
                </app-filter-dropdown>

                <app-filter-datepicker
                  *ngIf="searchFilters.filters[fIndex].type === 'date'"
                  [(section)]="searchFilters.sections[sIndex]"
                  [(filter)]="searchFilters.filters[fIndex]"
                  (inputChangeEmitter)="inputChangeHandler()"
                  class="filter-date datepicker-mb bgcolor"
                ></app-filter-datepicker>

                <app-filter-string
                  *ngIf="!searchFilters.filters[fIndex].type || searchFilters.filters[fIndex].type === 'string'"
                  [(section)]="searchFilters.sections[sIndex]"
                  [(filter)]="searchFilters.filters[fIndex]"
                  (inputChangeEmitter)="inputChangeHandler()"
                  class="filter-ff"
                ></app-filter-string>

                <app-filter-checkbox
                  *ngIf="searchFilters.filters[fIndex].type === 'boolean'"
                  [(section)]="searchFilters.sections[sIndex]"
                  [(filter)]="searchFilters.filters[fIndex]"
                  (inputChangeEmitter)="inputChangeHandler($event)"
                ></app-filter-checkbox>

                <app-filter-select
                  *ngIf="
                    searchFilters.filters[fIndex].type === 'array' &&
                    searchFilters.filters[fIndex].inputType === 'select'
                  "
                  [(section)]="searchFilters.sections[sIndex]"
                  [(filter)]="searchFilters.filters[fIndex]"
                  (inputChangeEmitter)="inputChangeHandler()"
                  class="filter-dd"
                ></app-filter-select>
              </div>
            </div>
          </div>
          <div class="btn-container" *ngIf="isPopup">
            <button class="btn-pop-cancel" type="button" (click)="cancel(); searchFilters.clearAll()" title="Cancel">
              CANCEL
            </button>
            <button class="btn-pop-success" type="submit">APPLY</button>
            <!-- <app-filter-button-panel [(searchFilters)]="searchFilters"></app-filter-button-panel> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="searchFilters && packagepop" class="">
  <div class="">
    <div class="">
      <div class="mt-4">
        <form name="{{ searchFilters.formName }}" (submit)="clickFilter()">
          <div class="" *ngFor="let section of searchFilters.sections; let sIndex = index">
            <div *ngFor="let filter of searchFilters.filters; let fIndex = index">
              <div class="d-flex align-items-center">
                <!-- <label>{{ filter.label }}</label> -->
                <app-filter-string
                  *ngIf="!searchFilters.filters[fIndex].type || searchFilters.filters[fIndex].type === 'string'"
                  [(section)]="searchFilters.sections[sIndex]"
                  [(filter)]="searchFilters.filters[fIndex]"
                  (inputChangeEmitter)="inputChangeHandler()"
                  style="width: 268px"
                  class="filter-ff"
                  [packagepop]="true"
                >
                </app-filter-string>
                <span class="search_icon">
                  <mat-icon (click)="clickFilter()">search</mat-icon>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
