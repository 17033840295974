import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, FormsModule } from '@angular/forms';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { RegexSearchModalComponent } from 'src/app/regex-search-modal/regex-search-modal.component';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-reward-earn-cap',
  templateUrl: './reward-earn-cap.component.html',
  styleUrls: ['./reward-earn-cap.component.scss'],
})
export class RewardEarnCapComponent implements OnInit {
  @Input() earnCap: any;
  periods: any;
  inclusionValues: any;
  faTimes = faTimes;
  faPlus = faPlus;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();
  @ViewChild(RegexSearchModalComponent) regexSearchModalComponent: RegexSearchModalComponent;

  constructor(
    private router: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal
  ) {
    this.debouncer.pipe(debounce(() => interval(1000))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  ngOnInit(): void {
    this.periods = [
      { value: 'CAMPAIGN', text: 'CAMPAIGN' },
      { value: 'YEAR', text: 'YEAR' },
      { value: 'QUARTER', text: 'QUARTER' },
      { value: 'MONTH', text: 'MONTH' },
      { value: 'WEEK', text: 'WEEK' },
      { value: 'DAY', text: 'DAY' },
      { value: 'PLANYEAR', text: 'PLANYEAR' },
      { value: 'CUSTOM', text: 'CUSTOM' },
    ];
  }

  regexSearchModal(
    searchFor: string,
    item: any,
    size: string,
    limitToOne: boolean,
    setValue: boolean,
    itemIndex: number
  ): void {
    this.openRegexSearchModal(searchFor, item, size, limitToOne, setValue, itemIndex);
  }

  openRegexSearchModal(
    searchFor: string,
    item: any,
    size: string,
    limitToOne: boolean,
    setValue: boolean,
    itemIndex: number
  ): void {
    const instance = this.modalHelper.open(RegexSearchModalComponent);
    instance.componentInstance.searchFor = searchFor;
    instance.componentInstance.item = item;
    instance.componentInstance.size = size;
    instance.componentInstance.limitToOne = limitToOne;
    instance.componentInstance.setValue = setValue;
    instance.componentInstance.itemIndex = itemIndex;
    instance.componentInstance.successEvent.subscribe((text: any) => {
      console.log(text);
      console.log(item);
      if (item.objectOfObjectName) {
        this.earnCap[itemIndex][item.objectOfObjectName][item.name] = text;
        //this is hacky, but I am sick of fighting with it.
        this.updateBehCodes(itemIndex, text);
      } else {
        this.earnCap[itemIndex][item.name] = text;
        this.updateCaps();
      }
      instance.close();
    });
  }

  initCapObject(): void {
    console.log(this.earnCap);
    var tmpObj = {
      class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Caps$EarnCap',
    };
    if (this.earnCap) {
      this.earnCap.push(tmpObj);
    } else {
      this.earnCap = [];
      this.earnCap.push(tmpObj);
    }
  }

  updateBehCodes(index: number, event: any) {
    if (!this.earnCap[index].behaviorCodes) {
      this.earnCap[index].behaviorCodes = {};
    }
    this.earnCap[index].behaviorCodes.class =
      'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Caps$EarnCap$BehaviorCodes';
    this.earnCap[index].behaviorCodes.value = event;
    this.updateCaps();
  }

  updateCaps() {
    this.debouncer.next(this.earnCap);
  }

  removeCap(cap: any) {
    this.earnCap.splice(this.earnCap.indexOf(cap), 1);
    this.updateCaps();
  }
}
