<mat-drawer-container class="example-container mat-typography" autosize>
  <mat-drawer
    class="user-side-background-image"
    #drawer
    mode="side"
    disableClose="true"
    opened="true"
    *ngIf="globalData.loggedIn && globalData.appReady"
  >
    <mat-nav-list [ngClass]="{ navWidth: isExpanded, navWidthClose: !isExpanded }">
      <div *ngFor="let navItem of navList; let idx = index">
        <div
          class="nav-head cursor-pointer"
          *ngIf="navItem.pages.length === 0 && navItem.display"
          class="btnhover"
          [ngClass]="{
            btnactive: ((!selectedSubmenu && isExpanded) || !isExpanded) && selectedindex === navItem.index
          }"
          (click)="click(navItem)"
          mat-raised-button
          matTooltip="{{
            navItem.linkDisabled ? 'Please select an organization to continue to ' + navItem.heading : navItem.heading
          }}"
          [matTooltipPosition]="'right'"
        >
          <a
            *ngIf="!navItem.linkDisabled"
            class="nav-link position-relative"
            [routerLink]="navItem.link"
            routerLinkActive="selected"
          >
            <img src="../../assets/svg/{{ navItem.icon }}.svg" />
            <span class="nav-link-text pr-3 pl-3 cursor-pointer" *ngIf="isExpanded">{{ navItem.heading }}</span>
          </a>
          <a *ngIf="navItem.linkDisabled" class="nav-link position-relative" link="#" routerLinkActive="selected">
            <img src="../../assets/svg/{{ navItem.icon }}.svg" />
            <span class="nav-link-text pr-3 pl-3 cursor-pointer" *ngIf="isExpanded">{{ navItem.heading }}</span>
          </a>
        </div>
        <div *ngIf="navItem.pages.length > 0 && navItem.display" [hideToggle]="true" class="mat-elevation-z0">
          <div class="nav-head" (click)="click(navItem)">
            <a [routerLink]="navItem.link" *ngIf="!navItem.linkDisabled" routerLinkActive="selected">
              <div
                class="nav-link d-flex align-items-center justify-content-between btnhover cursor-pointer position-relative"
                [ngClass]="{
                  btnactive: ((!selectedSubmenu && isExpanded) || !isExpanded) && selectedindex === navItem.index
                }"
                mat-raised-button
                matTooltip="{{ navItem.heading }}"
                [matTooltipPosition]="'right'"
              >
                <div class="cursor-pointer">
                  <img class="justify-content-center" src="../../assets/svg/{{ navItem.icon }}.svg" />
                  <span class="nav-link-text pl-3 cursor-pointer" *ngIf="isExpanded">{{ navItem.heading }}</span>
                </div>
                <mat-icon *ngIf="isExpanded" class="nav-link-text">{{
                  icon[navItem.index] ? "keyboard_arrow_up" : "keyboard_arrow_down"
                }}</mat-icon>
              </div>
            </a>
            <a link="#" *ngIf="navItem.linkDisabled" routerLinkActive="selected">
              <div
                class="nav-link d-flex align-items-center justify-content-between btnhover cursor-pointer position-relative"
                [ngClass]="{
                  btnactive: ((!selectedSubmenu && isExpanded) || !isExpanded) && selectedindex === navItem.index
                }"
                mat-raised-button
                matTooltip="Please select an organization to continue to {{ navItem.heading }}"
                [matTooltipPosition]="'right'"
              >
                <div class="cursor-pointer">
                  <img class="justify-content-center" src="../../assets/svg/{{ navItem.icon }}.svg" />
                  <span class="nav-link-text pl-3 cursor-pointer" *ngIf="isExpanded">{{ navItem.heading }}</span>
                </div>
                <mat-icon *ngIf="isExpanded" class="nav-link-text">{{
                  icon[navItem.index] ? "keyboard_arrow_up" : "keyboard_arrow_down"
                }}</mat-icon>
              </div>
            </a>
          </div>
          <div class="nav-section" *ngIf="submenushow[navItem.index] && isExpanded">
            <div *ngFor="let navPage of navItem.pages" class="nav-item">
              <a [routerLink]="navPage.link" routerLinkActive="selected">
                <span
                  class="nav-link btnhover submenuContainer cursor-pointer"
                  *ngIf="navPage.display"
                  (click)="submenuClick(navPage, navItem.index)"
                  [ngClass]="{ btnactive: selectedSubmenu === navPage.title }"
                  >{{ navPage.title }}</span
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </mat-nav-list>
    <button (click)="closeSidenav()" class="collapse-btn btnhover btnfocus">
      <mat-icon aria-label="Menu">{{ isExpanded ? "chevron_left" : "chevron_right" }}</mat-icon
      ><span class="pr-3 pl-3 nav-link-text ml-3" *ngIf="isExpanded">Close menu</span>
    </button>
    <img
      src="../../assets//images/Nav_Background.png"
      class="grapical_design"
      [ngClass]="{ grapical_design_closed: isDisplay && !isExpanded }"
      alt=""
    />
    <div class="user-side-version-false">
      <span class="user-side-version" *ngIf="isExpanded">3.3.006-beta.20240112225740</span>
    </div>
  </mat-drawer>
</mat-drawer-container>
