<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-sm-12 content-container">
      <div class="ibox">
        <div class="ibox-content">
          <ng-template #content let-modal>
            <div class="model_container">
              <div class="model_heading">Activity search</div>
              <div class="body_heading">Apply filters</div>
              <div class="col-lg-12 p-0">
                <div class="">
                  <div>
                    <div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label">Party ID</label>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                name="partyId"
                                [(ngModel)]="partyId"
                                (change)="setFilter('partyId', partyId)"
                                placeholder="Add party ID"
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label">First Name</label>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                name="fName"
                                [(ngModel)]="firstName"
                                (change)="setFilter('fName', firstName)"
                                placeholder="Add name"
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label">Last Name</label>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                name="lName"
                                [(ngModel)]="lastName"
                                (change)="setFilter('lName', lastName)"
                                placeholder="Add name"
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label">Identifier</label>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                name="instrument"
                                [(ngModel)]="instrument"
                                (change)="setFilter('instrument', instrument)"
                                placeholder="Add identifier"
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label">Party Attribute Name</label>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                name="pAttributeName"
                                [(ngModel)]="pAttributeName"
                                (change)="setFilter('pAttributeName', pAttributeName)"
                                placeholder="Add attribute value"
                              />
                              <div class="mt-1">
                                <small class="text-danger" *ngIf="pAttributeValueMessage && !pAttributeName"
                                  >Input party attribute name to be able to search by party attribute</small
                                >
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label">Party Attribute Value</label>
                            <div class="input-group">
                              <input
                                type="search"
                                class="form-control"
                                name="pAttributeValue"
                                [(ngModel)]="pAttributeValue"
                                (change)="setFilter('pAttributeValue', pAttributeValue)"
                                placeholder="Add attribute value"
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label">Segments</label>
                            <div class="">
                              <app-segment-filter
                                (filterSegments)="filterSegmentsHandler($event)"
                                (inputChangeEmitter)="inputChangeHandler()"
                              >
                              </app-segment-filter>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="d-flex justify-content-between">
                              <div class="">
                                <input
                                  type="radio"
                                  name="rewardStatus"
                                  id="rewardsAndNoRewards"
                                  [(ngModel)]="rewardStatusRadio"
                                  (change)="setFilter('rewarded', 2); disableInvalidFilters('rewarded', 2)"
                                  [value]="2"
                                  class="mr-2"
                                />
                                <label for="rewardsAndNoRewards"> All </label>
                              </div>
                              <div class="">
                                <input
                                  type="radio"
                                  name="rewardStatus"
                                  id="rewardOnly"
                                  [(ngModel)]="rewardStatusRadio"
                                  (change)="setFilter('rewarded', 1); disableInvalidFilters('rewarded', 1)"
                                  [value]="1"
                                  class="mr-2"
                                />
                                <label for="rewardOnly"> Rewarded Only </label>
                              </div>
                              <div class="">
                                <input
                                  type="radio"
                                  name="rewardStatus"
                                  id="noRewards"
                                  [(ngModel)]="rewardStatusRadio"
                                  (change)="setFilter('rewarded', 0); disableInvalidFilters('rewarded', 0)"
                                  [value]="0"
                                />
                                <label for="NoRewards" class="ml-2"> Not Rewarded </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label">Keyword</label>
                            <div class="input-group">
                              <input
                                type="text"
                                name="keyword"
                                class="form-control"
                                [(ngModel)]="keyword"
                                (change)="setFilter('keyword', keyword)"
                                placeholder="Add keyword"
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="input-group">
                              <div class="row">
                                <div class="col-md-6">
                                  <label class="control-label">Activity Start</label>
                                  <input
                                    class="cursor-pointer form-control bg_icon"
                                    (click)="aFromDate.toggle()"
                                    (ngModelChange)="setFilter('aFrom', $event, true)"
                                    placeholder="From:"
                                    name="aFromDate"
                                    ngbDatepicker
                                    #aFromDate="ngbDatepicker"
                                    [(ngModel)]="aFrom"
                                    [footerTemplate]="aFromTemplate"
                                  />
                                  <ng-template #aFromTemplate>
                                    <div class="width-100 datePickerTemplate">
                                      <button
                                        class="buttonDateTemplate"
                                        (click)="setFilter('aFrom', null); aFromDate.toggle()"
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </ng-template>
                                </div>
                                <div class="col-md-6">
                                  <label class="control-label">Activity End</label>
                                  <input
                                    class="cursor-pointer form-control bg_icon"
                                    (click)="aThruDate.toggle()"
                                    placeholder="To:"
                                    (ngModelChange)="setFilter('aThru', $event, true)"
                                    name="aThruDate"
                                    ngbDatepicker
                                    #aThruDate="ngbDatepicker"
                                    [(ngModel)]="aThru"
                                    [footerTemplate]="aThruTemplate"
                                  />
                                  <ng-template #aThruTemplate>
                                    <div class="width-100 datePickerTemplate">
                                      <button
                                        class="buttonDateTemplate"
                                        (click)="setFilter('aThru', null); aThruDate.toggle()"
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </ng-template>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label">Activity created on dates</label>
                            <div class="input-group">
                              <div class="row">
                                <div class="col-md-6">
                                  <input
                                    class="cursor-pointer form-control bg_icon"
                                    (click)="cFromDate.toggle()"
                                    (ngModelChange)="setFilter('cFrom', $event, true)"
                                    placeholder="From:"
                                    name="cFromDate"
                                    ngbDatepicker
                                    #cFromDate="ngbDatepicker"
                                    [(ngModel)]="cFrom"
                                    [footerTemplate]="cFromTemplate"
                                  />
                                  <ng-template #cFromTemplate>
                                    <div class="width-100 datePickerTemplate">
                                      <button
                                        class="buttonDateTemplate"
                                        (click)="setFilter('cFrom', null); cFromDate.toggle()"
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </ng-template>
                                </div>
                                <div class="col-md-6">
                                  <input
                                    class="cursor-pointer form-control bg_icon"
                                    (click)="cThruDate.toggle()"
                                    placeholder="To:"
                                    (ngModelChange)="setFilter('cThru', $event, true)"
                                    name="cThruDate"
                                    ngbDatepicker
                                    #cThruDate="ngbDatepicker"
                                    [(ngModel)]="cThru"
                                    [footerTemplate]="cThruTemplate"
                                  />
                                  <ng-template #cThruTemplate>
                                    <div class="width-100 datePickerTemplate">
                                      <button
                                        class="buttonDateTemplate"
                                        (click)="setFilter('cThru', null); cThruDate.toggle()"
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </ng-template>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label">Behavior Code</label>
                            <div class="input-group">
                              <input
                                type="search"
                                class="form-control"
                                name="bcode"
                                [(ngModel)]="bCode"
                                (change)="setFilter('bCode', bCode)"
                                placeholder="Select behavior code"
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label">Activity Attribute Name</label>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                name="aAttributeName"
                                [(ngModel)]="aAttributeName"
                                (change)="setFilter('aAttributeName', aAttributeName)"
                                placeholder="Select attribute name"
                              />
                              <small class="text-danger" *ngIf="aAttributeValueMessage && !aAttributeName">
                                Input activity attribute name to be able to search by activity attribute</small
                              >
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label">Activity Attribute Value</label>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                name="aAttributeValue"
                                [(ngModel)]="aAttributeValue"
                                (change)="setFilter('aAttributeValue', aAttributeValue)"
                                placeholder="Add attribute value"
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label">Import Channel</label>
                            <div class="">
                              <ng-select
                                [items]="importChannels"
                                bindLabel="name"
                                bindValue="value"
                                name="importChannel"
                                [(ngModel)]="select.importChannel"
                                (change)="setFilter('importChannel', select.importChannel)"
                                theme="bootstrap"
                                placeholder="Import channel"
                              >
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group" *ngIf="disableRewardFilters">
                            <label class="control-label">Reward Type</label>
                            <div class="">
                              <ng-select
                                [(ngModel)]="select.rewardTypes"
                                [items]="rewardTypesFilter"
                                bindValue="id"
                                bindLabel="code"
                                theme="bootstrap"
                                name="rewardType"
                                (change)="setFilter('rewardType', select.rewardTypes)"
                                placeholder="Select reward type"
                              >
                              </ng-select>
                            </div>
                          </div>
                          <div class="form-group" *ngIf="disableRewardFilters">
                            <label class="control-label">Reward amount range</label>
                            <div class="d-flex">
                              <div class="col-5 p-0">
                                <div class="input-group">
                                  <input
                                    type="text"
                                    name="rewardAmtFrom"
                                    class="form-control"
                                    [(ngModel)]="rewardAmtFrom"
                                    (change)="setFilter('rewardAmtFrom', rewardAmtFrom)"
                                    placeholder="Select range"
                                  />
                                </div>
                              </div>
                              <div class="col-2 p-0 d-flex justify-content-center align-items-center">
                                <span>and</span>
                              </div>
                              <div class="col-5 p-0">
                                <div class="input-group">
                                  <input
                                    type="search"
                                    name="rewardAmtTo"
                                    class="form-control"
                                    [(ngModel)]="rewardAmtTo"
                                    (change)="setFilter('rewardAmtTo', rewardAmtTo)"
                                    placeholder="Select range"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group" *ngIf="disableRewardFilters">
                            <div class="input-group d-flex align-items-center">
                              <input
                                class="checkbox checkbox-info"
                                name="includeReversed"
                                id="includeReversed"
                                [(ngModel)]="includeReversed"
                                (change)="setFilter('includeReversed', includeReversed)"
                                type="checkbox"
                              />
                              <span class="ml-2 control-label">Include reversed</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group" *ngIf="disableRewardFilters">
                            <label
                              class="control-label"
                              title="Note: You need to select a Program and at least one promotion for this to filter correctly. It does not filter by program alone."
                            >
                              Filter by Promotion</label
                            >
                            <div class="">
                              <ng-select
                                [(ngModel)]="select.program"
                                [items]="programs"
                                [closeOnSelect]="true"
                                name="programs"
                                placeholder="Select a Program first..."
                                [clearable]="false"
                                (change)="setPromotionsFilter($event.id)"
                                bindLabel="name"
                                bindValue="id"
                              >
                              </ng-select>
                            </div>
                            <div class="" style="margin-top: 43px">
                              <ng-select
                                [(ngModel)]="select.promotions"
                                name="promotions"
                                placeholder="Select promotions"
                                [multiple]="true"
                                bindLabel="code"
                                [disabled]="!select.program"
                                [items]="promotionsFilter"
                                bindValue="id"
                                [closeOnSelect]="false"
                              >
                              </ng-select>
                            </div>
                          </div>
                          <div class="form-group" *ngIf="disableRewardFilters">
                            <label class="control-label">Promotion Type</label>
                            <div class="">
                              <ng-select
                                name="promotionTypes"
                                [(ngModel)]="select.promotionTypes"
                                [items]="promotionTypesFilter"
                                bindLabel="name"
                                bindValue="id"
                                name="promotionType"
                                (change)="setFilter('promotionType', select.promotionTypes)"
                                placeholder="Select promotion type"
                              >
                              </ng-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="display: flex; justify-content: flex-end; flex-direction: row; gap: 10px">
                        <button type="Cancel" class="btn-pop-cancel" (click)="cancel()">Cancel</button>
                        <button type="submit" class="btn-pop-success" [disabled]="isLoading" (click)="applyFilter()">
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <div class="m-b">
            <h2 class="fullname">Activity search</h2>
          </div>
          <div class="filters_container">
            <div class="search-input2">
              <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
              <mat-icon class="search">search</mat-icon>
            </div>
            <div class="right_container2">
              <button class="filter-btn" (click)="open(content)">
                FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
              </button>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3 float-left">
              <button
                class="btn btn-sm btn-primary float-left"
                (click)="triggerProcessing()"
                [disabled]="selectedActivities.length === 0"
              >
                <span class="float-right" *ngIf="selectedActivities.length > 0">({{ selectedActivities.length }})</span>
                <span>
                  <fa-icon [icon]="faCheckCircle"></fa-icon>
                </span>
                Re-submit selected for processing
              </button>
            </div>
            <div class="col-md-3 offset-md-6 float-right" *ngIf="isProgramManager">
              <button
                class="btn btn-sm btn-primary float-right"
                (click)="triggerProcessingOfAllMatching()"
                [disabled]="selectedActivities.length > 0 || !searchParamsApplied"
              >
                <span>
                  <fa-icon [icon]="faFireExtinguisher"></fa-icon>
                </span>
                Re-submit all matching for processing
              </button>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3 offset-md-9 float-left" *ngIf="isProgramManager">
              <button
                class="btn btn-sm btn-primary float-right"
                (click)="triggerReversalOfAllMatching()"
                [disabled]="selectedActivities.length > 0 || !searchParamsApplied"
              >
                <span>
                  <fa-icon [icon]="faHistory"></fa-icon>
                </span>
                Reverse all matching
              </button>
            </div>
          </div>

          <!-- CHIP: -->

          <div style="margin-bottom: 10px">
            <mat-chip-list *ngIf="chipDisplay">
              <mat-chip class="matchips" (click)="clearAllChip()">
                <span>Clear filters</span>
              </mat-chip>
              <div *ngFor="let item of filter | keyvalue" style="display: flex">
                <mat-chip class="matchips" *ngIf="chipConditionalrendering(item.key)">
                  <span>{{ item.key }}: {{ item.value }}</span>
                  <mat-icon class="matcancel" matChipRemove (click)="removeChip(item.key)">cancel</mat-icon>
                </mat-chip>
              </div>
            </mat-chip-list>
          </div>

          <div class="row mb-1" style="padding-left: 14px; padding-right: 14px">
            <table class="table table-pointable table-bordered full-width">
              <thead>
                <tr>
                  <th class="width-60px">
                    <div class="checkbox checkbox-info float-right mt-0 mb-0" style="display: inline-block; width: 50%">
                      <input
                        id="select-all-cb"
                        [(ngModel)]="checkAll"
                        type="checkbox"
                        class="proc-check"
                        (change)="selectAllCb()"
                      />
                      <label for="select-all-cb"></label>
                    </div>
                  </th>
                  <th>ID</th>
                  <th>Party ID</th>
                  <th>Activity Date</th>
                  <th class="width-30">Ref Num</th>
                  <th class="width-20">Behavior Code</th>
                  <th class="width-7-rem">Amount</th>
                  <th class="width-8-rem">Reward</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let activity of activities | filter : searchText; let i = index">
                  <tr (click)="selectActivity(activity, i); toggle(activity.id)">
                    <td>
                      <div style="width: 100%; margin: 0; padding: 0; white-space: nowrap">
                        <span
                          style="display: inline-block; width: 50%"
                          *ngIf="
                            activity.rewardNumber > 0 || activity.processingCount > 0 || activity.attributesCount > 0
                          "
                        >
                          <fa-icon [icon]="expandedLookup[activity.id] ? faChevronDown : faChevronRight"> </fa-icon>
                        </span>
                        <div
                          class="checkbox checkbox-info float-right mt-0 mb-0"
                          style="display: inline-block; width: 50%"
                        >
                          <input
                            id="cb-{{ activity.id }}"
                            class="proc-check"
                            type="checkbox"
                            [(ngModel)]="isSelected[activity.id]"
                            (change)="selectOneCb(activity.id)"
                          />
                          <label for="cb-{{ activity.id }}"></label>
                        </div>
                      </div>
                    </td>
                    <td>{{ activity.id }}</td>
                    <td>
                      <a href="/members/{{ activity.partyId }}">{{ activity.partyId }}</a>
                    </td>
                    <td>{{ activity.activityDateStr }}</td>
                    <td>{{ activity.referenceNumber }}</td>
                    <td>{{ activity.behaviorCode }}</td>
                    <td>
                      <span>{{ activity.tenderAmount }}</span>
                    </td>
                    <td>
                      <span>
                        <strong>{{ activity.rewardAmount }}</strong>
                      </span>
                      <span class="label table-label label-info float-right">
                        <strong
                          >{{ activity.rewardNumber }}
                          <strong>
                            <fa-icon [icon]="faTrophy"></fa-icon>
                          </strong>
                        </strong>
                      </span>
                    </td>
                  </tr>
                  <tr
                    class="additional-data"
                    *ngIf="expandedLookup[activity.id] && activityDetails[i].rewards.length > 0"
                  >
                    <td class="no-padding" colspan="8">
                      <table class="reward-log table table-pointable additional">
                        <thead>
                          <tr class="align-text-left">
                            <td><b>Rewards:</b></td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let reward of activityDetails[i].rewards" class="align-text-left">
                            <td>Code: {{ reward.promotion.code }}</td>
                            <td>Sponsor ID: {{ reward.promotionSponsor.id }}</td>
                            <td>Date: {{ reward.rewardDateStr }}</td>
                            <td>Type: {{ reward.rewardType.code }}</td>
                            <td>
                              Amount: <strong>{{ reward.amount }}</strong>
                            </td>
                            <td>Amt/Quantity: {{ activity.tenderAmount }}</td>
                            <td>Notes: {{ reward.notes }}</td>
                            <td *ngIf="reward.reversedBy">
                              Reversed By: {{ reward.reversedB }} On reward.reversedOnStr | date : 'yyyy-MM-dd'
                            </td>
                            <td *ngIf="!reward.reversedBy">
                              <a href="" (click)="confirmReversal(i, reward.id, $event)">reverse</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr
                    class="additional-data"
                    *ngIf="expandedLookup[activity.id] && activityDetails[i].attributes.length > 0"
                  >
                    <td class="no-padding" colspan="8">
                      <table class="attribute-log table table-pointable additional">
                        <thead>
                          <tr class="align-text-left">
                            <td><b>Attributes:</b></td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let attribute of activityDetails[i].attributes" class="align-text-left">
                            <td>Name: {{ attribute.attrName }}</td>
                            <td>Value: {{ attribute.attrValue }}</td>
                            <td>Created On: {{ attribute.createdOn | date : "yyyy-MM-dd" }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr
                    class="additional-data"
                    *ngIf="expandedLookup[activity.id] && activityDetails[i].logs?.length > 0"
                  >
                    <td class="no-padding" colspan="8">
                      <table class="processing-log table table-pointable additional">
                        <thead>
                          <tr class="align-text-left">
                            <td><b>Processing Logs:</b></td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let log of activityDetails[i].logs" class="align-text-left">
                            <td>
                              Processing Log:
                              <a href="/processing-log/{{ log.id }}?activityId={{ selectedActivityId }}">{{
                                log.id
                              }}</a>
                            </td>
                            <td>Proc Code: {{ log.procCode }}</td>
                            <td>Status: {{ log.status }}</td>
                            <td>Started On: {{ log.createdOn | date : "yyyy-MM-dd" }}</td>
                            <td>Finished On: {{ log.createdOn | date : "yyyy-MM-dd" }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </ng-container>
                <tr app-is-there-more [newLengthEvent]="newLengthEvent" [numColumns]="8"></tr>
              </tbody>
            </table>
          </div>
          <app-add-more-data-to-list (onScroll)="getActivities(true)" [lock]="lock" [isLoading]="isLoading">
          </app-add-more-data-to-list>
        </div>
      </div>
    </div>
  </div>
</div>
