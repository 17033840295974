<div>
  <form>
    <label>Send communication:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="temporalConditions !== null">
        <div
          *ngFor="let temporalCondition of temporalConditions; let i = index"
          class="border-card-padding form-group col-lg-12"
        >
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeRestriction(temporalCondition)"
            title="Remove Restriction"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form #form="ngForm" name="form_{{ i }}">
            <div class="form-group">
              <input
                required
                class="form-control m-b"
                type="number"
                min="1"
                id="periodCount_{{ i }}"
                name="periodCount_{{ i }}"
                [(ngModel)]="temporalCondition.periodCount"
                (ngModelChange)="updateRestrictions()"
                (keypress)="keyPressNumbers($event)"
              />
              <div
                class="mt-sm"
                *ngIf="temporalCondition.periodCount === undefined || temporalCondition.periodCount === null"
              >
                <small class="text-danger">Input period count</small>
              </div>
            </div>
            <div class="form-group">
              <select
                required
                class="form-control styled-select"
                name="period{{ i }}"
                [(ngModel)]="temporalCondition.period"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let period of periods"
                  [selected]="temporalCondition.period === period.value"
                  [value]="period.value"
                >
                  {{ period.text }}
                </option>
              </select>
              <div class="mt-sm" [hidden]="temporalCondition.period">
                <small class="text-danger">Select period</small>
              </div>
            </div>
            <div class="form-group">
              <label>Window:</label>
              <div class="row">
                <div [ngClass]="temporalCondition.period !== undefined ? 'col-md-5' : 'col-md-6'">
                  <div class="row">
                    <label for="deltaMinus_{{ i }}" class="col-sm-3 col-form-label">Minus</label>
                    <div class="col-md-9">
                      <input
                        class="form-control m-b"
                        type="number"
                        id="deltaMinus_{{ i }}"
                        name="deltaMinus_{{ i }}"
                        [(ngModel)]="temporalCondition.deltaMinus"
                        (ngModelChange)="updateRestrictions()"
                        (keypress)="keyPressNumbers($event)"
                      />
                    </div>
                  </div>
                </div>
                <div [ngClass]="temporalCondition.period !== undefined ? 'col-md-5' : 'col-md-6'">
                  <div class="row">
                    <label for="deltaPlus_{{ i }}" class="col-sm-3 col-form-label">Plus</label>
                    <div class="col-md-9">
                      <input
                        class="form-control m-b"
                        type="number"
                        id="deltaPlus_{{ i }}"
                        name="deltaPlus_{{ i }}"
                        [(ngModel)]="temporalCondition.deltaPlus"
                        (ngModelChange)="updateRestrictions()"
                        (keypress)="keyPressNumbers($event)"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-form-label" *ngIf="temporalCondition.period">
                  <ng-template [ngIf]="temporalCondition.period === 'HOUR'" [ngIfElse]="elseTemplate">
                    {{ temporalCondition.period }}
                  </ng-template>
                  <ng-template #elseTemplate> {{ temporalCondition.period }}S </ng-template>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Receipt of the following:</label>
              <select
                required
                class="form-control styled-select"
                name="timeFromEventType_{{ i }}"
                [(ngModel)]="temporalCondition.timeFromEventType"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let type of timeFromEventType"
                  [selected]="temporalCondition.timeFromEventType === type"
                  [value]="type"
                >
                  {{ type }}
                </option>
              </select>
              <div class="mt-sm" [hidden]="temporalCondition.timeFromEventType">
                <small class="text-danger">Select Time From Event Type</small>
              </div>
            </div>
            <ng-container
              *ngIf="
                temporalCondition.timeFromEventType === 'ATTRIBUTE_EFFECTIVE' ||
                temporalCondition.timeFromEventType === 'ATTRIBUTE_EXPIRATION'
              "
            >
              <div class="form-group">
                <label class="control-label" for="attrName_{{ temporalCondition.attrName }}">Attribute Name:</label>
                <input
                  class="form-control m-b"
                  type="text"
                  id="attrName_{{ temporalCondition.attrName }}"
                  name="attrName"
                  [(ngModel)]="temporalCondition.attrName"
                  (ngModelChange)="updateRestrictions()"
                />
              </div>
              <div class="form-group">
                <label class="control-label" for="attrValue_{{ temporalCondition.attrName }}">Attribute Value:</label>
                <input
                  class="form-control m-b"
                  type="text"
                  id="attrValue_{{ temporalCondition.attrName }}"
                  name="attrValue"
                  [(ngModel)]="temporalCondition.attrValue"
                  (ngModelChange)="updateRestrictions()"
                />
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                temporalCondition.timeFromEventType === 'PREFERENCE_EFFECTIVE' ||
                temporalCondition.timeFromEventType === 'PREFERENCE_EXPIRATION'
              "
            >
              <div class="form-group">
                <label class="control-label" for="endpointType{{ i }}">A usable:</label>
                <select
                  name="endpointType{{ i }}"
                  class="form-control styled-select"
                  [(ngModel)]="temporalCondition.endpointType"
                  (ngModelChange)="updateRestrictions()"
                >
                  <option
                    *ngFor="let endpointType of endpointTypes"
                    [selected]="temporalCondition.endpointType === endpointType.name"
                    [value]="endpointType.name"
                  >
                    {{ endpointType.name }}
                  </option>
                </select>
              </div>
            </ng-container>
            <ng-container *ngIf="temporalCondition.timeFromEventType === 'BEHAVIOR'">
              <div class="form-group">
                <label class="control-label" for="behaviorCode{{ temporalCondition.behaviorCode }}"
                  >Behavior Code:</label
                >
                <input
                  class="form-control m-b"
                  type="text"
                  id="behaviorCode{{ temporalCondition.behaviorCode }}"
                  name="behaviorCode"
                  [(ngModel)]="temporalCondition.behaviorCode"
                  (ngModelChange)="updateRestrictions()"
                />
              </div>
            </ng-container>
            <ng-container *ngIf="temporalCondition.timeFromEventType === 'REWARD'">
              <div class="form-group">
                <label class="control-label" for="promotionCode{{ temporalCondition.promotionCode }}"
                  >Promotion Code:</label
                >
                <input
                  class="form-control m-b"
                  type="text"
                  id="promotionCode{{ temporalCondition.promotionCode }}"
                  name="promotionCode"
                  [(ngModel)]="temporalCondition.promotionCode"
                  (ngModelChange)="updateRestrictions()"
                />
              </div>
            </ng-container>
            <div class="form-group">
              <label class="control-label mb-2">Message:</label>
              <textarea
                type="text"
                class="form-control"
                rows="6"
                [(ngModel)]="temporalCondition.message"
                name="message"
                (ngModelChange)="updateRestrictions()"
              ></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group float-right col-md-12">
        <button class="btn btn-default float-right" (click)="initRestrictionObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Temporal Condition
        </button>
      </div>
    </div>
  </form>
</div>
