import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { EventSourcesService } from 'src/app/services/event-sources.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-new-event-source-measure-modal',
  templateUrl: './new-event-source-measure-modal.component.html',
  styleUrls: ['./new-event-source-measure-modal.component.scss'],
})
export class NewEventSourceMeasureModalComponent implements OnInit {
  @Input() selectedSource: any;
  @Input() units: any[] = [];
  @Input() temporalUnits: any[] = [];
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  measureForm: UntypedFormGroup;
  errorMsg = '';
  faTimes = faTimes;

  constructor(
    private responseHelper: ResponseHelperService,
    private modalHelper: NgbModal,
    private eventSourcesService: EventSourcesService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.title = 'New Base Identity Measure';
    this.measureForm = this.formBuilder.group({
      measureName: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      temporalUnitCode: [null, Validators.required],
      unit: [null, Validators.required],
    });
  }

  ok(): void {
    this.selectedSource.measuresCollection.push({ ...this.measureForm.value, functionName: 'IDENT' });
    this.eventSourcesService.updateEventSource(this.selectedSource).subscribe(
      (data: any) => {
        this.successEvent.emit(data);
      },
      (data: any) => {
        this.responseHelper.error(this, data.error.error);
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
