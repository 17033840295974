import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationsModalComponent } from '../communications-modal/communications-modal.component';
import { Choice, Filter, SearchFilters } from '../data/class';
import { Communication, DeliveryProtocol } from '../data/model';
import { CommunicationsService } from '../services/communications.service';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { TableHelperService } from '../services/helpers/table-helper.service';
import {
  faPlus,
  faEdit,
  faEye,
  faCircle,
  faObjectGroup,
  faPencilAlt,
  faCog,
  faEnvelope,
  faHome,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { UserPreferencesService } from '../services/user-preferences.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ChirpService } from '../services/chirp.service';
import { ToastrService } from 'ngx-toastr';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
import { selectRow } from '../data/selectRow';

@Component({
  selector: 'app-communication-management',
  templateUrl: './communication-management.component.html',
  styleUrls: ['./communication-management.component.scss'],
})
export class CommunicationManagementComponent implements OnInit, OnDestroy {
  limit: number;
  dropdown: any;
  filter = new Filter();
  searchFilters: SearchFilters;
  statuses: Choice[];
  communications: Communication[];
  lock = false;
  isLoading = false;
  lengthToCompare: number;
  selectedComm: Communication;
  faPlus = faPlus;
  lockedTables = false;
  commId: number;
  deliveryProtocols: DeliveryProtocol[];
  statusHelper: any;
  faEdit = faEdit;
  faEye = faEye;
  faCircle = faCircle;
  faObjectGroup = faObjectGroup;
  faPencilAlt = faPencilAlt;
  faCog = faCog;
  faEnvelope = faEnvelope;
  faHome = faHome;
  faPhone = faPhone;
  allIcons = fas;
  showSearchFilter = false;
  colors: string[] = [
    'program-card-bottom-yellow',
    'program-card-bottom-red',
    'program-card-bottom-blue',
    'program-card-bottom-green',
  ];
  appliedFilters: Filter[];
  filterButton: boolean = false;
  filterdata: SearchFilters;
  helpers: any;

  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private communicationsService: CommunicationsService,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private chirpService: ChirpService,
    private statusHelperService: StatusHelperService,
    private modalHelper: NgbModal,
    private userPreferences: UserPreferencesService,
    private toastr: ToastrService,
    private applyfilterService: ApplyFilterService
  ) {}
  ngOnDestroy() {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }
  ngOnInit(): void {
    this.statusHelper = this.statusHelperService.getStatus('badge');
    this.communicationsService.getAllEndpointTypes(false).subscribe((response: any) => {
      this.dropdown = response.entity;
      this.searchFilters = {
        formName: 'communications_search',
        filters: [
          {
            name: 'search',
            placeholder: 'Add keyword',
            label: 'Keyword',
          },
          {
            name: 'statuses',
            placeholder: 'Select status',
            type: 'array',
            label: 'Status',
            choices: this.tableHelperService.getStatuses('DROPDOWN'),
            nameProp: 'value',
            valueProp: 'value',
            inputType: 'dropdown',
            value: this.userPreferences.service.getPreference('communications.defaultFilterValues.status'),
          },
          {
            name: 'endpointTypeNames',
            placeholder: 'Select endpoint',
            type: 'array',
            label: 'Endpoint',
            choices: this.dropdown,
            nameProp: 'name',
            valueProp: 'name',
            inputType: 'dropdown',
            section: { key: 'firstRow' },
          },
          {
            name: 'communicationName',
            placeholder: 'Add name',
            label: 'Name',
            section: { key: 'firstRow' },
          },
          {
            name: 'ap',
            type: 'boolean',
            label: 'Require preference',
            section: { key: 'secondRow' },
          },
        ],
        searchCallback: () => {
          this.initNewSearch();
        },
      };
      this.initNewSearch();
    });
  }

  initFilter(): void {
    this.limit = 20;
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
    this.filter.withoutParent = true;
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  newCommunication(): void {
    const instance = this.modalHelper.open(CommunicationsModalComponent);
    instance.componentInstance.successEvent.subscribe(() => {
      this.initNewSearch();
      instance.close();
    });
  }

  communicationsModal(selectedComm: Communication): void {
    const instance = this.modalHelper.open(CommunicationsModalComponent);
    instance.componentInstance.communication = selectedComm;
    instance.componentInstance.successEvent.subscribe(() => {
      this.initNewSearch();
      instance.close();
    });
  }

  getCommunication(): void {
    this.communicationsService.getCommunication(this.activatedRoute.snapshot.params.id).subscribe((data: any) => {
      if (data.success) {
        this.selectedComm = data.entity;
        this.commId = this.selectedComm.id;
        this.title.setTitle(this.selectedComm.name);
        this.chirpService.getDeliveryProtocols(this.commId).subscribe((resp: any) => {
          this.deliveryProtocols = resp.entity;
        });
      }
    });
  }
  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };

  getCommunications(concat: boolean): void {
    this.filter = this.getFilters(this.filter);
    this.isLoading = true;
    this.communicationsService.getCommunications(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.communications = this.communications.concat(data.entity.records);
        } else {
          this.selectedComm = undefined;
          this.communications = data.entity.records;
        }
        this.lengthToCompare = data.entity.records.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.records.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.isLoading = false;
        this.lock = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getCommunications(false);
  }

  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Outreach plan';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(this.filterdata);
    });
  }

  showCommunication(event: MouseEvent, c: Communication): void {
    selectRow(event);
    this.selectedComm = c;
  }

  show(): boolean {
    if (this.selectedComm !== undefined) {
      return true;
    }
    return false;
  }
}
