import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  faCircle,
  faCog,
  faDownload,
  faList,
  faPencilAlt,
  faPlus,
  faTh,
  faTrash,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { Program, CommunicationStrategyConfiguration } from 'src/app/data/model';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ProgramService } from 'src/app/services/program.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { ProgramModalComponent } from 'src/app/program-modal/program-modal.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { ProgramImportModalComponent } from 'src/app/program-import-modal/program-import-modal.component';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { saveAs } from 'src/app/data/saveAs';
import { selectRow } from 'src/app/data/selectRow';

@Component({
  selector: 'app-program-tab',
  templateUrl: './program-tab.component.html',
  styleUrls: ['./program-tab.component.scss'],
})
export class ProgramTabComponent implements OnInit, OnDestroy {
  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  searchFilters: SearchFilters;
  statuses: Choice[];
  programs: Program[];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  show: string;
  selectedProgram: Program;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  faTh = faTh;
  faList = faList;
  faDownload = faDownload;
  faUpload = faUpload;
  faCog = faCog;
  faCircle = faCircle;
  allIcons = fas;
  showMoreButton = { name: 'Show More', active: true };
  fileNamePlease = { name: '', success: false };
  lockedTables: boolean;
  programIsInUse: Program;
  configurations: CommunicationStrategyConfiguration[] = [];
  colors: string[] = [
    'program-card-bottom-yellow',
    'program-card-bottom-red',
    'program-card-bottom-blue',
    'program-card-bottom-green',
  ];
  selectedColor: string;
  colorIndex = -1;
  anthemPromoView: any;
  searchText: any;
  filteredStatuses: Filter[] = [];
  statusHelper: any;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  helpers: any;
  filterdata: SearchFilters;
  filterButton: boolean = false;
  appliedFilters: Filter[];
  nametag: string;
  programspop: boolean = false;

  constructor(
    private tableHelperService: TableHelperService,
    private programService: ProgramService,
    private toastrService: ToastrService,
    private statusHelperService: StatusHelperService,
    private modalHelper: NgbModal,
    private toastr: ToastrService,
    private applyfilterService: ApplyFilterService
  ) {}
  ngOnDestroy() {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }
  ngOnInit(): void {
    this.statusHelper = this.statusHelperService.getStatus('badge');
    this.show = 'LIST';
    this.searchFilters = {
      formName: 'programSearch',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Product',
          if: true,
          callback: () => {
            this.programModal();
          },
          title: 'New Product',
        },
        {
          icon: faUpload,
          buttonText: 'Import Product',
          if: true,
          callback: () => {
            this.importProgramModal();
          },
          title: 'Import Product',
        },
      ],
    };
    this.initNewSearch();
  }

  getIcon(program: Program): any {
    if (program.imageUrl) {
      const iconName = program.imageUrl
        .split('-')
        .map((word: string, index: number) =>
          index === 0 ? word : word.substring(0, 1).toUpperCase() + word.substring(1)
        )
        .join('');
      return this.allIcons[iconName] || this.allIcons.faStar;
    }
    return this.allIcons.faStar;
  }

  // TODO:
  initProgramFilter(): void {
    // this.security.anthemPromoView().subscribe(resp=> {
    //   this.anthemPromoView = resp;
    //   this.programFilter['includeCounts'] = this.anthemPromoView ? !this.anthemPromoView : true;
    // });
  }

  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Products';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(this.filterdata);
    });
  }

  exportProgram(id: number): void {
    this.programService.exportProgram(id).subscribe((response: any) => {
      const blob = new Blob([JSON.stringify(response)], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, `Product-${response.program.code}.json`);
    });
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getPrograms(false);
  }

  clearAllFilters(): void {
    this.filteredStatuses = [];
    this.setStatuses();
    this.filter.search = null;
    this.filter = new Filter();
    this.initFilter();
    this.getPrograms(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };

  getPrograms(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);
    this.programService.getAllPrograms(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.programs = this.programs.concat(data.entity.records);
        } else {
          this.selectedProgram = null;
          this.programs = data.entity.records;
        }
        this.lengthToCompare = data.entity.records.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.records.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.isLoading = false;
        this.lock = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getPrograms(false);
    this.configurations = null;
  }

  getNextColor(): string {
    this.colorIndex++;
    if (this.colorIndex === this.colors.length) {
      this.colorIndex = 0;
    }
    return this.colors[this.colorIndex];
  }

  showProgram(event: MouseEvent, program: Program, index: number): void {
    selectRow(event);
    this.programService.getProgram(program.id, { includeCounts: true }).subscribe((data: any) => {
      if (data.success) {
        this.selectedProgram = data.entity;
        this.selectedColor = this.colors[index % 4];
      }
    });
  }

  deleteProgram(program: Program): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete program type with ID: ' + program.id;
    instance.componentInstance.header = 'Delete confirmation';
    instance.componentInstance.nametag = program.name;
    instance.componentInstance.id = program.id;
    instance.componentInstance.programspop = !this.programspop;
    instance.componentInstance.successEvent.subscribe(() => {
      this.programService.deleteProgram(program.id).subscribe(() => {
        this.toastrService.success('Product successfully removed');
        this.initFilter();
        this.getPrograms(false);
        instance.close();
      });
    });
  }

  programModal(program?: Program): void {
    const instance = this.modalHelper.open(ProgramModalComponent);
    if (program) {
      if (program.imageUrl) {
        instance.componentInstance.icon = this.getIcon(program);
      } else {
        instance.componentInstance.icon = this.allIcons.faHeart;
      }
      instance.componentInstance.program = program;
    } else {
      instance.componentInstance.icon = this.allIcons.faHeart;
    }
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data) {
        this.toastrService.success(`Product successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`);
        this.initNewSearch();
        instance.close();
      }
    });
  }

  importProgramModal(): void {
    const instance = this.modalHelper.open(ProgramImportModalComponent);
    instance.componentInstance.successEvent.subscribe(() => {
      if (instance.componentInstance.fileNamePlease.success) {
        this.toastrService.success(`Product successfully imported`);
        this.initNewSearch();
      }
      instance.close();
    });
  }
}
