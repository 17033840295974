<form [formGroup]="deliveryProtocolForm" class="com-model">
  <div class="com-outreach-modal">
    <div>
      <div>
        <span class="title">Delivery</span>
        <hr class="mb-0" />
      </div>
      <div class="mb-3 mt-3">
        <span class="com-title">{{ title }}</span>
      </div>
    </div>

    <div class="">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{ errorMsg }}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="com-label">Protocol name</label>
            <input type="text" formControlName="name" class="form-control" placeholder="Add name" />
            <div class="mt-sm" [hidden]="deliveryProtocolForm.controls['name'].valid">
              <!-- <small class="text-danger">Input protocol name</small> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="com-label">Endpoint type</label>
            <ng-select
              class="inputfontsize"
              [items]="endpointTypes"
              bindLabel="name"
              formControlName="endpointType"
              [hideSelected]="true"
              placeholder="Select status (0)"
            >
            </ng-select>
            <div class="mt-xs" [hidden]="deliveryProtocolForm.controls.endpointType.valid">
              <!-- <small class="text-danger">Select type</small> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="deliveryProtocolForm.value.endpointType?.name === 'EMAIL'">
        <div class="col-sm-12">
          <div class="radio radio-primary radio-inline">
            <input formControlName="emailType" type="radio" name="emailType" id="defaultSender" value="DEFAULTSENDER" />
            <label for="defaultSender"> Default Sender </label>
          </div>
          <div class="radio radio-primary radio-inline">
            <input formControlName="emailType" type="radio" id="mailGun" name="emailType" value="MAILGUN" />
            <label for="mailGun"> Mailgun </label>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-btn-container mt-3">
      <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!deliveryProtocolForm.valid"></app-modal-buttons>
    </div>
  </div>
</form>
