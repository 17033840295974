<div class="panel-body">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <table class="table table-pointable table-bordered" width="100%">
            <thead>
              <tr>
                <th>Relationship Type</th>
                <th>Party Name To</th>
                <th>Role From</th>
                <th>Role To</th>
                <th>From Date</th>
                <th>To Date</th>
              </tr>
            </thead>
            <tbody>
                <ng-container  *ngFor="let relationship of relationships">
                    <tr  *ngFor="let child of relationship.relationshipDTOList">
                        <td>{{ relationship.relationshipName }}</td>
                        <td title="{{relationship.id}}">{{ child.partyNameTo }}</td>
                        <td>{{ child.fromRoleName }}</td>
                        <td>{{ child.toRoleName }}</td>
                        <td>{{ child.fromDate }}</td>
                        <td>{{ child.thruDate }}</td>
                    </tr>
                </ng-container>
                
              <tr app-is-there-more [numColumns]="6" [newLengthEvent]="newLengthEvent"></tr>
            </tbody>
          </table>
        </div>
        <app-add-more-data-to-list (onScroll)="getRelationships(true)" [lock]="lock" [isLoading]="isLoading">
        </app-add-more-data-to-list>
      </div>
    </div>
  </div>
