import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailModel } from 'src/app/data/model';
import { EmailService } from 'src/app/services/email.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss'],
})
export class EmailModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public email: EmailModel = new EmailModel();
  @Input() public partyId: number;
  title: string;
  isEdit: boolean;
  emailForm: UntypedFormGroup;
  errorMsg: string;
  faTimes = faTimes;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private emailService: EmailService,
    private responseHelper: ResponseHelperService,
    private modalHelper: NgbModal
  ) {}

  ngOnInit(): void {
    if (this.email.id) {
      this.title = 'Edit email';
      this.isEdit = true;
    } else {
      this.title = 'Add email';
      this.isEdit = false;
    }
    this.emailForm = this.formBuilder.group({
      emailAddress: [this.email.emailAddress, Validators.required],
      addressName: [this.email.addressName, Validators.required],
      isPrimary: [this.email.isPrimary],
    });
  }

  ok(): void {
    const value = this.emailForm.value;
    value.partyId = this.partyId;
    this.emailService.createOrUpdateEmail(this.email.id, value).subscribe(
      () => {
        this.successEvent.emit('success');
      },
      (data: any) => {
        this.responseHelper.error(this, data.error.error, false);
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
