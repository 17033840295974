import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppDataService } from '../app-data.service';
import { ConfigurationService } from '../services/configuration.service';
import { Segment } from '../data/model';
import { TicketService } from '../services/ticket.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-jira-ticket-modal',
  templateUrl: './jira-ticket-modal.component.html',
  styleUrls: ['./jira-ticket-modal.component.scss'],
})
export class JiraTicketModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public segments: Segment[];
  @Input() public ticket: any;
  subject: any;
  description: string;
  title = 'Escalate Ticket';
  lockSave = false;
  errorMsg = '';
  jiraTicketForm: UntypedFormGroup;
  faTimes = faTimes;
  files: File[] = [];
  valid = false;
  maxFileSize: number;

  constructor(
    private globalData: AppDataService,
    private ticketService: TicketService,
    private toastr: ToastrService,
    private configurationService: ConfigurationService,
    private modalHelper: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private responseHelperService: ResponseHelperService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.subject = this.ticket.subject;
    this.getMaxFileSize();
    this.ticket.entryList.map((entry: any) => {
      if (this.description) {
        this.description += entry.logEntry + '\n\n';
      } else {
        this.description = entry.logEntry + '\n\n';
      }
    });
    this.description +=
      '\nMember: ' +
      this.ticket.memberLastName +
      ', ' +
      this.ticket.memberFirstName +
      '(' +
      this.ticket.memberPartyId +
      ')';

    let segmentsStr = '';
    if (this.segments) {
      this.segments.map((seg, i) => {
        segmentsStr += seg.code + (seg.description ? '(' + seg.description + ')' : '');
        if (i < this.segments.length - 1) {
          segmentsStr += ', ';
        }
      });
    }
    this.description += '\nSegments: ' + segmentsStr;

    this.description += '\nReporter User ID: ' + this.globalData.userFullName + '(' + this.globalData.username + ')';
  }

  getMaxFileSize(): void {
    this.configurationService.getConfiguration('MAX_FILE_SIZE').subscribe((data) => {
      if (data.success && data.entity[0] && data.entity[0].cfgValue) {
        this.maxFileSize = data.entity[0].cfgValue;
        console.log(data.entity[0]);
      }
    });
  }

  ok(): void {
    this.lockSave = true;
    const jiraTicketObj = {
      subject: this.subject,
      description: this.description,
      memberInteractionId: this.ticket.id,
    };
    const jiraData = {
      model: jiraTicketObj,
      files: this.files,
    };

    const formData = new FormData();
    formData.append('jiraData', JSON.stringify(jiraData.model));
    for (var i = 0; i < jiraData.files.length; i++) {
      formData.append('file' + i, jiraData.files[i]);
    }
    this.http
      .post<any>(this.globalData.baseUrl + 'member-interactions/jira', formData, {
        withCredentials: true,
      })
      .subscribe(
        () => {
          this.responseHelperService.success('Successfully uploaded file', false);
        },
        (data) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  onSelect(event: any): void {
    if (event.rejectedFiles.length > 0) {
      if (event.rejectedFiles[0].reason === 'size') {
        this.errorMsg =
          event.rejectedFiles[0].name + ' file is too big. Max file size is: ' + this.maxFileSize / 1000000 + 'Mib';
      } else {
        this.errorMsg = 'Wrong acceptance type.';
      }
    }

    this.files.push(...event.addedFiles);
    if (this.files.length > 0) {
      this.valid = true;
    }
  }

  onRemove(event): void {
    this.files.splice(this.files.indexOf(event), 1);
    if (this.files.length < 1) {
      this.valid = false;
    }
  }
}
