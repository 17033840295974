<div class="pop-btn-container mt-3">
  <button class="btn-pop-cancel" type="button" (click)="cancel.emit()" title="{{ cancelText }}">
    {{ cancelText | uppercase }}
  </button>
  <button
    *ngIf="showSubmitButton"
    class="btn-pop-success"
    type="submit"
    [disabled]="submitDisabled"
    (click)="ok.emit()"
    title="{{ submitText }}"
  >
    {{ submitText | uppercase }}
  </button>
</div>
