import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faPencilAlt, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from 'src/app/app-data.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { InstrumentType } from 'src/app/data/model';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { InstrumentTypeService } from 'src/app/services/instrument-type.service';
import { InstrumentTypesModalComponent } from '../instrument-types-modal/instrument-types-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-instrument-types-tab',
  templateUrl: './instrument-types-tab.component.html',
  styleUrls: ['./instrument-types-tab.component.scss'],
})
export class InstrumentTypesTabComponent implements OnInit {
  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  statuses: Choice[] = [];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  show: boolean;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  instrumentTypes: any[] = [];
  promotions: any[] = [];
  selectedInstrumentType: any;
  lockedTables = false;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  isAdmin: boolean;
  filteredStatuses: Choice[] = [];
  disabled: boolean;
  searchFilters: SearchFilters;
  faTimes = faTimes;

  constructor(
    private tableHelperService: TableHelperService,
    private responseHelper: ResponseHelperService,
    private instrumentTypeService: InstrumentTypeService,
    private modalHelper: NgbModal,
    private appDataService: AppDataService,
    private title: Title,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'instrument-types-search',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Identifier Type',
          if: true,
          callback: () => {
            this.instrumentTypeModal();
          },
          title: 'New Identifier Type',
        },
      ],
    };
    this.isAdmin = this.appDataService.isAdmin;
    this.title.setTitle('System Configurations');
    this.show = true;
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  initNewSearch(): void {
    this.initFilter();
    this.getInstrumentTypes(false);
  }

  getInstrumentTypes(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.instrumentTypeService.getInstrumentTypes(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.instrumentTypes = this.instrumentTypes.concat(data.entity.records);
        } else {
          this.instrumentTypes = data.entity.records;
        }
        this.lengthToCompare = data.entity.records.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.records.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      (err) => {
        this.lockedTables = false;
        this.isLoading = false;
        this.lock = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  deleteInstrumentType(instrumentType: InstrumentType): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete instrumentType with ID: ' + instrumentType.id;
    instance.componentInstance.header = 'Delete instrumentType?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.instrumentTypeService.deleteInstrumentType(instrumentType.id).subscribe(() => {
        this.responseHelper.success('Identifier Type successfully removed', true);
        instance.close();
        this.instrumentTypeService.getInstrumentType(instrumentType.id).subscribe((data: any) => {
          if (data.success) {
            this.instrumentTypes[this.instrumentTypes.findIndex((e: InstrumentType) => e.id === instrumentType.id)] =
              data.entity;
          }
        });
      });
    });
  }

  instrumentTypeModal(instrumentTypeId?: number): void {
    if (instrumentTypeId) {
      this.instrumentTypeService.getInstrumentType(instrumentTypeId).subscribe((data: any) => {
        if (data.success) {
          this.openModal(data.entity);
        }
      });
    } else {
      this.openModal();
    }
  }

  openModal(instrumentType?: InstrumentType): void {
    const instance = this.modalHelper.open(InstrumentTypesModalComponent);
    instance.componentInstance.instrumentType = instrumentType;
    instance.componentInstance.successEvent.subscribe((data) => {
      if (data.success) {
        this.responseHelper.success(
          `Identifier type successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`,
          true
        );
        this.initNewSearch();
      }
    });
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    if (!this.filter.statuses) {
      delete this.filter.statuses;
    }
    this.getInstrumentTypes(false);
  }
}
