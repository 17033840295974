import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';
import {
  faFlag,
  faPause,
  faPlay,
  faCheck,
  faMinus,
  faPencilAlt,
  faSave,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import { AppDataService } from '../app-data.service';
import { TicketService } from '../services/ticket.service';
import { ToastrService } from 'ngx-toastr';
import { PersonsService } from '../services/persons.service';
import { Segment } from '../data/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JiraTicketModalComponent } from '../jira-ticket-modal/jira-ticket-modal.component';

@Component({
  selector: 'app-open-tickets',
  templateUrl: './open-tickets.component.html',
  styleUrls: ['./open-tickets.component.scss'],
})
export class OpenTicketsComponent implements OnInit, OnChanges {
  @Input() ticket: any;
  @Input() openedTickets: any[];
  @Input() isTicketsOpened: boolean;
  @Input() interactionText: string;
  interactionTextOpenTicket = '';
  @Input() escalateTooltip: string;
  @Output() openedTicketsChange = new EventEmitter();
  @Output() ticketChange = new EventEmitter();
  @Output() isTicketsOpenedChange = new EventEmitter();
  @ViewChild('logEntry') logEntryElement: ElementRef;

  segments: Segment[];
  currentUserId: number;
  filterModel: any = { myTickets: true };
  startX: number;
  startY: number;
  faFlag = faFlag;
  faPause = faPause;
  faPlay = faPlay;
  faCheck = faCheck;
  faMinus = faMinus;
  faPencilAlt = faPencilAlt;
  faSave = faSave;
  faPaperPlane = faPaperPlane;

  isCSRManager = this.globalData.isCSRManager;

  constructor(
    private globalData: AppDataService,
    private ticketService: TicketService,
    private toastr: ToastrService,
    private personsService: PersonsService,
    private modalHelper: NgbModal
  ) {}

  ngOnInit(): void {
    this.currentUserId = this.globalData.userId;
  }

  ngOnChanges(): void {
    this.interactionTextOpenTicket = this.interactionText;
  }

  pauseInteraction(ticket: any): void {
    ticket.status = 'INACTIVE';
    this.ticketService.createOrUpdateTicket(ticket, ticket.id).subscribe(() => {
      this.ticket = null;
      this.ticketChange.emit(this.ticket);
      this.addTicketToList(ticket);
    });
  }

  resumeInteraction(ticket: any): void {
    ticket.status = 'ACTIVE';
    this.ticketService.createOrUpdateTicket(ticket, ticket.id).subscribe((data: any) => {
      this.ticket.entryList = data.entity.entryList;
      this.ticketChange.emit(this.ticket);
      $('#comments').animate({ scrollTop: $('#commentsLength').prop('scrollHeight') });
    });
  }

  addTicketToList(ticket: any): void {
    const index = this.openedTickets.findIndex((t) => t.id === ticket.id);
    if (index === -1) {
      if (this.openedTickets) {
        this.openedTickets.push(ticket);
        this.openedTicketsChange.emit(this.openedTickets);
      }
    }
  }

  pauseTicket(ticket: any): void {
    this.addTicketToList(ticket);
    this.ticket = null;
    this.ticketChange.emit(this.ticket);
  }

  focusMe(): void {
    this.logEntryElement.nativeElement.focus();
  }

  editLog(event: any): void {
    const parent = $(event.currentTarget).parents('.media-body');
    const well = parent.children('.well');
    const saveBtn = parent.children('div.text-muted').children('.save');
    const editBtn = parent.children('div.text-muted').children('a.edit');
    editBtn.css('display', 'none');
    saveBtn.css('display', 'block');
    well.css('background', '#FFFED4');
    well.attr('contenteditable', 'true');
  }

  saveLog(entry: any, event: any): void {
    const parent = $(event.currentTarget).parents('.media-body');
    const saveBtn = parent.children('div.text-muted').children('.save');
    const editBtn = parent.children('div.text-muted').children('a.edit');
    const well = parent.children('.well');
    const newEntry: any = {};
    newEntry.id = entry.id;
    newEntry.logEntry = parent.children('.well').text().trim();
    newEntry.memberInteractionId = entry.memberInteractionId;
    this.ticketService.createOrUpdateTicketEntry(newEntry, newEntry.id).subscribe(() => {
      editBtn.css('display', 'block');
      saveBtn.css('display', 'none');
      well.attr('contenteditable', 'false');
      well.css('background', '#E7EAEC');
    });
  }

  postInteraction(text: string, id: number): void {
    const entry: any = {};
    entry.logEntry = text;
    entry.memberInteractionId = id;
    this.ticketService.createOrUpdateTicketEntry(entry).subscribe((data: any) => {
      this.ticket.entryList.push(data.entity);
      $('#comments').animate({ scrollTop: $('#commentsLength').prop('scrollHeight') });
      this.interactionTextOpenTicket = '';
    });
  }

  completeInteraction(ticket: any): void {
    ticket.status = 'COMPLETED';
    this.ticketService.createOrUpdateTicket(ticket, ticket.id).subscribe(() => {
      this.ticket = null;
      this.ticketChange.emit(this.ticket);
      this.toastr.success('Successfully resolved!');
    });
  }

  createJiraTicket(ticket: any): void {
    // this.pauseTicket(ticket);
    if (this.isTicketsOpened) {
      this.toggleTickets();
    }
    const params = {
      children: false,
    };
    this.personsService.getPersonSegments(ticket.memberPartyId, params).subscribe((data: any) => {
      if (data.success) {
        this.modalHelper.dismissAll();
        this.segments = data.entity;
        const instance = this.modalHelper.open(JiraTicketModalComponent);
        instance.componentInstance.ticket = this.ticket;
        instance.componentInstance.segments = this.segments;
        this.isTicketsOpenedChange.emit(false);
        instance.componentInstance.successEvent.subscribe(() => {
          instance.close();
        });
      }
    });
  }

  toggleTickets(): void {
    if (this.openedTickets.length > 0) {
      this.isTicketsOpened = !this.isTicketsOpened;
      this.isTicketsOpenedChange.emit(this.isTicketsOpened);
    } else {
      this.getTickets().subscribe((tickets: any) => {
        this.openedTickets = tickets.entity.records;
        this.isTicketsOpened = !this.isTicketsOpened;
        this.isTicketsOpenedChange.emit(this.isTicketsOpened);
      });
    }
  }

  getTickets(): any {
    if (this.filterModel.myTickets) {
      return this.ticketService.getTicketList({
        status: ['ACTIVE', 'INACTIVE', 'ESCALATED'],
        startedBy: this.currentUserId,
      });
    } else {
      return this.ticketService.getTicketList({
        status: ['ACTIVE', 'INACTIVE', 'ESCALATED'],
      });
    }
  }
}
