/* eslint-disable unused-imports/no-unused-imports */
import { OnInit, EventEmitter, Component, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { AccessRoleService } from '../services/access-role.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from '../data/model';

@Component({
  selector: 'app-access-role-modal',
  templateUrl: './access-role-modal.component.html',
  styleUrls: ['./access-role-modal.component.scss'],
})
export class AccessRoleModalComponent implements OnInit {
  @Input() personId: number;
  @Input() roles: Role[];
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  roleForm: UntypedFormGroup;
  errorMsg = '';
  faTimes = faTimes;

  constructor(
    private modalHelper: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private accessRoleService: AccessRoleService,
    private responseHelper: ResponseHelperService
  ) {}

  ngOnInit(): void {
    this.roleForm = this.formBuilder.group({
      roleName: [null, Validators.required],
    });
  }

  ok(): void {
    this.accessRoleService.insertAccessRole({ ...this.roleForm.value, partyId: this.personId }).subscribe(
      (data: any) => {
        this.successEvent.emit(data);
        this.responseHelper.success('Access role successfully created', true);
      },
      (data: any) => {
        this.errorMsg = data.error.error;
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
