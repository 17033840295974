import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root',
})
export class LiveAgentSurveyService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getLiveAgentSurveys(params: any, liveAgentManager: boolean): any {
    if (liveAgentManager) {
      return this.http.get<any>(this.globalData.baseUrl + 'surveys/live-agent-manager', this.setParams(params));
    } else {
      return this.http.get<any>(this.globalData.baseUrl + 'surveys/live-agent', this.setParams(params));
    }
  }

  exportLiveAgent(params: any, liveAgentManager): any {
    if (liveAgentManager) {
      return this.http.get(this.globalData.baseUrl + 'surveys/live-agent-manager/export/', {
        responseType: 'text',
        params,
      });
    } else {
      return this.http.get(this.globalData.baseUrl + 'surveys/live-agent/export/', { responseType: 'text', params });
    }
  }

  getLiveAgentList(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'users/live-agents', this.setParams(params));
  }
}
