import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { SponsorsService } from 'src/app/services/sponsors.service';
import { Filter, SearchFilters } from 'src/app/data/class';
import { TableHelperService } from './../services/helpers/table-helper.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { faPlus, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-configurations-tab',
  templateUrl: './configurations-tab.component.html',
  styleUrls: ['./configurations-tab.component.scss'],
})
export class ConfigurationsTabComponent implements OnInit, OnChanges {
  @Input() public promoSponsorId: number;
  @Input() public sponsorId: number;
  @Input() public sponsorName: string;
  keyword = '';
  filteredStatuses = [];
  lockedTables = false;
  filter = new Filter();
  limit = 20;
  configurations = [];
  lengthToCompare: number;
  lock = false;
  isLoading = false;
  faPlus = faPlus;
  faPencilAlt = faPencilAlt;
  searchFilters: SearchFilters;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private sponsorsService: SponsorsService,
    private responseHelperService: ResponseHelperService
  ) {}

  statuses = this.tableHelperService.getStatuses('ENTITY');

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'generic',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
      ],
      searchCallback: () => {
        this.initNewSearch();
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.promoSponsorId && !changes.promoSponsorId.firstChange) {
      this.configurations = null;
      this.initNewSearch();
    }
  }

  initNewSearch(): void {
    this.initFilter();
    this.getConfigurations(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  filterConfiguration(keyword: string): void {
    this.keyword = keyword;
    this.filter.search = keyword;
    this.initFilter();
    this.getConfigurations(false);
  }

  getConfigurations(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.sponsorsService.getConfigurations(this.promoSponsorId, this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.configurations = this.configurations.concat(data.entity.records);
        } else {
          this.configurations = data.entity.records;
        }
        this.lengthToCompare = data.entity.records.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.records.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.lockedTables = false;
        this.lock = false;
        this.responseHelperService.error(this, 'Error occurred', true);
      }
    );
  }

  editConfiguration(): void {
    alert('Not yet implemented!');
  }
}
