<form [formGroup]="memberForm">
  <div class="modal-header">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-8">
          <img src="{{ globalData.organizationLogoUrl }}" alt="" class="organization-logo-lg" />
        </div>
        <div class="col-md-4 pull-right" style="margin-right: -50px" *ngIf="globalData.singleOrgUser === 'false'">
          <a class="pull-right" (click)="selectOrganization()"> Change Organization </a>
        </div>
      </div>
      <h3 class="col-md-12">
        {{ title }}
      </h3>
    </div>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg && !showExistingMemberLink">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="alert alert-danger alert-dismissable" *ngIf="showExistingMemberLink">
      A member with this identifier already exists. They have been reassigned to you. You can click
      <a href="/members/{{ existingMember.id }}">here</a> to open the member.
      <a (click)="clearErrorMessage()"> </a>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>First name <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" class="form-control" formControlName="firstName" />
          <!-- <div class="mt-sm" [hidden]="memberForm.controls.firstName.valid">
            <small class="text-danger">Input a first name</small>
          </div> -->
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Last name <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" class="form-control" formControlName="lastName" />
          <!-- <div class="mt-sm" [hidden]="memberForm.controls.lastName.valid">
            <small class="text-danger">Input last name</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>Middle initial</label>
          <input type="text" class="form-control" formControlName="middleInitial" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Gender <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <ng-select
            [items]="genders"
            bindLabel="name"
            [clearable]="false"
            formControlName="gender"
            [searchable]="false"
          >
          </ng-select>
          <!-- <div class="mt-sm" [hidden]="memberForm.controls.gender.valid">
            <small class="text-danger">Select gender</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>DOB <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <div class="input-group date">
            <input
              class="form-control cursor-pointer"
              (click)="dateOfBirth.toggle()"
              placeholder="yyyy-mm-dd"
              name="dateOfBirth"
              ngbDatepicker
              [footerTemplate]="footerTemplatedateOfBirth"
              #dateOfBirth="ngbDatepicker"
              formControlName="dateOfBirth"
              [minDate]="{ year: 1900, month: 1, day: 1 }"
            />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="dateOfBirth.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
            <ng-template #footerTemplatedateOfBirth>
              <div class="width-100 datePickerTemplate">
                <button class="buttonDateTemplate" (click)="clearDateInput(); dateOfBirth.toggle()">Clear</button>
              </div>
            </ng-template>
          </div>
          <!-- <div class="mt-sm" [hidden]="memberForm.controls.dateOfBirth.valid">
            <small class="text-danger">Select date of birth</small>
          </div> -->
        </div>
      </div>
      <div class="col-6" *ngIf="!globalData.orgId">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label class="com-label">Select segments <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <app-segment-tree-modal
            (filterSegments)="segments = $event"
            maxWidth="true"
            isInFilter="true"
            ngbDropdownClass="w-100"
            class="w-100"
            [personId]="personId"
            [allowMultiple]="false"
            (selectedSegmentsChange)="updateSelectedSegments($event)"
          ></app-segment-tree-modal>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>State <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <ng-select
            [items]="stateList"
            bindLabel="code"
            bindValue="code"
            [clearable]="false"
            [(ngModel)]="stateCode"
            [ngModelOptions]="{ standalone: true }"
            [searchable]="false"
          >
          </ng-select>
        </div>
      </div>
    </div>
    <div formArrayName="activityInstruments">
      <div class="row mb-2">
        <div class="col-md-5">
          <label>Identifier type <span style="color: red">*</span></label>
        </div>
        <div class="col-md-5">
          <label>Identifier number <span style="color: red">*</span></label>
        </div>
        <div class="col-md-2">
          <button class="btn btn-dark-blue btn-sm float-right" (click)="addInstrument()" title="Add instrument">
            <fa-icon [icon]="faPlus"></fa-icon>
          </button>
        </div>
      </div>
      <div
        class="row"
        *ngFor="let instrument of getActivityInstrumentsFormArray().controls; let i = index"
        [formGroupName]="i"
      >
        <div class="col-md-5">
          <div class="form-group">
            <ng-select
              [items]="instrumentTypes"
              bindLabel="code"
              [clearable]="false"
              formControlName="instrumentType"
              (change)="setInstrumentTypeValidation($event, instrument)"
            >
            </ng-select>
            <!-- <div class="mt-sm" [hidden]="instrument.controls.instrumentType.valid">
              <small class="text-danger">Select instrument type</small>
            </div> -->
          </div>
        </div>
        <div class="col-md-5">
          <input
            type="text"
            [imask]="getDisplayMask(instrument.controls.instrumentType.value) || undefined"
            [unmask]="!!getDisplayMask(instrument.controls.instrumentType.value)"
            type="text"
            class="form-control"
            formControlName="instrumentNumber"
          />
        </div>
        <div class="col-md-2">
          <input type="number" [hidden]="true" name="optionValue" class="form-control" formControlName="id" />
          <div *ngIf="getActivityInstrumentsFormArray().controls.length > 1" class="form-group float-right">
            <button class="btn btn-dark-blue btn-sm delete float-right" (click)="removeInstrument(i)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="row">
      <div
        class="mt-sm"
        [hidden]="
          memberForm.valid && ((selectedSegment && selectedSegment.length !== 0) || globalData.orgId) && stateCode
        "
      >
        <small class="text-danger">Please enter all required fields. </small>
      </div>
      <div
        class="mt-sm"
        [hidden]="memberForm.controls.dateOfBirth.valid || memberForm.controls.dateOfBirth.value === null"
      >
        <small class="text-danger">Date of birth must be in yyyy-mm-dd format.</small>
      </div>
    </div>
    <div class="row">
      <button class="btn-pop-cancel-wider" type="button" (click)="cancel()" title="Cancel">CANCEL</button>
      <button
        class="btn-pop-cancel-wider"
        type="submit"
        [disabled]="
          !memberForm.valid ||
          (!selectedSegment && !globalData.orgId) ||
          (selectedSegment?.length === 0 && !globalData.orgId) ||
          !stateCode
        "
        (click)="ok('')"
        title="Save and close"
      >
        SAVE
      </button>
      <!--<button
        class="btn-pop-cancel-wider"
        type="submit"
        [disabled]="
          !memberForm.valid ||
          (!selectedSegment && !globalData.orgId) ||
          (selectedSegment?.length === 0 && !globalData.orgId) ||
          !stateCode
        "
        (click)="ok('add-another')"
        title="Save and add another"
      >
        ADD ANOTHER
      </button>-->
      <button
        class="btn-pop-success-wider"
        type="submit"
        [disabled]="
          !memberForm.valid ||
          (!selectedSegment && !globalData.orgId) ||
          (selectedSegment?.length === 0 && !globalData.orgId) ||
          !stateCode
        "
        (click)="ok('navigate')"
        title="Save and go to member"
      >
        GO TO MEMBER
      </button>
    </div>
  </div>
</form>
