import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotionConfigService } from 'src/app/services/promotion-config.service';
import { UntypedFormBuilder, FormsModule } from '@angular/forms';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ActivatedRoute } from '@angular/router';
import { PromotionTemplate, PromotionConfiguration } from 'src/app/data/model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
})
export class ValidationComponent {
  @Input() configType: string;
  @Input() promotionTemplate: PromotionTemplate;
  @Input() promotionConfiguration: PromotionConfiguration;
  validationWarnings: string;
  validationErrors: string;
  faCheck = faCheck;

  debouncer: Subject<string> = new Subject<string>();

  constructor(
    private router: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal,
    private promotionConfigService: PromotionConfigService,
    private responseHelperService: ResponseHelperService
  ) {}

  doValidation(): void {
    if (this.configType === 'template') {
      this.validateTemplate();
    } else {
      this.validateConfig();
    }
  }

  validateTemplate(): void {
    this.promotionConfigService.validatePromotionTemplate(this.promotionTemplate, { format: 'json' }).subscribe(
      (data: any) => {
        this.responseHelperService.success('Config successfully sent for validation');
        if (data.success) {
          this.validationErrors = this.formatErrors(data.entity);
          this.validationWarnings = this.formatWarnings(data.entity);
        }
      },
      (data) => {
        this.validationErrors = this.formatErrors(data.error.error);
        this.validationWarnings = this.formatWarnings(data.error.error);
      }
    );
  }

  validateConfig(): void {
    this.promotionConfiguration.configuration = JSON.stringify(this.promotionConfiguration.configuration);

    this.promotionConfigService.validatePromotionConfig(this.promotionConfiguration, { format: 'json' }).subscribe(
      (data: any) => {
        this.responseHelperService.success('Config successfully sent for validation');
        if (data.success) {
          this.validationErrors = this.formatErrors(data.entity);
          this.validationWarnings = this.formatWarnings(data.entity);
        }
      },
      (data) => {
        this.validationErrors = this.formatErrors(data.error.error);
        if (this.validationErrors === '') {
          this.validationErrors = '<ul><li>' + data.error.error + '</ul></li>';
        }
        this.validationWarnings = this.formatWarnings(data.error.error);
        this.responseHelperService.error(this, 'Errors found in configuration', true);
      }
    );
  }

  formatErrors(msgs: string): string {
    var errors = '';
    if (msgs !== null && msgs.length > 0) {
      var msgA = msgs.split('\n');
      for (var i = 0; i < msgA.length; i++) {
        if (msgA[i].startsWith('ERROR: ')) {
          if (errors.length === 0) {
            errors = '<ul>';
          }
          errors += '<li>' + msgA[i].replace('ERROR: ', '').replace('<', '&lt;').replace('>', '&gt;') + '</li>';
        }
      }
      if (errors.length > 0) {
        errors += '</ul>';
      }
    }
    return errors;
  }

  formatWarnings(msgs: string): string {
    var errors = '';
    if (msgs.length > 0) {
      var msgA = msgs.split('\n');
      for (var i = 0; i < msgA.length; i++) {
        if (msgA[i].startsWith('WARNING: ')) {
          if (errors.length === 0) {
            errors = '<ul>';
          }
          errors += '<li>' + msgA[i].replace('WARNING: ', '').replace('<', '&lt;').replace('>', '&gt;') + '</li>';
        }
      }
      if (errors.length > 0) {
        errors += '</ul>';
      }
    }
    return errors;
  }
}
