import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCalendar, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DateHelperService } from '../services/helpers/date-helper.service';
import { ProgramService } from '../services/program.service';
import { Package } from '../data/model';

@Component({
  selector: 'app-package-modal',
  templateUrl: './package-modal.component.html',
  styleUrls: ['./package-modal.component.scss'],
})
export class PackageModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public package: Package;
  @Input() public programId: number;
  @Input() public isEdit: boolean;
  title: string;
  packageForm: UntypedFormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;
  faCalendar = faCalendar;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private programService: ProgramService,
    private dateHelperService: DateHelperService,
    private modalHelper: NgbModal
  ) {}

  ngOnInit(): void {
    if (this.isEdit) {
      this.title = 'Edit Package';
      this.isEdit = true;
      this.packageForm = this.formBuilder.group({
        packageName: [this.package.packageName, Validators.required],
        startDate: [this.dateHelperService.stringToNgbDate(this.package.startDate)],
        endDate: [this.dateHelperService.stringToNgbDate(this.package.endDate)],
      });
    } else {
      this.title = 'New Package';
      this.isEdit = false;
      this.packageForm = this.formBuilder.group({
        packageName: ['', Validators.required],
        startDate: [],
        endDate: [],
      });
    }
  }

  clearDateInput(name: string): void {
    this.packageForm.patchValue({
      [name]: null,
    });
  }

  ok(): void {
    let pac = Object.assign({}, this.packageForm.value);
    pac.programId = this.programId;
    pac.startDate = this.dateHelperService.ngbDateToString(pac.startDate);
    pac.endDate = this.dateHelperService.ngbDateToString(pac.endDate);
    if (this.isEdit) {
      pac.createdBy = this.package.createdBy;
      pac.createdOn = this.package.createdOn;
      pac.id = this.package.id;
    }
    this.programService.createOrUpdateProgramPackages(this.package && this.package.id, pac).subscribe(
      () => {
        this.successEvent.emit('success');
      },
      (data: any) => {
        this.errorMsg = data.error.error;
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
