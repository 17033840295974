import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Filter } from 'src/app/data/class';
import { RelationshipsService } from 'src/app/services/relationships.service';

@Component({
  selector: 'app-relationships-tab',
  templateUrl: './relationships-tab.component.html',
})
export class RelationshipsTabComponent implements OnInit {

  @Input() personId:number;
  @Input() selectedPersonName:string;

  lock: boolean;
  limit = 20;
  filter = new Filter();
  lengthToCompare: number;
  relationships: any[];
  isLoading = false;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private relationshipsService : RelationshipsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initNewSearch();
  }

  initNewSearch(): void {
    this.initFilter();
    this.getRelationships(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getRelationships(concat: boolean): void {
    this.isLoading = true;
    this.relationshipsService.getRelationships(this.personId, this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.relationships = this.relationships.concat(data.entity);
        } else {
          this.relationships = data.entity;
        }
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        const totalChilds  = data.entity.reduce((totalChilds: number, currentElement: any) => totalChilds += (currentElement.relationshipDTOList || []).length, 0);
        this.lock = totalChilds < this.limit;
        this.isLoading = false;
      },
      () => {
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

}
