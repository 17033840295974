import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NutritionService } from '../../services/nutrition.service';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-attribute-mappings-group-modal',
  templateUrl: './attribute-mappings-group-modal.component.html',
  styleUrls: ['./attribute-mappings-group-modal.component.scss'],
})
export class AttributeMappingsGroupModalComponent implements OnInit {
  @Input() selectedTagId: number;
  @Input() attrMappings: any;
  @Input() group: any;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  errorMsg = '';
  groupForm: UntypedFormGroup;
  faSave = faSave;
  faTimes = faTimes;

  constructor(
    private nutritionService: NutritionService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    if (this.group) {
      this.groupForm = this.formBuilder.group({
        groupName: [this.group.groupName, Validators.required],
      });
    } else {
      this.groupForm = this.formBuilder.group({
        groupName: ['', Validators.required],
      });
    }
  }

  ok(): void {
    var tagGroup = {
      nutritionTagsId: this.selectedTagId,
      groupName: this.groupForm.controls.groupName.value,
    };
    this.nutritionService.createOrUpdateGroup(this.selectedTagId, this.group && this.group.id, tagGroup).subscribe(
      (data: any) => {
        if (!this.group) {
          this.attrMappings.push(data.entity);
        }
        this.successEvent.emit();
        this.responseHelperService.success('Group successfully created', true);
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error);
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
