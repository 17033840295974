<div>
  <form>
    <label>Member receives a reward for:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="rewards !== null">
        <div *ngFor="let reward of rewards; let i = index" class="border-card-padding form-group col-lg-12">
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeReward(reward)"
            title="Remove Reward"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group pt-4">
              <label>Promotion</label>
              <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" *ngIf="reward.promotionList">
                <ngb-panel *ngFor="let promotionCode of reward.promotionList; let in = index">
                  <ng-template ngbPanelHeader>
                    <div class="d-flex align-items-center justify-content-between">
                      <div>Promotion Code {{ promotionCode ? "- " + promotionCode : "" }}</div>
                      <div class="d-flex">
                        <button ngbPanelToggle class="btn btn-dark-blue" (click)="editPromoCode(i)" title="Edit">
                          <fa-icon [icon]="i === openFieldChevronList.promoCodeIndex ? faChevronUp : faChevronDown">
                          </fa-icon>
                        </button>
                        <button class="btn btn-attention" (click)="removePromoCode(in, i)" title="Remove">
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <h2>
                      {{ promotionCode }}
                    </h2>
                    <app-promotion-code-form
                      [index]="in"
                      [promoCodeFormModel]="promotionCode"
                      [configurationType]="'rules'"
                      (promoCodeEvent)="handlePromoCodeEvent($event, i)"
                    >
                    </app-promotion-code-form>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <app-promotion-code-form [index]="null" (promoCodeEvent)="handlePromoCodeEvent($event, i)">
              </app-promotion-code-form>
              <div class="mt-sm" [hidden]="reward.promotionList?.length > 0">
                <small class="text-danger">Add promotion code</small>
              </div>
            </div>
            <div class="form-group pt-4">
              <label>Reward Types</label>
              <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" *ngIf="reward.rewardTypes">
                <ngb-panel *ngFor="let reward of reward.rewardTypes; let in = index">
                  <ng-template ngbPanelHeader>
                    <div class="d-flex align-items-center justify-content-between">
                      <div>Reward Type {{ reward ? "- " + reward : "" }}</div>
                      <div class="d-flex">
                        <button ngbPanelToggle class="btn btn-dark-blue" (click)="editRewardType(i)" title="Edit">
                          <fa-icon [icon]="i === openFieldChevronList.rewardTypeIndex ? faChevronUp : faChevronDown">
                          </fa-icon>
                        </button>
                        <button class="btn btn-attention" (click)="removeRewardType(in, i)" title="Remove">
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <h2>
                      {{ reward }}
                    </h2>
                    <app-reward-types-form
                      [index]="in"
                      [rewardTypeFormModel]="reward"
                      (rewardTypeEvent)="handleRewardTypeEvent($event, i)"
                    ></app-reward-types-form>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <app-reward-types-form [index]="null" (rewardTypeEvent)="handleRewardTypeEvent($event, i)">
              </app-reward-types-form>
              <div class="mt-sm" [hidden]="reward.rewardTypes?.length > 0">
                <small class="text-danger">Add reward type</small>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label" for="periodCount_{{ i }}">In the past:</label>
              <input
                class="form-control m-b"
                type="number"
                id="periodCount_{{ i }}"
                name="value"
                [(ngModel)]="reward.periodCount"
                (ngModelChange)="updateRewards()"
                (keypress)="keyPressNumbers($event)"
              />
            </div>
            <div class="form-group">
              <select
                class="form-control styled-select"
                name="period{{ i }}"
                [(ngModel)]="reward.period"
                (ngModelChange)="updateRewards()"
              >
                <option *ngFor="let unit of units" [selected]="reward.period === unit.value" [value]="unit.value">
                  {{ unit.text }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label mb-2">Message:</label>
              <textarea
                type="text"
                class="form-control"
                [(ngModel)]="reward.message"
                name="message"
                (ngModelChange)="updateRewards()"
              ></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group float-right col-md-12">
        <button class="btn btn-default float-right" (click)="initRewardObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Reward
        </button>
      </div>
    </div>
  </form>
</div>
