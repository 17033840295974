<div class="panel-body">
  <div class="row justify-content-end mb-3">
    <button class="btn btn-addattri font-weight-bold" (click)="prepareAttributeModal()">ADD ATTRIBUTES</button>
  </div>
  <div class="row">
    <div class="col-md-12 p-0">
      <table
        [(ngModel)]="attrs"
        name="attrs"
        ngDefaultControl
        class="table table-hover table-bordered table-pointable infinite-table"
        width="100%"
      >
        <thead>
          <tr>
            <th class="width-23">Name</th>
            <th class="width-33">Value</th>
            <th class="width-10-rem">
              <span class="float-left">Action</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <!--*ngFor="let attr of attrs | sort: 'attrName' : true ; let i = index">-->
            <tr *ngIf="attrs.length < 1">
              <td colspan="3" class="text-center">No attributes to show</td>
            </tr>
            <tr *ngFor="let attr of attrs">
              <td [attr.title]="attr.attrName">{{ attr.attrName }}</td>
              <td [attr.title]="attr.attrValue" class="word-break-break-all">
                {{ attr.attrValue }}
              </td>
              <td class="table-buttons">
                <a
                  class="btn btn-sm float-left btn-primary"
                  *ngIf="!disableActions && allowedToEditAttribs"
                  (click)="partyAttrModal(attr)"
                  [hidden]="disableActions"
                  title="Edit"
                >
                  <fa-icon [icon]="faPencilAlt"></fa-icon>
                </a>
                <a
                  class="btn btn-sm float-left btn-attention"
                  (click)="deleteAttribute(attr.id, attr.attrName)"
                  title="Delete"
                  *ngIf="!disableActions && allowedToEditAttribs"
                  [hidden]="disableActions"
                >
                  <fa-icon [icon]="faTrash"></fa-icon>
                </a>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
