<div class="border-bottom" *ngIf="globalData.loggedIn && globalData.appReady && !globalData.requiresPasswordReset">
  <nav class="navbar navbar-static-top margin-0" role="navigation">
    <div class="user-context-bar">
      <!-- <div class="d-flex w-100"> -->
      <ul class="nav navbar-top-links navbar-left system-context-bar mr-auto" *ngIf="showEnvs">
        <li role="presentation" class="dropdown">
          <div data-toggle="dropdown" role="button" aria-haspopup="true" class="dropdown-toggle">
            <span>Multi-Tenant <fa-icon [icon]="faCaretDown"></fa-icon></span>
          </div>
          <ul class="dropdown-menu dropdown-menu-left">
            <li>
              <a href=""
                ><span>{{ "BLUECHOICE" }}</span>
              </a>
            </li>
            <li>
              <a href=""
                ><span>{{ "BCBSN" }}</span>
              </a>
            </li>
            <li>
              <a href=""
                ><span>{{ "BCBST" }}</span>
              </a>
            </li>
            <li>
              <a href=""
                ><span>{{ "MEDICAID" }}</span>
              </a>
            </li>
            <li class="active">
              <a href=""
                ><span>{{ "Multi-Tenant" }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="col-md-12 cheetah-nav">
      <a
        class="navbar-brand mr-auto"
        style="margin-left: 18px"
        routerLink="/home"
        routerLinkActive="router-link-active"
      >
        <img src="../assets/images/Icario_Ocean_RGB.png" width="125px" alt="" loading="lazy" />
      </a>
      <ul class="nav navbar-top-links navbar-right pull-right">
        <li>
          <div *ngIf="globalData.organizationLogoUrl">
            <img class="profile-organization-logo" src="{{ globalData.organizationLogoUrl }}" alt="" />
          </div>
        </li>
        <div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn" id="dropdownBasic1" ngbDropdownToggle>
              <li class="drop-down-container">
                <div class="nav-profile">
                  <img src="../assets/images/PersonOutlineFilled.svg" alt="" />
                </div>
                <a *ngIf="globalData.username" class="global_username">Hi, {{ globalData.username }}</a>
              </li>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <li (click)="openAdmin()" *ngIf="globalData.isProgramManager" class="menu-container">
                <a class="menu-btn hr">
                  <img src="../assets/images/AdminPanelSettingsFilled.svg" alt="AdminPanelSettingsFilled" />{{
                    "Alpaca Admin"
                  }}</a
                >
              </li>

              <li class="menu-container">
                <a (click)="logout()" class="menu-btn">
                  <img src="../assets/images/LogoutFilled.svg" alt="LogoutFilled" /> Log out
                </a>
              </li>
            </div>
          </div>
        </div>
      </ul>
    </div>
  </nav>
</div>
<div [ngClass]="isToggle ? 'sidenav-content' : 'sidenav-contentClose'">
  <div *ngIf="globalData.loggedIn && globalData.appReady && !globalData.requiresPasswordReset">
    <app-bread-crumbs></app-bread-crumbs>

    <!-- Open Tickets -->
    <app-open-tickets
      #openTicket
      class="open-tickets"
      [(isTicketsOpened)]="isTicketsOpened"
      [(interactionText)]="interactionText"
      [(escalateTooltip)]="escalateTooltip"
      [(ticket)]="ticket"
      [(openedTickets)]="openedTickets"
      *ngIf="ticket"
    ></app-open-tickets>

    <!-- Tickets -->
    <app-tickets
      class="tickets-list-popup"
      [(isTicketsOpened)]="isTicketsOpened"
      [(interactionText)]="interactionText"
      [(ticket)]="ticket"
      [(escalateTooltip)]="escalateTooltip"
      [(openedTickets)]="openedTickets"
    ></app-tickets>

    <!-- <div class="footer footer-center">
      Copyright &copy; {{year}} Icario 3.3.006-beta.20240112225740
    </div> -->
  </div>
  <router-outlet></router-outlet>
</div>
<div *ngIf="globalData.loggedIn && globalData.appReady && !globalData.requiresPasswordReset">
  <app-nav (sideToggle)="changeToggle($event)"> </app-nav>
</div>
