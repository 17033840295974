import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from './../../services/helpers/response-helper.service';
import { OutboundService } from './../../services/outbound.service';
import { ReportsService } from './../../services/reports.service';
import { StatusHelperService } from './../../services/helpers/status-helper.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OutboundIntegration, Report } from 'src/app/data/model';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-outbound-modal',
  templateUrl: './outbound-modal.component.html',
  styleUrls: ['./outbound-modal.component.scss'],
})
export class OutboundModalComponent implements OnInit {
  @Input() public integration: OutboundIntegration;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();

  reports: Report[];
  title: string;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;
  isEdit: boolean;

  constructor(
    private statusHelperService: StatusHelperService,
    private reportsService: ReportsService,
    private outboundService: OutboundService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.integration) {
      this.title = 'Edit Outbound';
    } else {
      this.title = 'Outbound batch';
      this.integration = new OutboundIntegration();
    }
    this.getAllReports();
  }

  getAllReports(): void {
    this.reportsService.getAllReports().subscribe((data: any) => {
      if (data.success) {
        this.reports = data.entity.records;
      }
    });
  }

  addReportName(item: any): void {
    this.integration.reportName = item.name;
  }

  ok(): void {
    this.outboundService.createOrUpdateOutbound(this.integration, this.integration.id).subscribe(
      (data: any) => {
        this.responseHelperService.success('Outbound successfully created', false);
        this.successEvent.emit();
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error);
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
