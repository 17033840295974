import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faTimes, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-reward-types-form',
  templateUrl: './reward-types-form.component.html',
  styleUrls: ['./reward-types-form.component.scss'],
})
export class RewardTypesFormComponent implements OnInit {
  @Input() rewardTypeFormModel: any;
  @Input() index: any;
  @Input() configurationType: string;
  @Input() rewardTypeUse: boolean;
  @Output() rewardTypeEvent = new EventEmitter();
  rewardTypeForm: UntypedFormGroup;
  originalRewardType: string;
  faTimes = faTimes;
  faPlus = faPlus;
  faSave = faSave;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.index !== null) {
      this.rewardTypeForm = this.formBuilder.group({
        rewardTypeName: this.rewardTypeFormModel,
      });
      this.originalRewardType = this.rewardTypeFormModel;
    } else {
      this.rewardTypeForm = this.formBuilder.group({
        rewardTypeName: [''],
      });
    }
  }

  emitEvent(type: string, index?: number): void {
    const value = this.rewardTypeForm.value;
    this.rewardTypeEvent.emit({ type, index, value });
    if (type === 'add') {
      this.rewardTypeForm.reset();
    }
  }

  resetEdit(): void {
    this.rewardTypeForm.controls.promotionCode.setValue(this.originalRewardType);
  }
}
