import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { WorldCurrency } from '../../data/model';
import { WorldCurrencyService } from '../../services/world-currency.service';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-world-currency-modal',
  templateUrl: './world-currency-modal.component.html',
  styleUrls: ['./world-currency-modal.component.scss'],
})
export class WorldCurrencyModalComponent implements OnInit {
  title: string;
  editId: number;
  errorMsg = '';
  worldCurrencyForm: UntypedFormGroup;
  faTimes = faTimes;
  faSave = faSave;
  @Input() currency: WorldCurrency;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private worldCurrencyService: WorldCurrencyService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    if (this.currency) {
      this.title = 'Edit currency';
      this.worldCurrencyForm = this.formBuilder.group({
        name: [this.currency.name, Validators.required],
        alphaCode: [
          this.currency.alphaCode,
          Validators.compose([Validators.required, Validators.pattern('^[A-Za-z]{3}$')]),
        ],
        numericCode: [
          this.currency.numericCode,
          Validators.compose([Validators.required, Validators.pattern('[0-9]{3}')]),
        ],
        displayFormat: [this.currency.displayFormat],
        description: [this.currency.description],
      });
    } else {
      this.title = 'New currency';
      this.worldCurrencyForm = this.formBuilder.group({
        name: ['', Validators.required],
        alphaCode: ['', Validators.compose([Validators.required, Validators.pattern('^[A-Za-z]{3}$')])],
        numericCode: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]{3}')])],
        displayFormat: [''],
        description: [''],
      });
    }
  }

  ok(): void {
    this.worldCurrencyService
      .createOrUpdateCurrency(this.currency && this.currency.id, this.worldCurrencyForm.value)
      .subscribe(
        (data: any) => {
          this.responseHelperService.success('Currency successfully updated', true);
          this.successEvent.emit();
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
