import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ContactReason } from '../../data/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { ContactReasonService } from 'src/app/services/contact-reason.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';

@Component({
  selector: 'app-contact-reason-modal',
  templateUrl: './contact-reason-modal.component.html',
  styleUrls: ['./contact-reason-modal.component.scss'],
})
export class ContactReasonModalComponent implements OnInit {
  @Input() contactReason: ContactReason;
  @Input() statuses: any[] = [];
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  contactReasonForm: UntypedFormGroup;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;
  isEdit: boolean;
  id: number;

  constructor(
    private contactReasonService: ContactReasonService,
    private modalHelper: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private responseHelperService: ResponseHelperService
  ) {}

  ngOnInit(): void {
    if (this.contactReason) {
      this.title = 'Edit Contact Reason';
      this.isEdit = true;
      this.id = this.contactReason.id;
      this.contactReasonForm = this.formBuilder.group({
        value: [this.contactReason.value, Validators.required],
        code: [this.contactReason.code],
        status: [this.contactReason.status, Validators.required],
        surveyPath: [this.contactReason.surveyPath],
      });
    } else {
      this.title = 'New Contact Reason';
      this.isEdit = false;
      this.contactReasonForm = this.formBuilder.group({
        value: ['', Validators.required],
        code: [''],
        status: ['', Validators.required],
        surveyPath: [''],
      });
    }
  }

  ok(): void {
    this.contactReasonService
      .createOrUpdateContactReason(this.id, { ...this.contactReasonForm.value, id: this.id })
      .subscribe(
        (data: any) => {
          this.successEvent.emit(data);
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error, false);
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
