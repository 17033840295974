


import { Directive, ElementRef, HostListener, Input, } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appTrim]',
  providers:[ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: TrimDirective,
    multi: true
  }]
})
export class TrimDirective implements  ControlValueAccessor {
  @Input('appTrim') trim = true;
  _onChange = (_: any) => {};
  onTouched: any = () => {};

  constructor(private el: ElementRef) {}  
  writeValue(value: string): void {
    this.el.nativeElement.value = this.trimValue(value);
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setValue(emitedValue: string): void {
    this._onChange(this.trimValue(emitedValue));
  }

  @HostListener('focus', ['$event']) 
  focus(event): void {
    this.onTouched(event)
  }

  @HostListener('input', ['$event']) 
  input(event) {
    this._onChange(this.trimValue(event.target.value));
  }

  private trimValue(value: string | null | undefined): string | null | undefined {
    return typeof value === 'string' && this.trim ? value.trim() : value;
  }

}

