import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { LocationService } from './../services/location.service';
import { PromotionService } from './../services/promotion.service';
import { BehaviorsService } from './../services/behaviors.service';
import { InstrumentTypeService } from './../services/instrument-type.service';
import { CommunicationsService } from './../services/communications.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from 'src/app/app-data.service';
import { faSearch, faCopy, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { interval } from 'rxjs';
@Component({
  selector: 'app-regex-search-modal',
  templateUrl: './regex-search-modal.component.html',
  styleUrls: ['./regex-search-modal.component.scss'],
})
export class RegexSearchModalComponent implements OnInit {
  //TODO add these
  @Input() searchFor: string;
  @Input() item: any;
  @Input() limitToOne: boolean;
  @Input() setValue: boolean;
  @Input() searchPattern: string;
  @Input() itemIndex: number;
  //item.object: any;
  results: any;
  result: any;
  select: any = {};
  checkedItems: any = [];
  searchResults: any;
  title: string;
  disableSearchType: boolean;
  faSearch = faSearch;
  faCopy = faCopy;
  faTimes = faTimes;
  faCheck = faCheck;
  @Output() public successEvent: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();

  constructor(
    private http: HttpClient,
    private globalData: AppDataService,
    private responseHelperService: ResponseHelperService,
    private locationService: LocationService,
    private promotionService: PromotionService,
    private behaviorsService: BehaviorsService,
    private instrumentTypeService: InstrumentTypeService,
    private communicationsService: CommunicationsService,
    private modalHelper: NgbModal
  ) {
    this.debouncer.pipe(debounce(() => interval(1000))).subscribe((value) => this.successEvent.emit(value));
  }

  ngOnInit(): void {
    this.select = {};
    this.checkedItems = [];
    this.title = 'Regex Code Search';

    this.item = this.item ? this.item : [];
    if (this.item.objectOfObjectName) {
      if (!this.item.object) {
        this.item.object = {};
      }
      //this.item.object = this.item.object ? this.item.object : {};
      if (!this.item.objectOfObject) {
        this.item.object[this.item.objectOfObjectName] = {};
      } else {
        this.item.object[this.item.objectOfObjectName] = this.item.objectOfObject;
      }
      this.results = this.item.object[this.item.objectOfObjectName][this.item.name]
        ? this.item.object[this.item.objectOfObjectName][this.item.name].value
          ? this.item.object[this.item.objectOfObjectName][this.item.name].value.toString().split(',')
          : this.item.object[this.item.objectOfObjectName][this.item.name].toString().split(',')
        : [];
    } else {
      this.item.object = this.item.object ? this.item.object : {};
      this.results = this.item.object[this.item.name]
        ? this.item.object[this.item.name].value
          ? this.item.object[this.item.name].value.toString().split(',')
          : this.item.object[this.item.name].toString().split(',')
        : [];
    }

    this.result = this.results.join(',');
    this.searchFor = this.searchFor;
    this.limitToOne = this.limitToOne;
    this.setValue = this.setValue;

    if (this.searchFor) {
      this.disableSearchType = true;
      this.select.selectedSearch = this.searchFor;
      switch (this.searchFor) {
        case 'BEHAVIORS':
          this.title = 'Regex Search for Behaviors';
          break;
        case 'PROMOTIONS':
          this.title = 'Regex Search for Promotions';
          break;
        case 'LOCATIONS':
          this.title = 'Regex Search for Locations';
          break;
        case 'INSTRUMENTTYPES':
          this.title = 'Regex Search for Instrument Types';
          break;
        case 'COMMUNICATIONS':
          this.title = 'Search for Communications';
          break;
      }
    }
  }

  doRegexSearch = function () {
    this.checkedItems = [];
    if (!this.searchPattern) {
      this.searchPattern = '';
    }
    if (this.select.selectedSearch === 'PROMOTIONS') {
      this.promotionService.getPromotionsByRegex({ pattern: this.searchPattern }).subscribe((data: any) => {
        if (data.success) {
          this.searchResults = data.entity;
          this.checkedItems = [...this.results];
          $('#select-all-cb').prop('checked', false);
        }
      });
    } else if (this.select.selectedSearch === 'BEHAVIORS') {
      this.behaviorsService.getBehaviorsByRegex({ pattern: this.searchPattern }).subscribe((data: any) => {
        if (data.success) {
          this.searchResults = data.entity;
          $('#select-all-cb').prop('checked', false);
        }
      });
    } else if (this.select.selectedSearch === 'LOCATIONS') {
      this.locationService.getLocationsByRegex({ pattern: this.searchPattern }).subscribe((data: any) => {
        if (data.success) {
          this.searchResults = data.entity;
          $('#select-all-cb').prop('checked', false);
        }
      });
    } else if (this.select.selectedSearch === 'INSTRUMENTTYPES') {
      this.instrumentTypeService.getInstrumentTypesByRegex({ pattern: this.searchPattern }).subscribe((data: any) => {
        if (data.success) {
          this.searchResults = data.entity;
          $('#select-all-cb').prop('checked', false);
        }
      });
    } else if (this.select.selectedSearch === 'COMMUNICATIONS') {
      this.communicationsService.getCommunications({ pattern: this.searchPattern }).subscribe((data: any) => {
        if (data.success) {
          this.searchResults = data.entity.records;
          $('#select-all-cb').prop('checked', false);
        }
      });
    }
  };

  selectAllCb = function ($event) {
    var $selectAllCb;
    $selectAllCb = $('#select-all-cb');
    if (this.checkedItems.length > 0) {
      for (const result of this.checkedItems) {
        this.results.splice(this.results.indexOf(result), 1);
      }
      this.checkedItems = [];
      $('[id^=cb-]:checked').each(function ($index) {
        $(this).prop('checked', false);
      });
      $selectAllCb.prop('checked', false);
    } else {
      $('[id^=cb-]').each(function ($index) {
        $(this).prop('checked', true);
        //         this.checkedItems.push(this.id.substring(3));
      });
      for (const result of this.searchResults) {
        this.checkedItems.push(result.code || result.name);
        this.results.push(result.code || result.name);
      }
      $selectAllCb.prop('checked', true);
    }
    this.result = this.results.join(',');
  };

  updateSelectedCodes = function (code) {
    if (!this.limitToOne) {
      var $selectAllCb;
      $selectAllCb = $('#select-all-cb');

      if (this.results.indexOf(code) !== -1 && this.checkedItems.indexOf(code) !== -1) {
        var codeIdx = this.results.indexOf(code);
        this.results.splice(codeIdx, 1);
        if (this.checkedItems.indexOf(code) !== -1) {
          codeIdx = this.checkedItems.indexOf(code);
          this.checkedItems.splice(codeIdx, 1);
        }
        if (this.checkedItems.length === 0) {
          $selectAllCb.prop('checked', false);
        }
      } else if (this.results.indexOf(code) === -1) {
        this.checkedItems.push(code);
        this.results.push(code);
        if (!$selectAllCb.is('checked')) {
          $selectAllCb.prop('checked', true);
        }
      } else {
        this.checkedItems.push(code);
      }
      if(this.searchResults.length > this.checkedItems.length) {
        $selectAllCb.prop('checked', false);
      }
    } else {
      //we are only allowing one code to be selected in this case
      if (this.checkedItems.length > 0) {
        $('[id^=cb-]:checked').each(function ($index) {
          if (this.id.substring(3) !== code) {
            $(this).prop('checked', false);
          }
        });
      }
      if (this.results.indexOf(code) !== -1 && this.checkedItems.indexOf(code) !== -1) {
        this.checkedItems.splice(0, 1);
        this.results.splice(0, 1);
      } else {
        this.checkedItems.splice(0, 1);
        this.results.splice(0, 1);
        this.checkedItems.push(code);
        this.results.push(code);
      }
    }
    this.result = this.results.join(',');
  };

  insertCodes = function () {
    if (this.setValue) {
      this.item.object[this.item.name] = [];
      this.item.object[this.item.name].value =
        this.select.selectedSearch === 'PROMOTIONS' ? this.result || this.searchPattern : this.result;
    } else {
      if (!this.item.objectOfObjectName) {
        this.item.object[this.item.name] =
          this.select.selectedSearch === 'PROMOTIONS' ? this.result || this.searchPattern : this.result;
      } else {
        this.item.object[this.item.objectOfObjectName][this.item.name] =
          this.select.selectedSearch === 'PROMOTIONS' ? this.result || this.searchPattern : this.result;
      }
    }
    this.debouncer.next(this.result);
    this.modalHelper.dismissAll();
  };

  clearAll = function () {
    this.results = [];
    this.searchResults = [];
    this.searchPattern = '';
    this.result = '';
    this.checkedItems = [];
  };

  cancel(): void {
    this.modalHelper.dismissAll();
  }
}
