import { forkJoin } from "rxjs";
import { faExclamationCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

class BasePromotionsDetail {
  selectedCampaigns;
  promoList;
  categoryOrders;
  activityDetails;
  searchFilters;
  promotionInstance;
  http;
  personId;
  person;
  baseUrl;
  promoGroups;
  activeCategory;
  campaigns;
  wteWrapper;
  isSelected;
  scope;

  constructor(wteWrapper, personId, person, isSelected, activityDetails, searchFilters, http, baseUrl) {
    this.wteWrapper = wteWrapper;
    this.personId = personId;
    this.person = person;
    this.isSelected = isSelected;
    this.scope = {};
    this.http = http;
    this.baseUrl = baseUrl;
    this.searchFilters = searchFilters;
    this.$onInit();
  }

  $onInit() {
    console.log("Promotions: ", this.isSelected);

    this.promotionKeyword = "";
    this.filteredStatuses = [];
    this.select = {};
    this.filter = {};
    this.isCSRManager = this.wteWrapper.security.isCSRManager;
    this.isPartner = this.wteWrapper.security.isPartner;
    this.showHidden = {};
    this.showAllCampaigns = {};
    this.promoGroups = [];
    this.promoList = [];
    this.categoryOrders = [];
    this.capGroups = [];
    this.groupedPromos = [];
    this.selectedCampaigns = [];
  }

  buildPromoObj(value) {
    // implement in child
  }

  getEligibleCampaigns(partyId, effectiveDate) {
    this.campaigns = null;
    var params = {
      effectiveDate: effectiveDate ? effectiveDate : null,
    };
    this.wteWrapper.getEligibleCampaigns(partyId, params).subscribe((data) => {
      if (data.success) {
        this.campaigns = data.entity;
      }
    });
  }

  filterPromotions(keyword) {
    this.promotionKeyword = keyword;
    this.promotionInstance.rerender();
  }

  clearKeyword(that) {
    that.promotionKeyword = "";
    this.filterPromotions(that.promotionKeyword);
  }

  clearAllFilters(that) {
    this.filteredStatuses = [];
    setStatuses();
    this.selectedCampaigns = [];
    this.select.campaigns = null;
    this.clearKeyword(that);
  }

  handlePreviewRequestsCallback(res) {
    var props = ["accounts", "primaryInstrument"];
    for (let i = 0; i < props.length; i++) {
      let attr = props[i];
      let data = res[i];
      if (data && data.success) {
        this.person[attr] = data.entity;
      }
    }
  }

  promotionSuccessCallback(response) {
    var previewRequests = [];

    previewRequests.push(this.wteWrapper.getAccountsSummary(this.personId));
    previewRequests.push(this.wteWrapper.getPrimaryInstrumentForParty(this.personId));

    forkJoin(previewRequests).subscribe((res) => this.handlePreviewRequestsCallback(res));

    var promotions = [];
    var childPromotions = [];
    var grandchildPromotions = [];
    var capGroups = [];
    var campaigns = [];
    var started = false;
    this.promoGroups = [];

    childPromotions = response.entity.filter(function (p) {
      return p.path.split(",").length === 2;
    });
    grandchildPromotions = response.entity.filter(function (p) {
      return p.path.split(",").length === 3;
    });
    promotions = response.entity.filter(function (p) {
      return p.path.split(",").length <= 1;
    });

    for (const promo of promotions) {
      var xml = $.parseXML(promo.displayXML);

      promo.children = [];

      for (const childPromo of childPromotions) {
        if (childPromo.parentId === promo.id && childPromo.sponsorPartyId === promo.sponsorPartyId) {
          childPromo.grandchildren = [];
          for (const grandChildPromo of grandchildPromotions) {
            if (
              grandChildPromo.parentId === childPromo.id &&
              grandChildPromo.sponsorPartyId === childPromo.sponsorPartyId
            ) {
              childPromo.grandchildren.push(grandChildPromo);
            }
          }
          promo.children.push(childPromo);
        }
      }
      this.promoList.push(this.buildPromoObj.bind(this)(promo));
    }

    if (this.promoList.length === promotions.length) {
      this.buildGroups();
      console.log(this.promoList);
    }

    this.promoCategories = this.categoryOrders
      .map(function (cat) {
        var order = parseInt(cat.split("-")[1]);
        var name = cat.split("-")[0];
        return { order: order, name: name };
      })
      .sort(function (a, b) {
        return a.order - b.order;
      });

    this.sortPromos.bind(this)();

    if (this.promoCategories[0]) {
      this.activeCategory = this.promoCategories[0].name;
    }

    for (const group of this.promoGroups) {
      group.promos[0]["isSelected"] = true;
    }
  }

  getPromotionData(params) {
    const queryParams = {};
    if (this.filter["effectiveDate"]) {
      queryParams["effectiveDate"] = this.filter["effectiveDate"];
    }

    queryParams["showHidden"] = this.filter["showHidden"] ? this.filter["showHidden"] : false;

    return this.http.get(this.baseUrl + "members/" + params.personId + "/promotions", { params: queryParams });
  }

  promotionPreviewData(params) {
    return new Promise(() =>
      this.getPromotionData(params).subscribe((response) => {
        this.promotionSuccessCallback(response);
      })
    );
  }

  buildPromotionPreviewData() {
    const params = {
      personId: this.personId,
    };

    //null out the promo groups, so if nothing is returned the ui will update
    this.promoGroups = null;
    this.promotionPreviewData(params);
  }

  calculateStartDate(promotionCampaigns, scoreStartDate) {
    var startDate = null;
    var earliestCampaignStartDate;
    for (const campaign of promotionCampaigns) {
      if (!earliestCampaignStartDate || (campaign.fromDate && campaign.fromDate < earliestCampaignStartDate)) {
        earliestCampaignStartDate = campaign.fromDate;
      }
    }
    if (scoreStartDate) {
      startDate = earliestCampaignStartDate >= scoreStartDate ? earliestCampaignStartDate : scoreStartDate;
    } else if (earliestCampaignStartDate) {
      startDate = earliestCampaignStartDate;
    }
    return startDate;
  }

  calculateEndDate(promotionCampaigns, scoreEndDate) {
    var endDate = null;
    var latestCampaignThruDate;
    for (const campaign of promotionCampaigns) {
      if (!latestCampaignThruDate || (campaign.thruDate && campaign.thruDate > latestCampaignThruDate)) {
        latestCampaignThruDate = campaign.thruDate;
        //scope handles the case where the campaign is open ended, thus has no end date.
      } else if (!campaign.thruDate && scoreEndDate) {
        latestCampaignThruDate = scoreEndDate;
      }
    }
    if (scoreEndDate) {
      endDate = latestCampaignThruDate <= scoreEndDate ? latestCampaignThruDate : scoreEndDate;
    } else if (latestCampaignThruDate) {
      endDate = latestCampaignThruDate;
    }
    return endDate;
  }

  toggleFullDetails(promo) {
    promo.hideDetails = !promo.hideDetails;
  }

  selectCategory(e) {
    if (e.target.className.indexOf("ng-binding") !== -1) {
      $(".active.promo-tab").removeClass("active");
      var tab = e.target.parentElement;
      this.activeCategory = e.target.innerText;
      $(tab).addClass("active");
    }
  }

  selectPromo(e, targetPromo, promoGroup) {
    if (e.target.className.indexOf("ng-binding") !== -1) {
      var currentActive = $(".active.grouped-promo-tab:not(.ng-hide)");
      currentActive.removeClass("active");

      var isDetailsHidden = false;
      // unselect everything else in the group
      for (const promo of promoGroup) {
        if (promo.isSelected) {
          isDetailsHidden = promo.hideDetails;
        }
        promo.isSelected = false;
      }

      targetPromo.isSelected = true;
      targetPromo.hideDetails = isDetailsHidden;
      $(e.target.parentElement).addClass("active");
    }
  }

  buildBadgeData(promo) {
    var badgeData;
    var complete = false;
    var childCompleteClass = faCheckCircle;
    var childIncompleteClass = faExclamationCircle;
    var progress = promo.progress;
    var minCount = promo.minCount;
    var maxCount = promo.maxCount;
    var type = promo.progressType;
    var started = false;
    var rewardCount = progress ? progress.rewardCount : 0;
    switch (type) {
      case "SingleStep":
        if (promo.goalBased && promo.goalMet && rewardCount >= maxCount && rewardCount > 0) {
          badgeData = {
            type: "info",
            label: "Complete",
            tooltip: "Congratulations, you have completed scope activity.",
            complete: true,
            childIconClass: childCompleteClass,
            started: true,
            style: "color:green",
          };
        } else if (promo.goalBased && !promo.goalMet && rewardCount >= maxCount && rewardCount > 0) {
          badgeData = {
            type: "info",
            label: "Goal not met",
            tooltip: "Goal was not met for scope activity",
            complete: false,
            childIconClass: childIncompleteClass,
            started: true,
            style: "color:red",
          };
        } else if (rewardCount >= maxCount && rewardCount > 0) {
          badgeData = {
            type: "info",
            label: "Complete",
            tooltip: "Congratulations, you have completed scope activity.",
            complete: true,
            childIconClass: childCompleteClass,
            started: true,
            style: "color:green",
          };
        } else if (minCount > 0 && rewardCount > 0) {
          badgeData = {
            type: "info",
            label: rewardCount + " of " + maxCount,
            tooltip:
              "Congratulations, you have completed " +
              rewardCount +
              " of the " +
              maxCount +
              " available activities. See your activity history for more detail.",
            complete: false,
            childIconClass: childIncompleteClass,
            started: true,
            style: "color:blue",
          };
        } else if (minCount > 0 && rewardCount === 0) {
          badgeData = {
            type: "info",
            label: "",
            tooltip: "You have not started scope activity",
            complete: false,
            childIconClass: childIncompleteClass,
            started: false,
            style: "color:blue",
          };
        }
        break;
      case "MultiStep":
        if (rewardCount >= minCount && rewardCount > 0 && rewardCount < maxCount) {
          badgeData = {
            type: "info",
            label: rewardCount + " of " + maxCount,
            tooltip:
              "Congratulations, you have completed " +
              rewardCount +
              " of the " +
              maxCount +
              " available activities. See your activity history for more detail.",
            complete: false,
            childIconClass: childIncompleteClass,
            started: true,
            style: "color:blue",
          };
        } else if (rewardCount >= maxCount && rewardCount > 0) {
          badgeData = {
            type: "info",
            label: "Complete",
            tooltip: "Congratulations, you have completed scope activity. See your activity history for more detail.",
            complete: true,
            childIconClass: childCompleteClass,
            started: true,
            style: "color:green",
          };
        }
        if (minCount > 0 && rewardCount === 0) {
          badgeData = {
            type: "info",
            label: "",
            tooltip: "You have not started scope activity",
            complete: false,
            childIconClass: childIncompleteClass,
            started: false,
            style: "color:blue",
          };
        }
        break;
      case "COUNT":
        if (minCount > 0 && rewardCount > 0) {
          badgeData = {
            type: "info",
            label: rewardCount + " Complete",
            tooltip:
              "Congratulations, you have completed scope activity " + rewardCount + " times in the reward period.",
            complete: false,
            childIconClass: childCompleteClass,
            started: true,
            style: "color:green",
          };
        } else if (minCount > 0 && rewardCount === 0) {
          badgeData = {
            type: "info",
            label: "",
            tooltip: "You have not started scope activity",
            complete: false,
            childIconClass: childIncompleteClass,
            started: false,
            style: "color:blue",
          };
        }
        break;
    }
    return badgeData;
  }

  rollUpBadgeData(promo) {
    var badgeData;
    var complete = false;
    var childCompleteClass = faCheckCircle;
    var childIncompleteClass = faExclamationCircle;
    var minCount = promo.minCount;
    var maxCount = promo.maxCount;
    var completed = 0;
    var started = false;

    promo.children.forEach(function (child) {
      if (child.badgeData && child.badgeData.complete) {
        completed++;
      }
    });

    if (completed >= maxCount && completed > 0) {
      badgeData = {
        type: "info",
        label: "Complete",
        tooltip: "Congratulations, you have completed scope activity.",
        complete: true,
        childIconClass: childCompleteClass,
        started: true,
        style: "color:green",
      };
    } else if (minCount >= 0 && completed >= minCount) {
      badgeData = {
        type: "info",
        label: completed + " of " + maxCount,
        tooltip:
          "Congratulations, you have completed " +
          completed +
          " of the " +
          maxCount +
          " available activities. See your activity history for more detail.",
        complete: false,
        childIconClass: childIncompleteClass,
        started: true,
        style: "color:blue",
      };
    }
    return badgeData;
  }

  showCampaignDetails(camp) {
    var tpl = "tabset/tpl/modal/campaign-detail-modal.tpl.html";
    this.selectedCampaign = {
      id: camp.id,
      code: camp.code,
      cap: camp.cap,
      fromDate: camp.fromDate,
      thruDate: camp.thruDate ? camp.thruDate : camp.rolloverDate,
    };
    if (this.selectedCampaign.id) {
      console.log("Mock open campagin modal");
      // modalHelper.openModal($uibModal, scope, tpl, campaignDetailsCtrl, this.selectedCampaign, { windowClass: 'wider-modal-window' });
    }
  }

  // var campaignDetailsCtrl = ['scope', '$uibModalInstance', '$uibModal', 'item', function(scope, $uibModalInstance, $uibModal, item) {
  //   this.campaignDetails = item;
  //
  //   this.cancel = function() {
  //     modalHelper.dismissModal($uibModalInstance);
  //   };
  // }];

  showDetails(promotion) {
    var id;
    if (promotion.configCount > 0) {
      id = promotion.promoSponsorId;
    } else if (promotion.childPromoSponsorId > 0 && promotion.childConfigCount > 0) {
      id = promotion.childPromoSponsorId;
    }

    this.activityDetails = {
      id: id,
      startDate: promotion.promoStartDate,
      endDate: promotion.promoEndDate,
      selectedPersonId: this.personId,
      bCodes: [],
      regex: "",
      hideLink: false,
    };
    var tpl = "tabset/tpl/modal/behavior-code-modal.tpl.html";

    if (id) {
      this.wteWrapper.getBehaviorCodes(id).then(
        function (bCodes) {
          if (bCodes.success) {
            this.activityDetails.bCodes = bCodes.entity;
            this.activityDetails.regex = this.activityDetails.bCodes.find(
              (bCode) => bCode.behaviorCode === "regex"
            ).behaviorName;
            this.hideButton = false;
            if (this.activityDetails.regex.indexOf(".*") > 0) {
              this.activityDetails.bCodes = [
                {
                  behaviorCode: "Multiple Behavior Codes",
                  behaviorName: "Multiple Behavior Codes",
                },
              ];
              this.hideButton = true;
            }
            if (this.activityDetails.regex.indexOf("DOES_NOT_SCORE") > 0) {
              this.activityDetails.bCodes = [
                {
                  behaviorCode: "Non scoring promotion",
                  behaviorName: "Non scoring promotion",
                },
              ];
              this.activityDetails.hideLink = true;
            } else if (this.activityDetails.regex === "()") {
              this.activityDetails.bCodes = [
                {
                  behaviorCode: "No Behavior Code Configuration",
                  behaviorName: "No Behavior Code Configuration",
                },
              ];
              this.activityDetails.hideLink = true;
            } else {
              this.activityDetails.bCodes = this.activityDetails.bCodes.filter(
                (bCode) => bCode.behaviorCode !== "regex"
              );
            }
            console.log("mock open activity details modal");
            // modalHelper.openModal($uibModal, scope, tpl, detailsCtrl, this.activityDetails);
          }
        }.bind(this)
      );
    }
  }

  // var detailsCtrl = ['scope', '$uibModalInstance', '$uibModal', 'item', function(scope, $uibModalInstance, $uibModal, item) {
  //   this.title = "More Details";
  //   this.activityDetails = item;
  //
  //   this.cancel = function() {
  //     modalHelper.dismissModal($uibModalInstance);
  //   };
  // }];

  initNewSearch = () => {
    this.applyFilter();
  };

  applyFilter() {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.promoList = [];
    this.categoryOrders = [];
    this.buildPromotionPreviewData();
    this.getEligibleCampaigns(this.personId, this.filter["effectiveDate"]);
  }
}

class StandardPromotionsDetail extends BasePromotionsDetail {
  constructor(wteWrapper, personId, person, isSelected, activityDetails, searchFilters, http, baseUrl) {
    super(wteWrapper, personId, person, isSelected, activityDetails, searchFilters, http, baseUrl);
  }

  buildGroups = () => {
    this.buildNormalGroups(this.promoList);
  };

  sortPromos = () => {
    var completedPromos = this.promoGroups
      .filter(function (group) {
        return group.promos.some(function (p) {
          return p.badgeData && p.badgeData.complete;
        });
      })
      .sort(function (a, b) {
        return parseInt(a.displayOrder) > parseInt(b.displayOrder) ? 1 : -1;
      });

    var openPromos = this.promoGroups
      .filter(function (group) {
        return group.promos.some(function (p) {
          return (p.badgeData && !p.badgeData.complete) || !p.badgeData;
        });
      })
      .sort(function (a, b) {
        return parseInt(a.displayOrder) > parseInt(b.displayOrder) ? 1 : -1;
      });

    this.promoGroups = openPromos.concat(completedPromos);
  };

  buildNormalGroups(promoList) {
    // For Anthem, we have added the abililty to group promos with tabs. Part of that change required that the base
    // promoList object had to be manipulated a little, so scope method does that for non-anthem customers.
    for (const promo of promoList) {
      if (!promo.parent) {
        var singlePromoGroupObj = {};
        //  display order for special cases can go here
        singlePromoGroupObj.displayOrder = promo.displayOrder + 5000; // breathing room for special cases
        singlePromoGroupObj.promos = [];
        singlePromoGroupObj.promos.push(promo);
        this.promoGroups.push(singlePromoGroupObj);
      }
    }
  }

  // promotionCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
  //   $compile(nRow)(scope);
  //   $('td', nRow).unbind('click');
  //   $('td', nRow).bind('click', function () {
  //     this.$apply(function () {
  //       $('tr.active').removeClass('active');
  //       $(nRow).toggleClass('active');
  //       this.selectedPromotion = aData;
  //       //getPromotionCampaigns(aData.id, this.personId);
  //     });
  //   });
  // }

  buildPromoObj = (promo) => {
    var xml = $.parseXML(promo.displayXML);
    var dollarExp = new RegExp("^dollar", "i");
    var defaultRewardImage =
      "https://a89ddf5339eed28f17d8-9b103dd600868b1ec842fb3e60f99373.ssl.cf2.rackcdn.com/assets/rewardicon_star_60x60-a8bc24147126828cafd699d4dc030ea7.png";
    var alternatives =
      $(xml).find("alternativesConfiguration").length > 0 ? $(xml).find("alternativesConfiguration")[0].childNodes : [];
    var alternativesConfiguration = {};

    if (alternatives) {
      for (var i = 0; i < alternatives.length; i++) {
        var item = alternatives[i];
        alternativesConfiguration[item.nodeName] = item.textContent;
      }
    }
    if (alternativesConfiguration.displayLink === "false") {
      alternativesConfiguration.displayLink = false;
    }

    if (alternativesConfiguration.linkLabel === "") {
      alternativesConfiguration.linkLabel = "Reasonable alternative available on member portal";
    }

    var displayOn = $(xml).find("displayNewFlagOn").length > 0 ? $(xml).find("displayNewFlagOn")[0].textContent : "";
    var displayUntil =
      $(xml).find("displayNewFlagUntil").length > 0 ? $(xml).find("displayNewFlagUntil")[0].textContent : "";

    var categoryOrder = promo.promotionCategoryName + "-" + promo.promotionCategorySort;
    if ($.inArray(categoryOrder, this.categoryOrders) === -1) {
      this.categoryOrders.push(categoryOrder);
    }

    var promoObj = {
      id: promo.id,
      title: $(xml).find("title").length > 0 ? $(xml).find("title")[0].textContent : null,
      subtitle: $(xml).find("subtitle").length > 0 ? $(xml).find("subtitle")[0].textContent : "",
      description: $(xml).find("description").length > 0 ? $(xml).find("description")[0].textContent : "",
      displayAmount: $(xml).find("displayAmount").length > 0 ? $(xml).find("displayAmount")[0].textContent : "",
      displayCurrency: $(xml).find("displayCurrency").length > 0 ? $(xml).find("displayCurrency")[0].textContent : "",
      displayRewardImageUrl:
        $(xml).find("displayRewardImageUrl").length > 0
          ? $(xml).find("displayRewardImageUrl")[0].textContent
          : defaultRewardImage,
      buttonText: $(xml).find("buttonText").length > 0 ? $(xml).find("buttonText")[0].textContent : "",
      buttonUrl: $(xml).find("buttonUrl").length > 0 ? $(xml).find("buttonUrl")[0].textContent : "",
      videoHTML: $(xml).find("videoHTML").length > 0 ? $(xml).find("videoHTML")[0].textContent : "",
      displayOrder: $(xml).find("displayOrder").length > 0 ? parseInt($(xml).find("displayOrder")[0].textContent) : 0,
      hideDetails: true,
      category: promo.promotionCategoryName,
      alternativesConfiguration: alternativesConfiguration,
      progressType: $(xml).find("progressType").length > 0 ? $(xml).find("progressType")[0].textContent : "",
      minCount: $(xml).find("minCount").length > 0 ? $(xml).find("minCount")[0].textContent : "",
      maxCount: $(xml).find("maxCount").length > 0 ? $(xml).find("maxCount")[0].textContent : "",
      disclaimer: $(xml).find("disclaimer").length > 0 ? $(xml).find("disclaimer")[0].textContent : "",
      capGroup: $(xml).find("capGroup").length > 0 ? $(xml).find("capGroup")[0].textContent : "",
      capGroupValue: $(xml).find("capGroupValue").length > 0 ? $(xml).find("capGroupValue")[0].textContent : 1,
      promoGrouping: -1,
      isSelected: false,
      showResults: $(xml).find("showResults").length > 0 ? $(xml).find("showResults")[0].textContent : false,
      resultsLabel: $(xml).find("resultsLabel").length > 0 ? $(xml).find("resultsLabel")[0].textContent : "",
      resultsUrl: $(xml).find("resultsUrl").length > 0 ? $(xml).find("resultsUrl")[0].textContent : "",
      resultsIcon: $(xml).find("resultsIcon").length > 0 ? $(xml).find("resultsIcon")[0].textContent : "",
      rollUpCompletion:
        $(xml).find("useChildren").length > 0 ? $(xml).find("useChildren")[0].textContent === "true" : false,
    };
    promoObj.promoStartDate = this.calculateStartDate(promo.campaigns, promo.scoreStartDate);
    promoObj.promoEndDate = this.calculateEndDate(promo.campaigns, promo.scoreEndDate);
    promoObj.displayMoreInfo = promo.children.length === 0 && promo.configCount > 0 ? true : false;
    promoObj.currencyValue = promoObj.displayAmount === "" ? false : true;
    promoObj.hideDollarSign = dollarExp.test(promoObj.displayCurrency) ? false : true;
    promoObj.hidePromoAction = promoObj.buttonText === "" ? true : false;
    promoObj.hideAllActions = promoObj.buttonText === "" && promoObj.videoHTML === "" ? true : false;
    promoObj.promoSponsorId = promo.promotionSponsorId;
    promoObj.configCount = promo.configCount;
    promoObj.indicator = promo.indicator;
    promoObj.progress = promo.promoProgress ? promo.promoProgress : null;
    if (!promoObj.rollUpCompletion) {
      promoObj.badgeData = promo.promoProgress ? this.buildBadgeData(promoObj) : null;
    }
    promoObj.newFlag = {};

    promoObj.children = [];

    function buildChildPromoObj(child) {
      var xml = $.parseXML(child.displayXML);
      var promoChildObj = {
        id: child.id,
        title: $(xml).find("title").length > 0 ? $(xml).find("title")[0].textContent : "",
        description: $(xml).find("description").length > 0 ? $(xml).find("description")[0].textContent : "",
        grandchildren: [],
        displayMoreInfo: child.grandchildren.length === 0 && child.configCount > 0 ? true : false,
        promoSponsorId: child.promotionSponsorId,
        configCount: child.configCount,
        displayOrder: $(xml).find("displayOrder").length > 0 ? parseInt($(xml).find("displayOrder")[0].textContent) : 0,
        progress: child.promoProgress ? child.promoProgress : null,
        progressType: $(xml).find("progressType").length > 0 ? $(xml).find("progressType")[0].textContent : "",
        minCount: $(xml).find("minCount").length > 0 ? $(xml).find("minCount")[0].textContent : "",
        maxCount: $(xml).find("maxCount").length > 0 ? $(xml).find("maxCount")[0].textContent : "",
        disclaimer: $(xml).find("disclaimer").length > 0 ? $(xml).find("disclaimer")[0].textContent : "",
        displayAmount: $(xml).find("displayAmount").length > 0 ? $(xml).find("displayAmount")[0].textContent : "",
        displayCurrency: $(xml).find("displayCurrency").length > 0 ? $(xml).find("displayCurrency")[0].textContent : "",
        capGroup: $(xml).find("capGroup").length > 0 ? $(xml).find("capGroup")[0].textContent : "",
        capGroupValue: $(xml).find("capGroupValue").length > 0 ? $(xml).find("capGroupValue")[0].textContent : 1,
        parent: child.parentId,
        rollUpCompletion:
          $(xml).find("useChildren").length > 0 ? $(xml).find("useChildren")[0].textContent === "true" : false,
        buttonText: $(xml).find("buttonText").length > 0 ? $(xml).find("buttonText")[0].textContent : "",
        buttonUrl: $(xml).find("buttonUrl").length > 0 ? $(xml).find("buttonUrl")[0].textContent : "",
      };
      promoChildObj.promoStartDate = this.calculateStartDate(promo.campaigns, promo.scoreStartDate);
      promoChildObj.promoEndDate = this.calculateEndDate(promo.campaigns, promo.scoreEndDate);
      promoChildObj.currencyValue = promoChildObj.displayAmount !== "";
      promoChildObj.hideDollarSign = !dollarExp.test(promoChildObj.displayCurrency);
      promoChildObj.badgeData = child.promoProgress ? this.buildBadgeData(promoChildObj) : null;
      //for Anthem, we will only display the completion indicator at the lowest level. That is not the case for other clients.
      promoChildObj.displayCompletionIndicator = true;

      // TODO: check if scope variable is acceptable, it was `var xml` before
      if (child.grandchildren.length === 1) {
        var gChildXML = $.parseXML(child.grandchildren[0].displayXML);
        var grandchildTitle = $(gChildXML).find("title").length > 0 ? $(gChildXML).find("title")[0].textContent : "";
        var grandchildDescription =
          $(gChildXML).find("description").length > 0 ? $(gChildXML).find("description")[0].textContent : "";
        if (promoChildObj.title !== grandchildTitle || promoChildObj.description !== grandchildDescription) {
          for (const grandchild of child.grandchildren) {
            promoChildObj.grandchildren.push(buildGrandchildPromoObj.bind(this)(grandchild));
          }
        } else if (promoChildObj.title === grandchildTitle && promoChildObj.description === grandchildDescription) {
          promoChildObj.displayMoreInfo = child.configCount > 0 ? true : false;
          promoChildObj.childPromoSponsorId = child.grandchildren[0].promotionSponsorId;
          promoChildObj.childConfigCount = child.grandchildren[0].configCount;
          promoChildObj.childPromoStartDate = this.calculateStartDate(
            child.grandchildren[0].campaigns,
            child.grandchildren[0].scoreStartDate
          );
          promoChildObj.childPromoEndDate = this.calculateEndDate(
            child.grandchildren[0].campaigns,
            child.grandchildren[0].scoreEndDate
          );
        }
      } else if (child.grandchildren.length > 0) {
        for (const grandchild of child.grandchildren) {
          promoChildObj.grandchildren.push(buildGrandchildPromoObj.bind(this)(grandchild));
        }
      }

      function buildGrandchildPromoObj(grandchild) {
        var xml = $.parseXML(grandchild.displayXML);
        var promoGrandchildObj = {
          id: grandchild.id,
          title: $(xml).find("title").length > 0 ? $(xml).find("title")[0].textContent : "",
          description: $(xml).find("description").length > 0 ? $(xml).find("description")[0].textContent : "",
          displayMoreInfo: grandchild.configCount > 0 ? true : false,
          promoSponsorId: grandchild.promotionSponsorId,
          configCount: grandchild.configCount,
          displayOrder:
            $(xml).find("displayOrder").length > 0 ? parseInt($(xml).find("displayOrder")[0].textContent) : 0,
          progress: grandchild.promoProgress ? grandchild.promoProgress : null,
          progressType: $(xml).find("progressType").length > 0 ? $(xml).find("progressType")[0].textContent : "",
          minCount: $(xml).find("minCount").length > 0 ? $(xml).find("minCount")[0].textContent : "",
          maxCount: $(xml).find("maxCount").length > 0 ? $(xml).find("maxCount")[0].textContent : "",
          disclaimer: $(xml).find("disclaimer").length > 0 ? $(xml).find("disclaimer")[0].textContent : "",
          capGroup: $(xml).find("capGroup").length > 0 ? $(xml).find("capGroup")[0].textContent : "",
          capGroupValue: $(xml).find("capGroupValue").length > 0 ? $(xml).find("capGroupValue")[0].textContent : 1,
          displayCurrency:
            $(xml).find("displayCurrency").length > 0 ? $(xml).find("displayCurrency")[0].textContent : "",
          displayAmount: $(xml).find("displayAmount").length > 0 ? $(xml).find("displayAmount")[0].textContent : "",
          buttonText: $(xml).find("buttonText").length > 0 ? $(xml).find("buttonText")[0].textContent : "",
          buttonUrl: $(xml).find("buttonUrl").length > 0 ? $(xml).find("buttonUrl")[0].textContent : "",
        };
        promoGrandchildObj.promoStartDate = this.calculateStartDate(grandchild.campaigns, grandchild.scoreStartDate);
        promoGrandchildObj.promoEndDate = this.calculateEndDate(grandchild.campaigns, grandchild.scoreEndDate);
        promoGrandchildObj.currencyValue = promoGrandchildObj.displayAmount === "" ? false : true;
        promoGrandchildObj.hideDollarSign = dollarExp.test(promoGrandchildObj.displayCurrency) ? false : true;
        promoGrandchildObj.badgeData = grandchild.promoProgress ? this.buildBadgeData(promoGrandchildObj) : null;
        promoGrandchildObj.hidePromoAction = promoGrandchildObj.buttonText === "" ? true : false;

        return promoGrandchildObj;
      }

      promoChildObj.grandchildren.sort(function (a, b) {
        return parseInt(a.displayOrder) > parseInt(b.displayOrder) ? 1 : -1;
      });
      return promoChildObj;
    }

    if (promo.children.length > 0) {
      for (const child of promo.children) {
        promoObj.children.push(buildChildPromoObj.bind(this)(child));
      }
    }

    promoObj.children.sort(function (a, b) {
      return parseInt(a.displayOrder) > parseInt(b.displayOrder) ? 1 : -1;
    });
    //if scope is built to roll up from the child completion, base completion on the completion of the child promos
    if (promoObj.rollUpCompletion) {
      promoObj.badgeData = promo.promoProgress ? this.rollUpBadgeData(promoObj) : null;
    }

    if (displayOn && displayUntil) {
      var on = displayOn,
        until = displayUntil,
        today = new Date(),
        started = false;
      if (promoObj.badgeData) {
        started = promoObj.badgeData.started ? true : false;
      }

      if (new Date(on) <= today && new Date(until) >= today && !started) {
        promoObj.newFlag = {
          type: "info",
          label: "New",
        };
      }
    }
    return promoObj;
  };
}

class AnthemMemberPromotionsDetail extends BasePromotionsDetail {
  groupThresholds;
  constructor(wteWrapper, personId, person, isSelected, activityDetails, searchFilters, http, baseUrl) {
    super(wteWrapper, personId, person, isSelected, activityDetails, searchFilters, http, baseUrl);
    this.groupThresholds = [];
  }
  buildGroups = () => {
    this.promoList = this.buildThresholdGroups.bind(this)(this.promoList);
    this.buildCapGroups(this.promoList);
  };

  sortPromos = () => {
    this.promoGroups.sort(function (a, b) {
      return parseInt(a.displayOrder) > parseInt(b.displayOrder) ? 1 : -1;
    });
  };

  buildCapGroups(promoList) {
    for (const promo of promoList) {
      if (promo.capGroup !== "" && promo.capGroup !== undefined) {
        promo.hasGatekeeper = false;
        buildCapGroupObject.bind(this)(promo);
      }
      if (promo.children && promo.children.length > 0) {
        for (const child of promo.children) {
          if (child.capGroup !== null && child.capGroup !== "") {
            child.isGatekeeper = false;
            buildCapGroupObject.bind(this)(child);
          }
        }
      }

      function buildCapGroupObject(promo) {
        var capGroupName = promo.capGroup;
        var capGroupValue = promo.capGroupValue;

        let updateCapGroup = () => {
          for (var i = 0; i < this.capGroups.length; i++) {
            if (this.capGroups[i].capGroupName === capGroupName) {
              this.capGroups[i].parents.push(promo.parent);
              this.capGroups[i].minimumDisplayOrder =
                this.capGroups[i].minimumDisplayOrder < promo.displayOrder
                  ? this.capGroups[i].minimumDisplayOrder
                  : promo.displayOrder;
              return true;
            }
          }
          return false;
        };

        if (!updateCapGroup()) {
          var capGroupObj = {
            capGroupName: capGroupName,
            capGroupValue: capGroupValue,
            parents: [],
            minimumDisplayOrder: promo.displayOrder,
          };
          if (promo.parent) {
            capGroupObj.parents.push(promo.parent);
          }
          this.capGroups.push(capGroupObj);
        }
      }
    }

    let handleNoCapGroup = (promo) => {
      // promo is not in a cap group, so it needs it's own personal group
      var singlePromoGroupObj = {};
      singlePromoGroupObj.identifier = promo.id;
      //  display order for non-grouped special cases go here
      if (promo.hasGatekeeper === true) {
        singlePromoGroupObj.displayOrder = 0;
      } else {
        // everything else gets a healthy number added so we have breathing room for new special cases
        singlePromoGroupObj.displayOrder = promo.displayOrder + 5000;
      }

      singlePromoGroupObj.promos = [];
      singlePromoGroupObj.promos.push(promo);
      this.promoGroups.push(singlePromoGroupObj);
    };

    // set up "OR" grouping
    let finalizeAnthemPromoGroups = (capGroups, promoList) => {
      for (const promo of promoList) {
        // we are only concerned about parent promotions
        if (!promo.parent) {
          if (capGroups.length > 0) {
            var promoWasFoundInCapGroup = false;
            for (const capGroup of capGroups) {
              var capGroupContainsCurrentPromo = capGroup.parents.indexOf(promo.id) !== -1;
              // only do scope one time
              if (capGroupContainsCurrentPromo && !promoWasFoundInCapGroup) {
                // check promoGroups to see if we've already found scope capgroup before
                var groupExists = this.promoGroups.some(function (promoGroup, index) {
                  if (promoGroup.identifier === capGroup.parents.sort().join(",")) {
                    // group for scope promo already exists, so use it
                    promoGroup.promos.push(promo);
                    return true;
                  }
                });

                if (!groupExists) {
                  // must be new group
                  var newPromoGroupObj = {};
                  promo.isSelected = true;
                  newPromoGroupObj.identifier = capGroup.parents.sort().join(",");
                  // display order for grouped special cases
                  if (promo.isGatekeeper) {
                    newPromoGroupObj.displayOrder = 1;
                  } else {
                    newPromoGroupObj.displayOrder = 2;
                  }

                  newPromoGroupObj.promos = [];
                  newPromoGroupObj.promos.push(promo);
                  this.promoGroups.push(newPromoGroupObj);
                }
                // set scope so we don't double dip the promo
                promoWasFoundInCapGroup = true;
              }
            }
            // only do scope one time
            if (!promoWasFoundInCapGroup) {
              handleNoCapGroup(promo);
            }
          } else {
            // if there are no capGroups we still need to build the object that the template will use
            handleNoCapGroup(promo);
          }
        }
      }
    };

    finalizeAnthemPromoGroups(this.capGroups, this.promoList);
  }

  buildThresholdGroups(promoList) {
    var filteredPromoList = promoList.filter(function (p) {
      return p.thresholdGroup === "" ? true : false;
    });
    var thresholdPromotions = promoList.filter(function (p) {
      return p.thresholdGroup !== "" ? true : false;
    });

    for (const group of this.groupThresholds) {
      var newTitle = "";
      var newDescription = "";
      var squishedPromoObj = {};
      squishedPromoObj.promotions = [];
      squishedPromoObj.displayAmount = 0;
      var add = false;
      var thresholdPromoCount = 0;
      for (const promo of thresholdPromotions) {
        promo.hasGatekeeper = false;
        if (promo.thresholdGroup === group) {
          thresholdPromoCount++;
          add = true;
          var numberToComplete;
          numberToComplete =
            promo.thresholdGroupMin && promo.thresholdGroupMin !== "All" ? promo.thresholdGroupMin : "each";
          squishedPromoObj.thresholdGroup = promo.thresholdGroup;
          squishedPromoObj.thresholdGroupMin =
            promo.thresholdGroupMin && promo.thresholdGroupMin !== "All" ? promo.thresholdGroupMin : null;
          squishedPromoObj.displayOrder = 0;
          squishedPromoObj.category = promo.category;
          squishedPromoObj.hideDetails = true;
          squishedPromoObj.thresholdGroupTitle =
            "To receive credit you must complete " + numberToComplete + " of the following programs:";
          squishedPromoObj.displayAmount = parseInt(squishedPromoObj.displayAmount) + parseInt(promo.displayAmount);
          squishedPromoObj.displayRewardImageUrl = promo.displayRewardImageUrl;
          squishedPromoObj.thresholdGroupCount = thresholdPromoCount;
          squishedPromoObj.promotions.push(promo);
          if (!squishedPromoObj.thresholdGroupMin) {
            squishedPromoObj.hideDollarSign = false;
            squishedPromoObj.currencyValue = promo.currencyValue;
            squishedPromoObj.displayCurrency = promo.displayCurrency;
          }
        }
      }
      if (add) {
        filteredPromoList.push(squishedPromoObj);
      }
    }
    return filteredPromoList;
  }

  buildPromoObj = (promo) => {
    var xml = $.parseXML(promo.displayXML);
    var dollarExp = new RegExp("^dollar", "i");
    var defaultRewardImage =
      "https://a89ddf5339eed28f17d8-9b103dd600868b1ec842fb3e60f99373.ssl.cf2.rackcdn.com/assets/rewardicon_star_60x60-a8bc24147126828cafd699d4dc030ea7.png";
    var alternatives =
      $(xml).find("alternativesConfiguration").length > 0 ? $(xml).find("alternativesConfiguration")[0].childNodes : [];
    var alternativesConfiguration = {};

    if (alternatives) {
      for (var i = 0; i < alternatives.length; i++) {
        var item = alternatives[i];
        alternativesConfiguration[item.nodeName] = item.textContent;
      }
    }
    if (alternativesConfiguration.displayLink === "false") {
      alternativesConfiguration.displayLink = false;
    }

    if (alternativesConfiguration.linkLabel === "") {
      alternativesConfiguration.linkLabel = "Reasonable alternative available on member portal";
    }

    var displayOn = $(xml).find("displayNewFlagOn").length > 0 ? $(xml).find("displayNewFlagOn")[0].textContent : "";
    var displayUntil =
      $(xml).find("displayNewFlagUntil").length > 0 ? $(xml).find("displayNewFlagUntil")[0].textContent : "";

    var promoObj = {
      id: promo.id,
      title: promo.name,
      subtitle: $(xml).find("subtitle").length > 0 ? $(xml).find("subtitle")[0].textContent : "",
      unfriendlyTitle: $(xml).find("title").length > 0 ? $(xml).find("title")[0].textContent : "",
      description: promo.description,
      displayRewardImageUrl:
        $(xml).find("displayRewardImageUrl").length > 0
          ? $(xml).find("displayRewardImageUrl")[0].textContent
          : defaultRewardImage,
      buttonText: $(xml).find("buttonText").length > 0 ? $(xml).find("buttonText")[0].textContent : "",
      buttonUrl: $(xml).find("buttonUrl").length > 0 ? $(xml).find("buttonUrl")[0].textContent : "",
      videoHTML: $(xml).find("videoHTML").length > 0 ? $(xml).find("videoHTML")[0].textContent : "",
      displayOrder: $(xml).find("displayOrder").length > 0 ? parseInt($(xml).find("displayOrder")[0].textContent) : 0,
      hideDetails: true,
      category: promo.promotionCategoryName,
      alternativesConfiguration: alternativesConfiguration,
      progressType: $(xml).find("progressType").length > 0 ? $(xml).find("progressType")[0].textContent : "",
      minCount: $(xml).find("minCount").length > 0 ? $(xml).find("minCount")[0].textContent : "",
      maxCount: $(xml).find("maxCount").length > 0 ? $(xml).find("maxCount")[0].textContent : "",
      disclaimer: $(xml).find("disclaimer").length > 0 ? $(xml).find("disclaimer")[0].textContent : "",
      capGroup: $(xml).find("capGroup").length > 0 ? $(xml).find("capGroup")[0].textContent : "",
      capGroupValue: $(xml).find("capGroupValue").length > 0 ? $(xml).find("capGroupValue")[0].textContent : 1,
      thresholdGroup: $(xml).find("thresholdGroup").length > 0 ? $(xml).find("thresholdGroup")[0].textContent : "",
      thresholdGroupMin:
        $(xml).find("thresholdGroupMin").length > 0 ? $(xml).find("thresholdGroupMin")[0].textContent : "",
      promoGrouping: -1,
      isSelected: false,
      showResults: $(xml).find("showResults").length > 0 ? $(xml).find("showResults")[0].textContent : false,
      resultsLabel: $(xml).find("resultsLabel").length > 0 ? $(xml).find("resultsLabel")[0].textContent : "",
      resultsUrl: $(xml).find("resultsUrl").length > 0 ? $(xml).find("resultsUrl")[0].textContent : "",
      resultsIcon: $(xml).find("resultsIcon").length > 0 ? $(xml).find("resultsIcon")[0].textContent : "",
      rollUpCompletion:
        $(xml).find("useChildren").length > 0 ? $(xml).find("useChildren")[0].textContent === "true" : false,
    };
    promoObj.promoStartDate = this.calculateStartDate(promo.campaigns, promo.scoreStartDate);
    promoObj.promoEndDate = this.calculateEndDate(promo.campaigns, promo.scoreEndDate);
    (promoObj.nonMemberFacingTitle =
      $(xml).find("title").length > 0 &&
      $(xml).find("title")[0].textContent.toUpperCase() !== promoObj.unfriendlyTitle.toUpperCase()
        ? $(xml).find("title")[0].textContent
        : null),
      (promoObj.displayMoreInfo = promo.children.length === 0 && promo.configCount > 0 ? true : false);
    promoObj.hidePromoAction = promoObj.buttonText === "" ? true : false;
    promoObj.hideAllActions = promoObj.buttonText === "" && promoObj.videoHTML === "" ? true : false;
    promoObj.promoSponsorId = promo.promotionSponsorId;
    promoObj.configCount = promo.configCount;
    promoObj.indicator = promo.indicator;
    promoObj.progress = promo.promoProgress ? promo.promoProgress : null;
    if (!promoObj.rollUpCompletion) {
      promoObj.badgeData = promo.promoProgress ? this.buildBadgeData(promoObj) : null;
    }
    promoObj.displayAmount = "";
    promoObj.promotionCategoryName = promo.promotionCategoryName;
    promoObj.promotionCategorySort = promo.promotionCategorySort;
    promoObj.newFlag = {};

    if (displayOn && displayUntil) {
      var on = displayOn,
        until = displayUntil,
        today = new Date(),
        started = false;
      if (promoObj.badgeData) {
        // TODO: check if started needed to be var here
        started = promoObj.badgeData.started ? true : false;
      }

      if (new Date(on) <= today && new Date(until) >= today && !started) {
        promoObj.newFlag = {
          type: "info",
          label: "New",
        };
      }
    }
    promoObj.children = [];
    if (promo.children.length > 0) {
      for (const child of promo.children) {
        promoObj.children.push(buildChildPromoObj.bind(this)(child));

        let thresholdGroupExists = (groupName) => {
          for (i = 0; i < this.groupThresholds.length; i++) {
            if (this.groupThresholds[i] === groupName) {
              return true;
            }
          }
          return false;
        };

        if (child.thresholdGroup !== "") {
          if (!thresholdGroupExists(child.thresholdGroup, this.groupThresholds)) {
            this.groupThresholds.push(child.thresholdGroup);
          }
        }
      }
    }

    function buildChildPromoObj(child) {
      var xml = $.parseXML(child.displayXML);
      var promoChildObj = {
        id: child.id,
        title: $(xml).find("title").length > 0 ? $(xml).find("title")[0].textContent : "",
        memberFacingTitle: child.name,
        description: child.description,
        grandchildren: [],
        displayMoreInfo: child.grandchildren.length === 0 && child.configCount > 0 ? true : false,
        promoSponsorId: child.promotionSponsorId,
        configCount: child.configCount,
        displayOrder: $(xml).find("displayOrder").length > 0 ? parseInt($(xml).find("displayOrder")[0].textContent) : 0,
        progress: child.promoProgress ? child.promoProgress : null,
        progressType: $(xml).find("progressType").length > 0 ? $(xml).find("progressType")[0].textContent : "",
        minCount: $(xml).find("minCount").length > 0 ? $(xml).find("minCount")[0].textContent : "",
        maxCount: $(xml).find("maxCount").length > 0 ? $(xml).find("maxCount")[0].textContent : "",
        disclaimer: $(xml).find("disclaimer").length > 0 ? $(xml).find("disclaimer")[0].textContent : "",
        displayAmount: $(xml).find("displayAmount").length > 0 ? $(xml).find("displayAmount")[0].textContent : "",
        displayCurrency: $(xml).find("displayCurrency").length > 0 ? $(xml).find("displayCurrency")[0].textContent : "",
        capGroup: $(xml).find("capGroup").length > 0 ? $(xml).find("capGroup")[0].textContent : "",
        capGroupValue: $(xml).find("capGroupValue").length > 0 ? $(xml).find("capGroupValue")[0].textContent : 1,
        thresholdGroup: $(xml).find("thresholdGroup").length > 0 ? $(xml).find("thresholdGroup")[0].textContent : "",
        thresholdGroupMin:
          $(xml).find("thresholdGroupMin").length > 0 ? $(xml).find("thresholdGroupMin")[0].textContent : "",
        //needed to calculate Anthem's completion indicators for goal based promotions, which are at the activity level. Apparently scope will only exist on goal based ones
        goalBased: $(xml).find("resultsIndicator").length > 0,
        parent: child.parentId,
        isGatekeeper:
          $(xml).find("isGatekeeper").length > 0
            ? $(xml).find("isGatekeeper")[0].textContent === "true"
              ? true
              : false
            : false,
      };
      promoChildObj.promoStartDate = this.calculateStartDate(child.campaigns, child.scoreStartDate);
      promoChildObj.promoEndDate = this.calculateEndDate(child.campaigns, child.scoreEndDate);
      promoChildObj.currencyValue = promoChildObj.displayAmount === "" ? false : true;
      promoChildObj.hideDollarSign = dollarExp.test(promoChildObj.displayCurrency) ? false : true;
      promoChildObj.badgeData = child.promoProgress ? this.buildBadgeData(promoChildObj) : null;
      promoChildObj.inCapGroup = $(xml).find("capGroup").length > 0 ? true : false;
      promoChildObj.displayCompletionIndicator = child.grandchildren.length > 0 ? false : true;
      promoObj.currencyValue = promoObj.displayAmount === "" ? false : true;
      promoObj.hideDollarSign = false;
      dollarExp.test(promoObj.displayCurrency) ? false : true;
      promoObj.thresholdGroup = promoObj.thresholdGroup ? promoObj.thresholdGroup : promoChildObj.thresholdGroup;
      promoObj.thresholdGroupMin = promoObj.thresholdGroupMin
        ? promoObj.thresholdGroupMin
        : promoChildObj.thresholdGroupMin;
      promoObj.inCapGroup = promoObj.inCapGroup ? promoObj.inCapGroup : promoChildObj.inCapGroup;
      promoObj.capGroup = promoObj.capGroup ? promoObj.capGroup : promoChildObj.capGroup;
      promoObj.hasGatekeeper = promoObj.hasGatekeeper ? true : promoChildObj.isGatekeeper;
      if (promoChildObj.isGatekeeper === true) {
        // if child is a gatekeeper, it should be at top
        promoChildObj.displayOrder = 0;
      } else if (promoChildObj.displayAmount === "") {
        //Anthem wants child promotions that don't have a display amount to pull to the bottom
        promoChildObj.displayOrder = promoChildObj.displayOrder ? 10000 : promoChildObj.displayOrder + 10000;
      } else {
        // padding our display order for everything else
        promoChildObj.displayOrder += 5000;
      }

      if (child.grandchildren.length === 1) {
        // TODO: check if scope needs to be new var, it was `var xml` before
        var gChildrenXML = $.parseXML(child.grandchildren[0].displayXML);
        var grandchildTitle =
          $(gChildrenXML).find("title").length > 0 ? $(gChildrenXML).find("title")[0].textContent : "";
        var grandchildDescription =
          $(gChildrenXML).find("description").length > 0 ? $(gChildrenXML).find("description")[0].textContent : "";
        if (promoChildObj.title !== grandchildTitle || promoChildObj.description !== grandchildDescription) {
          for (const grandchild of child.grandchildren) {
            promoChildObj.grandchildren.push(buildGrandchildPromoObj.bind(this)(grandchild, promoChildObj));
          }
        } else if (promoChildObj.title === grandchildTitle && promoChildObj.description === grandchildDescription) {
          promoChildObj.displayMoreInfo = child.configCount > 0 ? true : false;
          promoChildObj.childPromoSponsorId = child.grandchildren[0].promotionSponsorId;
          promoChildObj.childConfigCount = child.grandchildren[0].configCount;
          promoChildObj.childPromoStartDate = this.calculateStartDate(
            child.grandchildren[0].campaigns,
            child.grandchildren[0].scoreStartDate
          );
          promoChildObj.childPromoEndDate = this.calculateEndDate(
            child.grandchildren[0].campaigns,
            child.grandchildren[0].scoreEndDate
          );
        }
      } else if (child.grandchildren.length > 0) {
        for (const grandchild of child.grandchildren) {
          promoChildObj.grandchildren.push(buildGrandchildPromoObj.bind(this)(grandchild, promoChildObj));
        }
      }

      function buildGrandchildPromoObj(grandchild, childObj) {
        var xml = $.parseXML(grandchild.displayXML);
        var promoGrandchildObj = {
          id: grandchild.id,
          title: $(xml).find("title").length > 0 ? $(xml).find("title")[0].textContent : "",
          description: grandchild.description,
          displayMoreInfo: grandchild.configCount > 0 ? true : false,
          promoSponsorId: grandchild.promotionSponsorId,
          configCount: grandchild.configCount,
          displayOrder:
            $(xml).find("displayOrder").length > 0 ? parseInt($(xml).find("displayOrder")[0].textContent) : 0,
          progress: grandchild.promoProgress ? grandchild.promoProgress : null,
          progressType: $(xml).find("progressType").length > 0 ? $(xml).find("progressType")[0].textContent : "",
          minCount: $(xml).find("minCount").length > 0 ? $(xml).find("minCount")[0].textContent : "",
          maxCount: $(xml).find("maxCount").length > 0 ? $(xml).find("maxCount")[0].textContent : "",
          disclaimer: $(xml).find("disclaimer").length > 0 ? $(xml).find("disclaimer")[0].textContent : "",
          capGroup: $(xml).find("capGroup").length > 0 ? $(xml).find("capGroup")[0].textContent : "",
          capGroupValue: $(xml).find("capGroupValue").length > 0 ? $(xml).find("capGroupValue")[0].textContent : 1,
          displayCurrency:
            $(xml).find("displayCurrency").length > 0 ? $(xml).find("displayCurrency")[0].textContent : "",
          displayAmount: $(xml).find("displayAmount").length > 0 ? $(xml).find("displayAmount")[0].textContent : "",
          parent: grandchild.parentId,
          goalBased: childObj.goalBased,
          goalMet: childObj.progress ? childObj.progress.rewardCount > 0 : false,
          buttonText: $(xml).find("buttonText").length > 0 ? $(xml).find("buttonText")[0].textContent : "",
          buttonUrl: $(xml).find("buttonUrl").length > 0 ? $(xml).find("buttonUrl")[0].textContent : "",
        };
        promoGrandchildObj.promoStartDate = this.calculateStartDate(grandchild.campaigns, grandchild.scoreStartDate);
        promoGrandchildObj.promoEndDate = this.calculateEndDate(grandchild.campaigns, grandchild.scoreEndDate);
        promoGrandchildObj.currencyValue = promoGrandchildObj.displayAmount === "" ? false : true;
        promoGrandchildObj.hideDollarSign = dollarExp.test(promoGrandchildObj.displayCurrency) ? false : true;
        promoGrandchildObj.badgeData = grandchild.promoProgress ? this.buildBadgeData(promoGrandchildObj) : null;
        promoGrandchildObj.hidePromoAction = promoGrandchildObj.buttonText === "" ? true : false;
        // pad display order for infinite number of special cases
        promoGrandchildObj.displayOrder += 5000;
        return promoGrandchildObj;
      }

      //if scope is built to roll up from the child completion, base completion on the completion of the child promos
      if (promoObj.rollUpCompletion) {
        promoObj.badgeData = promo.promoProgress ? this.rollUpBadgeData(promoObj) : null;
      }
      return promoChildObj;
    }

    //We have to total the incentives from the milestones for Anthem for display
    var total = 0;

    if (promoObj.children.length > 0) {
      var displayCurrency;
      for (const child of promoObj.children) {
        if (child.displayAmount > 0) {
          total = parseInt(total) + parseInt(child.displayAmount);
          displayCurrency = child.displayCurrency;
        }
      }

      if (total > 0) {
        promoObj.displayAmount = total;
        promoObj.hideDollarSign = dollarExp.test(displayCurrency) ? false : true;
        promoObj.displayCurrency = displayCurrency;
        promoObj.currencyValue = true;
      } else if (promoObj.hasGatekeeper && promoObj.thresholdGroup === "") {
        promoObj.displayAmount = 0;
        promoObj.displayCurrency = false;
        promoObj.currencyValue = false;
        promoObj.displayOrder = 0;
      } else {
        promoObj.promotionCategorySort = 2;
        promoObj.category = "Non Incented Programs";
      }
    } else {
      promoObj.promotionCategorySort = 2;
      promoObj.category = "Non Incented Programs";
    }
    var categoryOrder = promoObj.category + "-" + promoObj.promotionCategorySort;
    if ($.inArray(categoryOrder, this.categoryOrders) === -1) {
      this.categoryOrders.push(categoryOrder);
    }

    return promoObj;
  };
}

// module.exports = MemberPromotionsDetail;
export { StandardPromotionsDetail, AnthemMemberPromotionsDetail };
