import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CampaignService } from '../services/campaign.service';
import { Campaign } from '../data/model';
import { PromotionService } from '../services/promotion.service';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-campaign-modal',
  templateUrl: './add-campaign-modal.component.html',
  styleUrls: ['./add-campaign-modal.component.scss'],
})
export class AddCampaignModalComponent implements OnInit {
  @Input() inputData: any;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  campaignForm: UntypedFormGroup;
  errorMsg = '';
  campaigns: Campaign[] = [];
  faTimes = faTimes;

  constructor(
    private modalHelper: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private campaignService: CampaignService,
    private promotionService: PromotionService,
    private responseHelper: ResponseHelperService
  ) {}

  ngOnInit(): void {
    this.title = 'Add Program to promotion';
    this.campaignForm = this.formBuilder.group({
      campaignId: [null, Validators.required],
    });
    this.campaignService.getAllCampaigns({ statuses: ['ACTIVE', 'PENDING', 'STARTED'] }).subscribe((data: any) => {
      if (data.success) {
        this.campaigns = data.entity.records;
      }
    });
  }

  ok(): void {
    this.promotionService.addCampaignToPromotion({ ...this.inputData, ...this.campaignForm.value }).subscribe(
      (data: any) => {
        this.successEvent.emit(data);
        this.responseHelper.success('Program successfully created', true);
      },
      (data: any) => {
        this.errorMsg = data.error.error;
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
