import { Injectable } from '@angular/core';
import getLogger from './logging/logging.service';
import { OrganizationsService } from '../services/organizations.service';
import { CampaignService } from '../services/campaign.service';
import {
  PARENT,
  CLIENT,
  LINE_OF_BUSINESS,
  MARKET,
  PARENT_ROOT,
  PROGRAM,
  PACKAGED_SPONSORSHIP,
} from '../organization/organizations/constants';
import {
  CLIENT_ROUTE_PATH,
  LINE_OF_BUSINESS_ROUTE_PATH,
  MARKET_ROUTE_PATH,
  PACKAGED_SPONSORSHIP_ROUTE_PATH,
  PARENTS_ROUTE_PATH,
  PROGRAM_ROUTE_PATH,
} from '../route.constants';
import { Campaign, Organization } from '../data/model';

export type Breadcrumb = {
  text: any;
  url: any;
};

const logger = getLogger({ namespace: 'Organizations' });

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  constructor(private organizationsService: OrganizationsService, private campaignService: CampaignService) {}

  async createOrganizationBreadcrumbTrail(route: string): Promise<Breadcrumb[]> {
    const routeSegments = route.split('/');
    routeSegments[0] = 'Organizations';

    const [, , parentId, , clientId, , lineOfBusinessId, , marketId, , campaignId, , sponsorshipTag, ,] = routeSegments;

    const parentRouteSegment = `${PARENTS_ROUTE_PATH}/${parentId}`;
    const clientRouteSegment = `${parentRouteSegment}/${CLIENT_ROUTE_PATH}/${clientId}`;
    const lineOfBusinessRouteSegment = `${clientRouteSegment}/${LINE_OF_BUSINESS_ROUTE_PATH}/${lineOfBusinessId}`;
    const marketRouteSegment = `${lineOfBusinessRouteSegment}/${MARKET_ROUTE_PATH}/${marketId}`;
    const programRouteSegment = `${marketRouteSegment}/${PROGRAM_ROUTE_PATH}/${campaignId}`;
    const packagedSponsorshipRouteSegment = `${programRouteSegment}/${PACKAGED_SPONSORSHIP_ROUTE_PATH}/${sponsorshipTag}`;

    if (routeSegments.length < 2) {
      logger.error({
        message: 'Unable to parse route segments.',
        data: { route },
      });
      return [];
    }

    if (routeSegments.length === 2) {
      return [
        {
          text: PARENT_ROOT,
          url: routeSegments[1],
        },
      ];
    }

    const namedRouteSegments = [
      PARENT_ROOT,
      PARENTS_ROUTE_PATH,
      CLIENT_ROUTE_PATH,
      LINE_OF_BUSINESS_ROUTE_PATH,
      MARKET_ROUTE_PATH,
      PROGRAM_ROUTE_PATH,
      PACKAGED_SPONSORSHIP_ROUTE_PATH,
    ];

    const namedSegmentsInRoute = routeSegments.filter((segment) => namedRouteSegments.includes(segment));

    const crumbPromises: Promise<any>[] = namedSegmentsInRoute.map(async (segment) => {
      switch (segment) {
        case PARENT_ROOT:
          return this.buildBreadcrumb(null, null, PARENT_ROOT, routeSegments, null, null);
        case PARENTS_ROUTE_PATH:
          return this.buildBreadcrumb(parentId, PARENT, parentRouteSegment, routeSegments, null, null);
        case CLIENT_ROUTE_PATH:
          return this.buildBreadcrumb(clientId, CLIENT, clientRouteSegment, routeSegments, null, null);
        case LINE_OF_BUSINESS_ROUTE_PATH:
          return this.buildBreadcrumb(
            lineOfBusinessId,
            LINE_OF_BUSINESS,
            lineOfBusinessRouteSegment,
            routeSegments,
            null,
            null
          );
        case MARKET_ROUTE_PATH:
          return this.buildBreadcrumb(marketId, MARKET, marketRouteSegment, routeSegments, null, null);
        case PROGRAM_ROUTE_PATH:
          return this.buildBreadcrumb(null, PROGRAM, programRouteSegment, routeSegments, campaignId, null);
        case PACKAGED_SPONSORSHIP_ROUTE_PATH:
          return this.buildBreadcrumb(
            null,
            PACKAGED_SPONSORSHIP,
            packagedSponsorshipRouteSegment,
            route,
            null,
            sponsorshipTag
          );
      }
    });

    try {
      return await Promise.all(crumbPromises);
    } catch (error) {
      logger.error({
        message: 'Unable to create breadcrumbs.',
        data: { route },
      });
      return [];
    }
  }

  async getOrganization(organizationId: string): Promise<Organization> {
    const response = await this.organizationsService.getHierarchicalOrganization(organizationId);
    return response.entity;
  }

  async getCampaign(campaignId: string): Promise<Campaign> {
    const response = await this.campaignService.getCampaignObs(Number(campaignId));
    return response.entity;
  }

  async buildBreadcrumb(
    organizationId,
    organizationType,
    organizationRoute,
    routeSegments,
    campaignId,
    sponsorshipTag
  ): Promise<Breadcrumb> {
    if (campaignId !== null) {
      const campaign = await this.getCampaign(campaignId);
      return {
        text: `Program: ${campaign.name}`,
        url: organizationRoute,
      };
    } else if (sponsorshipTag !== null) {
      return {
        text: `Packaged Sponsorship`,
        url: organizationRoute,
      };
    } else if (organizationId === null) {
      return {
        text: 'Organizations',
        url: routeSegments[1],
      };
    } else {
      const organization = await this.getOrganization(organizationId);
      return {
        text: `${organizationType}: ${organization.name}`,
        url: organizationRoute,
      };
    }
  }
}
