<form [formGroup]="locationForm">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Name</label>
          <input  formControlName="name"  [appTrim]="true" type="text" class="form-control"/>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Code <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" class="form-control" formControlName="code" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Hierarchy Source <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <ng-select
            [items]="hierarchySources"
            bindLabel="name"
            bindValue="id"
            formControlName="hierarchySourceId"
            [clearable]="false"
          >
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Status <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <select formControlName="status" class="form-control">
            <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Parent</label>
          <select formControlName="parentCode" class="form-control">
            <option *ngFor="let parent of filteredParents" value="{{ parent.code }}">{{ parent.code }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Description</label>
          <textarea formControlName="description" class="form-control vertical-expand"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!locationForm.valid"></app-modal-buttons>
  </div>
</form>
