import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import {
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faTimes,
  faChevronUp,
  faSpinner,
  faCheckSquare,
} from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Filter, SearchFilters } from 'src/app/data/class';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/services/utility.service';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Communication } from 'src/app/data/model';
import { ImportChannelHelper } from 'src/app/services/ImportChannelHelper.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';

@Component({
  selector: 'app-communication-history-tab',
  templateUrl: './communication-history-tab.component.html',
  styleUrls: ['./communication-history-tab.component.scss'],
})
export class CommunicationHistoryTabComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tabIndex: number;
  commHistory: any[];
  @Input() commId: number;
  partyId: string;
  firstName: string;
  lastName: string;
  partyAttributeName: string;
  partyAttributeValue: string;
  keyword: string;
  deliveredFrom: NgbDate;
  deliveredTo: NgbDate;
  createdTo: NgbDate;
  createdFrom: NgbDate;
  behaviorCode: string;
  pAttributeName: string;
  pAttributeValue: string;
  aAttributeName: string;
  aAttributeValue: string;
  fromDateValue: any;
  toDateValue: any;
  communicationName: string;
  communications: Communication[];
  from: string;
  to: string;
  limit: number;
  filter = new Filter();
  commHistKeyword: string;
  lengthToCompare = 0;
  lockedTables: boolean;
  lock: boolean;
  isLoading: boolean;
  resize = true;
  subject: Subject<any> = new Subject<any>();
  faTimes = faTimes;
  faChevronLeft = faChevronLeft;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faSpinner = faSpinner;
  faCheckSquare = faCheckSquare;
  faChevronRight = faChevronRight;
  expandedLookup = [];
  importChannels: any[];
  importChannel: any;
  searchFilters: SearchFilters;
  searchText: any;
  closeResult = '';
  modelpop: any;
  appliedFilters: Filter[];
  filterButton: boolean = false;
  filterdata: SearchFilters;
  chipData: any;
  helpers: any;
  chipDisplay: boolean = false;
  applyChip: boolean = false;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  communicationSubject: Subject<string> = new Subject<string>();

  constructor(
    private communicationsService: CommunicationsService,
    private utilityService: UtilityService,
    private toastr: ToastrService,
    private dateHelperService: DateHelperService,
    private ngbCalendar: NgbCalendar,
    private importChannelHelper: ImportChannelHelper,
    private modalService: NgbModal,
    private applyfilterService: ApplyFilterService,
    private dateSerice: DateHelperService
  ) {}
  ngOnDestroy() {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }

  open(content, applyFilter) {
    this.modelpop = this.modalService.open(content, { size: 'lg', windowClass: 'filter_model' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  cancel() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.importChannels = this.importChannelHelper.getImportChannels();
    this.getComminications();
    this.initNewSearchHistory(false);
    this.subject.pipe(debounceTime(300), distinctUntilChanged()).subscribe((item) => {
      if (item.search !== '') {
        this.filter.search = item.search;
      } else {
        delete this.filter.search;
      }
      this.initFilter();
    });
    this.loadCommunication();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getComminications();
    if (changes.commId) {
      this.clearAllChip();
    }
  }

  initNewSearchHistory(applyFilter: boolean): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getCommunicationsHistory(false, applyFilter);
  }

  initFilter(): void {
    this.limit = 20;
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.column = 1;
    this.filter.dir = 'desc';
  }

  clearLookup(): void {
    this.expandedLookup = [];
  }

  getComminications(communicationName?: string): void {
    let param = communicationName ? { communicationName } : {};
    this.communicationsService.getCommunications(param).subscribe(
      (data: any) => {
        if (data.success) {
          this.communications = data.entity.records;
        }
      },
      () => {
        this.toastr.error('Error occured');
      }
    );
  }

  loadCommunication(): void {
    this.communicationSubject.pipe(debounceTime(200), distinctUntilChanged(), switchMap(term => {
      let param = term ? { communicationName: term } : {};
      return this.communicationsService.getCommunications(param)
    })).subscribe((data: any) => {
      if (data.success) {
        this.communications = data.entity.records;
      }
    });
  }

  chipConditionalrendering(value) {
    if (value === 'column') {
      return false;
    } else if (value === 'limit') {
      return false;
    } else if (value === 'dir') {
      return false;
    } else if (value === 'offset') {
      return false;
    } else if (value === 'commId') {
      return false;
    } else {
      return true;
    }
  }

  clearForm(objKey) {
    if (objKey === 'partyId') {
      this.partyId = '';
    } else if (objKey === 'firstName') {
      this.firstName = '';
    } else if (objKey === 'lastName') {
      this.lastName = '';
    } else if (objKey === 'pAttributeValue') {
      this.pAttributeValue = '';
    } else if (objKey === 'pAttributeValue') {
      this.pAttributeValue = '';
    } else if (objKey === 'keyword') {
      this.keyword = '';
    } else if (objKey === 'deliveredFrom') {
      this.deliveredFrom = null;
    } else if (objKey === 'deliveredTo') {
      this.deliveredTo = null;
    } else if (objKey === 'createdTo') {
      this.createdTo = null;
    } else if (objKey === 'createdFrom') {
      this.createdFrom = null;
    } else if (objKey === 'communicationName') {
      this.communicationName = null;
    }
  }

  deleteChip(check: boolean, key: string) {
    if (check) {
      delete this.filter[key];
    }
  }

  clearAllChip(): void {
    for (var k in this.filter) {
      this.clearForm(k);
      if (k === 'limit') {
        this.deleteChip(false, k);
      } else if (k === 'offset') {
        this.deleteChip(false, k);
      } else if (k === 'column') {
        this.deleteChip(false, k);
      } else if (k === 'dir') {
        this.deleteChip(false, k);
      } else if (k === 'commId') {
        this.deleteChip(false, k);
      } else {
        this.deleteChip(true, k);
      }
    }
    this.chipDisplay = false;
    this.initFilter();
    this.getCommunicationsHistory(false, false);
    this.getComminications();
  }

  getCommunicationsHistory(concat: boolean, applyFilter: boolean): void {
    this.isLoading = true;
    this.filter.commId = this.commId;
    this.filter = this.utilityService.removeNullAndEmptyProperties(this.filter);
    this.communicationsService.getCommunicationsHistory(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.commHistory = this.commHistory.concat(data.entity);
        } else {
          this.commHistory = data.entity;
        }
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;

        // if (this.filterLength(this.filter) <= 4) {
        //   this.chipDisplay = false;
        // } else {
        //   this.chipDisplay = true;
        // }
        for (var k in this.filter) {
          if (k === 'limit' || k === 'offset' || k === 'column' || k === 'dir' || k === 'commId') {
            this.chipDisplay = false;
          } else {
            this.chipDisplay = true;
          }
        }
        if (applyFilter) {
          this.modalService.dismissAll();
        }
        // console.log(this.filterLength(this.filter));
      },
      (error) => {
        this.toastr.error(error.error.error);
        this.isLoading = false;
        this.modalService.dismissAll();
      }
    );
  }

  filterLength(data) {
    const size = Object.keys(data).length;
    return size;
  }

  resizeIbox(): void {
    this.resize = !this.resize;
  }

  setFilter(filterKey: string, filterValue: any, convertFromNgbToString?: boolean): void {
    if (convertFromNgbToString) {
      /*
          if (convertFromNgbToString) {
      this[filterKey] = this.dateSerice.ngbDateToString(filterValue);
      this.filter[filterKey] = this.dateSerice.ngbDateToString(filterValue);
    }
       */
      this[filterKey] = this.dateSerice.ngbDateToString(filterValue);
      this.filter[filterKey] = this.dateSerice.ngbDateToString(filterValue);
      /*var formatedDate = null;
      if (filterKey === 'deliveredTo' || filterKey === 'createdTo') {
        formatedDate = this.ngbToString(this.ngbCalendar.getNext(filterValue, 'd', 1));
      } else {
        formatedDate = this.ngbToString(filterValue);
      }
      if (formatedDate) {
        this[filterKey] = formatedDate;
        this.filter[filterKey] = formatedDate;
      }*/
    } else {
      this[filterKey] = filterValue;
      this.filter[filterKey] = filterValue;
    }
  }

  private ngbToString(dateToFormat: NgbDate): string {
    return dateToFormat.month + '-' + dateToFormat.day + '-' + dateToFormat.year;
  }

  removeChip(filterKey) {
    if (filterKey === 'communicationName') {
      this.getComminications();
    }
    delete this.filter[filterKey];
    this.clearForm(filterKey);
    this.filter.offset = 0;
    this.getCommunicationsHistory(false, true);
  }

  handleFilterSegments($event: any): any {
    this.filter.segmentIds = $event;
  }

  clearInput(input: any): void {
    this[input] = '';
    delete this.filter[input];
  }

  getBadgeclass(processingStatus: any) {
    switch (processingStatus) {
      case 'OK':
        return 'badgeok';
      case 'COMPLETED':
        return 'badgecomplete';
      case 'FORMATTING_ERROR':
      case 'DELIVERY_ERROR':
      case 'OTHER_ERROR':
      case 'FAILED':
        return 'badgeerror';
      case 'PENDING':
      case 'PROCESSING':
      case 'STARTED':
        return 'badgeother';
      default:
        return 'badgeother';
    }
  }
}
