<form [formGroup]="commContentGroupForm" novalidate>
  <div class="modal-header">
    <h1>{{ contentGroup ? "Change Content Group" : "Add Content Group" }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Content Group <span style="color: red">*</span></label>
        <span>(Required)</span>
      </div>
      <ng-select
        placeholder="Select Content Group"
        formControlName="contentGroupId"
        [items]="contentGroups"
        bindLabel="name"
        bindValue="id"
        name="contentGroupId"
        [clearable]="false"
      ></ng-select>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn-pop-cancel" type="button" (click)="cancel()" title="Cancel">CANCEL</button>
    <button class="btn-save" type="submit" [disabled]="!commContentGroupForm.valid" (click)="ok()" title="Add">
      {{ contentGroup ? "CHANGE" : "ADD" }}
    </button>
  </div>
</form>
