<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox">
        <div class="ibox-title">
          <h2 class="fullname">Survey status</h2>
        </div>
        <div class="ibox-content">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="filters_container searchless_filter">
                  <div class="right_container1 segment_popover_container">
                    <button class="filter-btn" (click)="exportCSV()">EXPORT</button>
                    <button
                      *ngIf="isLiveAgentManager"
                      class="filter-btn segment_popover_btn"
                      placement="bottom"
                      #popOver="ngbPopover"
                      [ngbPopover]="popContent"
                      [autoClose]="'outside'"
                    >
                      SELECT SEGMENT ({{ segments.length }})
                    </button>
                    <button class="filter-btn" (click)="FilterModel()">
                      FILTERS <mat-icon class="ml-2">filter_list</mat-icon>
                    </button>
                  </div>
                </div>
                <app-filter-tags
                  *ngIf="searchFilters"
                  isPopup="true"
                  [(filterButton)]="filterButton"
                  [(searchFilters)]="searchFilters"
                  [(appliedFilters)]="appliedFilters"
                ></app-filter-tags>
              </div>
              <div class="row double-scroll">
                <table class="table table-pointable table-bordered infinite-table">
                  <thead>
                    <tr>
                      <th>First name</th>
                      <th>Last name</th>
                      <th>Assessment date</th>
                      <th>Identifier</th>
                      <th>Survey results</th>
                      <th *ngIf="isLiveAgentManager">CompletedBy</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let survey of liveAgentSurveys">
                      <td>
                        <a href="/members/{{ survey.memberId }}">{{ survey.memberFirstName }}</a>
                      </td>
                      <td>{{ survey.memberLastName }}</td>
                      <td>{{ survey.submittedAt }}</td>
                      <td>{{ survey.primaryInstrumentNumber }}</td>
                      <td>{{ survey.surveyStatus }}</td>
                      <td *ngIf="isLiveAgentManager">
                        {{ survey.completedByFirstName }} {{ survey.completedByLastName }}
                      </td>
                    </tr>
                    <tr app-is-there-more [numColumns]="numColumns" [newLengthEvent]="newLengthEvent"></tr>
                  </tbody>
                </table>
              </div>
              <app-add-more-data-to-list (onScroll)="getSurveys(true)" [lock]="lock" [isLoading]="isLoading">
              </app-add-more-data-to-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #popContent>
  <app-segment-tree-modal
    maxWidth="true"
    isInFilter="false"
    class="w-100"
    [dropdown]="false"
    [allowMultiple]="true"
    [(selectedSegments)]="segments"
    (selectedSegmentsChange)="initNewSearch()"
  ></app-segment-tree-modal>
</ng-template>
