<div class="wrapper wrapper-content" *ngIf="communication">
  <div class="col-lg-12 com-header header-container">
    <h1 class="width-100">
      <span class="cursor-pointer" (click)="selectComm(communication)">
        <fa-icon [icon]="getIcon(communication)"></fa-icon>
        {{ communication.name }}
      </span>
    </h1>
  </div>
  <app-communication-parent-tree
    (selectedCommunicationChanges)="selectComm($event)"
    [selectedCommunication]="selectedComm"
    [rootComm]="communication"
    (communicationChanged)="communicationChanged($event)"
    (addedCommunicationChanges)="getCommunicationStructure()"
  >
  </app-communication-parent-tree>
  <div class="col-lg-12 com-header" style="margin: 0px !important">
    <h2 class="com-title">{{ selectedComm?.name }}</h2>
    <div *ngIf="selectedComm" class="d-flex align-items-center">
      <span class="com-status">Status:</span>&nbsp;&nbsp;
      <!-- <span class="com-badge rounded-pill mr-3" [ngClass]="statusHelper[selectedComm.status]">{{
        selectedComm.status
      }}</span> -->
      <div class="new-badge rounded-pill mr-3" [ngClass]="statusHelper[selectedComm.status]">
        <span class="badge-statusmsg">{{ selectedComm.status }}</span>
      </div>
<!--      <button class="btn btn-outline-primary btn-sm" *ngIf="appDataService.isProgramManager" (click)="test()">Test</button>-->
      <button class="btn btn-outline-primary btn-sm" (click)="edit(selectedComm)">
        <fa-icon [icon]="faPencilAlt"></fa-icon>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12" *ngIf="selectedComm">
      <div>
        <div class="ibox-content-new" style="border: none">
          <div class="tabs-container">
            <mat-tab-group
              ngbNav
              #nav="ngbNav"
              class="remove-border-bottom nav-tabs"
              animationDuration="0ms"
              (selectedTabChange)="tabClick($event)"
              mat-stretch-tabs="false"
            >
              <mat-tab label="CONFIGURATION">
                <app-communication-configuration-tab
                  [selectedComm]="selectedComm"
                  (rulesChanged)="rulesChangedRefreshCommunication()"
                >
                </app-communication-configuration-tab>
              </mat-tab>
              <mat-tab label="CONTENT">
                <ng-template matTabContent>
                  <app-communication-content-tab
                    [selectedComm]="selectedComm"
                    deliveryProtocolName="{{ getDeliveryProtocolName() }}"
                    [endpointType]="
                      selectedComm.communicationDeliveryProtocols.length > 0
                        ? selectedComm.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name
                        : ''
                    "
                  >
                  </app-communication-content-tab>
                </ng-template>
              </mat-tab>
              <mat-tab label="ATTRIBUTES">
                <app-communication-attributes-tab [commId]="commId"></app-communication-attributes-tab>
              </mat-tab>
              <mat-tab label="ATTACHMENTS">
                <app-communication-attachments-tab [commId]="commId"></app-communication-attachments-tab>
              </mat-tab>
              <mat-tab label="HISTORY" (click)="tabClick($event)">
                <app-communication-history-tab [commId]="commId" [tabIndex]="tabIndex"></app-communication-history-tab>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
