<form [formGroup]="newIntegrationForm">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Name <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input formControlName="name" type="text" class="form-control" name="name" />
          <!-- <div class="mt-sm" *ngIf="!newIntegrationForm.controls.name.valid">
            <small class="text-danger">Please input name</small>
          </div> -->
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Customer name <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <ng-autocomplete
            #customerName
            formControlName="customerName"
            focusFirst="true"
            (selected)="selectCustomer($event)"
            searchKeyword="name"
            [data]="customers"
            keyProperty="name"
            historyIdentifier="states"
            [itemTemplate]="stateTemplate"
            [notFoundTemplate]="notFoundTemplate"
          >
          </ng-autocomplete>
          <!--<select formControlName="customerName" class="form-control">
            <option *ngFor="let customer of customers" value="{{ customer.name }}">{{ customer.name }}</option>
          </select>-->
          <!-- <div class="mt-sm" *ngIf="!newIntegrationForm.controls.customerName.valid">
            <small class="text-danger">Select customer name</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>File type <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <select formControlName="fileType" class="form-control">
            <option *ngFor="let fileType of fileTypes" value="{{ fileType }}">{{ fileType }}</option>
          </select>
          <!-- <div class="mt-sm" *ngIf="!newIntegrationForm.controls.fileType.valid">
            <small class="text-danger">Select file type</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>File layout schema</label>
          <input formControlName="fileLayoutSchema" type="text" class="form-control" name="fileLayoutSchema" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>File name pattern <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input formControlName="fileNamePattern" type="text" class="form-control" name="fileNamePattern" />
          <!-- <div class="mt-sm" *ngIf="!newIntegrationForm.controls.fileNamePattern.valid">
            <small class="text-danger">Please input a file name pattern</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Record delimiter</label>
          <input formControlName="recordDelimiter" type="text" class="form-control" name="recordDelimiter" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Status <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <select formControlName="status" class="form-control">
            <option *ngFor="let status of statuses" value="{{ status }}">{{ status }}</option>
          </select>
          <!-- <div class="mt-sm" *ngIf="!newIntegrationForm.controls.status.valid">
            <small class="text-danger">Select status</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Date format</label>
          <input formControlName="dateFormat" type="text" class="form-control" name="dateFormat" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Field count <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input formControlName="fieldCount" type="text" class="form-control" name="fieldCount" />
          <!-- <div class="mt-sm" *ngIf="!newIntegrationForm.controls.fieldCount.valid">
            <small class="text-danger">Please input a field count</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Activation realm</label>
          <input formControlName="activationRealm" type="text" class="form-control" name="activationRealm" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Post upload job name</label>
          <input formControlName="postUploadJobName" type="text" class="form-control" name="postUploadJobName" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Preprocessing job</label>
          <input formControlName="preprocessJob" type="text" class="form-control" name="preprocessJob" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Postprocessing job</label>
          <input formControlName="postprocessJob" type="text" class="form-control" name="postprocessJob" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Integration server</label>
          <ng-select [items]="servers" bindLabel="name" formControlName="server" [clearable]="true"></ng-select>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Integration key</label>
          <ng-select [items]="keys" bindLabel="name" formControlName="key" [clearable]="true"></ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Ignore header</label>
          <input formControlName="ignoreHeader" type="text" class="form-control" name="ignoreHeader" appNumbersOnly />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Ignore trailer</label>
          <input formControlName="ignoreTrailer" type="text" class="form-control" name="ignoreTrailer" appNumbersOnly />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Encoding charset</label>
          <input formControlName="encodingCharset" type="text" class="form-control" name="encodingCharset" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Record length</label>
          <input formControlName="recordLength" type="text" class="form-control" name="recordLength" appNumbersOnly />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="checkbox checkbox-success">
            <input
              formControlName="addsAndUpdates"
              id="addsAndUpdates"
              class="mr-2"
              type="checkbox"
              name="addsAndUpdates"
            />
            <label for="addsAndUpdates"> Import adds and updates only </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="checkbox checkbox-success">
            <input formControlName="autoImport" id="autoImport" class="mr-2" type="checkbox" name="autoImport" />
            <label for="autoImport"> Auto-import after stage </label>
          </div>
        </div>
      </div>
    </div>
    <!-- TODO: Needs a new check which happens when import type !== Organization -->
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="checkbox checkbox-success">
            <input
              formControlName="activateOnImport"
              id="activateOnImport"
              class="mr-2"
              type="checkbox"
              name="activateOnImport"
            />
            <ng-container>
              <label for="activateOnImport"> Activate on import/Auto-process after import </label>
            </ng-container>
            <!-- <ng-container *ngIf="newIntegrationForm.controls.importType.value !== 'Transaction'">
              <label for="activateOnImport"> Activate on import </label>
            </ng-container>
            <ng-container *ngIf="newIntegrationForm.controls.importType.value === 'Transaction'">
              <label for="activateOnImport"> Auto-process after import </label>
            </ng-container> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="checkbox checkbox-success">
            <input
              formControlName="acceptUploads"
              id="acceptUploads"
              class="mr-2"
              type="checkbox"
              name="acceptUploads"
            />
            <label for="acceptUploads"> Accept uploads </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons [submitText]="isEdit ? 'Save' : 'Add'" (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!newIntegrationForm.valid"></app-modal-buttons>
  </div>
</form>

<ng-template #stateTemplate let-item>
  <a [innerHTML]="item.name"></a>
</ng-template>

<ng-template #notFoundTemplate let-notFound>
  <div [innerHTML]="notFound"></div>
</ng-template>
